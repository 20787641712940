/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./admin.component";
var styles_AdminComponent = [i0.styles];
var RenderType_AdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "col-xs-12 col-md-12 col-xs-12 col-sm-12 nopadding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "div", [["aria-label", "Basic example"], ["class", "btn-group col-md-12 col-xs-12 col-sm-12"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "button", [["class", "btn btn-primary"], ["routerLink", "./marketdata"], ["routerLinkActive", "active"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Market Data"])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "button", [["class", "btn btn-primary"], ["routerLink", "./settings"], ["routerLinkActive", "active"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(11, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Settings"])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "button", [["class", "btn btn-primary"], ["routerLink", "./sourcemanagement"], ["routerLinkActive", "active"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, [[5, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(17, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Source Management"])), (_l()(), i1.ɵeld(21, 0, null, null, 5, "button", [["class", "btn btn-primary"], ["routerLink", "./authorizations"], ["routerLinkActive", "active"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, [[7, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(23, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, ["Authorizations"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(29, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "./marketdata"; _ck(_v, 4, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "./settings"; _ck(_v, 10, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 11, 0, currVal_3); var currVal_4 = "./sourcemanagement"; _ck(_v, 16, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 17, 0, currVal_5); var currVal_6 = "./authorizations"; _ck(_v, 22, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 23, 0, currVal_7); _ck(_v, 29, 0); }, null); }
export function View_AdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdminComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = i1.ɵccf("app-admin", i3.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
