import { variable } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';



@Injectable()
export class DashboardLineChartService {

    constructor(
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }



    //line axis
    getLineXandY(vis, multiFormat, header) {
       
        let yType = "notnumeric";
        let xType = "notnumeric";
        let scales = [];
        xType = vis.dashboardInitTypesScalesService.getVariableTypes(vis.data, header, vis);
        yType = vis.dashboardInitTypesScalesService.getVariableTypes(vis.dataFiltered, vis.yVariable, vis); 
      

        let types = [{name: "x", axisName: "xAxis", type: xType, variable: vis.xVariable, axisOrientation: d3.axisBottom(), dividor: vis.xDividor}, {name: "y", axisName:"yAxis", type: yType, variable: vis.yVariable, axisOrientation: d3.axisLeft(),dividor: vis.yDividor}]
       
        for (var k in types) {
            if (types[k].type === "date" && vis.dateBucketing != "Quarter") {
                scales.push({graphType:"line", name: types[k].name, axisName: types[k].axisName, type: d3.scaleUtc(), scaleType:"time", range: vis.width, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor},)
              
                for (var i in vis.dataFiltered) {
                    vis.dataFiltered[i][vis.xVariable] = new Date(vis.dataFiltered[i][vis.xVariable]);
                }
                
                if (vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                    vis.dataFiltered.sort((a, b) => (a.Categorie > b.Categorie) ? 1 : ((b.Categorie > a.Categorie) ? -1 : 0));
                }
                vis.dataFiltered = vis.dataFiltered.filter(item => item[vis.xVariable].toString() != "Invalid Date");
            }
            else if (types[k].type === 'numeric') {
                let range = vis.height;
                if (types[k].name === "x") {
                    range = vis.width;
                }
                scales.push({graphType:"line", name: types[k].name,axisName: types[k].axisName, type: d3.scaleLinear(), scaleType: "linear", range: range, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor},)
            }
            else {
             
                scales.push({graphType:"line", name: types[k].name, axisName: types[k].axisName, type: d3.scaleBand(), scaleType: "band", range: vis.width, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor},)
            }
        }
        if (vis.dateBucketing === "Quarter") {
           
                vis.dataFiltered.sort((a, b) => (a.Categorie < b.Categorie) ? 1 : ((b.Categorie < a.Categorie) ? -1 : 0));

        }
        vis.dashboardInitTypesScalesService.setScales(scales, vis, vis.dataFiltered)

        vis.bisectDate = d3.bisector(function (d) { return d[vis.xVariable]; }).left;

        return vis;
    }


    setLine(vis) {

        

    /*    vis.g1
            .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr("clip-path", "url(#"+vis.clip+")") */

        vis.g1.selectAll(".lineChart").remove();
        vis.g1.selectAll(".dot").remove();
        vis.g1
           // .attr("clip-path", "")

        vis.g1.append("path")
            // 27/03/2019 .attr("class", "line")
            .attr("class", "lineChart")
            .attr("fill", "none")
            .attr("stroke", vis.color)
            .attr("stroke-width", "1px");

        var dots = 'dots';
        let bandwidth = 0;
        let header = vis.dashboardChartService.getHeader(vis);
        vis.dataFiltered = vis.dashboardService.changeNullValues(vis.xVariable, vis.dataFiltered)
        if (vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis) && vis.dateBucketing != 'Quarter') {
            vis.dataFiltered.sort((a, b) => (a.Categorie > b.Categorie) ? 1 : ((b.Categorie > a.Categorie) ? -1 : 0));
        }
        else if (vis.dateBucketing === "Quarter"  ) {
            vis.dataFiltered.sort((a, b) => (a.Categorie < b.Categorie) ? 1 : ((b.Categorie < a.Categorie) ? -1 : 0));
            bandwidth = vis.x.bandwidth()/2;
        }
        else if (!vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis) && vis.dashboardInitTypesScalesService.getVariableTypes(vis.data, header, vis) != "numeric") {
            bandwidth = vis.x.bandwidth()/2;
        }


        var line = d3.line()
            .x(function (d, i) {
               /* if (isNaN(vis.x(d[vis.xVariable]))) {
                    return null;
                }*/
                    return vis.x(d[vis.xVariable]) +bandwidth;
            })
            .y(function (d, i) {
                if (isNaN(vis.y(d[vis.yVariable]) || vis.y(d[vis.yVariable])< vis.y.domain()[0] || vis.y(d[vis.yVariable] > vis.y.domain()[1]))) {
                    return vis.y.domain()[0];
                }
                    return vis.y(d[vis.yVariable])
               // }


                ;
            })
            .curve(d3.curveMonotoneX);

        //27/03/2019 vis.g1.select(".line")
        vis.g1.select(".lineChart")
            .transition(vis.t)
            .attr("d", line(vis.dataFiltered));

        vis[dots] = vis.g1.selectAll("dot")
            .data(vis.dataFiltered)
            .enter().append("circle")
            .attr('class', "dot")
            .attr('id', function (d, index) {
                return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + index
            })
            .attr("r", 4.5)
            .attr("cx", function (e) {
              /*  if (isNaN(vis.x(e[vis.xVariable]))) {
                    return null;
                }*/
                return vis.x(e[vis.xVariable]) + bandwidth; })
            .attr("cy", function (e) { 
                if (isNaN(vis.y(e[vis.yVariable]))) {
                    return null;
                }
                return vis.y(e[vis.yVariable]) })
            .attr("fill", vis.color)
            .attr("stroke", "white")

        return vis;
    }


}