import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class MarketDataService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
    ) { }

    url = this.appService.getUrl();


    //all the returned quotes
    quotes: any[] = [];
    //automatically updates the FX quotes
    quotesChange = new Subject<any>();
    //all the principal quotes
    principalQuotes: any[] = [];
    //automatically updates the principal quotes
    principalQuotesChange = new Subject<any>();
    //all the quotes
    allQuotes: any[] = [];
    //automatically updates allthe quotes
    allQuotesChange = new Subject<any>();
    //all the currency pairs
    allCurrencyPairs: any[] = [];
    //automatically updates allthe quotes
    allCurrencyPairsChange = new Subject<any>();

    //all the principal currency pairs
    principalCurrencyPairs: any[] = [];
    //automatically updates allthe quotes
    principalCurrencyPairsChange = new Subject<any>();
    //Fetch FX a nd view From DB buttons enabled or not
    displaySelectionFX: boolean = false;
    //automatically updates whether the Fetch FX and view From DB button should be enabled
    displaySelectionFXChange = new Subject<boolean>();

    //loads all the quotes/ or the principal quotes
    //called in market data component
    getAdminMarketDataQuotes(factor, varFile) {
        this.appService.startSpin();
        const url = this.url;
        const webservice = "AdminMarketGetQuotes";
        const completeUrl = url + webservice;


        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("factor", factor)
            .set("fileQuotes", varFile)
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                //response = response.replace(/[()]/g, '');
                response= response.substring(1,response.length-1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    if (varFile === "Principal") {
                        this.principalQuotes = [];
                        this.principalCurrencyPairs = [];

                        let quotes = response.wfStructure;

                        for (var i in quotes) {
                            var principalNewdata = {};
                            principalNewdata = quotes[i].name;
                            this.principalQuotes.push(principalNewdata);
                        }

                        for (var i in quotes) {
                            for (var j = 0; j < this.allQuotes.length; j++) {


                                if (quotes[i].name != this.allQuotes[j].name) {

                                    var principalNewdata2 = {
                                        name: quotes[i].name + this.allQuotes[j].name
                                    };

                                    this.principalCurrencyPairs.push(principalNewdata2);
                                }
                            }
                        }

                        this.principalQuotesChange.next(this.principalQuotes);
                        this.principalCurrencyPairsChange.next(this.principalCurrencyPairs)

                    }
                    else {
                        this.allQuotes = [];
                        this.allCurrencyPairs = [];


                        let quotes = response.wfStructure;
                        // create all  the possible currency pairs
                        for (var m in quotes) {
                            var newdata2 = {
                                name: quotes[m].name
                            };

                            this.allQuotes.push(newdata2);

                            for (var n in quotes) {
                                var newdata = {
                                    name: quotes[m].name + quotes[n].name
                                };

                                if (quotes[m].name != quotes[n].name) {
                                    this.allCurrencyPairs.push(newdata)
                                }
                            };
                        };
                        //  this.allQuotes = response.wfStructure;
                        this.allQuotesChange.next(this.allQuotes);
                        this.allCurrencyPairsChange.next(this.allCurrencyPairs)
                        this.getAdminMarketDataQuotes("FX", "Principal")
                    }

                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //returns all the quotes
    //called in view from db component
    returnAllCurrencyPairs() {
        this.appService.startSpin();
        this.allCurrencyPairsChange.next(this.allCurrencyPairs);
        //return this.allCurrencyPairs;
    }

    //returns all the principal quotes
    //called in view from db component
    returnPrincipalCurrencyPairs() {
        return this.principalCurrencyPairs;
    }

    //sets whether the fetch fx and view from db buttons are enabled if selection parameters are set to FX and cash
    //called in selection component
    setSelectionFX(param) {
        this.displaySelectionFX = param;
        this.displaySelectionFXChange.next(this.displaySelectionFX);
    }

    fetchFXData(liveHistorical, date, principal, provider) {
        this.appService.startSpin();
        let url;
        if (liveHistorical === true) {
            //url = 'http://apilayer.net/api/live?access_key=7aceced9d298bc54060f5431edbb2663&format=1';
            url = 'http://apilayer.net/api/live?access_key=G9KNGjUNZoaScfiHqta71UEcWtdzGneG&format=1';
        }
        else {
            var datePipe = new DatePipe('en-US');
            let newdate = datePipe.transform(date, 'yyyy-MM-dd');
            //url = 'http://apilayer.net/api/historical?access_key=7aceced9d298bc54060f5431edbb2663&date=' 
            url = 'https://api.apilayer.com/currency_data/historical?apikey=G9KNGjUNZoaScfiHqta71UEcWtdzGneG&date=' + newdate + '&format=1';
        }


        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.get(url)
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                if (response.success === true) {
                    var fetchData = response.quotes;
                    this.quotes = [];

                    let USDUSDexists = false;
                    for (var m in fetchData) {
                        if (fetchData[m] === "USDUSD") {
                            let USDUSDexists = true;
                            break;
                        }
                    }
                    if (!USDUSDexists) {
                        fetchData["USDUSD"] = 1;
                    }


                    if (liveHistorical === true) {
                        for (var i in fetchData) {
                            var quote = i.substring(3, 6);

                            if (this.principalQuotes.indexOf(quote) > -1) {

                                for (var k in fetchData) {
                                    var quote2 = k.substring(3, 6);

                                    //        if ($scope.principalQuotes.indexOf(l) > -1) {
                                    var newdata = { quote: "", value: "", date: "", source: "" };
                                    newdata.quote = quote + quote2;

                                    if (quote !== quote2) {
                                        newdata.value = ((fetchData[k]) / (fetchData[i])).toFixed(5);
                                        var datePipe = new DatePipe('en-US');
                                        newdata.date = datePipe.transform(date, 'yyyy/MM/dd');
                                        newdata.source = provider;
                                        this.quotes.push(newdata);
                                    }
                                    //  }// end for   
                                }
                            }
                        }
                        this.quotesChange.next(this.quotes)
                    }
                    else {
                        for (var i in fetchData) {
                            var quote = i.substring(3, 6);

                            for (var j in fetchData) {
                                var newdata2 = { quote: "", value: "", date: "", source: "" };
                                newdata2.quote = quote + j.substring(3, 6);

                                if (quote != j.substring(3, 6)) {
                                    newdata2.value = ((fetchData[j] / fetchData[i]).toFixed(5));
                                    var datePipe2 = new DatePipe('en-US');
                                    newdata2.date = datePipe2.transform(date, 'yyyy/MM/dd');
                                    newdata2.source = provider;
                                    this.quotes.push(newdata2);


                                }
                            }
                        }

                        this.quotesChange.next(this.quotes)
                    }

                    console.log(this.quotes)
                }

                else {
                    this.quotes = [];
                    this.quotesChange.next(this.quotes);
                    this.appService.showMessage('Error', response.error.info)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    //saves all the quotes in database
    //called in market data results component
    saveFXData() {
        this.appService.startSpin();
        const url = this.url;
        const webservice = "AdminMarketDataSave";
        const completeUrl = url + webservice;

        let saveFXData = [];

        for (var i in this.quotes) {
            if (this.quotes[i].quote.substring(0, 3) === 'USD') {
                var datePipe = new DatePipe('en-US');

                var newdata = {
                    ticker: this.quotes[i].quote,
                    stampDate: datePipe.transform(this.quotes[i].date, 'yyyyMMdd'),
                    source: this.quotes[i].source,
                    price: this.quotes[i].value,
                };
                saveFXData.push(newdata);

            }
        }


        var vartab = JSON.stringify(saveFXData);
        var saveFXDataTab = '{"ticker":' + vartab + '}';
        let direction = "MID"

        var riskfactor = 'FX';
        var openValue = "false";
        let error = false;
        //create new http params

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("factor", riskfactor)
            .set("direction", direction)
            .set("openValue", openValue)
            .set("tickerValueList", saveFXDataTab)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                //response = response.replace(/[()]/g, '');
                response= response.substring(1,response.length-1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText)
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }

    //saves all the quotes in database
    //called in market data results component
    viewFXDataFromDB(formdata, provider, selectedCurrencies) {

        this.appService.startSpin();
        const url = this.url;
        const webservice = "AdminMarketDataLoad";
        const completeUrl = url + webservice;

        let allSelected = true;
        let viewFXData = [];
        let principalQuotesView = [];

        if (selectedCurrencies.length != this.principalCurrencyPairs.length) {
            allSelected = false;
        }

        for (var i in selectedCurrencies) {
            if (selectedCurrencies[i].name.substring(0, 3) === 'USD') {
                viewFXData.push(selectedCurrencies[i].name);

            }
            else if (allSelected === false) {
                if (principalQuotesView.indexOf(selectedCurrencies[i].name.substring(0, 3)) === -1) {
                    principalQuotesView.push(selectedCurrencies[i].name.substring(0, 3))
                }
                if ((principalQuotesView.indexOf(selectedCurrencies[i].name.substring(3, 6)) === -1) && (selectedCurrencies[i].name.substring(3, 6) != 'USD')) {
                    principalQuotesView.push(selectedCurrencies[i].name.substring(3, 6))
                }
            }
        }

        if (allSelected === false) {
            for (var j in principalQuotesView) {
                if (viewFXData.indexOf('USD' + principalQuotesView[j]) === -1) {
                    viewFXData.push('USD' + principalQuotesView[j])
                }
            }
        }


        var vartab = JSON.stringify(viewFXData)
        let viewFXDataFromDB = '{"ticker":' + vartab + '}';
        var datePipe = new DatePipe('en-US');
        let startDate = datePipe.transform(formdata.startDate, 'yyyyMMdd');
        let endDate = datePipe.transform(formdata.endDate, 'yyyyMMdd');
        let riskfactor = "FX";
        let direction = "MID";
        let openValue = "false";
     
        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("factor", riskfactor)
            .set("direction", direction)
            .set("openValue", openValue)
            .set("ticker", viewFXDataFromDB)
            .set("startDate", startDate)
            .set("endDate", endDate)
            .set("sourceProvider", provider)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                //response = response.replace(/[()]/g, '');
                response= response.substring(1,response.length-1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    console.log(response);
                    let activeData = [];
                    let activeViewData = [];
                    let dates = [];

                    if (response.tableRows.length === 0) {
                        this.appService.showMessage("Error", "No results found for these dates. Please show another date.")
                    }
                    else {
                        // display all the results
                        //
                        for (var i = 0; i < response.tableRows.length; i++) {
                            let ndata = response.tableRows[i].stampDate;
                            var newdata = {
                                quote: response.tableRows[i].ticker,                          
                                date: ndata.substring(0, 4) + '-' + ndata.substring(4, 6) + '-' + ndata.substring(6),
                                value: (response.tableRows[i].value).toFixed(5),
                                source: response.tableRows[i].source

                            };
                         
                            activeData.push(newdata);

                            if (dates.indexOf(newdata.date) === -1) {
                                dates.push(newdata.date);
                                var newdata2 = {
                                    quote: 'USDUSD',
                                    value: 1,
                                    source: newdata.source,
                                    date: newdata.date,
                                };    
                                activeData.push(newdata2);
                            }
                        }


                        for (var m in activeData) {
                            var quote = activeData[m].quote.substring(0, 3);
                            var quote1 = activeData[m].quote.substring(3, 6);
                            for (var j in activeData) {
                                var quote2 = activeData[j].quote.substring(3, 6);

                                if ((quote1 != quote2) && (activeData[j].date === activeData[m].date)) {
                                    if (allSelected === false) {
                                        for (var k in selectedCurrencies) {
                                            if (selectedCurrencies[k].name === (quote1 + quote2)) {
                                                var newdata3 = {
                                                    quote: quote1 + quote2,
                                                    date: activeData[j].date,
                                                    value: ((activeData[j].value) / (activeData[m].value)).toFixed(5),
                                                    source: activeData[m].source
                                                };
                                               
                                                activeViewData.push(newdata3);
                                            }
                                        }
                                    }
                                    else {

                                        if (formdata.currency === false) {
                                            var newdata4 = {
                                                quote: quote1 + quote2,
                                                date : activeData[j].date,
                                                value : ((activeData[j].value) / (activeData[m].value)).toFixed(5),
                                                source : activeData[m].source

                                            };
                                          
                                            activeViewData.push(newdata4);

                                        }
                                        else {
                                            if (this.principalQuotes.indexOf(quote1) > -1) {
                                                if (quote1 !== quote2) {
                                                    var newdata5 = {
                                                        quote:quote1 + quote2,
                                                        value : ((activeData[j].value) / (activeData[m].value)).toFixed(5),
                                                        date : activeData[j].date,
                                                        source : activeData[m].source,
                                                    };
                                                   
                                                    activeViewData.push(newdata5);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        this.quotes = activeViewData;
                        this.quotesChange.next(this.quotes);
                        this.appService.showMessage('Success', response.statusText)

                    }

                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }



}