import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AppService } from "src/app/app.service";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { SharedGeneralFunctionsService } from "src/app/shared/shared-general-functions.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";

@Injectable()
export class AnalysisMatchingService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
        private workflowNameService: WorkflowNameService,
        private sharedService: SharedInitializationService,
        private sharedFunctionService: SharedGeneralFunctionsService) {
    }

    url = this.appService.getUrl();

    //matching
    matchingViewChange: { [index: string]: Subject<any> } = {};
    selectedMatchingView: {} = {};
    matchingDetailsButtonDisabledChange: { [index: string]: Subject<boolean> } = {};
    matchingDetailsViewChange: { [index: string]: Subject<boolean> } = {};
    matchingDetailsScrollChange: { [index: string]: Subject<boolean> } = {};
    matchingDetailsBookmarkChange: { [index: string]: Subject<boolean> } = {};
    viewMatchingDetailsDataChange : { [index: string]: Subject<any> } = {};
    matchingData = {};

    initTabChanges(tabs) {
      for (var i in tabs) {
        this.matchingViewChange[tabs[i].name] = new Subject<any>();
        this.selectedMatchingView[tabs[i].name]= "cardabel_rec";
        this.matchingDetailsButtonDisabledChange[tabs[i].name] = new Subject<boolean>()
        this.matchingDetailsViewChange[tabs[i].name] = new Subject<boolean>();
        this.matchingDetailsScrollChange[tabs[i].name] = new Subject<boolean>();
        this.matchingDetailsBookmarkChange[tabs[i].name] = new Subject<boolean>();
        this.viewMatchingDetailsDataChange[tabs[i].name] = new Subject<any>();
        this.matchingData[tabs[i].name] = [];
      }

    }

    
  //allows to change the matching group view
  //called in matching subtable selected menu
  // sets in analysisGrid
  changeMatchingView(selectedMatchingView, tab) {
    this.selectedMatchingView[tab]= selectedMatchingView;
    this.matchingViewChange[tab].next(this.selectedMatchingView[tab]);
  }

  //allows to set the param to choose whether the matching details button is disabled or not
  //called in analysis grid
  // sets in matching subtable selected menu
  changeMatchingDisableButtonDetails(param, tab) {
    this.matchingDetailsButtonDisabledChange[tab].next(param);
  }

  //allows to display matching details area view 
  //called in matching subtable selected menu
  //sets in analysis grid
  setMatchingDetailsView(param, tab) {
    this.matchingDetailsViewChange[tab].next(param);
  }

   //allows to display matching details scroll (if tables are scrolled together)
  //called in matching subtable selected menu
  //sets in analysis grid
  setMatchingDetailsScroll(param, tab) {
    this.matchingDetailsScrollChange[tab].next(param);
  }

   //allows to have the same bookmark for both tables
  //called in matching subtable selected menu
  //sets in analysis grid
  setMatchingDetailsBookmark(param, tab) {
    this.matchingDetailsBookmarkChange[tab].next(param);
  }
}