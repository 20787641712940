import { HttpClient, HttpClientJsonpModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminMarketGetFactorSource } from './admin-market-get-factor-source.model';
import { AdminMarketSetFactorSource } from './admin-market-set-factor-source.model';
import { AppService } from 'src/app/app.service';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class MarketDataSourceService {
    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ){}

    marketDataSource:string;
    //this is for the market data source component, it automatically updates the market data source
    marketDataSourceChange =new Subject<string>();
    url= this.appService.getUrl();

    //Gets all the sources for the existing factors.
    //called in market data source component
    getMarketDataSource() {
        const url = this.url;
        const webservice = "AdminMarketGetFactorSource";
        const completeUrl = url+webservice;
        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.marketDataSource = response.tableRows[0].Source;
                this.marketDataSourceChange.next(this.marketDataSource);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });

    }

    //Saves the source for the factor passed as an argument.
    //called in market data source component
    setMarketDataSource(source) {
        const url = this.url;
        const webservice = "AdminMarketSetFactorSource";
        const completeUrl = url+webservice;

        this.appService.startSpin();

       let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
       .set("factor","FX")
       .set("source", source); //Create new HttpParams
       
       const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
       return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
         .pipe(
           catchError(this.appService.handleError)
         )
         .subscribe((response:any) => {
           console.log(response);
           //response = response.replace(/[()]/g, '');
           response= response.substring(1,response.length-1);
           response = JSON.parse(response);
           if (response.statusCode > -1 ) {
            this.appService.showMessage('Success', response.statusText)          
           }
           else {
             this.appService.showMessage('Error', response.statusText)          
           }
           this.appService.stopSpin();
         }, (error) => {
           this.appService.showMessage('Error', error.statusText)          
           this.appService.stopSpin();
         });
     
    }
    

}