import { Component, Input, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { WorkflowListModel } from 'src/app/shared/models/gui/workflow-list-model';
import { FormControl, Validators } from '@angular/forms';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';


@Component({
  selector: 'cardabel-topics-dashboard-item',
  templateUrl: './topics-dashboard-item.component.html',
  styleUrls: ['./topics-dashboard-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopicsDashboardItemComponent implements OnInit, OnDestroy {

  @Input() selectedTopic: string = "";
  @Input() selectedTopicData: any;

  workflows: WorkflowListModel[];
  filteredWorkflows: any[] = [];
  workflowListSubscription = new Subscription();

  //this is to set the first and second group fields so that they are automatically updated
  groupField1: string = "";
  groupField2: string = "";
  groupField3: string = "";
  groupField4: string = "";
  topicData: any[];

  //this is the variable used to create the first group (i.e first by sources)
  rowGroupMetadata: any;
  //this is the variable used to create the second group (i.e second by workflows)
  secondRowGroupMetadata: any;
  //this allows to display the second group row
  displaySecondRow: any = {};
  //this allows to display the elements that are in the first group and second group
  displayThirdRow: any = {};

  //this is the variable used to create the second group (i.e second by tables)
  thirdRowGroupMetadata: any;
  //this allows to display the elements that are in the first group, second group  and  third group
  displayForthRow: any = {};

  constructor(private sharedInitService: SharedInitializationService,
    private cd: ChangeDetectorRef,
    private appService: AppService, ) {

  }

  ngOnInit() {

    console.log("topicccccccccccccccccccccc");
    this.sharedInitService.getAuthorizedWorkflows("topicsDashboardItem");

    this.workflowListSubscription = this.sharedInitService.topicsDashboardItemWorkflowListChange
      .subscribe((workflowList: WorkflowListModel[]) => {
        this.workflows = [...workflowList];
        workflowList.sort(function (a, b) {
          if (a["WFSource"] < b["WFSource"]) { return -1; }
          else {
            if (a["WFName"] < b["WFName"]) { return -1; } else return 0;
          }
        }
        );

        this.topicData = [];
        for (var i in workflowList) {
          let index = 0;
          if (this.topicData.length != 0) {
            index = this.topicData[this.topicData.length - 1].index + 1;
          }

          if (workflowList[i].WFSource.length != 0) {
            this.setWorkflows(workflowList[i].WFSource, index, workflowList[i])
          }
          else {
            this.setWorkflows("No source", index, workflowList[i])
          }


        }
        console.log(this.topicData);
        this.rowGroupMetadata = {};
        this.groupField1 = "WFSource";
        this.groupField2 = "WFName";
        this.groupField3 = "WFTable";
        this.groupField4 = "WFSubTable";

        this.updateRowGroupMetaData("WFSource");

        this.setActive('WFSource');

        if (this.topicData.length == 0) {
          this.appService.showMessage('Warning', "no report available");
        }

        this.cd.detectChanges();

      })
  }
  setWorkflows(source, index, reportsData) {
    if (reportsData.WFName.length != 0) {
      for (var k in reportsData.WFTables) {
        var key = Object.keys(reportsData.WFTables[k])[0];
        
        var values = Object.values(reportsData.WFTables[k]);
        let allvalues;
        if (values[0]!=undefined){
           allvalues = values[0];
        }
        else{
          allvalues = values;
        }
        if (allvalues.length == 0){
          var newdata = {
            WFName: reportsData.WFName,
            WFSource: reportsData.WFSource,
            WFTable: key,
            WFSubTable: "",
            index: index

          };

          this.topicData.push(newdata);
        }
        else{
        for (var s in allvalues) {

          var newdata2 = {
            WFName: reportsData.WFName,
            WFSource: reportsData.WFSource,
            WFTable: key,
            WFSubTable: allvalues[s],
            index: index

          };

          this.topicData.push(newdata2);
        }
      }
      }

    }
    else {
      var newdata3 = {
        WFName: "",
        WFSource: reportsData.WFSource,
        WFTable: "",
        WFSubTable: "",
        index: index

      };

      this.topicData.push(newdata3);
    }
  }
  //this is to set an active button
  setActive = function (buttonName) {
    this.activeButton = buttonName;
  }

  //thisi is to give the active class to the active button
  isActive = function (buttonName) {
    return this.activeButton === buttonName;
  }
  //this is to create the first group(s) so that the data is proprely formatted and displayed in html 
  updateRowGroupMetaData(groupField) {

    if (this.topicData) {
      this.topicData = this.topicData.sort(this.fieldSorter([groupField]))
      for (let i = 0; i < this.topicData.length; i++) {
        let rowData = this.topicData[i];
        let username = rowData[groupField];
        this.displaySecondRow[rowData[groupField]] = true;

        if (i == 0) {
          this.rowGroupMetadata[username] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.topicData[i - 1];
          let previousRowGroup = previousRowData[groupField];
          if (username === previousRowGroup)
            this.rowGroupMetadata[username].size++;
          else
            this.rowGroupMetadata[username] = { index: i, size: 1 };
        }
      }
    }
    var keys = Object.keys(this.rowGroupMetadata);
    this.displayThirdRow = {};
    for (var o in keys) {
      this.displayThirdRow[keys[o]] = {};
    }
   
    console.log(this.displayThirdRow)
    this.updateSecondRowGroupMetaData();
    console.log(this.rowGroupMetadata);
    this.updateThirdRowGroupMetaData();
  }

  //this is to create the second group(s) according to the first group(s) so that the data is proprely formatted and displayed in html 
  updateSecondRowGroupMetaData() {

    if (this.topicData) {
      let preparedTopicData = [];
      let topicData = this.topicData.sort(this.fieldSorter([this.groupField1]));

      for (var m in topicData) {
        if (preparedTopicData.length === 0) {
          let newdata = {
            groupField1: topicData[0][this.groupField1],
            groupField2: []
          }
          preparedTopicData.push(newdata)
        }
        let groupField1ValueExists = false;
        for (var j in preparedTopicData) {
          if (preparedTopicData[j]["groupField1"] === topicData[m][this.groupField1]) {

            preparedTopicData[j]["groupField2"].push(topicData[m]);
            groupField1ValueExists = true;
            break;
          }
        }
        if (groupField1ValueExists === false) {
          let newdata = {
            groupField1: topicData[m][this.groupField1],
            groupField2: [this.topicData[m]]
          }

          preparedTopicData.push(newdata)

        }
      }


      console.log(preparedTopicData)

      this.secondRowGroupMetadata = {};
      for (let k = 0; k < preparedTopicData.length; k++) {
        let groupField1Value = preparedTopicData[k]["groupField1"]
        this.secondRowGroupMetadata[groupField1Value] = {};
        preparedTopicData[k].groupField2 = preparedTopicData[k].groupField2.sort(this.fieldSorter([this.groupField2]));

        for (let i = 0; i < preparedTopicData[k].groupField2.length; i++) {
          let rowData = preparedTopicData[k].groupField2[i];
          let groupField2 = rowData[this.groupField2];
          if (i == 0) {
            if (k == 0) {
              this.secondRowGroupMetadata[groupField1Value][groupField2] = { index: 0, size: 1 };
            }
            else {
              let index = this.secondRowGroupMetadata[preparedTopicData[k - 1]["groupField1"]][preparedTopicData[k - 1].groupField2[preparedTopicData[k - 1].groupField2.length - 1][this.groupField2]].index;
              let size = this.secondRowGroupMetadata[preparedTopicData[k - 1]["groupField1"]][preparedTopicData[k - 1].groupField2[preparedTopicData[k - 1].groupField2.length - 1][this.groupField2]].size;
              let newIndex = index + size;
              this.secondRowGroupMetadata[groupField1Value][groupField2] = { index: index + size, size: 1 };

            }

          }
          else {
            let previousRowData = preparedTopicData[k].groupField2[i - 1];
            let previousRowGroup = previousRowData[this.groupField2];
            if (groupField2 === previousRowGroup) {
              this.secondRowGroupMetadata[groupField1Value][groupField2].size++;

            }
            else {
              let index = this.secondRowGroupMetadata[groupField1Value][previousRowGroup].index;
              let size = this.secondRowGroupMetadata[groupField1Value][previousRowGroup].size;
              let newIndex = index + size;
              this.secondRowGroupMetadata[groupField1Value][groupField2] = { index: newIndex, size: 1 };

            }
          }
        }
      }

    }

    console.log(this.secondRowGroupMetadata)
    var keys = Object.keys(this.secondRowGroupMetadata);
    this.displayForthRow = {};
    for (var o in keys) {
      var keys2 = Object.keys(this.secondRowGroupMetadata[keys[o]]);
      this.displayForthRow[keys[o]] = {};
      for (var p in keys2){
        this.displayForthRow[keys[o]][keys2[p]] = {};
      }
    }
  }

  updateThirdRowGroupMetaData() {

    if (this.topicData) {
      let preparedTopicData = [];
      let topicData = this.topicData.sort(this.fieldSorter([this.groupField1]));

      for (var m in topicData) {
        if (preparedTopicData.length === 0) {
          let newdata = {
            groupField1: topicData[0][this.groupField1],
            groupField2: topicData[0][this.groupField2],
            groupField3: []
          }
          preparedTopicData.push(newdata)
        }
        let groupField2ValueExists = false;
        for (var j in preparedTopicData) {
          if ((preparedTopicData[j]["groupField1"] === topicData[m][this.groupField1]) && (preparedTopicData[j]["groupField2"] === topicData[m][this.groupField2])) {

            preparedTopicData[j]["groupField3"].push(topicData[m]);
            groupField2ValueExists = true;
            break;
          }
        }
        if (groupField2ValueExists === false) {
          let newdata = {
            groupField1: topicData[m][this.groupField1],
            groupField2: this.topicData[m][this.groupField2],
            groupField3: [this.topicData[m]]
          }

          preparedTopicData.push(newdata)

        }


      }

      console.log(preparedTopicData)
      let tem= 0;
      this.thirdRowGroupMetadata = {};
      for (let f = 0; f < preparedTopicData.length; f++) {
        let groupField1Value = preparedTopicData[f]["groupField1"]
        this.thirdRowGroupMetadata[groupField1Value] = {};
        for (let k=f ; k < preparedTopicData.length; k++) {
          tem = k;
          if (preparedTopicData[k]["groupField1"]!= groupField1Value) {
            tem = k-1;
            break;
          }
          let groupField2Value = preparedTopicData[k]["groupField2"]

          this.thirdRowGroupMetadata[groupField1Value][groupField2Value] = {};

          for (let i = 0; i < preparedTopicData[k].groupField3.length; i++) {
            let rowData = preparedTopicData[k].groupField3[i];
            let groupField3 = rowData[this.groupField3];
            if (i == 0) {
              if (k == 0) {
                this.thirdRowGroupMetadata[groupField1Value][groupField2Value][groupField3] = { index: 0, size: 1 };
              }
              else {
                let index = this.thirdRowGroupMetadata[preparedTopicData[k - 1]["groupField1"]][preparedTopicData[k - 1]["groupField2"]][preparedTopicData[k - 1].groupField3[preparedTopicData[k - 1].groupField3.length - 1][this.groupField3]].index;
                let size = this.thirdRowGroupMetadata[preparedTopicData[k - 1]["groupField1"]][preparedTopicData[k - 1]["groupField2"]][preparedTopicData[k - 1].groupField3[preparedTopicData[k - 1].groupField3.length - 1][this.groupField3]].size;
                let newIndex = index + size;
                this.thirdRowGroupMetadata[groupField1Value][groupField2Value][groupField3] = { index: index + size, size: 1 };

              }

            }
            else {
              let previousRowData = preparedTopicData[k].groupField3[i - 1];
              let previousRowGroup = previousRowData[this.groupField3];
              if (groupField3 === previousRowGroup) {
                this.thirdRowGroupMetadata[groupField1Value][groupField2Value][groupField3].size++;

              }
              else {
                let index = this.thirdRowGroupMetadata[groupField1Value][groupField2Value][previousRowGroup].index;
                let size = this.thirdRowGroupMetadata[groupField1Value][groupField2Value][previousRowGroup].size;
                let newIndex = index + size;
                this.thirdRowGroupMetadata[groupField1Value][groupField2Value][groupField3] = { index: newIndex, size: 1 };

              }
            }
          }

        }
        f=tem;
      }

    }

    console.log(this.thirdRowGroupMetadata)
  }
  //this is to sort an array of objects
  fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o = o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
  }).reduce((p, n) => p ? p : n, 0);
  //this is to destroy the subscriptions when component is destroyed
  ngOnDestroy() {
    this.workflowListSubscription.unsubscribe();
  }
}
