import { ChangeDetectorRef, ErrorHandler, Injectable, Injector } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

/* import * as StackTraceParser from 'error-stack-parser'; */


import { AppService } from 'src/app/app.service';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth-service';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
    constructor(
        private injector: Injector,

    ) { }

    handleError(error: Error | HttpErrorResponse) {
        const notificationService = this.injector.get(AppService);
        const confirmationService = this.injector.get(ConfirmationService);
        const router = this.injector.get(Router);

     //   const cd = this.injector.get(ChangeDetectorRef);

        if (error instanceof HttpErrorResponse) {
            // Server error happened      
            if (!navigator.onLine) {
                // No Internet connection
                return notificationService.showMessage('Error', 'No Internet Connection');
            }
            // Http Error
            return notificationService.showMessage('Error', 'No Internet Connection');
        } else {
            // Client Error Ha ppend 
            console.log("error " + error);
            //alert(error)
           // notificationService.showMessage('Error', error);
          //  router.navigate(['/error'], { queryParams: { error: error, fromTab: "workflow" } });
             confirmationService.confirm({
                message: "<span class='errorConfirm'>" + error+"<br><br> There was an error in the console. This might lead to other errors in the application. Do you wish to reload the application to ensure no other errors occur? </span>",
                header: 'Error in the application.',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                 //   location.reload()
                 router.navigate(['/login'])
                 window.location.reload();

                },
                reject: () => {

                }

            });
            //router.navigate(['/error'], { queryParams: {error: error} });
            console.error(error);
        }
        // Log the error anyway
        console.error(error);
    }
}
