import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-errors-component',
  templateUrl: './errors-component.component.html',
  styleUrls: ['./errors-component.component.css']
})
export class ErrorsComponent implements OnInit {

  routeParams;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.routeParams = this.activatedRoute.snapshot.queryParams;
  }

}
