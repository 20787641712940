<div class="cardabel-listbox-checkbox">

    <mat-checkbox [disabled]="disabledOption" (change)="changeItem(item)" [checked]="checked">
    </mat-checkbox>

  <span *ngIf="!strong" (click)="checkItem(item)" [cardabelTooltip]="getTooltip(option)" [cardabelTooltipPosition]="tooltipPosition" [cardabelTooltipDisabled]="tooltipDisabled" 
  [ngClass]="(disabledOption) ?'cardabel-listbox-checkbox-disabled':''">{{item}}</span>

  <span *ngIf="strong" (click)="checkItem(item)" [cardabelTooltip]="getTooltip(option)" [cardabelTooltipPosition]="tooltipPosition" [cardabelTooltipDisabled]="tooltipDisabled" 
  [ngClass]="(disabledOption) ?'cardabel-listbox-checkbox-disabled':''"><strong>{{item}}</strong></span>

</div>