import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings.component';
import { SettingsStartComponent } from './settings-start/settings-start.component';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { MarketDataSourceComponent } from './market-data-source/market-data-source.component';
import { LogoutTimeComponent } from './logout-time/logout-time.component';
import { TrueStatusTypeComponent } from './true-status-type/true-status-type.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { HistogramCellThresholdComponent } from './histogram-cell-threshold/histogram-cell-threshold.component';
import { LogsComponent } from './logs/logs.component';
//import { ReconciliationClassesComponent } from './reconciliation-classes/reconciliation-classes.component';
import { ScriptManagementComponent } from './script-management/script-management.component';
import { TopicsComponent } from './topics/topics.component';
import { NewtopicComponent } from './topics/newtopic/newtopic.component';
import { TopicComponent } from './topics/topic/topic.component';
import { TopicstartComponent } from './topics/topicstart/topicstart.component';
import {WorkflowTopicsComponent} from './topics/workflow-topics/workflow-topics.component';
import { OwnersComponent } from './owners/owners.component';
import { OwnerComponent } from './owners/owner/owner.component';
import {OwnerstartComponent } from './owners/ownerstart/ownerstart.component';
import {WorkflowOwnerComponent} from './owners/workflow-owner/workflow-owner.component';
import { ServerConfigComponent } from './server-config/server-config.component';

const settingsRoutes: Routes = [
     {path: '', component: SettingsComponent, children: [
         {path: '', component: SettingsStartComponent, canActivate: [AuthGuard]},
         {path: 'emailconfiguration', component: EmailConfigurationComponent, canActivate: [AuthGuard]},
         {path: 'marketdatasource', component: MarketDataSourceComponent, canActivate: [AuthGuard]},
         {path: 'logouttime', component: LogoutTimeComponent, canActivate: [AuthGuard]},
         {path: 'truestatustype', component: TrueStatusTypeComponent, canActivate: [AuthGuard]},
         {path: 'histogramcellthreshold', component: HistogramCellThresholdComponent, canActivate: [AuthGuard]},
        // {path: 'reconciliationclasses', component: ReconciliationClassesComponent, canActivate: [AuthGuard]},
         {path: 'logs', component: LogsComponent, canActivate: [AuthGuard]},
         {path: 'scriptmanagement', component: ScriptManagementComponent, canActivate: [AuthGuard]},
         {path: 'topics', component: TopicsComponent, canActivate: [AuthGuard], children: [
            {path: '', component: TopicstartComponent, canActivate: [AuthGuard]},
            {path: 'new', component: NewtopicComponent, canActivate: [AuthGuard]},
            {path: 'topic/:topicname', component: TopicComponent, canActivate: [AuthGuard]},
            {path: 'workflow/:workflowname', component: WorkflowTopicsComponent, canActivate: [AuthGuard]},
        ]},
        {path: 'owners', component: OwnersComponent, canActivate: [AuthGuard], children: [
                {path: '', component: OwnerstartComponent, canActivate: [AuthGuard]},
                {path: 'owner/:Username', component: OwnerComponent, canActivate: [AuthGuard]},
                {path: 'workflow/:workflowname', component: WorkflowOwnerComponent, canActivate: [AuthGuard]},
         ]},
         {path: 'serverconfig', component: ServerConfigComponent, canActivate: [AuthGuard]},
 
     ]}
]


@NgModule({
    imports: [
        RouterModule.forChild(settingsRoutes)
    ],
    exports: [RouterModule],
})



export class SettingsRoutingModule{}