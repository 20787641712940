
import { Component, OnInit, NgModule, ViewEncapsulation, Input, SimpleChanges, ElementRef, ChangeDetectionStrategy, Output, ViewChild, ChangeDetectorRef, forwardRef, ViewRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule, FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatFormField, MatFormFieldModule, MatFormFieldControl } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule, MatList, MatSelectionList } from '@angular/material/list';
import { AppModule } from 'src/app/app.module';
import { MatInputModule, MatSelectModule, MatRipple, MatIconModule, TooltipPosition, MatMenuModule, MatMenuTrigger, MatCheckboxModule } from '@angular/material';
import { EventEmitter } from '@angular/core';
import { ContextMenu } from 'primeng/contextmenu';
import { SharedGeneralFunctionsService } from '../shared-general-functions.service';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { HeroFilterPipe } from '../filter.pipe';
import { Sidebar } from 'primeng/sidebar';
import { CardabelListBoxModule } from '../cardabel-listbox/cardabel-listbox.component';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-cardabel-dropdown-listbox',
  templateUrl: './cardabel-dropdown-listbox.component.html',
  styleUrls: ['./cardabel-dropdown-listbox.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CardabelDropdownListboxComponent),
    multi: true
  }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardabelDropdownListboxComponent implements ControlValueAccessor, OnInit {

  //init inputs
  @Input() title: string;
  @Input() options: any[];
  @Input() filterBy: string = "";
  @Input() multiple: boolean;
  @Input() placeholder: string;
  @Input() btnGroup: boolean = false;
  @Input() disabled: boolean = false;
  @Input() small: boolean = false;

  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() disabledOption: boolean = false;
  @Input() formControl: FormControl = new FormControl('');
  @Input() tooltipText: any;
  @Input() tooltipDisabled: boolean;
  @Input() tooltipPosition: string;
  @Input() dropdownAbove: boolean = false;
  @Input() dropdownAbove1: boolean = false;

  @ViewChild("myDrop") myDrop;

  value;
  onChange;
  

  myControl = new FormControl('');


  allOptionsSelected: boolean = false;

  //Tooltip
  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  //init window height
  windowHeight: number = 1200;
  windowMiddle: number = 600;

  listboxPosition: any = {top: 0, bottom: 0, left: 0, right: 0, width: 0}
  name1: string = "";
  name2: string = "";
  @ViewChild("dropdownListbox") dropdownListbox;
  @ViewChild("dropdownButton") dropdownButton;

  constructor(private cd: ChangeDetectorRef,
    private sharedFunctionsService: SharedGeneralFunctionsService) { }
  ngOnInit() {
    console.log(this.options)

    //set window height
    this.windowHeight = window.innerHeight;
    this.windowMiddle = this.windowHeight/2;

    if (this.filterBy.indexOf('.') > -1) {
      this.name1 = this.filterBy.substring(0, this.filterBy.indexOf('.'));
      this.name2 = this.filterBy.substring(this.filterBy.indexOf('.') + 1, this.filterBy.length);

      console.log(this.name1);
      console.log(this.name2);
    }

    //when the formControl is changed then set selected rows and filtered options
    if (this.formControl) {
      this.formControl.valueChanges.subscribe((value: any) => {
        console.log(this.formControl.value)
        console.log(this.options)
        console.log(this.multiple);


        if (this.formControl.value[this.filterBy] != '') {
          this.change.emit({value: this.formControl.value})
        }
       
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
        //  this.onChange(value)

       // this.setFormControlValue();
      });
    }

  }

  //make sure list box is closed
  public close() {
    this.myDrop.close();
  }

  //control value accessor so that formcontrol works
  writeValue(value) {
    this.value = value;
  }

  registerOnChange(fn) {
    
    this.onChange = fn;
  }

  registerOnTouched(fn) {
  }

  //return correct height class

  getDropdownClass(position) {

    if (position > this.windowMiddle) {
      if (position - 350 < 0 ) {
        return "dropdown-sm"
      }
      else {
        return "dropdown-md"
      }
    }
    else {
      if (position + 350 > window.innerHeight) {
        return "dropdown-sm"
      }
      else {
        return "dropdown-md"
      }
    }
    
  }
  
  //((cardabelDropdown.getBoundingClientRect().top +300 > windowHeight ) && (cardabelDropdown.getBoundingClientRect().top -300 <0))  ? 'dropdown-sm': 'dropdown-md'

}

@NgModule({
  imports: [
    CardabelListBoxModule, CommonModule, ScrollingModule, ReactiveFormsModule, FormsModule, DropdownModule, NgbModule
  ],
  exports: [RouterModule, ScrollingModule, CardabelDropdownListboxComponent, FormsModule, CardabelListBoxModule, DropdownModule, NgbModule],
  declarations: [CardabelDropdownListboxComponent],
  providers: [
    
  ]
})
export class CardabelDropdownListBoxModule {

}
