import { Component, OnInit, OnDestroy, ViewRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { AuthorizationsService } from '../../authorizations/authorizations.service';
import { PackagesService } from '../packages.service';


@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit, OnDestroy {

  //initialize nature of logs
  viewNatures = [
    { label: 'Topics view', value: 'Topics view' },
    { label: 'Workflows view', value: 'Workflows view' }
  ];
  selectedViewNature: string = 'Topics view';
  //variable to get all the topics (active and inactive)
  topics: any[] = [];
  filteredTopics: any[] = [];
  allShownTopics: boolean = true;
  //subscription to get all the topics when updated
  topicsSubscription = new Subscription();

  //form control to initialize the selected topic in the list
  topic = new FormControl('');
  //subscription to update the selected topic (when changing)
  topicSubscription = new Subscription();

  //all the possible workflows
  workflows: any[] = [];
  filteredWorkflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();
  //form control to initialize the selected topic in the list
  workflow = new FormControl('');
  //subscription to update the selected topic (when changing)
  workflowSubscription = new Subscription();

  constructor(private authorizationsServices: AuthorizationsService,
    private packagesService: PackagesService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    //allows to get all the active and inactive topics, all the sources, webservices, workflows and users
    this.authorizationsServices.getAllWorkflows();
    this.packagesService.getTopicData();

    //allows to update the topics when one is changed // created
    this.topicsSubscription = this.packagesService.topicsChange
      .subscribe((topics: any) => {
        this.topics = topics;
        console.log(this.topics)
        this.filterActive('');
        for (var i in this.route.children) {
          if (this.route.children[i].snapshot.url[0]) {
            if (this.route.children[i].snapshot.url[0].path === "new") {
              this.router.navigate(["admin", "settings", "topics"]);
            }
          }
        }

        this.cd.detectChanges();

      })

    //allows to update the selected topic and navigate to its specific page
    this.topicSubscription = this.topic.valueChanges
      .subscribe((topic: any) => {
        console.log(topic)
        if (topic.topicName) {

          this.router.navigate(["admin", "settings", "topics", "topic",topic.topicName])
        }

        else {
          this.router.navigate(["admin", "settings", "topics"])
        }

      })
    this.workflowsSubscription = this.authorizationsServices.workflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        this.filterActiveWorkflows('');
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })
    //allows to update the selected topic and navigate to its specific page
    this.workflowSubscription = this.workflow.valueChanges
      .subscribe((workflow: any) => {
        console.log(workflow)
        if (workflow.WFName) {

          this.router.navigate(["admin", "settings", "topics", "workflow", workflow.WFName])
        }

        else {
          this.router.navigate(["admin", "settings", "topics"])
        }

      })
  }// ng_init

  //filter active or all topics
  filterActive(param) {
    this.topic.setValue("");

    this.filteredTopics = JSON.parse(JSON.stringify(this.topics))
  }
  //filter active or all workflows
  filterActiveWorkflows(param) {
    this.workflow.setValue("");

    this.filteredWorkflows = JSON.parse(JSON.stringify(this.workflows))
  }
  //allows to clear the form control when clicking on new
  navigateNewTopic() {
    this.topic.setValue("");
    this.router.navigate(['admin', 'settings', 'topics', 'new'])
  }
  viewNatureChange(){
    this.topic.setValue("");
    this.workflow.setValue("");
    this.router.navigate(['admin', 'settings', 'topics'])
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.topicsSubscription.unsubscribe();
    this.topicSubscription.unsubscribe();
    this.workflowsSubscription.unsubscribe();
    this.workflowSubscription.unsubscribe();
  }

}
