import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../app.service';
import { Subject } from 'rxjs';
import { catchError, max } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { Router } from '@angular/router';
import * as d3 from 'd3';


@Injectable()
export class DashboardService {

    constructor(private httpClient: HttpClient,
        private appService: AppService,
        private router: Router,

    ) { }


    url = this.appService.getUrl();
    dashboardDataFields: Object;
    dashboardDataFieldsChange = new Subject<Object>();
    dashboardData: any = {};
    dashboardDataChange: { [index: string]: Subject<any[]> } = {};
    dataFromHeader: any = {};

    dashboardRepeatedHeadersChange: { [index: string]: Subject<any[]> } = {};
    dashboardConfig: any = {}
    dashboardConfigChange: { [index: string]: Subject<any[]> } = {};
    sharedWithModifications: any = {};
    sharedWithoutModifications: any = {};
    sharedWithModificationsChange: { [index: string]: Subject<any[]> } = {};
    sharedWithoutModificationsChange: { [index: string]: Subject<any[]> } = {};
    dashboardList: any[] = [];
    dashboardListChange = new Subject<{ dashboardList: any[], from: string }>();
    dashboardSavedChange: { [index: string]: Subject<boolean> } = {};

    sourceArray: { name: string }[] = [];

    dashboardFileData: any = {};
    dashboardAggregatedVariable: any = {};
    initFiles: any = {};
    files: any = {};

    chartChange: { [index: string]: Subject<any[]> } = {};
    chartConfigData: any = {};
    initDashboardTabChanges(tabs) {
        for (var i in tabs) {
            this.dashboardDataChange[tabs[i].name] = new Subject<any[]>();
            this.dataFromHeader[tabs[i].name] = false;
            this.dashboardRepeatedHeadersChange[tabs[i].name] = new Subject<any[]>();
            this.dashboardConfigChange[tabs[i].name] = new Subject<any[]>();
            this.sharedWithModificationsChange[tabs[i].name] = new Subject<any[]>();
            this.sharedWithoutModificationsChange[tabs[i].name] = new Subject<any[]>();
            this.dashboardSavedChange[tabs[i].name] = new Subject<boolean>();
        }
    }

    initChartChanges(mainTab, tabAlias, columns) {
        for (var i in columns) {
            this.chartChange[mainTab + tabAlias + i] = new Subject<any>();
            this.chartConfigData[mainTab + tabAlias + i] = []
        }
    }
    initEditColumnChartChanges(mainTab, tabAlias, graphIndex) {
        this.chartChange[mainTab + tabAlias + graphIndex] = new Subject<any>();
        this.chartConfigData[mainTab + tabAlias + graphIndex] = []
    }

    setChartChanges(mainTab, tabAlias, columns) {
        for (var i in columns) {
            this.chartConfigData[mainTab + tabAlias + i] = columns[i];
            this.chartChange[mainTab + tabAlias + i].next(columns[i]);
        }
    }

    setChartChange(mainTab, tabAlias, columns, graphIndex, index) {
        this.chartConfigData[mainTab + tabAlias + graphIndex] = columns[index];
        this.chartChange[mainTab + tabAlias + graphIndex].next(this.chartConfigData[mainTab + tabAlias + graphIndex]);
    }

    getChartChange(mainTab, tabAlias, graphIndex) {

        return this.chartConfigData[mainTab + tabAlias + graphIndex]
    }

    setDataFromHeader(param, tab) {
        this.dataFromHeader[tab] = param;
    }

    //Gets all the anomalies already generated for the selected record type in the chosen dashboard.
    //used for dashboard data as well as Email Configuration List;
    getDashboardDataFields(dashboardName) {

        this.appService.startSpin();
        const url = this.url;
        const webservice = "AdminGetDBDataFields";
        const completeUrl = url + webservice;

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("dashboardName", dashboardName)


        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    console.log(response);
                    this.dashboardData = response;
                    this.dashboardDataFieldsChange.next(this.dashboardData);
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });

    }

    //Gets join of allpredictions, anomalystatus and source tables
    //used for dashboard
    getDashboardData(source, source_only, predictionMode, tab, filter, filterNumber) {
        this.appService.startSpin2();
        let topicName = this.appService.getSelectedTopic();
        const url = this.url;
        const webservice = "DashboardUserGetData";
        const completeUrl = url + webservice;
        source = JSON.stringify(source);
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("sourceList", source)
            .set("source_only", source_only)
            .set('predictionMode', predictionMode)
            .set("topicName", topicName)
            .set("dataFilter", filter)
            .set("filterNumber", filterNumber)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                if (response != "") {
                    response = response.substring(1, response.length - 1);
                    response = JSON.parse(response);
                    if (response.statusCode > -1) {
                        // console.log(response);
                        if (response.tableRows.length > 0) {
                            response.tableRows.map((obj) => {
                                obj.system = "CARDABEL";
                                obj.aggregated = false;
                                return obj;
                            })

                            if (!this.dashboardFileData[tab]) {
                                this.dashboardFileData[tab] = [];
                            }
                            this.dashboardData[tab] = this.dashboardFileData[tab].concat(response.tableRows);

                            this.returnDashboardData(tab);
                        }
                        else {
                            this.appService.showMessage('Warning', response.statusText);
                        }
                        if (response.tableRows2.length > 0) {
                            this.dashboardRepeatedHeadersChange[tab].next(response.tableRows2[0].AttrInAllSources);
                        }


                    }
                    else {
                        this.appService.showMessage('Error', response.statusText)
                    }
                }
                else {
                    this.appService.showMessage('Error', "The data source is too large to be loaded in the application.")
                }

                this.appService.stopSpin2();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin2();
            });

    }

    //returns the dashboard data
    //used for dashboard
    returnDashboardData(tab) {
        if (this.dashboardData[tab]) {
            this.dashboardDataChange[tab].next([...this.dashboardData[tab]])
        }
    }

    //set file data
    setFileData(tab, data, aggregatedOption, aggregatedVariable) {
        let aggregated = false;
        if (aggregatedOption === "Aggregated") {
            aggregated = true;
        }
        data.map((obj) => {
            obj.aggregated = aggregated;
            return obj;
        })

        this.dashboardAggregatedVariable[tab] = aggregatedVariable;
        this.dashboardFileData[tab] = data;
    }

    setDashboardFiles(initFiles, files, tab) {
        this.initFiles[tab] = [...initFiles];
        this.files[tab] = [...files];
    }

    getDashboardFiles(tab) {
        return [this.initFiles[tab], this.files[tab]]
    }

    //Gets the user dashboard config for given source and dashboardtype
    //used for dashboard
    getDashboardList(username, from) {

        // let newDashboardList = JSON.stringify([])
        let topicName = this.appService.getSelectedTopic();
        const url = this.url;
        const webservice = "DashboardUserGetDashboardList";
        const completeUrl = url + webservice;

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

            .set("userName", username)
            //.set("newDashboardList", newDashboardList)
            .set("topicName", topicName)
            ;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    console.log(response);
                    this.dashboardList = response.tableRows;
                    for (var i in this.dashboardList) {
                        this.dashboardList[i].sharedWithModifications = JSON.parse(this.dashboardList[i].sharedWithModifications);
                        this.dashboardList[i].sharedWithoutModifications = JSON.parse(this.dashboardList[i].sharedWithoutModifications);
                        this.dashboardList[i].sources = JSON.parse(this.dashboardList[i].sources);

                    }
                    let newdata = {
                        dashboardList: this.dashboardList,
                        from: from
                    }
                    this.dashboardListChange.next(newdata);

                }
                else {
                    this.dashboardList = [];
                    let newdata = {
                        dashboardList: this.dashboardList,
                        from: from
                    }
                    this.dashboardListChange.next(newdata);
                }

                //    this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                //     this.appService.stopSpin();
            });

    }
    //return dashboardList
    returnDashboardList() {
        return this.dashboardList;
    }

    //Gets the user dashboard config for given source and dashboardtype
    //used for dashboard
    getDashboardUserConfig(username, sourceOnlyMode, dashboardtype, tab) {

        const url = this.url;
        const webservice = "DashboardUserLoad";
        const completeUrl = url + webservice;


        let db = this.dashboardList.find(item => item.name === dashboardtype);
        if (db) {


            let creator = db.creator;

            let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
                .set("userName", username)
                .set("sourceOnlyMode", sourceOnlyMode)
                .set("dashboardType", dashboardtype)
                .set("creator", creator)


            const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            //   this.appService.startSpin();

            return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
                .pipe(
                    //map(response => new NmUser(response)),
                    catchError(this.appService.handleError)
                )
                .subscribe((response: any) => {
                    console.log(response);
                    //response = response.replace(/[()]/g, '');
                    response = response.substring(1, response.length - 1);
                    response = JSON.parse(response);
                    if (response.statusCode > -1) {
                        console.log(response);
                        this.dashboardConfig[tab] = response.tableRows;

                        for (var i in this.dashboardConfig[tab]) {
                            this.dashboardConfig[tab][i].config = JSON.parse(this.dashboardConfig[tab][i].config);
                            //this.dashboardConfig[i].filters = JSON.parse(this.dashboardConfig[i].filters);
                        }
                        for (var k in this.dashboardList) {
                            if (this.dashboardList[k].name === dashboardtype) {
                                this.sharedWithModifications[tab] = this.dashboardList[k].sharedWithModifications;
                                this.sharedWithoutModifications[tab] = this.dashboardList[k].sharedWithoutModifications;
                                break;
                            }
                        }
                        this.dashboardConfigChange[tab].next(this.dashboardConfig[tab]);
                        this.sharedWithModificationsChange[tab].next(this.sharedWithModifications[tab]);
                        this.sharedWithoutModificationsChange[tab].next(this.sharedWithoutModifications[tab]);
                    }
                    else {
                        this.dashboardConfig[tab] = [];
                        this.dashboardConfigChange[tab].next(this.dashboardConfig[tab]);
                        this.sharedWithModifications[tab] = [];
                        this.sharedWithoutModifications[tab] = [];
                        this.sharedWithModificationsChange[tab].next(this.sharedWithModifications[tab]);
                        this.sharedWithoutModificationsChange[tab].next(this.sharedWithoutModifications[tab]);
                        //  this.appService.showMessage('Error', response.statusText)
                    }
                    //this.appService.stopSpin();
                }, (error) => {
                    this.appService.showMessage('Error', error.statusText);
                    //this.appService.stopSpin();
                });
        }
    }

    //Saves the user dashboard config for given source and dashboardtype
    //used for dashboard
    saveDashboardUserConfig(username, source, sourceOnlyMode, dashboardtype, dashboardconfig, sharedWithModifications, sharedWithoutModifications, tab, from, filter, filterNumber) {

        const url = this.url;
        const webservice = "DashboardUserSave";
        const completeUrl = url + webservice;

        dashboardconfig = JSON.stringify(dashboardconfig);

        let previousAuthorizedUsersArray = [];
        let sourceList = JSON.stringify(source);

        let dashboardNameExists = false;
        let creator = username;
        for (var i in this.dashboardList) {
            if ((this.dashboardList[i].name === dashboardtype)) {
                creator = this.dashboardList[i].creator;
                if (creator === this.dashboardList[i].creator) {
                    let arr1 = this.dashboardList[i].sharedWithModifications;
                    let arr2 = this.dashboardList[i].sharedWithoutModifications;
                    previousAuthorizedUsersArray = Array.from(new Set(arr1.concat(arr2)))
                    /*  this.dashboardList[i].sharedWithModifications = [...sharedWithModifications];
                      this.dashboardList[i].sharedWithoutModifications = [...sharedWithoutModifications];
                      this.dashboardList[i].sources = source;
                      this.dashboardList[i].sourceOnly = sourceOnlyMode; */
                }


                dashboardNameExists = true;
                break;
            }
        }
        /*  if (dashboardNameExists === false) {
              this.dashboardList.push({ name: dashboardtype, sources: source, sourceOnly: sourceOnlyMode, creator: username, sharedWithModifications: sharedWithModifications, sharedWithoutModifications: sharedWithoutModifications })
          } */

        /*  let dashboardList = JSON.stringify(this.dashboardList);*/
        let sharedWithList = JSON.stringify(sharedWithModifications)
        let sharedWithoutList = JSON.stringify(sharedWithoutModifications);

        let sourceOnly = sourceOnlyMode;

        if ((sourceOnlyMode === false) || (sourceOnlyMode.toLowerCase() === "false")) {
            sourceOnly = "anomaly";
        }
        else if ((sourceOnlyMode === true) || (sourceOnlyMode.toLowerCase() === "true")) {
            sourceOnly = "sourceOnly"
        }
        let otherAuthorizedUsersArray = Array.from(new Set(sharedWithModifications.concat(sharedWithoutModifications)));
        let otherAuthorizedUsers = JSON.stringify(otherAuthorizedUsersArray);

        let difference = previousAuthorizedUsersArray.filter(x => !otherAuthorizedUsersArray.includes(x));

        let previousAuthorizedUsers = JSON.stringify(difference);

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("sourceOnlyMode", sourceOnly)
            .set("dashboardType", dashboardtype)
            .set("dashboardConfig", dashboardconfig)
            .set("creator", creator)
            .set("dashboardSourcesList", sourceList)
            .set("sharedWithModifications", sharedWithList)
            .set("sharedWithoutModifications", sharedWithoutList)
            .set("otherAuthorizedUsers", otherAuthorizedUsers)
            .set("previousAuthorizedUsers", previousAuthorizedUsers)
            .set("dataFilter", filter)
            .set("filterNumber", filterNumber)
            ;
        // this.appService.startSpin();

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText);
                    this.dashboardConfig[tab] = JSON.parse(dashboardconfig);
                    this.dashboardSavedChange[tab].next(true);
                    if (from === "header") {
                      
                        
                    this.dashboardConfigChange[tab].next(this.dashboardConfig[tab]);
                    }  
                  
                    this.getDashboardList(username, from);
                    //this.router.navigate(['dashboard', source, sourceOnly, dashboardtype, true])
                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                    this.dashboardSavedChange[tab].next(false);
                }
                //this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.dashboardSavedChange[tab].next(false);
                // this.appService.stopSpin();
            });

    }

    //Saves the user dashboard config for given source and dashboardtype
    //used for dashboard
    deleteDashboardUser(username, sourceOnlyMode, dashboardtype, tab) {

        const url = this.url;
        const webservice = "DashboardUserDelete";
        const completeUrl = url + webservice;

        let creator = "";
        let users = []
        for (var i in this.dashboardList) {
            if ((this.dashboardList[i].name === dashboardtype)) {
                creator = this.dashboardList[i].creator;
                users = this.dashboardList[i].sharedWithModifications.concat(this.dashboardList[i].sharedWithoutModifications)
                // this.dashboardList.splice(+i, 1)
                break;
            }
        }

        let userList = JSON.stringify(users);

        //  let dashboardList = JSON.stringify(this.dashboardList);

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("sourceOnlyMode", sourceOnlyMode)
            .set("dashboardType", dashboardtype)
            .set("creator", creator)
            .set("users", userList)
            //.set("dashboardList", dashboardList)
            ;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText);
                    this.router.navigate(['dashboard']);
                    this.getDashboardList(username, "header");
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                    this.getDashboardList(username, "header");
                }


                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });

    }


    //this is to format data according to aggregation method
    // used for card, and dashbaord chart

    formatDashboardData(initData, header, xHeader, yHeader, method, name, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, tab) {
        let filteredData = [];
        let formattedData = [];
        var vis = this;
        let data = [...initData];
        let systems = [];
        let removeDates = false;
        let headers = [header, xHeader, yHeader];
        if (data.length != 0) {
            for (var i in data) {
                for (var k in headers){
                    if (headers[k] != "") {
                        if ((data[i][headers[k]] === null) || (data[i][headers[k]] === 'Null')) {
                            if (header === "truestatus") {
                                data[i][headers[k]] = "Unknown";
                            }
                            else {
                                data[i][headers[k]] = "Null";
                            }
        
                        }
                        else if (data[i][headers[k]] == undefined) {
        
                        }
                        else if ((data[i][headers[k]] === "") || (data[i][headers[k]] === " ")) {
                            data[i][headers[k]] = "Unknown";
                        }
                        else if (typeof data[i][headers[k]] === 'string') {
                            if (data[i][headers[k]].indexOf(":") > -1) {
                                data[i][headers[k]] = data[i][headers[k]].replace(":", "-");
                            }
        
                        }
                    }
                }
               
            }

            if ((dateBucketing != "") && dateBucketing) {
                // var today = new Date();
                /* var lastDate = new Date(
                     Math.max(
                       ...data.map(element => {
                         return new Date(element[header]);
                       }),
                     ),
                   );
                   */
                // 15/06/2022
                // pb sort with null
                for (var i in data) {
                    if (data[i][header] == null) {
                        data[i][header] = "";
                    }
                }


                let chartDataLength = JSON.parse(JSON.stringify(data.length));
                data = data.filter(item => new Date(item[header]).toString() != "Invalid Date");

                if (chartDataLength != data.length) {
                    removeDates = true;
                }

                if (data.length > 0) {


                    let sorteddata = [...data];
                    sorteddata = sorteddata.sort((a, b) => a[header] > b[header] ? 1 : -1)
                    var lastDate = sorteddata[sorteddata.length - 1][header];
                    lastDate = new Date(lastDate)
                    let maxDate = new Date(lastDate);

                    if (datePeriod === "Days" || (!datePeriod)) {
                        maxDate.setDate(lastDate.getDate() - datePeriodValue)

                    }
                    else if (datePeriod === "Months") {
                        let numberOfDays = datePeriodValue * 30;
                        maxDate.setDate(lastDate.getDate() - numberOfDays)
                    }
                    else if (datePeriod == "Weeks") {
                        let numberOfDays = datePeriodValue * 7;
                        maxDate.setDate(lastDate.getDate() - numberOfDays)
                    }
                    else if (datePeriod === "Years") {
                        let numberOfDays = datePeriodValue * 365;
                        maxDate.setDate(lastDate.getDate() - numberOfDays)
                    }
                    else if (datePeriod === "Fixed Date") {
                        maxDate = new Date(datePeriodFixedDate);
                    }


                    filteredData = data.filter(item => new Date(item[header]) > maxDate)

                    console.log(maxDate);
                    console.log(filteredData);

                    data = JSON.parse(JSON.stringify(filteredData));
                }

            }

            if ((method === "count") || (!method)) {
                if ((xHeader != "") || (yHeader != "")) {
                    formattedData = this.formatAggregatedDashboardData(data, header, xHeader, yHeader, method, name, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate)
                }
                else if ((!dateBucketing) || (dateBucketing === "")) {
                    /* formattedData = data.reduce(
                         (map => (r, item) => (!map.has(item[header]) && map.set(item[header], r[r.push({ Categorie: item[header], [name]: 0 }) - 1]), map.get(item[header])[name]++, r))(new Map),
                         []) */
                    let dashboardAggregatedVariable = this.dashboardAggregatedVariable[tab];



                    data.reduce(function (res, item) {
                        if (item[header] && item[header] != null) {


                            if ((item["system"] === "CARDABEL") || (item["aggregated"] === false) || (!dashboardAggregatedVariable) || (!item["system"])) {
                                if (!res[item[header]]) {
                                    res[item[header]] = { Categorie: item[header], [name]: 0, increase: "0%" };
                                    formattedData.push(res[item[header]])
                                }

                                if (systems.indexOf(item["system"]) === -1) {
                                    systems.push(item["system"])
                                }

                                res[item[header]][name]++;
                            }
                            else if ((dashboardAggregatedVariable != "") || (dashboardAggregatedVariable)) {
                                if (!res[item[header]]) {
                                    res[item[header]] = { Categorie: item[header], [name]: +item[dashboardAggregatedVariable] };
                                    formattedData.push(res[item[header]])
                                }
                                else {
                                    res[item[header]][name] = res[item[header]][name] + (+item[dashboardAggregatedVariable])
                                }
                                if (systems.indexOf(item["system"]) === -1) {
                                    systems.push(item["system"])
                                }
                            }
                        }
                        return res;
                    }, {})
                }

                else {
                    data.reduce(function (res, item) {

                        if (item[header] != null && typeof item[header] === "string") {

                            let [year, month, day] = item[header].split('-');
                            let newHeader = "";
                            let newName = "";

                            [newName, newHeader] = vis.getNewNameNewHeader(year, month, day, dateBucketing)



                            if (!res[newName]) {
                                res[newName] = { Categorie: newHeader, [name]: 0 };
                                formattedData.push(res[newName])
                            }
                            if (systems.indexOf(item["system"]) === -1) {
                                systems.push(item["system"])
                            }

                            res[newName][name]++;

                        }




                        return res;
                    }, {});
                }
            }
            else {
                let dashboardAggregatedVariable = this.dashboardAggregatedVariable[tab];
                data.reduce(function (res, value) {
                    if (value[header] && value[header] != null) {
                        let newHeader = value[header];
                        let newName = value[header];
                        if ((xHeader != "") || (yHeader != "")) {
                            newName = newName + value[xHeader] + value[yHeader];
                        }


                        if (dateBucketing != "" && dateBucketing) {
                            if (value[header] != null) {


                                let [year, month, day] = value[header].split('-');
                                [newName, newHeader] = vis.getNewNameNewHeader(year, month, day, dateBucketing)
                            }
                        }

                        if (!res[newName]) {
                            let formattedValue = value[numericHeader];
                            if (!isNaN(value[numericHeader]) && value[numericHeader] != null && value != "") {
                                console.log(formattedValue);
                                formattedValue = +value[numericHeader];
                                formattedValue = formattedValue.toFixed(numericDecimal)

                                if ((xHeader != "") || (yHeader != "")) {
                                    res[newName] = { Categorie: value[header], xHeader: value[xHeader], yHeader: value[yHeader], [name]: +formattedValue, number: 1 };
                                }
                                else {
                                    res[newName] = { Categorie: newHeader, [name]: +formattedValue, number: 1 };
                                }

                                if ((value["system"] === "CARDABEL") || (value["aggregated"] === false) || (!dashboardAggregatedVariable) || (!value["system"])) {
                                }
                                else if (dashboardAggregatedVariable != '') {
                                    res[newName].number = +value[dashboardAggregatedVariable]
                                }
                                if (systems.indexOf(value["system"]) === -1) {
                                    systems.push(value["system"])
                                }
                                formattedData.push(res[newName])
                            }

                        }
                        else {
                            let formattedValue = value[numericHeader];
                            let formattedValueNumericType = true;
                            if ((!isNaN(value[numericHeader]) && value[numericHeader] != null)) {
                                formattedValue = +value[numericHeader];
                                formattedValue = formattedValue.toFixed(numericDecimal)
                            }
                            else {
                                formattedValueNumericType = false;
                            }



                            if (method === "min") {
                                res[newName][name] = Math.min(res[newName][name], formattedValue);
                            }
                            if (method === "max") {
                                res[newName][name] = Math.max(res[newName][name], formattedValue);
                            }

                            if (method === "sum") {
                                if (formattedValueNumericType) {
                                    res[newName][name] = +res[newName][name] + (+formattedValue);
                                }

                            }
                            if (method === "average") {
                                if (formattedValueNumericType) {
                                    res[newName][name] = +res[newName][name] + (+formattedValue);
                                }

                                if ((value["system"] === "CARDABEL") || (value["aggregated"] === false) || (!dashboardAggregatedVariable) || (!value["system"])) {
                                    res[newName]["number"]++;
                                }
                                else if (dashboardAggregatedVariable != '') {
                                    res[newName].number = res[newName].number + (+value[dashboardAggregatedVariable]);
                                }


                            }
                            if (systems.indexOf(value["system"]) === -1) {
                                systems.push(value["system"])
                            }
                        }
                    }

                    return res;
                }, {});
            }

            if (method === "average") {
                for (var m in formattedData) {
                    formattedData[m][name] = (formattedData[m][name] / formattedData[m].number);
                    if ((!isNaN(formattedData[m][name])) && formattedData[m][name] != null) {
                        formattedData[m][name] = formattedData[m][name].toFixed(numericDecimal)

                    }
                }
            }



            for (var j in formattedData) {
                if (formattedData[j].Categorie === null) {
                    formattedData[j].Categorie = "Null";
                }
                if (formattedData[j].Categorie === 0) {
                    formattedData[j].Categorie = "0";
                }
            }
        }

        systems = this.getSystems(systems, tab)
        return [formattedData, systems, removeDates];
    }

    getSystems(data, tab) {
        let [initFiles, files] = this.getDashboardFiles(tab);
        let systems = [];
        if (initFiles) {
            if (initFiles.length != 0) {
                systems = data;

            }
        }
        return systems;
    }

    formatAggregatedDashboardData(data, header, xHeader, yHeader, method, name, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate) {
        let formattedData = [];
        data.reduce(function (res, value) {

            let newName = value[header];

            if (xHeader != "") {
                newName = newName + value[xHeader];
            }
            if (yHeader != "") {
                newName = newName + value[yHeader];
            }


            if (!res[newName]) {
                res[newName] = { Categorie: value[header], xHeader: value[xHeader], yHeader: value[yHeader], [name]: 1 }
                formattedData.push(res[newName])
            }
            else {
                res[newName][name]++;
            }

            return res;

        }, {});

        return formattedData;
    }

    getNewNameNewHeader(year, month, day, dateBucketing) {
        let newName = "";
        let newHeader = "";

        if (dateBucketing === "Month") {
            newHeader = year + '-' + month;
            newName = year + month;
        }
        else if (dateBucketing === "Year") {
            newHeader = year+"-"+"01";
            newName = year+"-"+"01";
        }
        else if (dateBucketing === "Week") {
            let newDay = "01";
            if ((+day >= 7) && (+day < 14)) {
                newDay = "07"
            }
            else if ((+day >= 14) && (+day < 21)) {
                newDay = "14"
            }
            if ((+day >= 21) && (+day < 28)) {
                newDay = "21"
            }
            if ((+day >= 28)) {
                newDay = "28"
            }
            newHeader = year + '-' + month + '-' + newDay;
            newName = year + month+newDay;
        }
        else if (dateBucketing === "Day") {
            newHeader = year + '-' + month + '-' + day;
            newName = year + month + day;
        }
        else if (dateBucketing === "Quarter") {
            let newMonth = "03";
            if (+month > 3 && +month <= 6) {
                newMonth="06";
            }
            if (+month > 6 && +month <= 9) {
                newMonth="09";
            }
            if (+month > 9 && +month <= 12) {
                newMonth="12";
            }
            newHeader = year + '-' + newMonth;
            newName = year + newMonth;
        }


        return [newName, newHeader]
    }

    getTooltip(type, aggregatedDimensionMethod, aggMethod, header, numericHeader, dateBucketing) {
        let tooltip = "";
        let lasttooltippart = " for each" + header;

        if (dateBucketing != "") {
            if (dateBucketing === "20last") {
                lasttooltippart = " for the last 20 " + header;
            }
            else if (dateBucketing === "month") {
                lasttooltippart = " for " + header + "grouped by month";
            }

        }

        if (type === "Click here to see") {
            if (aggregatedDimensionMethod === "count") {
                tooltip = type + " the number of records in each " + header;
            }
            else if ((aggMethod === 'count') || (!aggMethod)) {
                tooltip = type + " the number of " + header + " that have the same number of records."
            }
        }

        else if (aggregatedDimensionMethod === "count") {
            tooltip = type + " the number of " + header + " that have the same number of records."
        }
        else if ((aggMethod === 'count') || (!aggMethod)) {
            tooltip = type + " the number of records in each " + header;
        }
        else if (aggMethod === 'min') {
            tooltip = type + " the minimum " + numericHeader + lasttooltippart;
        }
        else if (aggMethod === 'max') {
            tooltip = type + " the maximum " + numericHeader + lasttooltippart;
        }
        else if (aggMethod === 'average') {
            tooltip = type + " the average " + numericHeader + lasttooltippart;
        }
        else if (aggMethod === 'sum') {
            tooltip = type + " the sum of all the " + numericHeader + lasttooltippart;
        }



        return tooltip;
    }

    returnLabels(xAxisLabel, yAxisLabel, header, aggMethod, aggregatedDimensionMethod, numericHeader, dateBucketing, dateHeader, xHeader, yHeader, type) {
        let label1 = "";
        let label2 = "";
        if ((xAxisLabel === "") || (!xAxisLabel)) {
            if (type === "bubble") {
                label1 = xHeader;
            }
            else if ((dateBucketing != "") && (dateBucketing)) {

                label1 = dateBucketing + " of " + dateHeader;
            }
            else if ((aggregatedDimensionMethod != "") || (aggMethod === "")) {
                label1 = "count";
            }
            else {
                label1 = aggMethod;
                if ((aggMethod != "") && (aggMethod != "count")) {
                    label1 = aggMethod + " of " + numericHeader;
                }
            }
        }
        else {
            label1 = xAxisLabel;
        }
        if ((dateBucketing != "") && (dateBucketing) && (yAxisLabel === "")) {
            label2 = aggMethod + " of " + numericHeader;
            if ((aggMethod === "") || (aggMethod === "count")) {
                label2 = "count";
            }
        }
        else if ((yAxisLabel === "") || (!yAxisLabel)) {
            label2 = header;
            if (type === "bubble") {
                label2 = yHeader;
            }
        }
        else {
            label2 = yAxisLabel;
        }

        if ((type === "line") && ((xAxisLabel === '') || (!xAxisLabel)) && ((yAxisLabel === "") || (!yAxisLabel))) {
            xAxisLabel = label2;
            yAxisLabel = label1;
        }
        else {
            xAxisLabel = label1;
            yAxisLabel = label2;
        }

        return [xAxisLabel, yAxisLabel]

    }

    expandChart(displayContextMenu, height, width, vis) {
      
        // this.margin.bottom = 100;
        displayContextMenu = false;
        height = window.innerHeight - 330;
        width = window.innerWidth - 200;

        if (vis.type === "treemap") {
            width = window.innerWidth - 300;
        }
        return [displayContextMenu, height, width, vis];
    }

    setSourceArray(source) {
        this.sourceArray = source;
    }
    getSourceArray() {
        return this.sourceArray;
    }

    timeFormat(dateFormat) {
        var timeFormat = "%d-%m-%Y";

        if (dateFormat === "YYYY") {
            timeFormat = "%Y";
        }
        else if (dateFormat === "YYYY-MM") {
            timeFormat = "%Y-%m";
        }
        else if (dateFormat === "YYYY-MM-DD") {
            timeFormat = "%Y-%m-%d";
        }
        else if (dateFormat === "YYYY-DD-MM") {
            timeFormat = "%Y-%d-%m";
        }
        else if (dateFormat === "DD-MM-YYYY") {
            timeFormat = "%d-%m-%Y";
        }
        else if (dateFormat === "MM-DD-YYYY") {
            timeFormat = "%m-%d-%Y";
        }
        else if (dateFormat === "month-YYYY") {
            timeFormat = "%b-%Y";
        }
        else if (dateFormat === "YYYY-month") {
            timeFormat = "%Y-%b";
        }
        else if (dateFormat === "YYYY-Q") {
            timeFormat = "%Y-Q%q";
        }
        else if (dateFormat === "Q-YYYY") {
            timeFormat = "Q%q-%Y";
        }
        return timeFormat;
    }

    changeNullValues(header, data) {
        for (var i in data) {
                if (header != "") {
                    if ((data[i][header] === null) || (data[i][header] === 'Null')) {
                        if (header === "truestatus") {
                            data[i][header] = "Unknown";
                        }
                        else {
                            data[i][header] = "Null";
                        }
    
                    }
                    else if (data[i][header] == undefined) {
    
                    }
                    else if ((data[i][header] === "") || (data[i][header] === " ")) {
                        data[i][header] = "Unknown";
                    }
                    else if (typeof data[i][header] === 'string') {
                        if (data[i][header].indexOf(":") > -1) {
                            data[i][header] = data[i][header].replace(":", "-");
                        }
    
                    }
                }
            
           
        }
        return data;
    }
  
}