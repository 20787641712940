import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowNameService } from './workflow-name.service';
import { MultiBarChart } from 'src/app/shared/d3/d3multibarchart';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { MultiStackedBarChart } from 'src/app/shared/d3/d3multistackedbarchart';
import { StackedBarChart } from 'src/app/shared/d3/d3stackedbarchart';


@Injectable()
export class WorkflowBayesianStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  url = this.appService.getUrl();

  multiBayesChartDataChange = new Subject<any[]>();
  preparedDataChange = new Subject<any[]>();
  analysisMultiBayesChartDataChange : { [index: string]: Subject<any[]> } = {};
  analysisPreparedDataChange : { [index: string]: Subject<any[]> } = {};

  //init tabs
  initTabChanges(tabs) {
    for (var i in tabs) {
      this.analysisMultiBayesChartDataChange[tabs[i].name] = new Subject<any[]>();
      this.analysisPreparedDataChange[tabs[i].name] = new Subject<any[]>();
    }
  }

  // allows to discover anomalies from the use of rules
  //called in rules component
  launchBayesTableRules(apply_and_chain, workflow, tableName, rulesList) {
    const url = this.url;
    const webservice = "WFBayesGenerateFromRules";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('rulesList', rulesList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response);

          this.multiBayesChartDataChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  //
  //Identifies simple anomalies, creates the corresponding tables and returns back the anomaly table and a table with the counts of anomalies by type.
  // called in detect simple component
  launchBayesTableDetectSimple(apply_and_chain, workflow, tableName, alpha, nbbins, inactiveForSpl) {
    const url = this.url;
    const webservice = "WFBayesTableDetectSimple";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('alpha', alpha)
      .set('nbbins', nbbins)
      .set('inactiveForSpl', inactiveForSpl)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response);
          this.multiBayesChartDataChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Identifies complex anomalies, updates the corresponding tables.
  //called in detect complex component
  launchBayesTableDetectComplex(workflow, tableName, K, alpha, algo,inactivePairs,gamma) {
    const url = this.url;
    const webservice = "WFBayesTableDetectComplex";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('K', K)
      .set('alpha', alpha)
      .set('algo', algo)
      .set('inactivePairs',inactivePairs)
      .set('gamma', gamma)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response);

          this.multiBayesChartDataChange.next(response.tableRows);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, "");

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //returns the distribution of anomalies by attribute name for the Bayes workflow
  //called in analysis grid component and in input of rule,detectpsl and detectcpx
  //getMultiBayesBarChartData(workflow, source, segAttribute, table, proMode) {
  getMultiBayesBarChartData(workflow, tableName, proMode, postfix, isCompletedNecessary, tab) {
    // from analysis neccessary to be completed


    const url = this.url;
    const webservice = "AnalysisBayesGetAnomalyByVariable";
    const completeUrl = url + webservice;
    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('proMode', proMode)
      .set('postfix', postfix)
      .set('isCompletedNecessary', isCompletedNecessary)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (tab === "") {
            this.multiBayesChartDataChange.next(response.tableRows);
          }
          else {
            this.analysisMultiBayesChartDataChange[tab].next(response.tableRows);
          }
         
          //this.detectCpxChartDataChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  // preparedata for the multibar chart
  // called from DetectCpx, DetectSpl, DetectRule and analysis

  prepareMultiBayesData(multiBayesData, tab) {
    console.log(multiBayesData)
    var datapoints = [];

    var dataintermediaire = [];
    var total = [];
    var nbcorrect = 0;
    var nbanomaly = 0;
    total["Combined"] = 0;
    total["Simple"] = 0;
    total["Rule"] = 0;

    for (var j in multiBayesData) {
      var argsname = "";
      var count = 0;
      var variable = "";

      var ichart = [];

      ichart["Combined"] = 0;
      ichart["Simple"] = 0;
      ichart["Rule"] = 0;
      for (var k in multiBayesData[j]) {
        if ((k === "Rule") || (k === "Combined") || (k === "Simple"))
          argsname = k;
        else {
          count = multiBayesData[j][k];
          variable = k;
          /// quand k="NoErr" alors le skipper 
        }
      }
      if (variable === "Correct") {
        nbcorrect = count;
      }
      else {
        if (variable === "Anomaly") {
          nbanomaly = count;
        }
        else {
          if (!(variable === "NoErr")) {
            ichart["x"] = variable;
            //ichart[argsname] = $scope.chartFile[j][k];
            ichart[argsname] = count;
            total[argsname] = total[argsname] + count;
            dataintermediaire.push(ichart);
          }
        }
      }
    }
    // regrouper quand x identique
    for (var i = 0; i < dataintermediaire.length; i++) {
      // verifier que pas deja pris dans datapoint
      var exist = false
      for (var p in datapoints) {
        if (datapoints[p].x === dataintermediaire[i].x)
          exist = true;
      }

      if (exist === false) {
        var point;
        point = dataintermediaire[i];
        for (var l = i + 1; l < dataintermediaire.length; l++) {
          if (dataintermediaire[l].x === point.x) {
            point["Combined"] = point["Combined"] + dataintermediaire[l]["Combined"];
            point["Simple"] = point["Simple"] + dataintermediaire[l]["Simple"];
            point["Rule"] = point["Rule"] + dataintermediaire[l]["Rule"];
          }
        }
        datapoints.push(point);
        var varname = point.x;

      }
    }

    // color dans le html
    var totalerror;
    totalerror = [];

    totalerror["Combined"] = { name: 'Combined', count: total['Combined'] };
    totalerror["Simple"] = { name: 'Simple', count: total['Simple'] };
    totalerror["Rule"] = { name: 'Rule', count: total['Rule'] };

    var datacol = new Array(totalerror.length);
    for (i = 0; i < totalerror.length; i++) {
      datacol[i] = new Array(datapoints.length)

    }
    var dataCategorie = new Array(datapoints.length);

    for (i = 0; i < datapoints.length; i++) {

      dataCategorie[i] = datapoints[i].x;
      for (var q = 0; q < totalerror.length; q++) {
        datacol[q][i] = datapoints[i][totalerror[q].name];

      }
    }


    var data = [];

    var dataCategoriefin = [];
    var datacolfin = [];
    for (var o = 0; o < totalerror.length; o++) {
      datacolfin[o] = [];
    }

    for (i = 0; i < datapoints.length; i++) {
      var newdata;
      newdata = {};
      newdata.Categorie = datapoints[i].x;

      newdata["Combined"] = datapoints[i]["Combined"];
      newdata["Simple"] = datapoints[i]["Simple"];
      newdata["Rule"] = datapoints[i]["Rule"];
      data.push(newdata);
    }
    console.log(data);
    var allData = [];
   // allData[0] = data;
   // allData[1] = this.prepareMultiBarChartData(data);

    allData = [].concat(allData)

    if (tab === "") {
      this.preparedDataChange.next(data);
    }
    else {
      this.analysisPreparedDataChange[tab].next(data);
    }
  
  }
  // formats multi bar chart data
  // called from DetectCpx, DetectSpl, DetectRule and analysis
  /*
  prepareMultiBarChartData(data) {

    var multiBarChartData = [];
    var variable = "Categorie";

    if (data.length != 0) {
      var keyNames = Object.keys(data[0]);
    }

    for (var m in data) {
      var newdata1;
      newdata1 = {};

      newdata1.values = [];

      for (var j in keyNames) {
        var key = keyNames[j];
        if (key === variable) {
          newdata1[keyNames[j]] = data[m][key];
        }
        else {
          var newdata2;
          newdata2 = {};
          newdata2.value = data[m][key];
          newdata2.value = + newdata2.value;
          newdata2.name = key;
          newdata2.category = data[m][variable];
          newdata1.values.push(newdata2);
        }

      }
      if (newdata1) {
        if (newdata1.length != 0) {
          multiBarChartData.push(newdata1)
        }
      }

    }
    return multiBarChartData;
  }
  */

  //allows to display the multi bar chart data
  // called from DetectCpx, DetectSpl, DetectRule
  setChartMultiDetect(tagHtml, multiBarChartData, variable,height,width) {

    var yVariable = "col";
    var title = "";
    var colorMultiBarChart = ['indianred', 'lightsalmon', '#0daaba', 'grey', 'blue'];
  // var colorMultiBarChart = ['#097682', '#0daaba', '#e6f6f8', 'grey', 'blue'];
    var lines: any[] = [];

    let result = new StackedBarChart(tagHtml, yVariable, title, height, width, variable, colorMultiBarChart, lines, multiBarChartData);
    return result;
  }
}