import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-topicstart',
  templateUrl: './topicstart.component.html',
  styleUrls: ['./topicstart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicstartComponent implements OnInit {

  constructor(private sharedService: SharedInitializationService) { }

  ngOnInit() {
  }

   //this allows to get the help documentation for topics
   getHelpDocumentation() {
    let parameters = ["admin", "settings", "topics"];
    this.sharedService.showHelpDocumentation("Admin Settings - Topics");

    this.sharedService.getHelpDocumentation(parameters);
  }

}
