import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpClientJsonpModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AdminGetLogoutTime } from './admin-get-logout-time.model';
import { AdminSetLogoutTime } from './admin-set-logout-time.model';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class LogoutTimeService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ){}

    logoutTime:number;
    logoutTimeChange =new Subject<number>();
    url= this.appService.getUrl();

    //Gets the logout time.
    //called in logout time component
    getLogoutTime() {
        const url = this.url;
        const webservice = "AdminGetLogoutTime";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.logoutTime = response.tableRows[0].logoutTime;
                this.logoutTimeChange.next(this.logoutTime);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }

    //Sets the logout time.
    //called in logout time component
    setLogoutTime(logoutTime) { 
        const url = this.url;
        const webservice = "AdminSetLogoutTime";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("logoutTime",logoutTime);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.appService.showMessage('Success', response.statusText); 
                this.logoutTimeChange.next(logoutTime);       
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
       
    
}