import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, OnDestroy, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PackagesService } from '../../packages.service';
import { AuthorizationsService } from '../../../authorizations/authorizations.service';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { ConfirmationService } from 'primeng/api';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';


@Component({
  selector: 'app-workflow-owner',
  templateUrl: './workflow-owner.component.html',
  styleUrls: ['./workflow-owner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowOwnerComponent implements OnInit, OnDestroy {

  //name of selected owner
  workflowName: string = "";
  //data (isActive, guitabs, workflows...)for selected owner
  workflowData: {
    workflowName: string,
    isActive: boolean,
    owners: any[],
  }[] = [];
  //subscription so that html elements are automatically updated
  workflowDataSubscription = new Subscription();
  //all the possible workflows
  owners: any[] = [];
  //subscription so that workflows are automatically updated
  ownersWorkflowSubscription = new Subscription();
  ownerDataSubscription = new Subscription();
  //form controls
  //form control for the workflows assigned to the owner
  selectedOwners = new FormControl('');
  //check whether the admin has changed one of these categories
  ownersChanged: boolean = false;
  //sets data for update (converting from array of objetcs, to array of strings)
  newOwners: any[] = [];

  constructor(private route: ActivatedRoute,
    private authorizationsServices: AuthorizationsService,
    private packagesServices: PackagesService,
    private sharedFunctionsService: SharedGeneralFunctionsService,
    private confirmationService: ConfirmationService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {

    //this is to get the username and ownerdata according to the param passed in the url
    this.route.params
      .subscribe((params: Params) => {
        if (params['workflowname']) {
          this.workflowName = params['workflowname'];
          //this is to get the users, sources, workflows and webservices
          //this.owners = this.packagesServices.returnAllOwners();
          this.packagesServices.getSingleWorkflowOwnersList(this.workflowName);
          console.log("owners", this.owners);
          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }
          //reset variables
          this.selectedOwners.setValue("");

          if (this.cd && !(this.cd as ViewRef).destroyed) {
            this.cd.detectChanges();
          }

          this.packagesServices.getSingleWorkflowOwnersData(this.workflowName);
        }
      })

    //this is to automatically update workflow data information
    this.workflowDataSubscription = this.packagesServices.workflowDataChange
      .subscribe((workflowData: any[]) => {
        this.workflowData = workflowData;

        if (this.workflowData.length != 0) {

          if (this.owners.length != 0) {
            console.log(this.owners)
            console.log(this.owners.length)
            this.getSelectedData("Owners", "UserName")
          }

        }
        // reinitialisation when owner changes;
        this.ownersChanged = false;

      })
    /*
//this is to automatically update workflow data information
this.ownerDataSubscription = this.packagesServices.ownerDataChange
.subscribe((ownerData: any[]) => {
 if (ownerData[0]!=undefined) {
 this.selectedOwners.setValue(ownerData[0]["owners"][0]);
 this.getSelectedData("Owners", "UserName")
 }
 
        
 // reinitialisation when owner changes;
 this.ownersChanged = false;

})*/

    //subscription to the value changes in the selected workflows for the owner
    this.selectedOwners.valueChanges
      .subscribe((owners: any) => {
        console.log(owners)
        this.newOwners = [];
        /*for (var i in owners) {
          this.newOwners.push(owners[i].UserName)
        }*/
        
        if (owners !== undefined) {
          if (owners.UserName !== undefined) {
            this.newOwners.push(owners.UserName);
          }
          else {
            for (var i in owners) {
              this.newOwners.push(owners[i].UserName)
            }
          }
          if (this.workflowData.length != 0) {
            if (this.sharedFunctionsService.arraysEqual(this.newOwners, this.workflowData[0].owners) === false) {
              this.ownersChanged = true;
            }
            else {
              this.ownersChanged = false;
            }
          }
          else {
            this.ownersChanged = true;
          }
        }
      })
      /*

          //subscription to the value changes in the selected workflows for the owner
    this.selectedOwners.valueChanges
    .subscribe((owners: any) => {
      console.log(owners)
      this.newOwners = [];
      for (var i in owners) {
        this.newOwners.push(owners[i].UserName)
      }
      if (this.workflowData.length != 0) {
        if (this.sharedFunctionsService.arraysEqual(this.newOwners, this.workflowData[0].owners) === false) {
          this.ownersChanged = true;
        }
        else {
          this.ownersChanged = false;
        }
      }
      else {
        this.ownersChanged = true;
      }
    })
    */

    this.ownersWorkflowSubscription = this.packagesServices.ownersWorkflowChange
      .subscribe((owners: any[]) => {
        this.owners = owners;
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }

        if (this.workflowData.length != 0) {
          // if (this.sharedFunctionsService.objectsEqual(this.workflowData[0].owners, this.selectedOwners.value) === false) {

          this.getSelectedData("Owners", "UserName");
          // }
        }
      })


  }// end on init
  /*
    //to able or disable the reactive/deactive/apply buttons
    getIsActive(){
      let active = false;
      if (this.ownerData.length != 0) {
         active = this.ownerData[0].isActive;
      }
      return active;
    }
  */
  //allows to update the selected workflows, users...for the owner in the list boxes
  getSelectedData(param, key) {

    let paramLowerCase = param.toLowerCase();

    let varSelected = "varSelected" + param;
    this[varSelected] = [];
    for (var i in this.workflowData[0][paramLowerCase]) {
      for (var j in this[paramLowerCase]) {
        if (this[paramLowerCase][j][key] === this.workflowData[0][paramLowerCase][i]) {
          this[varSelected].push(this[paramLowerCase][j]);

          break;
        }
      }
    }
    console.log(this[varSelected]);
    let selected = "selected" + param;
    this[selected].setValue(this[varSelected][0]);
  }

  //allows to update the owner data, delete the owner or reactivate the owner
  applyUpdateWorkflowOwnersData(param) {
    //let isActive = this.ownerData[0].isActive;
    let isActive = true;
    console.log(isActive)
    if (param === "update") {
      isActive = true;
    }
    else if (param === "delete") {
      isActive = false;
    }

    if ((param === "update") || (param === "delete")) {

      if (param === "delete") {
        /*  this.confirmationService.confirm({
            message: 'Are you sure that you want to delete ' +  this.ownerName + '?',
            header: 'Confirmation',
      
            accept: () => {
              this.packagesServices.createUpdateOwnerData(
                this.ownerName,
                isActive, 
                this.ownerData[0].workflows,
  
              )
            }
          });
          */
      }
      else {
        this.packagesServices.createUpdateWorkflowOwnersData(
          this.workflowName,
          isActive,
          //this.ownerData[0].workflows,
          this.newOwners
        )

      }

    }
    else {

      /* this.packagesServices.createUpdateOwnerData(
         this.ownerName,
         isActive, 
         this.newWorkflows,
 
       )
       */
    }
  }

  //this allows to get the help documentation for editing a owner
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "owners", "editowner"];
    this.sharedService.showHelpDocumentation("Admin Settings - Edit Owner");

    this.sharedService.getHelpDocumentation(parameters)
  }

  //this is to make sure the subscription won't work once the component is destroyed
  ngOnDestroy() {
    this.workflowDataSubscription.unsubscribe();
    this.ownersWorkflowSubscription.unsubscribe();
    this.ownerDataSubscription.unsubscribe();
  }
}

