import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { compileBaseDefFromMetadata } from '@angular/compiler';

@Component({
  selector: 'app-email-configuration-display',
  templateUrl: './email-configuration-display.component.html',
  styleUrls: ['./email-configuration-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailConfigurationDisplayComponent implements OnInit {

  @Input() emailConfigurationForm: FormGroup = new FormGroup({
    emailSender: new FormControl('', Validators.required),
    emailObject: new FormControl('', Validators.required),
    emailPassword: new FormControl('', Validators.required),
    emailFrequency: new FormControl('', Validators.required),
    selectedFieldFP: new FormControl('', Validators.required),
    selectedFieldSP: new FormControl('', Validators.required),
    selectedFieldTP: new FormControl('', Validators.required),
    companyText: new FormControl('')
  });
;
  email: { emailSender: string,
           emailObject: [],
           emailPassword: string, 
           emailFrequency: string,
           selectedFieldFP: [],
           selectedFieldSP: [],
           selectedFieldTP: [],
           companyText: string,
          };
  dateNow = new Date();        
  date= new Date();
  emailTitle: string;


  constructor() { }

  ngOnInit() {
    console.log(this.emailConfigurationForm);
    this.email = this.emailConfigurationForm.value;

    if (this.email.emailFrequency === 'weekly') {
      this.date = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(),this.dateNow.getDate()-7);
    }
    else if (this.email.emailFrequency === 'monthly') {
      this.date = new Date(this.dateNow.getFullYear(), this.dateNow.getMonth(),this.dateNow.getDate()-30);
    }

    console.log(this.email.emailObject)

    this.emailTitle="";

    for (var i in this.email.emailObject) {
      if (this.email.emailObject[i] === "# New Predictions") {
        this.emailTitle = this.emailTitle + ' 2 New Predictions';
      }
      else if (this.email.emailObject[i] === "# Old Predictions") {
        this.emailTitle = this.emailTitle+ ' 5 Old Predictions';
      }
      else if (this.email.emailObject[i] === "# New Predictions By Workflow") {
        this.emailTitle = this.emailTitle+ ' 5 New Predictions in Workflow25';
      }
      else {
        this.emailTitle = this.emailTitle +" "+ this.email.emailObject[i];
      }
    }
   

  }

}
