
export class MatchingTab {
    public name: string;
    public workflow: string;
    public type: string;
    public table: string;
    public subtable: string;
    public segAttributeValue: string;
    public dynSegAttributeValue: string;
    public matchingType: string;
    public tabType: string;
    public accountNumber: string;
    public accountNumberValue: string;
    public gridSegregationAttribute: string;
    public reconciliationAttribute: string;
    constructor(
        name: string,
        workflow: string,
        type: string,
        table: string,
        subtable: string,
        segAttributeValue: string,
        dynSegAttributeValue: string,
        matchingType: string,
        tabType: string,
        accountNumber: string,
        accountNumberValue: string,
        gridSegregationAttribute: string,
        reconciliationAttribute: string
        ) {
        this.name = name;
        this.workflow = workflow;
        this.type = type;
        this.table = table;
        this.subtable =subtable;
        this.segAttributeValue = segAttributeValue;
        this.dynSegAttributeValue = dynSegAttributeValue;
        this.matchingType = matchingType;
        this.tabType = tabType;
        this.accountNumber = accountNumber;
        this.accountNumberValue = accountNumberValue;
        this.gridSegregationAttribute = gridSegregationAttribute;
        this.reconciliationAttribute = reconciliationAttribute;
    }
}