import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from '../../shared/shared-common-initialization.service';
import { DatePipe } from '@angular/common';
import { WorkflowNameService } from '../../workflow/services/workflow-name.service';
import { CustomHttpParamEncoder } from '../../shared/custom-http-param-encoder';
import { SharedGeneralFunctionsService } from '../../shared/shared-general-functions.service';
import { MultiStackedBarChart } from 'src/app/shared/d3/d3multistackedbarchart';
import { Bookmark } from '../models/analysis-bookmark.model';
import { AnalysisTab } from '../models/analysis-tab.model';
import { AnalysisService } from './analysis.service';

@Injectable()
export class AnalysisAuditService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowNameService: WorkflowNameService,
    private analysisService: AnalysisService,
    private sharedService: SharedInitializationService,
    private sharedFunctionService: SharedGeneralFunctionsService) {
  }

  url = this.appService.getUrl();

  //fieldID
  fieldID: any = {};
  //this is for the analysis grid, allows to automatically update the field id
  fieldIDChange: { [index: string]: Subject<string[]> } = {};

  //this is for the audit page of the analysis and dashboard tabs, it allows to automatically update the transactions's data
  auditDataChange: { [index: string]: Subject<any> } = {};
  //this is for the audit page of the analysis and dashboard tabs, it allows to automatically update the transactions's previous comments and true status
  auditRecordsChange: { [index: string]: Subject<any> } = {};
  auditOpenCloseChange : { [index: string]: Subject<any> } = {};
  // currency for anomaly cost in audit or label selected
  currencyArray: {name: string}[];
  currencyArrayChange = new Subject<{name: string}[]>();
  //initialize variable for users
  usersArray = [];
  usersArrayChange : { [index: string]: Subject<any> } = {};
  //init analysis tab changes
  initTabChanges(tabs) {
    for (var i in tabs) {
      this.auditDataChange[tabs[i].name] = new Subject<any>();
      this.auditRecordsChange[tabs[i].name] = new Subject<any>();
      this.auditOpenCloseChange[tabs[i].name] = new Subject<any>();
      this.usersArrayChange[tabs[i].name] = new Subject<any>();
    }
  }

  //this allows to update audit files for each comment
  //called in audit component
  analysisDownloadAuditFile(wfName, fieldIDValue, filename) {

    const url = this.url;
    const webservice = "AnalysisDownloadAuditFile";
    const completeUrl = url + webservice;
    let files = filename.split(".");
    let filetype = files[files.length - 1].toLowerCase();
    fieldIDValue = fieldIDValue.replace(/:/g, '');
    console.log(filetype)
    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", wfName)
      .set("fieldidvalue", fieldIDValue)
      .set("fileName", filename)
      .set("fileTypeAudit", filetype)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        this.sharedService.downloadAllFiles(response, filename, filetype)
        // let name = workflow + "_" + table;

        // window.open(downloadURL);


        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }




  //Loads the unique record from the input parameters and the history of its status.
  //called in analysis grid component

  getAuditRecord(workflow, table, fieldIDValue, predictionMode, tab) {

    // take the field id used for the audit
    // fieldID is the field ID of the workflow or
    // when it's not detail
    // for aggregate workflow  "CycleID" or "aggregationID"
    // for temporal workflow "leaf_name"
    let fieldID = this.analysisService.getFieldID_audit(tab);

    const url = this.url;
    const webservice = "AnalysisGetTableRecord";
    const completeUrl = url + webservice;

    this.appService.startSpin2();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      // .set("srcName", source)
      .set('segAttrValue', table)
      .set('fieldIDValue', fieldIDValue)
      .set('predictionMode', predictionMode)
      .set('fieldID', fieldID);
      
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.auditDataChange[tab].next(response.tableRows);
          this.auditRecordsChange[tab].next(response.tableRows2);
          console.log("c'est là.............................");
          this.auditOpenCloseChange[tab].next(response.tableRows3);
          // this.gaussAnomaliesChange.next(response.tableRows[0].Anomaly);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

    //Loads the ladder from the config directory.
  //called in new dim component
  getCurrency() {
    const url = this.url;
    const webservice = "WFGetCurrency";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.currencyArray = response.tableRows;
          this.currencyArrayChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

    //return the currency
    returnCurrency() {
      return this.currencyArray;
    }

        //Loads the ladder from the config directory.
  //called in new dim component
  getUsersWorkflow(workflow, tab) {
    const url = this.url;
    const webservice = "WFGetUsersWorkflow";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
    .set("wfName", workflow)
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.usersArray = response.tableRows;
          this.usersArrayChange[tab].next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
     
  
}
