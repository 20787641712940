import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminStartComponent } from './admin-start/admin-start.component';
import { AdminComponent } from './admin.component';
import { SettingsModule } from './settings/settings.module';
import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

//import { SourceManagementModule } from './source-management/source-management.module';

@NgModule({
    declarations: [
        AdminStartComponent,
        AdminComponent,
    ],
    imports: [
        AdminRoutingModule,
        RouterModule,
        CommonModule,
        SettingsModule,
        TableModule,
       // SourceManagementModule
    ]
})

export class AdminModule {}