import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { SharedGeneralFunctionsService } from "src/app/shared/shared-general-functions.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { BayesErrors } from "../../models/bayes/bayes-error.model";

@Injectable()
export class AnalysisBayesService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
        private workflowNameService: WorkflowNameService,
        private sharedService: SharedInitializationService,
        private sharedFunctionService: SharedGeneralFunctionsService) {
    }

    url = this.appService.getUrl();


    //anomaly count by type (rule, simple, combined)
    errorsDetected: {} = {};
    errorsDetectedChange: { [index: string]: Subject<BayesErrors[]> } = {};
    errorfieldcombined: {} = {}


    //init variables for view combinations button and area view
    viewCombinationsChange: { [index: string]: Subject<boolean> } = {};
    viewCombinations: {} = {}
    viewCombinationsDataChange: { [index: string]: Subject<any[]> } = {};
    disableViewCombinationsButtonChange: { [index: string]: Subject<boolean> } = {};

  

    //this is to set up the bayes tab changes 
    initTabChanges(tabs) {
        for (var i in tabs) {

            this.errorsDetectedChange[tabs[i].name] = new Subject<any[]>();
            this.viewCombinationsDataChange[tabs[i].name] = new Subject<any[]>();
            this.viewCombinationsChange[tabs[i].name] = new Subject<boolean>();
            this.disableViewCombinationsButtonChange[tabs[i].name] = new Subject<boolean>();
            this.viewCombinations[tabs[i].name] = false;
            this.errorsDetected[tabs[i].name] = [];
            this.errorfieldcombined[tabs[i].name] = [];
        }
    }


    //Returns the number of anomalies and correct records for the bayesian workflows
    //called in analysis menu 
    // getBayesStatsCount(workflow,source, segAttribute, segAttributeValue, proMode )  {
    getBayesStatsCount(workflow, segAttributeValue, proMode, tab) {
        const url = this.url;
        const webservice = "StatBayesCountTables";
        const completeUrl = url + webservice;

        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttrValue", segAttributeValue)
            .set("proMode", proMode)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                console.log(response)
                if (response.statusCode > -1) {
                    console.log(response.tableRows);
                    this.errorsDetected[tab] = response.tableRows;
                    this.errorsDetectedChange[tab].next([...this.errorsDetected[tab]]);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    //get the number of records for bayes combinations
    //called in 
    getBayesCombinationNbRec(tab) {
        console.log("errorsDetected");
        let errorFile = { "Rule": 0, "Simple": 0, "Combined": 0, "Correct": 0, "AllAnomaly": 0 };
        for (var i in this.errorsDetected[tab]) {
            let name = this.errorsDetected[tab][i]
            switch (Object.keys(this.errorsDetected[tab][i])[0]) {
                case 'Rule':
                    errorFile.Rule = this.errorsDetected[tab][i]["Rule"];
                    break;
                case 'Simple':
                    errorFile.Simple = this.errorsDetected[tab][i]["Simple"];
                    break;
                case 'Combined':
                    errorFile.Combined = this.errorsDetected[tab][i]["Combined"];
                    break;
                case 'Correct':
                    errorFile.Correct = this.errorsDetected[tab][i]["Correct"];
                    break;
                case 'AllAnomaly':
                    errorFile.AllAnomaly = this.errorsDetected[tab][i]["AllAnomaly"];
                    break;
                case 'FielID':
                    //fieldID =this.errorsDetected[i]["FielID"];// from apservices tablestat
                    break;
                default:
                    break;
            }
        }
        var nbRec = 0;
        if (this.errorsDetected[tab] !== undefined) {
            nbRec = errorFile.AllAnomaly + errorFile.Correct - errorFile.Simple - errorFile.Rule;
        }
        return nbRec;
    }
        //computes the possible field anomaly combinations for a given recordID
    //called in analysis menu bayes
    //automatically updates analysis labelise selected for rules
    getBayesCombinations(workflow, segAttribute, table, selection, typeOfAnomaly, tab) {
        const url = this.url;
        const webservice = "AnalysisBayesOneRecordAllAnomalies";
        const completeUrl = url + webservice;

        this.appService.startSpin()
        let sourceRec = "{\"AttrName\":[" + JSON.stringify(selection[0]) + "]}";;
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttr", segAttribute)
            .set('segAttrValue', table)
            .set('sourceRec', sourceRec)
            .set('typeOfAnomaly', typeOfAnomaly)
            ;
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                console.log(response);
                if (response.statusCode > -1) {
                    this.viewCombinationsDataChange[tab].next(response.tableRows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }
    /*
    //computes the possible field anomaly combinations for a given recordID
    //called in analysis menu bayes
    //automatically updates analysis labelise selected for rules
    getBayesCombinations(workflow, segAttribute, table, alpha, nbRec, K, algo, selection, tab) {
        const url = this.url;
        const webservice = "AnalysisBayesOneRecordAllAnomalies";
        const completeUrl = url + webservice;

        this.appService.startSpin()
        let sourceRec = "{\"AttrName\":[" + JSON.stringify(selection[0]) + "]}";;
        //Create new HttpParams 
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttr", segAttribute)
            .set('segAttrValue', table)
            .set('alpha', alpha)
            .set('nbRec', nbRec)
            .set('K', K)
            .set('algo', algo)
            .set('sourceRec', sourceRec)
            ;
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                console.log(response);
                if (response.statusCode > -1) {
                    this.viewCombinationsDataChange[tab].next(response.tableRows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }
*/
    setBayesCombinationNotCombined(errorfieldcombined, tab) {
        //this.errorfieldcombined = [].concat(errorfieldcombined);
        // need to double clcik on veiw combindation ...?
        this.errorfieldcombined[tab] = JSON.parse(JSON.stringify(errorfieldcombined));
        errorfieldcombined[tab] = [].concat(errorfieldcombined[tab]);

        this.viewCombinationsDataChange[tab].next(this.errorfieldcombined[tab]);
    }

    //determines whether the view combinations within labelise selected area should be shown or not
    //called in analysis menu component and analysis labelise selected component
    showViewCombinations(tab) {
        // this.viewCombinations = !this.viewCombinations;
        this.viewCombinations[tab] = true;
        this.viewCombinationsChange[tab].next(this.viewCombinations[tab]);
    }
    hideViewCombinations(tab) {
        // this.viewCombinations = !this.viewCombinations;
        this.viewCombinations[tab] = false;
        this.viewCombinationsChange[tab].next(this.viewCombinations[tab]);
    }
    //set the view combinations param to make sur the real value of view combination is given
    //called in analysis grid component
    setViewCombinations(param, tab) {
        this.viewCombinations[tab] = false;
    };

    //determines whether the view combinations button within analysis menu should be shown or not
    //called in analysis bayes menu
    disableViewCombinationsButton(param, tab) {
        this.disableViewCombinationsButtonChange[tab].next(param);
    }


}