<div class="displayEmailContainer container">
    <div class="row">
        <div class="form-row col-md-12">
          <div class="form-group col-md-6 col-sm-12 col-xs-12">
              <label for="emailRecipient" class="col-md-4 col-xs-12 col-sm-12">To: </label>
              <input autocomplete="off" type="email" id="emailRecipient" class="form-control" [disabled]="true" value="cardabeluser@cardabel.com">
          </div>
          <div class="form-group col-md-6 col-sm-12 col-xs-12">
              <label for="emailSender" class="col-md-4 col-xs-12 col-sm-12">From: </label>
              <input autocomplete="off" type="email" id="emailSender" class="form-control" [disabled]="true" value="{{email.emailSender}}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
            <label for="emailObject" class="col-md-4 col-xs-12 col-sm-12">Object: </label>
            <input autocomplete="off" type="text" id="emailObject" class="form-control" [disabled]="true" value="{{emailTitle}}">
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
            <h4>Hi CardabelUser, </h4>
        </div>
      </div>
      <div class="row">
          <div class="centerText col-md-12">               
            <p>This is the {{email.emailFrequency}} report of predictions found by Cardabel</p>
          </div>
      </div>
      <div class="row">
          <div class="centerText col-md-12">
           <p><strong>Total new predictions found since {{ date | date: 'dd-MM-yyyy'}} : </strong> </p>
           <p>Please check the following new anomalies since {{ date | date: 'dd-MM-yyyy' }}: </p>
      
          </div>
       </div>
      <div class="row">
        <div class="col-md-12">
            
            <table style="border-collapse:collapse; text-align:center;width:99%" >
                <tr style ="background-color:#116fbf; color:#ffffff;">
                    <td *ngFor="let header of email.selectedFieldFP" style=" border-color:#116fbf; border-style:solid; border-width:thin; padding: 5px;">{{header.label}}</td>
                </tr>
                <tr><td *ngFor="let header of email.selectedFieldFP" style=" border-color:#116fbf; border-style:solid; border-width:thin; padding: 5px;"></td></tr>
            </table>
        </div>
      </div>
      <div class="row">
          <div class="centerText col-md-12">
              <p><strong>Total unknown predictions before : {{date | date: 'dd-MM-yyyy'}} : </strong> </p>
              <p>Please check the following anomalies found before {{date | date: 'dd-MM-yyyy'}} that have yet to be analyzed: the true status is unknown. </p>
      
          </div>
       </div>
      <div class="row">
        <div class="col-md-12">
            
            <table style="border-collapse:collapse; text-align:center;width:99%" >
                <tr style ="background-color:#116fbf; color:#ffffff;">
                    <td *ngFor="let header of email.selectedFieldSP" style=" border-color:#116fbf; border-style:solid; border-width:thin; padding: 5px;">{{header.label}}</td>
                </tr>
                <tr><td *ngFor="let header of email.selectedFieldSP" style=" border-color:#116fbf; border-style:solid; border-width:thin; padding: 5px;"></td></tr>
            </table>
        </div>
      </div>
      <div class="row">
          <div class="centerText col-md-12">
              <p><strong>Total assign/follow-up predictions before {{date | date: 'dd-MM-yyyy'}} : </strong> </p>
              <p>Please check the following anomalies found before {{date | date: 'dd-MM-yyyy'}} where the true status is assign or follow-up. </p>
      
          </div>
       </div>
      <div class="row">
        <div class="col-md-12">
            
            <table style="border-collapse:collapse; text-align:center;width:99%" >
                <tr style ="background-color:#116fbf; color:#ffffff;">
                    <td *ngFor="let header of email.selectedFieldTP" style=" border-color:#116fbf; border-style:solid; border-width:thin; padding: 5px;">{{header.label}}</td>
                </tr>
                <tr><td *ngFor="let header of email.selectedFieldTP" style=" border-color:#116fbf; border-style:solid; border-width:thin; padding: 5px;"></td></tr>
            </table>
        </div>
      </div>
      
      <div class="row">
          <div class="centerText col-md-12">
              <p>{{email.companyText}}</p>
      
          </div>
       </div>
</div>

