import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { WorkflowListModel } from './models/gui/workflow-list-model';
import { SourceListModel } from './models/gui/source-list.model';
//import { GetWorkflowListModel } from '../workflow/models/webservices/workflow-get-workflow-list.model';
//import { GetSourceListModel } from '../workflow/models/webservices/workflow-get-source-list.model';
import { catchError, skipUntil } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { DashboardService } from '../dashboard/dashboard.service';
import { TypeListModel } from './models/gui/type-list.model';

@Injectable()
export class SharedInitializationService {
  constructor(private appService: AppService,
    private dashboardService: DashboardService,
    private httpClient: HttpClient) { }

  url = this.appService.getUrl();

  workflows: WorkflowListModel[] = [];
  topics: any[] = [];
  topicsChange = new Subject<any[]>();

  //this is for the analysis and the workflow components, so that the workflow list is automatically updated
  WorkflowListChange = new Subject<WorkflowListModel[]>();
  analysisWorkflowListChange = new Subject<WorkflowListModel[]>();
  matchingWorkflowListChange = new Subject<WorkflowListModel[]>();
  workflowWorkflowListChange = new Subject<WorkflowListModel[]>();
  executionWorkflowListChange = new Subject<WorkflowListModel[]>();
  topicsDashboardItemWorkflowListChange = new Subject<WorkflowListModel[]>();
  sources: SourceListModel[];
  //this is for the analysis and the workflow components, so that the source list is automatically updated
  sourceListChange = new Subject<SourceListModel[]>();
  trueStatusTypes: any[] = [];
  trueStatus: any[] = [];
  trueStatusChange = new Subject<WorkflowListModel[]>();

  //types
  types: TypeListModel[] = [
    { name: 'Bayes' },
    { name: 'Gauss' },
    { name: 'Aggregate' },
    { name: 'Temporal' },
    { name: 'Supervised' },
    { name: 'Matching'},
    { name: 'Kernel'}
  ]
  
  
 
  // this is for the analysis audit and for the supercycle component, so that the true status are automatically updated
  // trueStatusTypesChange = new Subject<any>();
  users: any[] = [];
  //!! this is not updated anywhere
  usersChange = new Subject<any>();
 //initialize variable for users
 usersWorkflow = [];
 usersWorkflowChange = new Subject<any>();

  arrayObjects: { truestatustype: string, label: string, prefix: string, value: string }[];

  visibleSidebarChange = new Subject<boolean>();
  helpTitleChange = new Subject<string>();
  helpDataChange = new Subject<any>();
  helpImageChange = new Subject<any>();
  helpParameters:string[] = [];
  parametersChange = new Subject<any>();

  existMatchingChange = new Subject<boolean>();

  //Returns types
  getTypes() {
    return [...this.types];
  }
 
  //O. Returns the list of authorized workflows for the logged in user.
  // called in workflow and analysis components
  getAuthorizedWorkflows(from) {

    let topicName = this.appService.getSelectedTopic();
    const url = this.url;
    const webservice = "WFGetWorkflowList";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
    .set("topicName", topicName)
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.workflows = response.tableRows;
          if (from === "matching") {
            this.workflows = this.workflows.filter(item => item.WFType === "Matching")
          }
          let workflowListChange = from+ "WorkflowListChange";
          this[workflowListChange].next(this.workflows)
          
        }
        else {
          //add 27/03
          this.workflows = [];
          // end add
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //returns the workflows
  // called in new workflow component
  getWorkflows() {
    return this.workflows;
  }

  //O. Returns the list of authorized data sources for the logged in user.
  //called in workflow component 
  getAuthorizedSources(from) {

    const url = this.url;
    const webservice = "WFGetSourceList";
    const completeUrl = url + webservice;
    let topicName = this.appService.getSelectedTopic();
    if (from === 'ALL') {
      topicName = "ALL";
    }

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
    .set("topicName", topicName)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.sources = response.tableRows;
          this.sourceListChange.next(this.sources);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  getTopicData() {
    console.log("AdminGetTopicsList");

    this.appService.startSpin2();
    const url = this.url;
   // const webservice = "AdminGetTopicsList";
    const webservice = "AccessGetTopicsList";
    const completeUrl = url + webservice;


    //create new http params
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
        .pipe(
            //map(response => new NmUser(response)),
            catchError(this.appService.handleError)
        )
        .subscribe((response: any) => {

            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                this.topics = response.tableRows;
                console.log(this.topics)
                this.topicsChange.next(this.topics);
            }
            else {
                this.appService.showMessage('Error', response.statusText)
            }
            this.appService.stopSpin2();
        }, (error) => {
            this.appService.showMessage('Error', error.statusText);
            this.appService.stopSpin2();
        });


}

getTopics() {
  return this.topics;
}

checkIfMatchingWorkflowExists(topicName) {
  for (var k in this.topics) {
    let topic = Object.keys(this.topics[k])[0];
    if (topic === topicName) {
      if( this.topics[k][topic][0].Exist_matching) {
        this.existMatchingChange.next(true);
      }
      else {
        this.existMatchingChange.next(false);
      }
      break;
    }
  }
  if (this.topics.length === 0) {
    this.existMatchingChange.next(false);
  }
  
}

checkIfWorkflowExistsInTopic(topicName, workflow) {
  for (var k in this.topics) {
    let topic = Object.keys(this.topics[k])[0];
    if (topic === topicName) {
      if( this.topics[k][topic][0].Workflows.indexOf(workflow) > -1) {
        return true;
      }
      else {
        return false;
      }
      break;
    }
  }
  return false;
}

checkIfSourceExistsInTopic(topicName, source) {
  for (var k in this.topics) {
    let topic = Object.keys(this.topics[k])[0];
    if (topic === topicName) {
      if( this.topics[k][topic][0].Sources_list.indexOf(source) > -1) {
        return true;
      }
      else {
        return false;
      }
      break;
    }
  }
  return false;
}
  // returns the list of truestatus
  // called in rules component and audit and label
  getTrueStatus() {
    /*
    return [
      { label: 'Anomaly', value: 'Anomaly' },
      { label: 'Normal', value: 'Normal' },
      { label: 'Assign', value: 'Assign' },
      { label: 'Follow Up', value: 'Follow Up' },
      { label: 'Unknown', value: 'Unknown' },
    ]*/

        const url = this.url;
        const webservice = "AdminGetTrueStatus";
        const completeUrl = url+webservice;
      
        this.appService.startSpin();
         //Create new HttpParams 
        let params = new HttpParams(); 
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            response = response.replace(/[()]/g, '');
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
              this.trueStatus = response.tableRows;
              this.trueStatusChange.next(this.trueStatus);
            }
            else {
              this.appService.showMessage('Error', response.statusText);
            }
            this.appService.stopSpin();
          }, (error) => {
              this.appService.showMessage('Error', error.statusText);
              this.appService.stopSpin();
          });

  }
  /*
      //Returns the list of true status types.
      //called in analysis component
      //!!!! webservice is also initialized in workflow main steps
      getTrueStatusTypes() {
          const url = this.url;
          const webservice = "WFGetTrueStatusTypes";
          const completeUrl = url+webservice;
        
          this.appService.startSpin();
           //Create new HttpParams 
          let params = new HttpParams(); 
          const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        
          return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
            .pipe(
              catchError(this.appService.handleError)
            )
            .subscribe((response:any) => {
              console.log(response);
              response = response.replace(/[()]/g, '');
              response = JSON.parse(response);
              if (response.statusCode > -1 ) {
                this.trueStatusTypes = response.tableRows[0].trueStatusType;
                this.trueStatusTypesChange.next(this.trueStatusTypes);
              }
              else {
                this.appService.showMessage('Error', response.statusText);
              }
              this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
      }
  */
 
  //O. Returns the list of users.
  // called in analysis component
  getUsers() {

    const url = this.url;
    const webservice = "WFGetUserNames";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.users = response.tableRows;
          this.usersChange.next(this.users);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  getUsersWorkflow(workflow) {
    const url = this.url;
    const webservice = "WFGetUsersWorkflow";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
    .set("wfName", workflow)
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.usersWorkflow = response.tableRows;
          this.usersWorkflowChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  //returns true status types
  //called in analysis labelise selected, and in analysis audit
  returnTrueStatusTypes() {
    return this.trueStatusTypes;
  }

  // returns the background color following the truestatustype
  getTrueStatusColor(truestatus) {
    var boolFollowUp = false;
    var boolAssign = false;

    var boolAnomaly = false;
    var boolNormal = false;
    var boolUnknown = false;

    switch (truestatus) {
      case ('Normal'):
        boolNormal = true;
        break;
      case ('Follow Up'):
        boolFollowUp = true;
        break;
      case ('Assign'):
        boolAssign = true;
        break;
      case ('Anomaly'):
        boolAnomaly = true;
        break;
      case ('Unknown'):
        boolUnknown = true;
        break;
      default:
        break;
    }
    return { 'NormalCss': boolNormal, 'FollowUpCss': boolFollowUp, 'AssignCss': boolAssign, 'AnomalyCss': boolAnomaly, 'UnknownCss': boolUnknown };

  }
  getTrueStatusColor1(trueStatus) {

    return trueStatus.replace(/\s+/g, '') + 'Color';
  }
  //returns true status types
  //called in analysis audit component
  returnUsers() {
    return this.users;
  }

  showHelpDocumentation(title) {
    this.visibleSidebarChange.next(true);
    this.helpTitleChange.next(title);
  }

  //this allows to get the pictures included in the help file and then display them in the help sidebar
  //called by getHelpDocumentation 
  getHelpDocumentationImages(parameters) {
    let listOfParams = JSON.stringify(parameters)
    listOfParams = "{\"listOfParams\":" + listOfParams + "}";
    const url = this.url;
    const webservice = "DocumentationGetHelpImages";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("parameters", listOfParams)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
      //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
      
        if (response.statusCode > -1) {
          this.helpImageChange.next(response.tableRows);
         // this.helpDataChange.next(response.tableRows[0].data);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this allows to get the text included in the help file and then display it in the help sidebar
  //called by app component
  getHelpDocumentation(parameters) {
    this.helpParameters = parameters;
    let listOfParams = JSON.stringify(parameters)
    listOfParams = "{\"listOfParams\":" + listOfParams + "}";
    const url = this.url;
    const webservice = "DocumentationGetHelp";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("parameters", listOfParams)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        this.parametersChange.next(parameters);
        response= response.substring(1,response.length-1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.helpDataChange.next(response.tableRows[0].data);
          this.getHelpDocumentationImages(parameters);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this allows to export the help file to pdf
  //called by app component
  exportHelpDocumentationFile(param) {
    let listOfParams = JSON.stringify(this.helpParameters)
    listOfParams = "{\"listOfParams\":" + listOfParams + "}";
    const url = this.url;
    const webservice = "DocumentationDownloadHelpPDF";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("parameters", listOfParams)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      const newBlob = new Blob([(response)], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const downloadURL = URL.createObjectURL(newBlob);
        window.open(downloadURL);
    
      
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //this is to convert array of objects to csv file
  ConvertToCSV(objArray: any, separator: any): string {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + separator;
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += separator

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

  downloadAllFiles(response, filename, filetype) {
    let newBlob;
    if ((filetype === "csv") || (filetype === "xlsx")) {
      newBlob = new Blob([(response)], { type: 'text/csv' });
    }
    else if (filetype === "pdf") {
      newBlob = new Blob([(response)], { type: 'application/pdf' });
    }
    else if ((filetype === "jpeg") || (filetype === "png") || (filetype === 'gif')) {
      newBlob = new Blob([(response)], { type: 'image/'+filetype });
    }
    else if (filetype === "txt") {
      newBlob = new Blob([(response)], { type: 'text/plain' });
    }
    else {
      newBlob = new Blob([(response)], {type: 'application/octet-stream'})
    }

      // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob, filename);
            return;
          }
  
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const downloadURL = URL.createObjectURL(newBlob);
  
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(newBlob);
          a.href = url;
          //a.download = filename+".csv";
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
  }
  //this is to download the csv file to client server
  downloadCSVFile(response, filename) {
  // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([(response)], { type: 'text/csv' });
       console.log("coucouc")
       // const newBlob = new Blob([(response)], { type: 'application/vnd.ms.excel'});

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob, filename);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const downloadURL = URL.createObjectURL(newBlob);

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(newBlob);

        a.href = url;

        a.download = filename+".csv";


        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
  }

   //this is to download the csv file to client server
   downloadExcelFile(response, filename) {
    // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          const newBlob = new Blob([(response)], { type: 'text/csv' });
         console.log("coucouc")
         // const newBlob = new Blob([(response)], { type: 'application/vnd.ms.excel'});
  
          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob, filename);
            return;
          }
  
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const downloadURL = URL.createObjectURL(newBlob);
  
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(newBlob);
          a.href = url;
          //a.download = filename+".csv";
          a.download = filename+".xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
    }
  

  returnFullTime(fulldate) {
    var h = fulldate.getHours();
    var m = fulldate.getMinutes();
    var s = fulldate.getSeconds();
    h = this.checkTime(h);
    m = this.checkTime(m);
    s = this.checkTime(s);
    return h + ":" + m + ":" + s;
  }

  checkTime(i) {
    if (i < 10) { i = "0" + i };  // add zero in front of numbers < 10
    return i;
  }


}