import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { TrueStatusTypeService } from './true-status-type.service';
import { Subscription } from 'rxjs';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-true-status-type',
  templateUrl: './true-status-type.component.html',
  styleUrls: ['./true-status-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TrueStatusTypeComponent implements OnInit, OnDestroy {

  //selectedTrueStatusTypes: { truestatustype: string, label: string, prefix: string, value: string };
 // selectedPrefixes: { truestatustype: string, label: string, prefix: string, value: string };
  subscriptionTrueStatusType = new Subscription();
  //trueStatusTypes: { truestatustype: string, label: string, prefix: string, value: string }[];
  //allTrueStatusTypes: { truestatustype: string, label: string, prefix: string, value: string }[];
  //prefixes: { prefix: string, label: string, value: string }[];
  number: number;
  //showAddNewTrueStatusType: boolean = false;
  //showAddNewPrefix: boolean = false;
  //
 
  //this is to set the first and second group fields so that they are automatically updated
  groupField1: string = "prefix";
  groupField2: string = "truestatustype";
  trueStatusData: { truestatustype: string, prefix: string, editing: boolean }[] = [];

  @ViewChild('trueStatusTypesTable') trueStatusTypesTable;

  constructor(private trueStatusTypeService: TrueStatusTypeService,
    private sharedService: SharedInitializationService) {

  }

  ngOnInit() {

  //  this.allTrueStatusTypes = [];
    this.trueStatusData = [];
    this.trueStatusTypeService.getTrueStatusTypes();

    this.subscriptionTrueStatusType = this.trueStatusTypeService.trueStatusTypesChange
      .subscribe(
        (trueStatusTypes: { truestatustype: string, prefix: string }[]) => {
         
          this.trueStatusData.length = 0;

          for (let i = 0; i < trueStatusTypes.length; i++) {
            // editing false for all
            // remove the prefix and the ":" from truestatustype

            let newdata = { truestatustype: trueStatusTypes[i].truestatustype, prefix: trueStatusTypes[i].prefix, editing: false };
            if (trueStatusTypes[i].truestatustype.startsWith(trueStatusTypes[i].prefix)) {
              let l = trueStatusTypes[i].prefix.length;
              newdata.truestatustype = trueStatusTypes[i].truestatustype.substr(l + 1);
            }
            newdata.prefix = newdata.prefix.toUpperCase();
            this.trueStatusData.push(newdata);
          }
          this.trueStatusData = this.trueStatusData.sort(this.fieldSorter(["prefix"]));
          this.trueStatusData = [...this.trueStatusData];
          // this.updateRowGroupMetaData();
 
        }
      );
  }// end On Init;
 

  //this is to sort an array of objects
  fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o = o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
  }).reduce((p, n) => p ? p : n, 0);

  applyTrueStatusType(){

    this.trueStatusData = this.trueStatusTypesTable.returnTableData();
    //let allTrueStatusTypes = "{\"trueStatusType\":"+JSON.stringify(this.trueStatusData)+"}";
    let allTrueStatusTypes = JSON.stringify(this.trueStatusData);
    this.trueStatusTypeService.updateTrueStatusTypes(allTrueStatusTypes);

   
  }

   //this allows to get the help documentation for settings
   getHelpDocumentation() {
    let parameters = ["admin", "settings", "truestatustype"];
    this.sharedService.showHelpDocumentation("Admin Settings True Status Types");

    this.sharedService.getHelpDocumentation(parameters)
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionTrueStatusType.unsubscribe();
  }

}
