import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, ChangeDetectorRef, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { CardabelTooltipDirective } from '../../cardabel-tooltip/cardabel-tooltip.directive';


@Component({
  selector: 'app-cardabel-listbox-item',
  templateUrl: './cardabel-listbox-item.component.html',
  styleUrls: ['./cardabel-listbox-item.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardabelListboxItemComponent implements OnInit {

  //init variables for checkboxes
  @Input() item: string = "";
  @Input() checked: boolean;
  @Input() multiple: boolean = false;
  @Input() disabledOption: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() tooltipPosition: string;
  @Input() tooltipDisabled: boolean;
  @Input() tooltipText: any;
  @Input() option: any;
  @Input() strong: boolean = false;

  @ViewChild(CardabelTooltipDirective) tooltip = null

  //tooltip
  pendingTooltip: string = "";


  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    console.log(this.disabledOption)
    console.log(this.strong)

  }

  //makes sure the checked value is taken into account
  ngOnChanges(changes: SimpleChanges) {

    if (changes.checked) {
      if (changes.checked.currentValue) {
        this.checked = changes.checked.currentValue;
      }
    }
    if (changes.strong) {
      if (changes.strong.currentValue) {
        this.strong = changes.strong.currentValue
      }
    }

    console.log(this.strong)

  }
  //when span is clicked, make sure option is not disabled
  checkItem(item) {
    if (!this.disabledOption) {
      this.checked = !this.checked;
      if (this.cd && !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }
      this.changeItem(item);
    }
  }

  //this allows to check/unckeck item and to send it back to the parent component (and form control)
  changeItem(item) {
    this.change.emit({ value: item });

  }



  //return the tooltip text 
  getTooltip(option) {
    if (Array.isArray(this.tooltipText)) {
      this.pendingTooltip = "";
      for (var i in this.tooltipText) {
        this.pendingTooltip = this.pendingTooltip + this.tooltipText[i] + ": " + option[this.tooltipText[i]] + "\n"
      }
      return this.pendingTooltip;
    }
    else if (this.tooltipText === 'filterBy') {
      return this.item;
    }
    else {
      return this.tooltipText;
    }

  }
}
