import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpClientJsonpModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class HistogramCellThresholdService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
    ){}

    histogramCellThreshold:number;
    histogramCellThresholdChange =new Subject<number>();
    url= this.appService.getUrl();

    //Gets the histogramCellThreshold
    //called in histogramCellThreshold component
    getHistogramCellThreshold() {
        const url = this.url;
        const webservice = "AdminGetHistogramCellThreshold";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.histogramCellThreshold = response.tableRows[0].histogramCellThreshold;
                this.histogramCellThresholdChange.next(this.histogramCellThreshold);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }

    //Sets the histogramCellThreshold.
    //called in histogramCellThreshold component
    setHistogramCellThreshold(histogramCellThreshold) { 
        const url = this.url;
        const webservice = "AdminSetHistogramCellThreshold";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("histogramCellThreshold",histogramCellThreshold);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.appService.showMessage('Success', response.statusText); 
                this.histogramCellThresholdChange.next(histogramCellThreshold);       
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
       
    
}