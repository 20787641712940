import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { TableModel } from 'src/app/shared/models/gui/table-models/table.model';
import { TypeTableModel } from 'src/app/shared/models/gui/type-table.model.ts/typetable.model';
import { TypeTableWFSequenceModel } from 'src/app/shared/models/gui/type-table.model.ts/typetable-wfsequence.model';


@Injectable()
export class WorkflowNameService {

    constructor(private appService: AppService,
        private sharedInitializationService: SharedInitializationService,
        private sharedGeneralService: SharedGeneralFunctionsService,

        private httpClient: HttpClient) { }

    url = this.appService.getUrl();

    //INITIALIZATION OF VARIABLES
    wfStructure: any[] = [];
    //this is for the workflow view component so tha the workflow view is automatically changed
    wfStructureChange = new Subject<any>();
    segregationAttribute: string = "";
    dynSegregationAttribute: string = "";
    //this is for the workflow name component so that the segregation attribute is automatically updated
    segregationAttributeChange = new Subject<string>();
    dynSegregationAttributeChange = new Subject<string>();
    workflowCommentChange = new Subject<string>();
    workflowComment: string = "";
    workflowOwner: string = "";
    workflowOwnerChange = new Subject<string>();
    type: string = "";
    // workflow tables
    tables: TableModel[] = [];
    tablesArray: {} = {};
    tablesArrayChange: { [index: string]: Subject<TableModel[]> } = {};
    dynSegregationAttributeArray: {} = {};
    dynSegregationAttributeArrayChange: { [index: string]: Subject<string> } = {};
    dynSegregationAttributeValuesArray: {} = {};
    dynSegregationAttributeValuesArrayChange: { [index: string]: Subject<string> } = {};

    typeTablesArray: {} = {};
    typeTablesArrayChange: { [index: string]: Subject<TypeTableModel[]> } = {};


    subtablesArray: {} = {};
    subtablesArrayChange: { [index: string]: Subject<TypeTableWFSequenceModel[]> } = {};

    numberRecordsArray: {} = {};
    numberRecordsArrayChange: { [index: string]: Subject<number> } = {};
    workflowOwnerArray: {} = {};
    workflowOwnerArrayChange: { [index: string]: Subject<string> } = {};
    table: any = {};

    // Bayes,Gauss,Aggreg Supervised Temporal Tables
    typeTables: TypeTableModel[] = [];
    // the subtables for a given table
    subtables: any[] = [];
    subtable: any = {};
    typeChange = new Subject<string>();
    //this is for the analysis menu and the workflow name tables component so that the tables are automatically updated
    tablesChange = new Subject<any[]>();
    //this is for the table steps component so that the table is automatically updated
    tableChange = new Subject<any>();
    //this is for the workflow name tables component so that the type tables are automatically updated
    typeTablesChange = new Subject<any[]>();
    //this is for number of subtables of a typetable
    //typetableChange = new Subject<any>();
    //this is for the workflow name table selected component so that the subtables are automatically updated
    subtablesChange = new Subject<any[]>();
    //this is for the table steps and workflow subtable selected components so that the subtable is automatically updated
    subtableChange = new Subject<any>();

    apply_and_chain_change = new Subject<any>();

    fieldID: string = "";
    fieldIDChange = new Subject<any>();

    newDimensionAttrArray: any[] = [];
    //this is for the new dim component so that the new dimension array is automatically updated
    newDimAttrArrayChange = new Subject<any[]>();

    attributeValuesArray: any[] = [];
    //this is for the short-long and filters component so that the attribute values (i.e. value TRADER1 for TRADER_ID attribute) are automatically updated
    attributeValuesArrayChange = new Subject<any[]>();

    //this updates the chart attributes but is not used anywhere
    chartAttributes: any[] = [];
    chartAttributesChange = new Subject<any[]>();

    // BAYES
    // filtersInfoArray : any[] = [];
    //this is for the filters component so the filter information is automatically updated
    filtersInfoArrayChange = new Subject<any[]>();
    anomalyDetected: { Rules: number, Simple: number, Combined: number, AllAnomaly: number, Correct: number, NbRecords: number } = { Rules: 0, Simple: 0, Combined: 0, AllAnomaly: 0, Correct: 0, NbRecords: 0 };
    //this is for the detect complex and detect simple components so that the number of anomalies are automatically updated
    anomalyDetectedChange = new Subject<any>();

    //GAUSS dynamic
    dynSegAttrValuesArray: any[] = [];
    // to get all the dynamic seg attributes values in the gauss subtable
    dynSegAttrValuesArrayChange = new Subject<any[]>();

    //this is to reset workflow, source and types values to null in workflow component
    deleteWorkflowValue: boolean = false;
    //this is for the workflow component
    deleteWorkflowChange = new Subject<boolean>();

    //number of records in workflow
    numberRecords: number = 0;
    //called in workflow name component, it automatically updates the number of records
    numberRecordsChange = new Subject<number>();

    subtableSelected: boolean = false;
    subtableSelectedName: string = "";
    //number of records in subtable
    nbRecords: number = 0;
    //called in workflow name component, it automatically updates the number of records
    nbRecordsChange = new Subject<number>();
    // for proportion, Means and covariance for each mixture
    gaussMixtures: any[] = [];
    gaussMixturesChange = new Subject<any[]>();

    //to make sure the tablename, and the subtable name are cleared
    tableNameChange = new Subject<string>();
    subtableNameChange = new Subject<string>();

    //for analysis navigations

    sourceTablesArray: any = {};
    targetTablesArray: any = {};
    sourceTablesArrayChange: { [index: string]: Subject<TableModel[]> } = {};
    targetTablesArrayChange: { [index: string]: Subject<TableModel[]> } = {};


    //Loads the selected workflow and sends it back.
    sourceStructure: any = {};
    targetStructure: any = {};

    sourceSubtablesArray: any[] = [];
    targetSubtablesArray: any[] = [];
    //called in analysis grid and automatically updates sourceSubTables
    sourceSubtablesArrayChange: { [index: string]: Subject<TypeTableWFSequenceModel[]> } = {};
    //called in analysis grid and automatically updates targetSubTables
    targetSubtablesArrayChange: { [index: string]: Subject<TypeTableWFSequenceModel[]> } = {};

    sourceSegAttr: any = {};
    targetSegAttr: any = {};
    //called in analysis grid and automatically updates the seg attributes for the source and target workflows
    sourceSegAttrChange: { [index: string]: Subject<string> } = {};
    targetSegAttrChange: { [index: string]: Subject<string> } = {};

    sourceSource: any = {};
    targetSource: any = {};


    //called in analysis grid and automatically updates the sources for the source and target workflows
    sourceSourceChange: { [index: string]: Subject<string> } = {};
    targetSourceChange: { [index: string]: Subject<string> } = {};

    // called for supervised workflow in last step (optimize)
    OptimizeArrayChange = new Subject<string>();

    // temporal
    nbLeavesChange = new Subject<any[]>();

    //matching
    nbTrainMatchingChange = new Subject<any[]>();
    // nbScenarioMatchingChange = new Subject<any[]>();
    nbTestMatchingChange = new Subject<any[]>();

    // supervised -target-rule
    subtableAttributeValuesArrayChange = new Subject<any[]>();
    // supervised and gauss predict
    modelsPredictChange = new Subject<any[]>();

    // for kernel
    kernelResult: any[];
    kernelResultChange = new Subject<any[]>();

    sourceFromWFName : string;
    sourceFromWFNameChange = new Subject<string>();
    sourceFromWFNameArray: {} = {};
    sourceFromWFNameArrayChange: { [index: string]: Subject<string> } = {};

    //to init analysis tabs
    initAnalysisTabSubjects(tabs) {
        for (var i in tabs) {
            this.tablesArrayChange[tabs[i].name] = new Subject<TableModel[]>();
            this.typeTablesArrayChange[tabs[i].name] = new Subject<TypeTableModel[]>();
            this.numberRecordsArrayChange[tabs[i].name] = new Subject<number>();
            this.workflowOwnerArrayChange[tabs[i].name] = new Subject<string>();
            this.sourceFromWFNameArrayChange[tabs[i].name] = new Subject<string>();
            this.subtablesArrayChange[tabs[i].name] = new Subject<TypeTableWFSequenceModel[]>();

            this.dynSegregationAttributeArrayChange[tabs[i].name] = new Subject<string>();
            this.dynSegregationAttributeValuesArrayChange[tabs[i].name] = new Subject<any>();
            //navigation

            this.sourceTablesArrayChange[tabs[i].name] = new Subject<TableModel[]>();
            this.targetTablesArrayChange[tabs[i].name] = new Subject<TableModel[]>();
            this.sourceSubtablesArrayChange[tabs[i].name] = new Subject<TypeTableWFSequenceModel[]>();
            this.targetSubtablesArrayChange[tabs[i].name] = new Subject<TypeTableWFSequenceModel[]>();
            this.sourceSegAttrChange[tabs[i].name] = new Subject<string>();
            this.targetSegAttrChange[tabs[i].name] = new Subject<string>();
            this.sourceSourceChange[tabs[i].name] = new Subject<string>();
            this.targetSourceChange[tabs[i].name] = new Subject<string>();

            this.sourceTablesArray[tabs[i].name] = [];
            this.targetTablesArray[tabs[i].name] = [];
            this.sourceSubtablesArray[tabs[i].name] = [];
            this.targetSubtablesArray[tabs[i].name] = [];
            this.sourceStructure[tabs[i].name] = [];
            this.targetStructure[tabs[i].name] = [];


        }
    }


    //sets the name of the selected subtable
    //called n workflow name service
    setSubtableSelectedName(name) {
        this.subtableSelectedName = name;
        this.subtableSelected = true;
    }

    //sets the selected subtable value (true or not)
    //called in worfklow name service
    setSubtableSelected(value) {
        this.subtableSelected = value;
    }

    //returns the workflow details an structure
    //called in analysis and workflow name components
    getLoadWorkflowDetails(workflow, navigation, tab) {
        this.appService.startSpin();
        const url = this.url;
        const webservice = "WFLoadWorkflow";
        const completeUrl = url + webservice;

        this.tables = [];
        this.typeTables = []
        if ((tab != '') && (!navigation.nav)) {
            this.tablesArray[tab] = [];
            this.typeTablesArray[tab] = [];
        }
        this.appService.startSpin();

        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);

                if (response.statusCode > -1) {
                    if (navigation.nav === false) {
                        this.wfStructure = response.wfStructure;
                        this.wfStructureChange.next(this.wfStructure);
                        this.type = response.wfStructure.WFType;
                        this.typeChange.next(this.type);

                        this.segregationAttribute = response.wfStructure.WFSegregationAttribute;
                        this.segregationAttributeChange.next(this.segregationAttribute);
                        this.dynSegregationAttribute = response.wfStructure.WFDynSegAttribute;
                        this.dynSegregationAttributeChange.next(this.dynSegregationAttribute);

                        this.workflowComment = response.wfStructure.WFComment;
                        this.workflowCommentChange.next(this.workflowComment);
                        this.workflowOwner = response.wfStructure.WFOwner;
                        this.workflowOwnerChange.next(this.workflowOwner);
                        if (response.wfStructure.WFSourceFromWFName){
                            this.sourceFromWFName = response.wfStructure.WFSourceFromWFName;
                        }
                        else{
                            this.sourceFromWFName = "";
                        }
                        this.sourceFromWFNameChange.next(this.sourceFromWFName);
                        if (response.wfStructure.WFConfigStatus.WorkflowTables) {
                            this.tables = response.wfStructure.WFConfigStatus.WorkflowTables.TablesWF;
                            this.tablesChange.next(this.tables);


                            var typetableName = this.type + 'Tables';

                            this.typeTables = response.wfStructure.WFConfigStatus[typetableName].TablesWF;
                            this.typeTablesChange.next(this.typeTables);
                            this.numberRecords = response.wfStructure.WFNbRecords;
                            this.numberRecordsChange.next(this.numberRecords);

                            console.log(this.typeTables);
                            console.log(this.tables);

                            if (tab != '') {
                                this.tablesArray[tab] = [...response.wfStructure.WFConfigStatus.WorkflowTables.TablesWF];
                                this.tablesArrayChange[tab].next([...this.tablesArray[tab]]);

                                this.typeTablesArray[tab] = [...response.wfStructure.WFConfigStatus[typetableName].TablesWF];
                                this.typeTablesArrayChange[tab].next([...this.typeTablesArray[tab]]);
                                this.dynSegregationAttributeArray[tab] = response.wfStructure.WFDynSegAttribute;
                                this.dynSegregationAttributeArrayChange[tab].next(this.dynSegregationAttributeArray[tab]);

                                this.numberRecordsArray[tab] = response.wfStructure.WFNbRecords;
                                this.numberRecordsArrayChange[tab].next(this.numberRecordsArray[tab]);
                                this.workflowOwnerArray[tab] = response.wfStructure.WFOwner;
                                this.workflowOwnerArrayChange[tab].next(this.workflowOwnerArray[tab]);
                                if (response.wfStructure.WFSourceFromWFName){
                                    this.sourceFromWFNameArray[tab] = response.wfStructure.WFSourceFromWFName;
                                }
                                else{
                                    this.sourceFromWFNameArray[tab] = "";
                                }
                                this.sourceFromWFNameArrayChange[tab].next(this.sourceFromWFNameArray[tab]);
                            }
                        }
                        else {
                            this.appService.showMessage("Warning", "Please consider deleting this workflow as it is no longer configured to work with this new release.")
                        }
                    }
                    else {
                        var structure = navigation.type + "Structure";
                        this[structure][tab] = response.wfStructure.WFConfigStatus;
                        var tables = navigation.type + "TablesArray";
                        var tablesChange = navigation.type + "TablesArrayChange";
                        this[tables][tab] = response.wfStructure.WFConfigStatus.WorkflowTables.TablesWF;
                        this[tablesChange][tab].next(this[tables][tab]);

                        var segAttribute = navigation.type + "SegAttr";
                        this[segAttribute][tab] = response.wfStructure.WFSegregationAttribute;
                        var segAttributeChange = navigation.type + "SegAttrChange";
                        this[segAttributeChange][tab].next(this[segAttribute][tab]);
                        var source = navigation.type + "Source";
                        this[source][tab] = response.wfStructure.WFSource;
                        var sourceChange = navigation.type + "SourceChange";
                        this[sourceChange][tab].next(this[source][tab]);
                    }


                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();

            }, (error) => {
                this.appService.stopSpin();
                this.appService.showMessage('Error', error.statusText);

            });

    }

    //returns the workflow files in data hub
    //called in analysis component
    getLoadWorkflowDetailsDataHub(workflow, navigation, tab) {
        this.appService.startSpin();
        const url = this.url;
        const webservice = "WFLoadWorkflowDataHub";
        const completeUrl = url + webservice;
        this.appService.startSpin();

        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                console.log(response)
                if (response.statusCode > -1) {
                    if (navigation.nav === false) {
                        let tables = [];
                        for (var k in response.tableRows) {
                            let newdata = {
                                TableDescription: {
                                    TableName: response.tableRows[k].TableDescription
                                }
                            }

                            tables.push(newdata);
                        }

                        let typeTables = [];
                        for (var j in response.tableRows2) {
                            var newArr = response.tableRows2[j].files.map(function (value) {
                                return { FileName: value };
                            });
                            let newdata = {
                                TableDescription: {
                                    TableName: response.tableRows2[j].date,
                                    WFSequence: newArr
                                }
                            }
                            typeTables.push(newdata);
                        }

                        tables.sort((a, b) => +new Date(b.TableDescription.TableName) - +new Date(a.TableDescription.TableName));
                        typeTables.sort((a, b) => +new Date(b.TableDescription.TableName) - +new Date(a.TableDescription.TableName));

                        this.tablesArray[tab] = [...tables];
                        this.tablesArrayChange[tab].next([...this.tablesArray[tab]]);

                        this.typeTablesArray[tab] = [...typeTables];
                        this.typeTablesArrayChange[tab].next([...this.typeTablesArray[tab]]);

                        /* this.numberRecordsArray[tab] = response.wfStructure.WFNbRecords;
                         this.numberRecordsArrayChange[tab].next(this.numberRecordsArray[tab]); */


                    }
                    else {
                        var structure = navigation.type + "Structure";
                        this[structure][tab] = response.wfStructure.WFConfigStatus;
                        var tables = navigation.type + "TablesArray";
                        var tablesChange = navigation.type + "TablesArrayChange";
                        this[tables][tab] = response.wfStructure.WFConfigStatus.WorkflowTables.TablesWF;
                        this[tablesChange][tab].next(this[tables][tab]);

                        var segAttribute = navigation.type + "SegAttr";
                        this[segAttribute][tab] = response.wfStructure.WFSegregationAttribute;
                        var segAttributeChange = navigation.type + "SegAttrChange";
                        this[segAttributeChange][tab].next(this[segAttribute][tab]);
                        var source = navigation.type + "Source";
                        this[source][tab] = response.wfStructure.WFSource;
                        var sourceChange = navigation.type + "SourceChange";
                        this[sourceChange][tab].next(this[source][tab]);
                    }


                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();

            }, (error) => {
                this.appService.stopSpin();
                this.appService.showMessage('Error', error.statusText);

            });

    }

    resetTableNameAndSubtableName() {
        this.tableNameChange.next("");
        this.subtableNameChange.next("");
    }

    resetTables() {
        this.tables = [];
        this.table = {};
        this.tablesChange.next(this.tables);
        this.tableChange.next(this.table);
        this.resetSubtables();
    }

    resetSubtables() {
        this.subtables = [];
        this.subtable = {};
        //21/09/2020 this.subtablesChange.next(this.tables);
        //21/09/2020 this.subtableChange.next(this.subtables);
        this.subtablesChange.next(this.subtables);
        this.subtableChange.next(this.subtable);
    }


    //returns the segregation attribute for the workflow
    //!!!! called in analysis-label-selected.service 
    getSegregationAttribute() {
        return this.segregationAttribute;
    }
    getType(){
        return this.type;
    }

    //returns the segregation attribute for the workflows
    //called in analysis menu gauss component
    getDynSegregationAttribute() {
        return this.dynSegregationAttribute;
    }

    //returns the number of records in the workflow
    //called in analysis container, and table steps component
    getNumberRecords() {
        return this.numberRecords;
    }

    //returns the workflow structure
    //called in workflow name table selected for jsoneditor
    getWFStructure() {
        return this.wfStructure;
    }

    getWorkflowComment() {
        return this.workflowComment;
    }

    getWorkflowOwner() {
        return this.workflowOwner;
    }

    getSourceFromWFName() {
        return this.sourceFromWFName;
    }
    //returns the workflow tables in a workflow (main steps --> common to all workflow types)
    //called in workflow name tables component
    getWorkflowTables(tab) {    //workflowTables in workflow
        if (tab === '') {
            return this.tables;
        }
        else {
            return this.tablesArray[tab];
        }


    }

    //returns the type tables in a specific workflow (bayesTables , GauusTables, ..)
    //called in workflow name tables component
    gettypeTables(tab) {

        if (tab === '') {
            return this.typeTables;
        }
        else {
            return this.typeTablesArray[tab];
        }
    }

    //returns the subtables for a specific table
    // called in analysis menu and workflow name table selected
    getSubTables(tableName, tab) { // all the subtables in the typetable selected
        if (tab != '') {
            this.subtablesArray[tab] = [];
            for (var i in this.typeTablesArray[tab]) {
                if (this.typeTablesArray[tab][i].TableDescription.TableName === tableName) {
                    this.subtablesArray[tab] = this.typeTablesArray[tab][i].TableDescription.WFSequence;
                    //  this.subtablesChange.next(this.subtables);

                }
            }
            return this.subtablesArray[tab];
        }
        for (var i in this.typeTables) {
            if (this.typeTables[i].TableDescription.TableName === tableName) {
                this.subtables = this.typeTables[i].TableDescription.WFSequence;
                //  this.subtablesChange.next(this.subtables);
                break;
            }
        }
        return this.subtables;
    }
    setSubTables(subtables) {
        this.subtables = subtables;
    }
    //returns the subtables for a specific table in navigation
    //called in analysis grid component
    getNavigationSubTables(type, WFType, tableName, tab) {
        var structure = type + "Structure";
        var subtables = type + "SubtablesArray";
        var subtablesChange = type + "SubtablesArrayChange";
        //var typetables = WFType + "NavigationTables";
        var typetablename = WFType + "Tables";

        if (!(this.sharedGeneralService.isWithSubtables(WFType))) {
            this[subtables] = []
        }
        else {
            // console.log(this[structure])
            let typetables = this[structure][tab][typetablename].TablesWF;
            //  console.log(this[structure][typetablename].TablesWF)
            //  console.log(this[typetables]);
            for (var i in typetables) {
                if (typetables[i].TableDescription.TableName === tableName) {
                    this[subtables][tab] = typetables[i].TableDescription.WFSequence;
                    //  this.subtablesChange.next(this.subtables);
                    break;
                }
            }
        }

        this[subtablesChange][tab].next(this[subtables][tab]);
    }

    //changes the table info and automatically updates the table steps component
    //called in workflow name service
    changeWorkflowTable(tableName) {
        for (var i in this.tables) {
            if (this.tables[i].TableDescription.TableName === tableName) {
                this.table = this.tables[i].TableDescription;
                this.tableChange.next(this.table);

            }
        }

    }

    //changes all the subtables for the given tablename and it automatically updates the workflow-name-table-selected component (the menu on the left)
    //called in workflow name service, and workflow name table selected component
    changeSubTables(tableName) {
        // all the subtables
        for (var i in this.typeTables) {
            if (this.typeTables[i].TableDescription.TableName === tableName) {
                this.subtables = this.typeTables[i].TableDescription.WFSequence;
                this.subtablesChange.next(this.subtables);
                break;
            }
        }
    }

    //changes the subtable in the workflow and it is automatically updated in the table steps component and in the selected subtable component
    //called in worklow name service, and table steps component
    changeWorkflowSubTable(subtableName) {
        //look in all the subtables
        this.setSubtableSelectedName('');
        this.setSubtableSelected(false);

        if (this.sharedGeneralService.isWithSubtables(this.type)) {

            for (let j = 0; j < this.subtables.length; j++) {
                if (this.subtables[j][this.type + 'Name'] === subtableName) {
                    //  this.subtablenameSelected = subtableName;
                    this.setSubtableSelectedName(subtableName);
                    this.setSubtableSelected(true);
                    // the selected subtable 
                    this.subtable = this.subtables[j];
                    this.subtableChange.next(this.subtable);
                    if (this.subtable !== undefined) {
                        this.nbRecords = this.subtable.NbRecords;
                        this.nbRecordsChange.next(this.nbRecords);

                    }
                    break;
                }
            }
        }
        else {
            this.subtable = this.subtables[0];
            // this.subtablenameSelected = '';
            this.setSubtableSelectedName('');
            this.setSubtableSelected(true);
            this.subtableChange.next(this.subtable);


        }

    }

    //gets the table info and structure by passing the table name
    //called in workflow name table selected, table steps and workflow name tables components
    getWorkflowTable(tableName) {
        for (var i in this.tables) {
            if (this.tables[i].TableDescription.TableName === tableName) {
                this.table = this.tables[i].TableDescription;
                return this.tables[i].TableDescription;
                break;
            }
        }
    }

    //gets the attribute type (string, numeric or date) in the given table
    // called in analysis grid component
    getTableAttributeType(table, attribute, tab) {
        //  console.log("TABLES "+this.tables);
        let attrType = "";
        for (var i in this.tablesArray[tab]) {
            if (this.tablesArray[tab][i].TableDescription.TableName === table) {
                for (var j in this.tablesArray[tab][i].TableDescription.Steps.AttrStatus.Attributes) {
                    if (this.tablesArray[tab][i].TableDescription.Steps.AttrStatus.Attributes[j].AttrName === attribute) {
                        attrType = this.tablesArray[tab][i].TableDescription.Steps.AttrStatus.Attributes[j].AttrType
                        break;
                    }
                }
            }
        }
        if (attrType === "") {
            for (var i in this.tablesArray[tab]) {
                if (this.tablesArray[tab][i].TableDescription.TableName === table) {
                    for (var j in this.tablesArray[tab][i].TableDescription.Steps['New Dim'].Attributes) {
                        if (this.tablesArray[tab][i].TableDescription.Steps['New Dim'].Attributes[j].AttrName === attribute) {
                            attrType = this.tablesArray[tab][i].TableDescription.Steps['New Dim'].Attributes[j].AttrType
                            break;
                        }
                    }
                }
            }
        }
        if (attrType === "") {
            attrType = "string";
        }
        return attrType;
    }


    //returns all of the subtable info and structure by passing only its name
    //called in the workflow subtable selected and table steps components
    getWorkflowSubTable(subtableName, tab, workflowType) {

        let subtables = this.subtables;
        let type = this.type;
        if (tab != '') {
            subtables = this.subtablesArray[tab];
            type = workflowType;
        }
        if (this.sharedGeneralService.isWithSubtables(type)) {
            this.subtable = undefined;
            for (var j in subtables) {
                if (subtables[j][type + 'Name'] === subtableName) {
                    // the selected subtable 
                    this.subtable = subtables[j];
                    this.setSubtableSelected(true);
                    break;
                }
            }
        }
        else {
            this.subtable = subtables[0];

        }
        return this.subtable;
    }

    //returns the field id for the table
    //called in workflow name service
    getTableFieldID() {
        return this.table.IDField;
    }

    //returns the number of records for the table
    //called in detect cpx and detect spl component
    getTableNbRecords() {
        return this.table.NbRecords;
    }
    //returns all the attributes for new dim attributes
    //called in new dim component
    getTableAllNewDim() {
        // var attributes=[];
        // attributes= this.table.Steps["New Dim"].Attributes;
        // same as angular.copy
        //
        // let attributes = this.table.Steps["New Dim"].Attributes.map(x => Object.assign({}, x));
        let attributes = [];
        if (this.table.Steps) {
            attributes = JSON.parse(JSON.stringify(this.table.Steps["New Dim"].Attributes));

            for (var i in attributes) {
                if (attributes[i].AttrStatus === "Active") {
                    attributes[i].AttrStatus = true;
                }
                else {
                    attributes[i].AttrStatus = false;
                }
                if (attributes[i].AttrVisible === "Visible") {
                    attributes[i].AttrVisible = true;
                }
                else {
                    attributes[i].AttrVisible = false;
                }
            }
        }
        return attributes;
    }
    //returns the number of bins for the discretization step
    //called in detect simple component
    getTableNbBins() {
        if (this.table.Steps) {
            return this.table.Steps["Discret"].NumberOfIntervals;
        }
    }

    //returns all the attributes in the Active attributes and new dim attributes steps.
    //called in aggreg, cycle, info attr, filters, new dim components
    getTableAttributesAll() {
        // object.assign same as angular.copy
        // let attributes= Object.assign([],this.table.Steps["AttrStatus"].Attributes);
        // let attributes = this.table.Steps["AttrStatus"].Attributes.map(x => Object.assign({}, x));
        let attributes = [];
        if (this.table.Steps) {
            if (this.table.Steps["AttrStatus"]) {
                attributes = JSON.parse(JSON.stringify(this.table.Steps["AttrStatus"].Attributes));

            }

            if (this.table.Steps["New Dim"]) {
                for (var i in this.table.Steps["New Dim"].Attributes) {
                    let newdata = { 'AttrName': '', 'AttrType': '', 'AttrStatus': '', 'AttrVisible': '' };
                    Object.assign(newdata, this.table.Steps["New Dim"].Attributes[i]);
                    /*
                                newdata.AttrName = this.table.Steps["New Dim"].Attributes[i].AttrName;
                                newdata.AttrType = this.table.Steps["New Dim"].Attributes[i].AttrType;
                                newdata.AttrStatus = this.table.Steps["New Dim"].Attributes[i].AttrStatus;
                                newdata.AttrVisible = this.table.Steps["New Dim"].Attributes[i].AttrVisible;
                                */
                    attributes.push(newdata);
                }
            }

        }
        //  attributes = this.table.Steps["ActiveAttr"].Attributes; 

        return attributes;
    }

    //returns all the attributes in the Active attributes and new dim attributes steps.
    //called in aggreg, cycle, info attr, filters, new dim components
    getTableAttributesAllTab(tab) {
        // object.assign same as angular.copy
        // let attributes= Object.assign([],this.table.Steps["AttrStatus"].Attributes);
        // let attributes = this.table.Steps["AttrStatus"].Attributes.map(x => Object.assign({}, x));
        let attributes = [];
        let table = this.tablesArray[tab.name].find(item => item.TableDescription.TableName === tab.table).TableDescription;

        if (table.Steps) {
            if (table.Steps["AttrStatus"]) {
                attributes = JSON.parse(JSON.stringify(table.Steps["AttrStatus"].Attributes));

            }

            if (table.Steps["New Dim"]) {
                for (var i in table.Steps["New Dim"].Attributes) {
                    let newdata = { 'AttrName': '', 'AttrType': '', 'AttrStatus': '', 'AttrVisible': '' };
                    Object.assign(newdata, table.Steps["New Dim"].Attributes[i]);
                    /*
                                newdata.AttrName = this.table.Steps["New Dim"].Attributes[i].AttrName;
                                newdata.AttrType = this.table.Steps["New Dim"].Attributes[i].AttrType;
                                newdata.AttrStatus = this.table.Steps["New Dim"].Attributes[i].AttrStatus;
                                newdata.AttrVisible = this.table.Steps["New Dim"].Attributes[i].AttrVisible;
                                */
                    attributes.push(newdata);
                }
            }

        }
        //  attributes = this.table.Steps["ActiveAttr"].Attributes; 

        return attributes;
    }

    //returns the active/inactive attributes and new dim attributes by type (string or numeric or date)
    //called in aggreg, measures, short long, new dim components
    getTableAttributesByType(attrType) {
        var attributes = this.getTableAttributesAll();
        attributes = attributes.filter(function (e) { if (e.AttrType == attrType) { return e } });

        return attributes;
    }

    //returns the active/inactive attributes and new dim attributes by status (active or inactive)
    //called in detect simple and target component
    getTableAttributesByStatus(attrStatus) {
        var attributes = this.getTableAttributesAll();
        attributes = attributes.filter(function (e) { if (e.AttrStatus == attrStatus) { return e } });

        return attributes;
    }

    //returns the visible/invisible attributes and new dim attributes by visibility
    //called in filters-body component,Rules component
    getTableAttributesByVisibility(attrVisible) {
        var attributes = this.getTableAttributesAll();
        attributes = attributes.filter(function (e) { if (e.AttrVisible == attrVisible) { return e } });

        return attributes;
    }

    //returns the visible/invisible attributes and new dim attributes by visibility
    //called in filters-body component,Rules component
    getTableAttributesByVisibilityTab(attrVisible, tab) {
        var attributes = this.getTableAttributesAllTab(tab);
        attributes = attributes.filter(function (e) { if (e.AttrVisible == attrVisible) { return e } });

        return attributes;
    }
    // gets the information for the step filters in the subtable
    //called in filters component
    getSubTableFiltersInfo() {
        let attributes = [];
        if (this.subtable.Steps) {
            attributes = JSON.parse(JSON.stringify(this.subtable.Steps["Filters"].Filters));
            // add the last column for the filters : search button 
            for (var i in attributes) {
                attributes[i].searchBtnPressed = false;
            }
        }

        return attributes;
    }

    //gets the where clause in the filters step in the subtable
    //CALLED NO WHERE
    getSubTableFiltersWhereClause(subTable) {
        return subTable.Steps["Filters"].WhereClause;
    }

    //updates the table structure when it is changed.
    //called in workflow services
    updateWorkflowTable(tableName, wfStructure) {
        console.log(tableName)
        this.wfStructure = wfStructure;
        this.wfStructureChange.next(wfStructure);

        this.tables = wfStructure.WFConfigStatus.WorkflowTables.TablesWF;
        this.tablesChange.next(this.tables);

        var typetableName = this.type + 'Tables';
        // update typeTables
        this.typeTables = wfStructure.WFConfigStatus[typetableName].TablesWF;
        this.typeTablesChange.next(this.typeTables);

        this.changeWorkflowTable(tableName);

        //  update allsubtables
        this.changeSubTables(tableName);

        // if the subtable steps were open, we need to refresh the subtables steps
        if (this.subtableSelected) {
            this.subtable = this.getWorkflowSubTable(this.subtableSelectedName, '', this.type);
            this.subtableChange.next(this.subtable);
            if (this.subtable !== undefined) {
                this.nbRecords = this.subtable.NbRecords;
                this.nbRecordsChange.next(this.nbRecords);
            }

        }
        this.apply_and_chain_change.next();
        // update of the common steps
        this.fieldID = this.getTableFieldID();
        this.fieldIDChange.next(this.fieldID);
        this.newDimensionAttrArray = this.getTableAllNewDim();
        this.newDimAttrArrayChange.next(this.newDimensionAttrArray);

        this.chartAttributes = this.getTableAttributesAll();
        this.chartAttributesChange.next(this.chartAttributes);
    }

    //function that is used to update the subtable structure when it is changed
    //called in workflow services
    updateWorkflowTableSubTable(tableName, wfStructure, subTableName) {
        // find all the subtables corresponding to the table
        this.wfStructure = wfStructure;
        this.wfStructureChange.next(wfStructure);
        this.type = wfStructure.WFType;
        var typetableName = this.type + 'Tables';
        // update typeTables
        this.typeTables = wfStructure.WFConfigStatus[typetableName].TablesWF;
        this.typeTablesChange.next(this.typeTables);  // up

        //  update allsubtables
        this.changeSubTables(tableName); // update allsubtables
        // update selected subtable
        this.changeWorkflowSubTable(subTableName);

        // filters

        console.log("the filters");
        this.filtersInfoArrayChange.next(this.getSubTableFiltersInfo());
        //  }
        // bayes steps;
        if (this.type === 'Bayes') {
            // rules, detectSpl, detectCpx
            this.anomalyDetected = this.getSubTableAnomalyDetected();
            this.anomalyDetectedChange.next(this.anomalyDetected);
        }
        if (this.type === 'Supervised') {
            this.OptimizeArrayChange.next();
        }
        if (this.type === 'Temporal') {
            //nb leaves
            this.nbLeavesChange.next(this.getSubTableNbleaves());
        }

        if (this.type === 'Matching') {
            //nb  trained records and total-tp-record and last rundate

            this.nbTrainMatchingChange.next(this.getSubTableNbTrainMatching());
            //this.nbScenarioMatchingChange.next(this.getSubTableNbScenarioMatching());
            this.nbTestMatchingChange.next(this.getSubTableNbTestMatching());
        }
        if (this.type === 'Kernel') {
            // rules, detectSpl, detectCpx
            this.kernelResult = this.getSubTableKernelResult();
            this.kernelResultChange.next(this.kernelResult);
        }

    }
    //updates the table structure when it is changed.
    //called in workflow services
    updateWorkflowAllTables(wfStructure) {

        this.wfStructure = wfStructure;
        this.wfStructureChange.next(wfStructure);

        this.numberRecordsChange.next(wfStructure.WFNbRecords);
        this.tables = wfStructure.WFConfigStatus.WorkflowTables.TablesWF;
        this.tablesChange.next(this.tables);

        var typetableName = this.type + 'Tables';
        // update typeTables
        this.typeTables = wfStructure.WFConfigStatus[typetableName].TablesWF;
        this.typeTablesChange.next(this.typeTables);

    }
    //Gets all the possible values or min and max values for the attribute.
    //called in short/long and filters component
    getTableAttributeValues(workflow, table, AttrName, AttrType) {

        const url = this.url;
        const webservice = "WFBaseTableGetAttributeValues";
        const completeUrl = url + webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            .set('attrName', AttrName)
            .set('attrType', AttrType)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //  console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.attributeValuesArray = response.tableRows;
                    this.attributeValuesArrayChange.next(this.attributeValuesArray);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    // not used 
    //Gets all the possible values or min and max values for the attribute.
    //called in short/long and filters component
    // not used but may be useful
    getSubTableAttributeValues(workflow, table, subTableName, subTableType, AttrName, AttrType) {

        const url = this.url;
        const webservice = "WFBaseTableGetAttributeValuesSubTable";
        const completeUrl = url + webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            .set('subTableName', subTableName)
            .set('subTableType', subTableType)
            .set('attrName', AttrName)
            .set('attrType', AttrType)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //  console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.subtableAttributeValuesArrayChange.next(response.tableRows);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }
    //Deletes the selected workflow.
    //called in workflow component
    deleteWorkflow(workflow) {
        const url = this.url;
        const webservice = "WFDeleteWorkflow";
        const completeUrl = url + webservice;

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)


        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //  console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText);
                    this.sharedInitializationService.getAuthorizedWorkflows("");
                    this.appService.setWorkflow("");
                    this.appService.setTable("");
                    this.appService.setType("");
                    this.appService.setSegAttribute("");
                    this.appService.setSource("");
                    this.appService.setSubtable("");
                    this.deleteWorkflowValue = true;
                    this.deleteWorkflowChange.next(this.deleteWorkflowValue)

                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();

            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    //Gets all the possible values of the dynsegAttribute
    //called in gauss param step and in analysis
    // promode is 'normal' or 'incremental')
    //getDynSegAttributeValues(workflow, table, proMode, subTableName) {
    getDynSegAttributeValues(workflow, table, subTableName, dataSetName, tab) {

        const url = this.url;
        const webservice = "AnalysisGaussDynAttrList";
        const completeUrl = url + webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', table)
            // .set('proMode', proMode)
            .set('gaussName', subTableName)
            .set("dataSet", dataSetName);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //  console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.dynSegAttrValuesArray = response.tableRows;
                    this.dynSegAttrValuesArrayChange.next(this.dynSegAttrValuesArray);

                    if (tab != "") {
                        this.dynSegregationAttributeValuesArray[tab] = [...response.tableRows];
                        this.dynSegregationAttributeValuesArrayChange[tab].next(this.dynSegregationAttributeValuesArray[tab]);
                    }
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    updateWorkflowComment(workflow, workflowComment) {

        this.appService.startSpin();
        const url = this.url;
        const webservice = "WFSetWorkflowComment";
        const completeUrl = url + webservice;
        this.appService.startSpin();

        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("wfComment", workflowComment)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);

                if (response.statusCode > -1) {

                    this.wfStructure = response.wfStructure;
                    this.wfStructureChange.next(this.wfStructure);

                    this.workflowComment = response.wfStructure.WFComment;
                    this.workflowCommentChange.next(this.workflowComment);

                    this.appService.showMessage('Success', response.statusText);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();

            }, (error) => {
                this.appService.stopSpin();
                this.appService.showMessage('Error', error.statusText);

            });

    }

    // gets the status of the following table step to able/disable Apply and chain
    //called in attr status, discret, new dim and workflow view chart components
    isTableNextStepStatusDone(step) {
        var found = false;
        var status = "";
        console.log(step);

        for (var i in this.table.Steps) {
            if (!found) {
                if (i === step) {
                    found = true;
                }
            }
            else {
                status = this.table.Steps[i].Status;
                break;
            }
        }
        if (status === "DONE") {
            return true;
        }
        else {
            return false;
        }
    }

    // gets the status of the following subtable step to able/disable Apply and chain
    //called in aggreg, cycle, measures, post aggreg, short long, detect simple, rules, gauss attr, filters components  
    isSubtableNextStepStatusDone(step) {
        var found = false;
        var status = "";
        console.log(step);
        if (this.subtable) {
            for (var i in this.subtable.Steps) {
                if (!found) {
                    if (i === step) {
                        found = true;
                    }
                }
                else {
                    status = this.subtable.Steps[i].Status;
                    break;
                }
            }
            if (status === "DONE") {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    // gets the status of the following subtable step to able/disable Apply and chain
    //called in discret component
    isSubtableStepStatusDone(step) {
        console.log(this.subtable);
        if ((this.subtable) && (this.subtable.Steps)) {
            if (this.subtable.Steps[step]) {
                status = this.subtable.Steps[step].Status;
                if (status === "DONE") {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }

        }
        else {
            return false;
        }
    }

    // bayesian
    //gets the rules attributes 
    //called in rules component
    getSubTableRules() {
        // let rules= Object.assign([], this.subtable.Steps["Rules"].Attributes);
        // let rules = this.subtable.Steps["Rules"].Attributes.map(x => Object.assign({}, x));
        let rules = [];
        if (this.subtable.Steps) {
            rules = JSON.parse(JSON.stringify(this.subtable.Steps["Rules"].Attributes));
        }
        return rules;
    }

    //gets the number of anomalies detected by type (rules, simple, combined) and the number of correct transactions
    //called in detect complex, and detect simple
    getSubTableAnomalyDetected() {

        this.anomalyDetected.NbRecords = this.subtable.NbRecords;
        if (this.subtable.Steps) {
            this.anomalyDetected.Rules = this.subtable.Steps["Rules"].Anomaly;
            this.anomalyDetected.Simple = this.subtable.Steps["DetectSpl"].Anomaly;
            this.anomalyDetected.Combined = this.subtable.Steps["DetectCpx"].Anomaly;
        }
        this.anomalyDetected.AllAnomaly = this.anomalyDetected.Rules + this.anomalyDetected.Simple + this.anomalyDetected.Combined;
        this.anomalyDetected.Correct = this.anomalyDetected.NbRecords - this.anomalyDetected.AllAnomaly;
        return this.anomalyDetected;
    }
    // supervised 
    //gets the rules attributes 
    //called in target rules component
    getSubTableTargetRules() {
        //  let rules= Object.assign([], this.subtable.Steps["Tgt Rules"].Attributes);
        //let rules = this.subtable.Steps["Tgt Rules"].Attributes.map(x => Object.assign({}, x));
        let rules = [];
        if (this.subtable.Steps) {
            rules = JSON.parse(JSON.stringify(this.subtable.Steps["Tgt Rules"].Attributes));
        }

        return rules;
    }

    //returns the number of class in supervised target
    //called in workflow supervised service
    getSubTableTargetNbClasses() {
        if (this.subtable.Steps) {
            return this.subtable.Steps["Target"].NbClasses;
        }
    }
    //returns the number of class in supervised target
    //called in workflow supervised service
    getSubTableTargetAttribute() {
        if (this.subtable.Steps) {
            return this.subtable.Steps["Target"].Attribute;
        }
    }
    getSubTableTargetAttributeValues() {
        let attributeValuesArray = [];

        if (this.subtable.Steps) {
            for (var i in this.subtable.Steps["Target"].Values) {
                let newdata = { "AttrValue": this.subtable.Steps["Target"].Values[i] };
                attributeValuesArray.push(newdata)

            };
        }
        return attributeValuesArray;
    }
    // returns the number of leaves and nodes for tempooral subtable
    // called in sequencekey component
    getSubTableNbleaves() {
        let number = [];
        number[0] = this.subtable.Steps["Seq Key"].NbLeaves;
        number[1] = this.subtable.Steps["Seq Key"].NbNodes;
        return number;
    }
    // return nb  trained records and total-tp-record
    // in matching subtable
    // called in train component

    getSubTableNbTrainMatching() {
        // it could be a matching train or a matching test
        let number = [];
        if (this.subtable.Steps["Train"]) {

            number[0] = this.subtable.Steps["Train"].NbTrained;
            number[1] = this.subtable.Steps["Train"].NbMatchable;
            number[2] = this.subtable.Steps["Train"].TotalTpRecord;
            number[3] = this.subtable.Steps["Train"].LastRunDate;
        }
        else {

        }
        return number;
    }

    getSubTableKernelResult() {

        if (this.subtable.Steps) {
            this.kernelResult = this.subtable.Steps["Rules-scripts"].Results;
        }
        return this.kernelResult;
    }
    /*
    getSubTableNbScenarioMatching() {
        // it could be a matching train or a matching test
        let number = [];
        if (this.subtable.Steps["Scenario"]) {
            number[0] = this.subtable.Steps["Scenario"].NbTrained;
            number[1] = this.subtable.Steps["Scenario"].NbMatchable;
            number[2] = this.subtable.Steps["Scenario"].TotalTpRecord;
            number[3] = this.subtable.Steps["Scenario"].LastRunDate;
        }
        else {

        }
        return number;
    }*/
    getSubTableNbTestMatching() {
        // it could be a matching train or a matching test
        let number = [];
        if (this.subtable.Steps["Test"]) {

            number[0] = this.subtable.Steps["Test"].NbTested;
            number[1] = this.subtable.Steps["Test"].NbMatchable;
            number[2] = this.subtable.Steps["Test"].TotalTpRecord;
            number[3] = this.subtable.Steps["Test"].LastRunDate;
        }
        else {

        }
        return number;
    }
    //reset Variables
    resetWorkflowInputs() {
        this.tables = [];
        this.table = {};
        this.tablesChange.next(this.tables);
        this.tableChange.next(this.table);
        this.subtable = {};
        this.subtableChange.next(this.subtable);
        this.subtables = [];
        this.subtablesChange.next(this.subtables)
    }


    // get all models (train table allready done for this workflow/table/subtable)
    getBaseTableModels(workflow, tableName, subtableName) {
        const url = this.url;
        const webservice = "WFBaseTableGetModels";
        const completeUrl = url + webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set('segAttrValue', tableName)
            .set('subTableName', subtableName)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    console.log(response);
                    this.modelsPredictChange.next(response.tableRows);
                }
                else {
                    this.appService.showMessage("Error", response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage("Error", error.statusText);
                this.appService.stopSpin();
            });
    }

}