import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { AuthService } from "src/app/auth/auth-service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { TypeListModel } from "src/app/shared/models/gui/type-list.model";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { WorkflowBayesianStepsService } from "src/app/workflow/services/workflow-bayesian-steps.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { AnalysisTab } from "../models/analysis-tab.model";
import { AnalysisAuditService } from "./analysis-audit.service";
import { AnalysisLabelSelectedService } from "./analysis-label-selected.service";
import { AnalysisNavigationService } from "./analysis-navigation.service";
import { AnalysisService } from "./analysis.service";
import { AnalysisAggregateService } from "./types/analysis-aggregate.service";
import { AnalysisBayesService } from "./types/analysis-bayes.service";
import { AnalysisGaussService } from "./types/analysis-gauss.service";
import { AnalysisMatchingService } from "./types/analysis-matching.service";
import { AnalysisSupervisedService } from "./types/analysis-supervised.service";
import { AnalysisTemporalService } from "./types/analysis-temporal.service";
import { TrueStatusTypeService } from 'src/app/admin/settings/true-status-type/true-status-type.service';
import { AnalysisKernelService } from "./types/analysis-kernel.service";

@Injectable()
export class AnalysisInitializationService {

    types: TypeListModel[] = [];
    tabs: AnalysisTab[] = [];
    allTabs: AnalysisTab[] = [];

    constructor(
        public httpClient: HttpClient,
        private sharedInitService: SharedInitializationService,
        private workflowNameService: WorkflowNameService,
        private analysisService: AnalysisService,
        private analysisBayesService: AnalysisBayesService,
        private analysisGaussService: AnalysisGaussService,
        private analysisTemporalService: AnalysisTemporalService,
        private analysisSupervisedService: AnalysisSupervisedService,
        private analysisAggregateService: AnalysisAggregateService,
        private analysisMatchingService: AnalysisMatchingService,
        private analysisKernelService: AnalysisKernelService,
        private bayesianStepsService: WorkflowBayesianStepsService,
        private analysisLabelSelectedService: AnalysisLabelSelectedService,
        private analysisAuditService: AnalysisAuditService,
        private analysisNavigationService: AnalysisNavigationService,
        private trueStatusTypesService: TrueStatusTypeService,
        private authService: AuthService,
        public appService: AppService) {
        this.types = this.sharedInitService.getTypes();
        
    }


    tabsChange = new Subject<AnalysisTab[]>();
    activeTabChange = new Subject<string>();
    //changes in tabs 
    tabChange: {[index: string]: Subject<AnalysisTab>} = {};

    url = this.appService.getUrl();
    username = this.authService.getUsername();

    getTabs() {
        return [...this.tabs];
    }

    addTab(tabName) {
        let tabNameExists = this.allTabs.find(item => item.name === tabName);
        if (tabNameExists) {
            let i = 0;

            while (tabNameExists) {
                tabName = tabName+i;
                tabNameExists = this.allTabs.find(item => item.name === tabName);
                i++;
            }
            
        }
        if (!tabNameExists) {
            let tab = {
                name: tabName,
                workflow: "",
                type: "",
                table: "",
                subtable: "",
                segAttributeValue: "",
                dynSegAttributeValue: "",
                tabType: "normal",
                subtableType:""
            }
            this.tabs.push(tab);
            this.allTabs.push(tab)
            this.tabsChange.next([...this.tabs]);
           // this.saveTabs(this.username, this.tabs);
        }
        else {
            this.appService.showMessage("Error", "This tab name already exists. Please choose another one. Thank you.")
        }

    }

    editTab(tab, i, allTabsIndex) {
        this.tabs[i] = tab;
        this.initTabSubjects([tab]);
        this.tabsChange.next([...this.tabs]);

        this.allTabs[allTabsIndex] = tab;
        let tabs = this.allTabs.filter(item => item.tabType != "navigation");
        tabs = tabs.filter(item => item.name.indexOf("FromWorkflow") === -1)
        
        this.saveTabs(this.username, tabs, "Analysis");
    }

    //change tab
    changeTab(tab, tabName) {
        this.tabChange[tabName].next(tab);
        let tabIndex = this.tabs.findIndex(item => item.name === tabName);
        let allTabsIndex = this.allTabs.findIndex(item => item.name === tabName);
        this.editTab(tab, tabIndex, allTabsIndex);
    }
    
    deleteTab(index, username, allTabsIndex) {
            let tabName = this.tabs[index].name;
            this.tabs.splice(index, 1);
            this.tabsChange.next([...this.tabs]);
            if (allTabsIndex) {
                this.tabs.splice(allTabsIndex, 1);
            }
            
            let tabs = this.allTabs.filter(item => item.tabType != "navigation");
            tabs = tabs.filter(item => item.name.indexOf("FromWorkflow") === -1)
            this.saveTabs(this.username, tabs, "Analysis");
            this.analysisNavigationService.deleteTab(tabName, username);
        
    }

    addNavTab(tab) {
        this.tabs.push(tab);
        let tabIndex = this.tabs.length - 1;
        let tabName = "Tab" + tabIndex;
        this.activeTabChange.next(tabName);
        this.initTabSubjects([tab]);
        this.tabsChange.next([...this.tabs]);

    }

    initTabSubjects(tabs) {
        this.types = this.sharedInitService.getTypes();
        this.trueStatusTypesService.getTrueStatusTypes();
        this.analysisAuditService.getCurrency();
        
        if (tabs.length != 0) {
            this.workflowNameService.initAnalysisTabSubjects(tabs);
            this.analysisService.initAnalysisTabChanges(tabs);
            this.analysisLabelSelectedService.initTabChanges(tabs);
            this.analysisAuditService.initTabChanges(tabs);
            this.analysisNavigationService.iniTabSubjects(tabs);

            for (var i in this.types) {
                let filteredTabs = tabs.filter((item => item.type === this.types[i].name));

                if (filteredTabs.length != 0) {
                    let service = "analysis" + this.types[i].name + "Service";
                    this[service].initTabChanges(filteredTabs);

                    if (this.types[i].name === "Bayes") {
                        this.bayesianStepsService.initTabChanges(filteredTabs);
                    }
                }


            }

            for (var k in tabs) {
                this.tabChange[tabs[k].name] = new Subject<AnalysisTab>();;
            }
        }
    }

    setNavigationTab(navData, globalFilterData, globalFilterDataForGrid, tab, username) {
        let tabs = this.getTabs();
        let targetTabName = "nav" + tab;
        let targetTab = tabs.find(item => item.name === targetTabName);
        let targetTabIndex = tabs.findIndex(item => item.name === targetTabName)
        
        if (targetTab) {
            if ((navData.targetWorkflow === targetTab.workflow) && (navData.targetTable === targetTab.table) && (navData.targetSubTable === targetTab.subtable)) {

                let tabName = "Tab" + targetTabIndex;
                this.activeTabChange.next(tabName);
            }
            else {
                this.deleteTab(targetTabIndex, username, undefined);
                targetTab = undefined;
            }

        }
        if (!targetTab) {
            targetTab = new AnalysisTab(targetTabName, navData.targetWorkflow, navData.targetType, navData.targetTable, navData.targetSubTable, navData.segAttribute, "", "navigation","")
            this.addNavTab(targetTab);
        }

        this.analysisNavigationService.setNavigationGlobalFilters(globalFilterData, globalFilterDataForGrid, targetTab);

    }

    //Sets the last user bookmark grid parameters for the workflow/ table/ subtable.
    //called in analysis grid component
    saveTabs(username, tabs, fromTab) {
        const url = this.url;
        //const webservice = "AnalysisUserTabsSave";
        const webservice = "UserTabsSave";
        const completeUrl = url + webservice;

        tabs = JSON.stringify(tabs);
        //  this.appService.startSpin();
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("tabs", tabs)
            .set("fromTab", fromTab)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    // this.appService.showMessage('Success', response.statusText);
                }
                else {
                    //15112020 this.appService.showMessage('Error', response.statusText);
                    this.appService.stopSpin();
                }
                //01/08/2020 this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();

            });
    }

    //Loads the last user bookmark grid parameters for the workflow/ table/ subtable.
    //called in analysis grid component
    loadTabs(username, fromTab) {
        const url = this.url;
        //const webservice = "AnalysisUserTabsLoad";
        const webservice = "UserTabsLoad";
        const completeUrl = url + webservice;


        // this.appService.startSpin();
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("fromTab", fromTab)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.tabs = [];
                    let topicName = this.appService.getSelectedTopic()
                    for (var k in response.tableRows) {
                      if (this.sharedInitService.checkIfWorkflowExistsInTopic(topicName, response.tableRows[k].workflow)) {
                          this.tabs.push(response.tableRows[k])
                      }
                    }
                    this.allTabs = [...response.tableRows];
                    let number = this.tabs.length+1;
                    let tabName = "FromWorkflowTab"+number;
                    let wfName = this.appService.getWorkflow();
                    
                    if (wfName != "") {
                        let table = this.appService.getTable();
                        let type = this.appService.getType();
                        let subtable = this.appService.getSubtable();
                        let segAttrValue = this.appService.getSegAttribute();
                        let tab = new AnalysisTab(tabName, wfName, type, table, subtable, segAttrValue, "", "normal" ,"");
                        
                        const indexOfItem = this.tabs.findIndex(item => item.workflow === tab.workflow && item.table === tab.table && item.subtable === tab.subtable );
                        
                        console.log("index "+indexOfItem);
                        if (indexOfItem === - 1) {
                            this.tabs.push(tab);
                        }
                        else {
                            tabName = "Tab"+indexOfItem;
                        }
                        this.activeTabChange.next(tabName);
                    }
                   
                  
                    this.initTabSubjects(this.tabs);
                   
                    this.tabsChange.next(this.tabs); 

                   
                         
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                //   this.appService.stopSpin();

            }, (error) => {
                //  this.appService.stopSpin();
                this.appService.showMessage('Error', error.statusText);

            });
    }

}