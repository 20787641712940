<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center">
    <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
      <h3 class="sectionTitle">Script management</h3>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-10 offset-md-1 col-xs-12 col-sm-12">
    <div class="container">

      <div class="row">
        <div class="col-xs-12 col-md-12 col-sm-12">

          <div class="row margin-top-20">
            <div class="col-md-4 offset-md-4 col-xs-4 col-sm-7">
              <p-selectButton [options]="optionsScriptManagement" [(ngModel)]="selectedOptionScript" (onChange)="scriptManagementChange()"
                optionLabel="label">
                <ng-template let-item>
                  <div style="padding: .5em 1em">
                    <i class="pi pi-download" style="width:16px;vertical-align:middle" *ngIf="item.label === 'Download'"></i>
                    <i class="pi pi-upload" style="width:16px;vertical-align:middle" *ngIf="item.label === 'Upload'"></i>

                    {{item.label}}
                  </div>
                </ng-template>
              </p-selectButton>

            </div>
          </div>
        </div>
      </div>
      <div class="row margin-top-20" *ngIf="(selectedOptionScript!==undefined) &&(selectedOptionScript.value=='Upload')">
        <div class="col-xs-12 col-md-8 col-sm-12 offset-md-2">
          <div class="fileContainer col-md-12" appDragDrop (fileDropped)="onFileDropped($event)">
            <input autocomplete="off" type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />
            <!-- <img src="assets/img/dnd/ic-upload-file.svg" alt=""> -->
            <h3>Drag and drop file here</h3>
            <h3>or</h3>
            <label for="fileDropRef">Browse for file</label>
          </div>
          <div  class="files-list">
            <div class="single-file" *ngFor="let file of files; let i = index; trackBy: trackByIdx">
              <div class="col-md-11">
    
                <div class="row">
                  <h4 class="name">
                    {{ file?.name }}
                  </h4>
                  <p class="size">
                    {{ formatBytes(file?.size) }}
                  </p>
                </div>
    
                <div class="row">
                  <div class="progress-cont">
                    <div class="progress" [style.width]="file?.progress + '%'">
                    </div>
                  </div>
    
                </div>

              </div>
              <button (click)="deleteFile(i)"><span><i class="fa fa-trash"></i></span> </button>
          </div>
          </div>
    
          <div class="row margintop20">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <button class="btn btn-primary btn-sm" (click)="acceptFile()"> Accept</button>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <button class="btn btn-primary btn-sm" (click)="cancelFile()" style="float:right"> Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
