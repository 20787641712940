import { ViewRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { DashboardTooltipContextMenuService } from './shared/dashboard-tooltip-contextmenu.service';
var DashboardBubbleChartService = /** @class */ (function () {
    function DashboardBubbleChartService(httpClient, router, dashboardService, sharedGeneralService, dashboardTooltipContextMenuService) {
        this.httpClient = httpClient;
        this.router = router;
        this.dashboardService = dashboardService;
        this.sharedGeneralService = sharedGeneralService;
        this.dashboardTooltipContextMenuService = dashboardTooltipContextMenuService;
    }
    //bubble axis
    DashboardBubbleChartService.prototype.getBubbleXandY = function (vis) {
        console.log("bubble");
        // get bubble distinct values in order to get correct dividor
        var keys = ['xHeader', 'yHeader'];
        var newdata = vis.dataFiltered.map(function (item) { return keys.map(function (element) { return item[element]; }); });
        vis.bubbleDistinctValues = [];
        for (var m in newdata) {
            //let findIndex = distinctValues.findIndex(item => newdata[m]["xHeader"]===item["xHeader"] && newdata[m]["yHeader"]===item["yHeader"] );
            var findIndex = vis.bubbleDistinctValues.findIndex(function (item) { return item["xHeader"] === newdata[m][0] && item["yHeader"] === newdata[m][1]; });
            if (findIndex === -1) {
                vis.bubbleDistinctValues.push({ xHeader: newdata[m][0], yHeader: newdata[m][1] });
            }
        }
        var yType = "notnumeric";
        var xType = "notnumeric";
        var scales = [];
        xType = vis.dashboardInitTypesScalesService.getVariableTypes(vis.dataFiltered, vis.xVariable, vis);
        yType = vis.dashboardInitTypesScalesService.getVariableTypes(vis.dataFiltered, vis.yVariable, vis);
        vis.yDividor = vis.dashboardAxisFormattingService.getYDividor(vis.yVariable, yType, vis);
        vis.xDividor = vis.dashboardAxisFormattingService.getXDividor(vis.xVariable, vis, vis.bubbleDistinctValues);
        var types = [{ name: "x", axisName: "xAxis", type: xType, variable: vis.xVariable, axisOrientation: d3.axisBottom(), dividor: vis.xDividor, range: vis.width }, { name: "y", axisName: "yAxis", type: yType, variable: vis.yVariable, axisOrientation: d3.axisLeft(), dividor: vis.yDividor, range: vis.height }];
        for (var k in types) {
            if (types[k].type === 'numeric') {
                scales.push({ graphType: "bubble", name: types[k].name, axisName: types[k].axisName, type: d3.scaleLinear(), scaleType: "linear", range: types[k].range, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor });
            }
            else {
                vis.dataFiltered.sort(function (a, b) {
                    return d3.ascending(a[vis.xVariable], b[vis.xVariable]);
                });
                scales.push({ graphType: "bubble", name: types[k].name, axisName: types[k].axisName, type: d3.scaleBand(), scaleType: "band", range: types[k].range, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor });
            }
        }
        vis.dashboardInitTypesScalesService.setScales(scales, vis, vis.bubbleDistinctValues);
        var minx = Math.abs(d3.min(vis.dataFiltered, function (d) { return d[vis.zVariable]; }));
        var maxx = Math.abs(d3.max(vis.dataFiltered, function (d) { return d[vis.zVariable]; }));
        var difference = (maxx - minx) / minx;
        var maxRange = Math.min(Math.max(5, 4 * Math.abs(difference)), 40);
        if (Math.abs(difference) < 5) {
            maxRange = 20;
        }
        var minRange = 4;
        if (vis.dataFiltered.length === 1) {
            minRange = 10;
            maxRange = 10;
        }
        vis.z = d3.scaleLinear()
            .range([minRange, maxRange]);
        vis.z.domain([0, Math.max(Math.abs(d3.min(vis.dataFiltered, function (d) { return d[vis.zVariable]; })), Math.abs(d3.max(vis.dataFiltered, function (d) { return d[vis.zVariable]; })))]);
        vis.colorScale = d3.scaleOrdinal()
            .domain(vis.domain)
            .range(vis.colorScheme);
        vis.xDomain = vis.x.domain();
        // initialize legend (get data keys, get color and show rect + text)
        if (vis.g) {
            vis.g.selectAll(".legendClass").remove();
        }
        vis.xType = xType;
        vis.yType = yType;
        return vis;
    };
    //set bubbles chart
    DashboardBubbleChartService.prototype.setBubbles = function (vis) {
        var data = [];
        vis.xAxis.selectAll("line")
            .style("stroke", function (d, i) {
            return i % vis.xDividor == 0 ? "#bbbbbb" : "transparent";
        });
        if (vis.cd && !vis.cd.destroyed) {
            vis.cd.detectChanges();
            var count = 0;
            vis.colorScale = d3.scaleOrdinal()
                .range(vis.colorScheme);
            vis.g1.selectAll(".arc").remove();
            var _loop_1 = function () {
                var filteredData = vis.dataFiltered.filter(function (item) { return vis.bubbleDistinctValues[k]['xHeader'] === item[vis.xVariable] && vis.bubbleDistinctValues[k]["yHeader"] === item[vis.yVariable]; });
                count = count + filteredData.length;
                pie = d3.pie()
                    .sort(null)
                    .value(function (d) { return Math.abs(d["col"]); });
                var max = Math.abs(d3.max(filteredData, function (d) { return d[vis.zVariable]; }));
                if (vis.bubbleDistinctValues[k].yHeader === "Other" && vis.bubbleDistinctValues[k].xHeader === "Other") {
                    console.log(max);
                }
                path = d3.arc()
                    .outerRadius(vis.z(max))
                    .innerRadius(0);
                var className = "arc item" + k;
                var pies = vis.g1.selectAll("." + className)
                    .data(pie(filteredData))
                    .enter().append("g")
                    .attr("class", className);
                pies.append("path")
                    .attr("d", path)
                    .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i + vis.bubbleDistinctValues[k].xHeader; })
                    .attr("fill", function (d) {
                    if (filteredData.length === 0) {
                        return '#fff';
                    }
                    return vis.colorScale(d.data["Categorie"]);
                })
                    .attr("stroke", function (d) {
                    if (filteredData.length === 0) {
                        return vis.colorScale(d.data["Categorie"]);
                    }
                    return '#fff';
                })
                    .attr("transform", function (d) {
                    var x = vis.dashboardInitTypesScalesService.getCoordinates(vis, d, "x");
                    var y = vis.dashboardInitTypesScalesService.getCoordinates(vis, d, "y");
                    return "translate(" + x + "," + y + ")";
                })
                    .on("mouseover", function (d) { vis.tooltip = d3.selectAll('body').append("div").attr("class", "toolTip"); })
                    .on("mouseout", function () {
                    d3.selectAll(".toolTip").remove();
                    if (vis.focus) {
                        vis.focus.style('display', "none");
                    }
                })
                    .on("mousemove", function (d) {
                    if (vis.tooltip) {
                        vis.dashboardTooltipContextMenuService.mousemoved(vis, d3.mouse(this), d3.event.pageX, d3.event.pageY, filteredData);
                    }
                })
                    .on("click", function (d, i) {
                    vis.bubbleContextMenu2 = false;
                    vis.bubbleClickMenu = true;
                    vis.bubbleMouseThis = d3.mouse(this)[1];
                    if (d3.event.altKey) {
                        vis.bubbleContextMenu2 = true;
                    }
                    vis = vis.dashboardBubbleChartService.setClickMenu(d, i, vis, filteredData, d3.mouse(this)[1], d3.event, "click");
                    setTimeout(function () {
                        vis.bubbleClickMenu = false;
                    }, 1000);
                })
                    .on("contextmenu", function (d, i) {
                    d3.event.preventDefault();
                    vis.bubbleMouseThis = d3.mouse(this)[1];
                    vis.bubbleContextMenu2 = true;
                    vis.bubbleClickMenu = true;
                    vis = vis.dashboardBubbleChartService.setClickMenu(d, i, vis, filteredData, d3.mouse(this)[1], d3.event, "contextmenu");
                });
            };
            var pie, path;
            for (var k in vis.bubbleDistinctValues) {
                _loop_1();
            }
            return vis;
        }
    };
    DashboardBubbleChartService.prototype.setClickMenu = function (d, i, vis, filteredData, mouseThis, event, param) {
        var _a;
        if (filteredData.length > 1) {
            vis.displayClickMenu = true;
            vis.cd.detectChanges();
            d3.selectAll(".toolTip").remove();
            vis.clickMenu = d3.selectAll(".clickMenu")
                .attr("class", "toolTip2 clickMenu");
            vis.clickValues = filteredData;
            for (var k in vis.clickValues) {
                vis.clickValues[k].color = vis.colorScale(vis.clickValues[k].Categorie);
            }
            if (param === "contextmenu") {
                vis.bubbleContextMenu = true;
            }
            var pageX_1 = vis.x(d.data.xHeader) + vis.x.bandwidth() * 2 + 15;
            var pageY_1 = vis.y(d.data.yHeader) + vis.y.bandwidth();
            var orientation_1 = "left";
            var orientationPx_1 = pageX_1 + 50;
            var topPx_1 = pageY_1 - 50;
            var windowWidth_1 = window.innerWidth;
            var windowHeight_1 = window.innerHeight;
            if (windowWidth_1 - orientationPx_1 < 150) {
                orientation_1 = "right";
                orientationPx_1 = pageX_1;
                topPx_1 = pageY_1 - 50;
            }
            vis.clickMenu
                .style("display", "inline-block")
                .style("z-index", 2200);
            setTimeout(function () {
                if (vis.clickMenu.node() != null) {
                    if (windowWidth_1 - orientationPx_1 < vis.clickMenu.node().getClientRects()[0].width + 50) {
                        orientation_1 = "right";
                        orientationPx_1 = windowWidth_1 - pageX_1;
                    }
                    if (pageY_1 + vis.clickMenu.node().getClientRects()[0].height + 10 > windowHeight_1) {
                        topPx_1 = windowHeight_1 - 10 - vis.clickMenu.node().getClientRects()[0].height;
                    }
                }
                vis.clickMenu
                    .style(orientation_1, orientationPx_1 + "px")
                    .style("top", topPx_1 + "px");
            }, 100);
        }
        else if (param === "click") {
            vis.displayClickMenu = false;
            vis.dashboardFilterChartService.setClickFilter(vis, mouseThis, d.data, i, event);
        }
        else if (param === "contextmenu") {
            var yValue = void 0;
            if (vis.view != 'sidebar') {
                _a = vis.dashboardTooltipContextMenuService.setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu, mouseThis, d.data, event), vis.xPosition = _a[0], vis.yPosition = _a[1], vis.filtervalue = _a[2], vis.displayContextMenu = _a[3], yValue = _a[4];
                if (vis.cd && !vis.cd.destroyed) {
                    vis.cd.detectChanges();
                }
                var element = document.getElementsByClassName("contextMenu");
                document.body.appendChild(element[0]);
                // vis.expandedChartEmit.emit({ value: true });
                if (vis.cd && !vis.cd.destroyed) {
                    vis.cd.detectChanges();
                }
            }
        }
        return vis;
    };
    return DashboardBubbleChartService;
}());
export { DashboardBubbleChartService };
