import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, Inject, HostListener } from '@angular/core';

import '@fortawesome/fontawesome-free/css/all.css';
import { SharedInitializationService } from './shared/shared-common-initialization.service';
import { interval, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AppService } from './app.service';
import { AuthService } from './auth/auth-service';
import { DOCUMENT } from '@angular/platform-browser';
import { SharedGeneralFunctionsService } from './shared/shared-general-functions.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', './app.component.scss', './scss/theme-dark.scss', './scss/theme-light.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class AppComponent implements OnInit, OnDestroy {
  theme = "theme-light";
  themeUrl = "./" + this.theme + ".scss";
  themeSubscription = new Subscription();
  title = 'Cardabel';
  /* export const theme = "theme-dark"; */
  visibleHelpSidebar: boolean = false;
  displayFullScreen: boolean = false;
  visibleSidebar2: boolean = false;

  visibleAggregateButton: boolean = false;
  parametersSubscription = new Subscription();
  visibleHelpSubscription = new Subscription();
  helpTitle: string = "";
  helpTitleSubscription = new Subscription();
  parameters: any[] = [];

  helpData: any;
  helpDataSubscription = new Subscription();

  helpImage: any;
  helpImages: any[] = [];
  helpImageSubscription = new Subscription();

  errors;
  pastErrors;

  items: MenuItem[] = [];
  @ViewChild('slideMenu') slideMenu: any;

  imageIsLoaded: boolean = false;

  scrollTop: number = 0;

  aggregParam: string = "simple";

  @HostListener('window:beforeunload', ['$event'])
  unload(event: any) {
    this.closeCardabelWindows();
  }

  @ViewChild('helpTitleStyle') helpTitleStyle;
  documentationHeight: number = 1000;
  constructor(private sharedService: SharedInitializationService,
    private appService: AppService,
    private authService: AuthService,
    private sharedGeneralService: SharedGeneralFunctionsService,
    @Inject(DOCUMENT) private document,
    private cd: ChangeDetectorRef) {


  }

  ngOnInit() {

    console.log(this.items);
    this.documentationHeight = window.innerHeight - 120;
    /* let errors = [];
    console.error = (originLogFn => function () {
      console.log(this);
      console.log(arguments);
      errors.push(arguments[1]);
      //alert("Error:" + arguments);
    
      originLogFn.apply(this, arguments);
    })(console.error); */



    this.visibleHelpSubscription = this.sharedService.visibleSidebarChange
      .subscribe((data: boolean) => {
        this.visibleHelpSidebar = data;
      })

    this.helpTitleSubscription = this.sharedService.helpTitleChange
      .subscribe((data: string) => {
        this.helpTitle = data;
      })

    this.helpDataSubscription = this.sharedService.helpDataChange
      .subscribe((data: any) => {
        this.helpData = data;
        console.log(this.helpData)
        this.slideMenu.activeItem = this.items[0][0];
        document.getElementById("helpDocumentation").scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        document.getElementById("fullScreenHelpDocumentation").scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      })

    this.helpImageSubscription = this.sharedService.helpImageChange
      .subscribe((images: any) => {
        this.helpImages = images;
        if (this.helpData != "") {
          this.getHelpImages();
        }

      })

    this.parametersSubscription = this.sharedService.parametersChange
      .subscribe((parameters: any) => {
        //  this.visibleAggregateButton = data;
        this.parameters = parameters;
        if ((parameters.indexOf("Aggregate") > -1) && ((parameters.indexOf("simple") > -1) || (parameters.indexOf("cycles")))) {
          this.visibleAggregateButton = true;
        }
        else {
          this.visibleAggregateButton = false;
        }
      })

    this.items = [
      {
        label: 'Cardabel Software & Key Features',
        command: (event) => { this.sharedService.getHelpDocumentation(['cardabel']); this.helpTitle = "Cardabel Software & Key Features" }
      },
      {
        label: 'Getting Started',
        command: (event) => { this.sharedService.getHelpDocumentation(['gettingstarted']); this.helpTitle = "Getting Started" }
      },
      {
        label: 'Topics',
        command: (event) => { this.sharedService.getHelpDocumentation(['general', 'topics', 'dashboard']); this.helpTitle = "Topics Page" },
        items: [
          {
            label: 'Topics Detail',
            command: (event) => { this.sharedService.getHelpDocumentation(['general', 'topics', 'dashboard', 'detail']); this.helpTitle = "Topics Page Detail" }
          }
        ]
      },
      {
        label: 'Dashboard',
        command: (event) => { this.sharedService.getHelpDocumentation(['dashboard']); this.helpTitle = "Dashboard" },
        items: [
          {
            label: 'Dashboard Header',
            command: (event) => { this.sharedService.getHelpDocumentation(['dashboard', 'header']); this.helpTitle = "Dashboard Header" },

          },
          {
            label: 'Dashboard Viewer Mode',
            command: (event) => { this.sharedService.getHelpDocumentation(['dashboard', 'viewer']); this.helpTitle = "Dashboard Viewer Mode" },
            items: [
              { label: 'Dashboard Viewer Tab', command: (event) => { this.sharedService.getHelpDocumentation(['dashboard', 'viewer', 'tab']); this.helpTitle = "Dashboard Viewer Tab Mode" } },
            ]
          },
          {
            label: 'Dashboard Designer Mode',
            command: (event) => { this.sharedService.getHelpDocumentation(['dashboard', 'designer']); this.helpTitle = "Dashboard Designer Mode" },
            items: [
              { label: 'Dashboard Designer Tab', command: (event) => { this.sharedService.getHelpDocumentation(['dashboard', 'designer', 'tab']); this.helpTitle = "Dashboard Designer Tab Mode" } },
            ]
          }
        ]
      }
      ,
      {
        label: 'Analysis',
        command: (event) => { this.sharedService.getHelpDocumentation(['analysis']); this.helpTitle = "Analysis" },
        items: [
          {
            label: 'Analysis Header',
            command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'header']); this.helpTitle = "Analysis Header" },
          },
          {
            label: 'Analysis Menu',
            command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'start']); this.helpTitle = "Analysis Menu" },
            items: [
              { label: 'Analysis Menu Bayes', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'menu', 'bayes']); this.helpTitle = "Analysis Menu Bayes" } },
              { label: 'Analysis Menu Supervised', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'menu', 'supervised']); this.helpTitle = "Analysis Menu Supervised" } },
              {
                label: 'Analysis Subtable', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'start', 'subtable']); this.helpTitle = "Analysis Subtable" },
                items: [
                  { label: 'Analysis Menu Gauss', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'menu', 'gauss']); this.helpTitle = "Analysis Menu Gauss" } },
                  { label: 'Analysis Menu Aggregate', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'menu', 'aggregate']); this.helpTitle = "Analysis Menu Aggregate" } },
                  { label: 'Analysis Menu Matching', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'menu', 'matching']); this.helpTitle = "Analysis Menu Matching" } },
                  { label: 'Analysis Menu Temporal', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'menu', 'temporal']); this.helpTitle = "Analysis Menu Temporal" } },
                ]
              }

            ]
          },

          {
            label: 'Analysis Grid',
            command: (event) => {
              this.sharedService.getHelpDocumentation(['analysis', 'grid']); this.helpTitle = "Analysis Grid"
            },
            items: [
              { label: 'Analysis Grid Temporal Details', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'grid', 'temporal', 'details']); this.helpTitle = "Analysis Grid Temporal Details" } },
            ]
          },
          {
            label: 'Analysis Tools',
            command: (event) => { this.helpTitle = "Analysis Tools" },
            items: [
              { label: 'Analysis Label Selected', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'labeliseselected']); this.helpTitle = "Analysis Bottom Container Labelise Selected" } },
              { label: 'Analysis View Combinations', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'viewcombinations']); this.helpTitle = "Analysis Bottom Container View Combinations" } },
              { label: 'Analysis Audit', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'audit']); this.helpTitle = "Analysis Audit" } },
              {
                label: 'Analysis Charts',
                command: (event) => { this.helpTitle = "Analysis Charts" },
                items: [
                  { label: 'Analysis Bayes Charts', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'chart', 'bayes']); this.helpTitle = "Analysis Bayes Charts" } },
                  { label: 'Analysis Gauss Charts', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'chart', 'gauss']); this.helpTitle = "Analysis Gauss Charts" } },
                  { label: 'Analysis Gauss Scatterplot Chart', command: (event) => { this.sharedService.getHelpDocumentation(['general', 'gauss', 'chart', 'scatterplot']); this.helpTitle = "Analysis Gauss Scatterplot Chart" } },
                  { label: 'Analysis Temporal Charts', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'chart', 'temporal']); this.helpTitle = "Analysis Temporal Charts" } },
                  { label: 'Analysis Supervised Charts', command: (event) => { this.sharedService.getHelpDocumentation(['analysis', 'chart', 'supervised']); this.helpTitle = "Analysis Supervised Charts" } },
                  {
                    label: 'Analysis Column Charts', command: (event) => {
                      this.sharedService.getHelpDocumentation(['analysis', 'chart', 'columns']); this.helpTitle = "Analysis Column Charts"
                    }
                  },
                ]
              },

            ]
          }
        ]
      }
      ,
      {
        label: 'Workflow',
        command: (event) => { this.sharedService.getHelpDocumentation(['workflow']); this.helpTitle = "Workflow" },
        items: [
          {

            label: 'Workflow Start', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'start']); this.helpTitle = "Workflow Start" },
          },
          {

            label: 'New workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new']); this.helpTitle = "New Workflow" },
            items: [
              { label: 'New Bayes Workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new', 'bayes']); this.helpTitle = "New Bayes Workflow" } },
              { label: 'New Gauss Workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new', 'gauss']); this.helpTitle = "New Gauss Workflow" } },
              { label: 'New Aggregate Workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new', 'aggregate']); this.helpTitle = "New Aggregate Workflow" } },
              { label: 'New Temporal Workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new', 'temporal']); this.helpTitle = "New Temporal Workflow" } },
              { label: 'New Supervised Workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new', 'supervised']); this.helpTitle = "New Supervised Workflow" } },
              { label: 'New Matching Workflow', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'new', 'matching']); this.helpTitle = "New Matching Workflow" } },
            ]
          },
          {
            label: 'Main Steps',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'selectedtable', 'start']); this.helpTitle = "Workflow Table Configuration" },
            items: [

              { label: 'Field ID', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'fieldid']); this.helpTitle = "All Workflows - FieldID" } },
              { label: 'Attribute Status', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'attrstatus']); this.helpTitle = "All Workflows - Attribute Status" } },
              {
                label: 'New Dimension',
                command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim']); this.helpTitle = "All Workflows - New Dim" },
                items: [
                  {
                    label: 'Numeric',
                    command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric']); this.helpTitle = "All Workflows - New Dim - Numeric" },
                    items: [
                      { label: 'FX Date Field', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'fxdatefield']); this.helpTitle = "All Workflows - New Dim - Numeric - FX Date Field" } },
                      { label: 'FX Fixed Date', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'fxfixeddate']); this.helpTitle = "All Workflows - New Dim - Numeric - FX Fixed Date" } },
                      { label: 'FX Custom Date Field', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'fxcustomdatefield']); this.helpTitle = "All Workflows - New Dim - Numeric - FX Custom Date Field" } },
                      { label: 'FX Custom Fixed Date', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'fxcustomfixeddate']); this.helpTitle = "All Workflows - New Dim - Numeric - FX Custom Fixed Date" } },
                      { label: 'Custom', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'custom']); this.helpTitle = "All Workflows - New Dim - Numeric - Custom" } },
                      { label: 'Custom Sequential', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'customsequential']); this.helpTitle = "All Workflows - New Dim - Numeric - Custom Sequential" } },
                      { label: 'Copy', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'numeric', 'copy']); this.helpTitle = "All Workflows - New Dim - Numeric - Copy" } },
                    ]
                  },
                  { label: 'Date Bucket', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'datebucket']); this.helpTitle = "All Workflows - New Dim - Date Bucket" } },
                  { label: 'Generic', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'generic']); this.helpTitle = "All Workflows - New Dim - Generic" } },
                  { label: 'Financial Instrument', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'newdim', 'financialinstrument']); this.helpTitle = "All Workflows - New Dim - Financial Instrument" } },
                ]
              },
              { label: 'Discretization', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'discret']); this.helpTitle = "All Workflows - Discretization " } },
            ]
          }, {
            label: 'Bayes',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'bayes']); this.helpTitle = "Bayesian Workflow" },
            items: [
              { label: 'Filters', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'filters']); this.helpTitle = "Bayesian Workflow - Filters" } },
              { label: 'Rules', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'bayes', 'rules']); this.helpTitle = "Bayesian Workflow - Rules" } },
              { label: 'DetectSpl', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'bayes', 'DetectSpl']); this.helpTitle = "Bayesian Workflow - Detect Simple" } },
              { label: 'DetectCpx', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'bayes', 'DetectCpx']); this.helpTitle = "Bayesian Workflow - Detect Complex" } },
            ]
          },
          {
            label: 'Aggregate',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'Aggregate']); this.helpTitle = "Aggregate Workflow" },
            items: [
              { label: 'Workflow Subtable Configuration', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'selectedsubtable', 'start']); this.helpTitle = "Start Workflow SubTable Configuration" } },
              { label: 'Filters', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'filters']); this.helpTitle = "Aggregate Workflow - Filters" } },
              {
                label: 'Simple Aggregation',
                items: [
                  { label: 'Aggregation', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'simple', 'aggreg']); this.helpTitle = "Simple Aggregate Workflow - Aggreg" } },
                  { label: 'Measures', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'simple', 'Measures']); this.helpTitle = "Simple Aggregate Workflow - Measures" } },
                  { label: 'Short Long', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'simple', 'ShortLong']); this.helpTitle = "Simple Aggregate Workflow - Short Long" } },
                  { label: 'Post Aggreg', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'simple', 'PostAggreg']); this.helpTitle = "Simple Aggregate Workflow - Post Aggreg" } },
                  { label: 'Cycle', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'simple', 'Cycle']); this.helpTitle = "Simple Aggregate Workflow - Cycle" } },
                  { label: 'Super Cycle', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'simple', 'SuperCycle']); this.helpTitle = "Simple Aggregate Workflow - Super Cycle" } },
                ]
              },
              {
                label: 'Aggregation with Cycles ',
                items: [
                  { label: 'Aggregation', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'cycles', 'aggreg']); this.helpTitle = "Aggregate Workflow with Cycles - Aggreg" } },
                  { label: 'Measures', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'cycles', 'Measures']); this.helpTitle = "Aggregate Workflow with Cycles - Measures" } },
                  { label: 'Short Long', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'cycles', 'ShortLong']); this.helpTitle = "Aggregate Workflow with Cycles - Short Long" } },
                  { label: 'Post Aggreg', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'cycles', 'PostAggreg']); this.helpTitle = "Aggregate Workflow with Cycles - Post Aggreg" } },
                  { label: 'Cycle', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'cycles', 'Cycle']); this.helpTitle = "Aggregate Workflow with Cycles - Cycle" } },
                  { label: 'Super Cycle', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'aggregate', 'cycles', 'SuperCycle']); this.helpTitle = "Aggregate Workflow with Cycles - Super Cycle" } },
                ]
              },

            ]
          },
          {
            label: 'Gauss',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'gauss']); this.helpTitle = "Gaussian Workflow" },
            items: [
              { label: 'Workflow Subtable Configuration', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'selectedsubtable', 'start']); this.helpTitle = "Start Workflow SubTable Configuration" } },
              { label: 'Filters', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'filters']); this.helpTitle = "Gaussian Workflow - Filters" } },
              { label: 'Gauss Attributes', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'gauss', 'GaussAttr']); this.helpTitle = "Gaussian Workflow - Gauss Attributes" } },
              { label: 'Gauss Param', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'gauss', 'GaussParam']); this.helpTitle = "Gaussian Workflow - Gauss Param" } },
              { label: 'Gauss Predict', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'gauss', 'GaussPredict']); this.helpTitle = "Gaussian Workflow - Gauss Predict" } },
              { label: 'Gauss Scatterplot Chart', command: (event) => { this.sharedService.getHelpDocumentation(['general', 'gauss', 'chart', 'scatterplot']); this.helpTitle = "Gauss Scatterplot Chart" } },
            ]
          },
          {
            label: 'Matching',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'gauss']); this.helpTitle = "Gaussian Workflow" },
            items: [
              { label: 'Workflow Subtable Configuration', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'selectedsubtable', 'start']); this.helpTitle = "Start Workflow SubTable Configuration" } },
              { label: 'Filters', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'filters']); this.helpTitle = "Matching Workflow - Filters" } },
              { label: 'Features 1', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'matching', 'features1']); this.helpTitle = "Matching Workflow - Features 1" } },
              { label: 'Features 2', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'matching', 'features2']); this.helpTitle = "Matching Workflow - Features 2" } },
              { label: 'Train', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'matching', 'train']); this.helpTitle = "Matching Workflow - Train" } },
              { label: 'Test', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'matching', 'test']); this.helpTitle = "Matching Workflow - Test" } },
              { label: 'Rules', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'matching', 'Rules']); this.helpTitle = "Matching Workflow - Rules" } },
            ]
          },
          {
            label: 'Supervised',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised']); this.helpTitle = "Supervised Workflow" },
            items: [
              { label: 'Encoding', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'encoding']); this.helpTitle = "Supervised Workflow - Encoding" } },
              { label: 'Model', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'model']); this.helpTitle = "Supervised Workflow - Model" } },
              { label: 'Optimize', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'optimize']); this.helpTitle = "Supervised Workflow - Optimize" } },
              { label: 'Target', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'target']); this.helpTitle = "Supervised Workflow - Target" } },
              { label: 'Target Rules', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'tgtrules']); this.helpTitle = "Supervised Workflow - Target Rules" } },
              { label: 'Train Param', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'trainparam']); this.helpTitle = "Supervised Workflow - Train Param" } },
              { label: 'Train Param', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'supervised', 'predict']); this.helpTitle = "Supervised Workflow - Predict" } },
            ]
          },
          {
            label: 'Temporal',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'temporal']); this.helpTitle = "Temporal Workflow" },
            items: [
              { label: 'Workflow Subtable Configuration', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'selectedsubtable', 'start']); this.helpTitle = "Start Workflow SubTable Configuration" } },
              { label: 'Tree Structure', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'temporal', 'treestruct']); this.helpTitle = "Temporal Workflow - Tree Structure" } },
              { label: 'Leaf Measures', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'temporal', 'leafmeasures']); this.helpTitle = "Temporal Workflow - Leaf Measures" } },
              { label: 'Sequence Key', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'temporal', 'seqkey']); this.helpTitle = "Temporal Workflow - Sequence Key" } },

              { label: 'Models', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'temporal', 'models']); this.helpTitle = "Temporal Workflow - Models" } },
            ]
          }
          ,
          {
            label: 'Workflow and Table Tools',
            command: (event) => { this.helpTitle = "Workflow and table tools." },
            items: [
              { label: 'Workflow Source Charts', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'sourcecharts']); this.helpTitle = "All Workflows - Source Charts" } },
              { label: 'Workflow View Structure', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'workflowstructure']); this.helpTitle = "All Workflows - Workflow Structure" } },
              { label: 'Workflow Table Charts', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'tablecharts']); this.helpTitle = "All Workflows - Table Charts" } },
              { label: 'Workflow Mutual Information', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'mutualinformation']); this.helpTitle = "All Workflows - Mutual Information" } },
              { label: 'Workflow Correlation', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'all', 'correlation']); this.helpTitle = "All Workflows - Correlation" } },

            ]
          },
          {
            label: 'Kernel',
            command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'kernel']); this.helpTitle = "Kernel Workflow" },
            items: [
              { label: 'Attribute Types', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'kernel', 'attrtypes']); this.helpTitle = "Kernel Workflow - Attribute Types" } },
              { label: 'Rules-scripts', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'kernel', 'rulesscripts']); this.helpTitle = "Kernel Workflow - Rules-scripts" } },
              { label: 'Duplicates', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'kernel', 'duplicates']); this.helpTitle = "Kernel Workflow - Duplicates" } },
              { label: 'Missing values', command: (event) => { this.sharedService.getHelpDocumentation(['workflow', 'kernel', 'missingvalues']); this.helpTitle = "Kernel Workflow - Missing Values" } },
            ]
          },
        ]
      },
      {
        label: 'Matching',
        command: (event) => { this.sharedService.getHelpDocumentation(['matching']); this.helpTitle = "Matching" },
        items: [
          { label: 'Matching Header', command: (event) => { this.sharedService.getHelpDocumentation(['matching', 'header']); this.helpTitle = "Matching Header" } },
          { label: 'Matching Menu', command: (event) => { this.sharedService.getHelpDocumentation(['matching', 'menu']); this.helpTitle = "Matching Menu" } },
          { label: 'Matching Grid', command: (event) => { this.sharedService.getHelpDocumentation(['matching', 'grid']); this.helpTitle = "Matching Grid" } },
          { label: 'Matching Reconciliations', command: (event) => { this.sharedService.getHelpDocumentation(['matching', 'grid', 'reconciliations']); this.helpTitle = "Matching Reconciliations" } },
        ]
      },
      {
        label: 'Execution',
        command: (event) => { this.sharedService.getHelpDocumentation(['execution']); this.helpTitle = "Execution" },
        items: [
          {
            label: 'Execution Table', command: (event) => { this.sharedService.getHelpDocumentation(['execution', 'start']); this.helpTitle = "Execution Table" },
            items: [
              {
                label: 'Execution Subtable', command: (event) => { this.sharedService.getHelpDocumentation(['execution', 'start', 'selectsubtable']); this.helpTitle = "Execution Subtable" },
                items: [
                  { label: 'Execution Grid', command: (event) => { this.sharedService.getHelpDocumentation(['execution', 'grid']); this.helpTitle = "Execution Grid" } },
                ]
              },
            ]

          },
        ]
      },
      {
        label: 'Admin',
        command: (event) => { this.sharedService.getHelpDocumentation(['admin']); this.helpTitle = "Admin" },
        items: [
          //  { label: 'Configure Dashboard', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'configuredashboard']); this.helpTitle = "Admin - Configure Dashboard" }, },
          {
            label: 'Settings', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings']); this.helpTitle = "Admin - Settings" },
            items: [
              { label: 'Market Data Source', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'marketdatasource']); this.helpTitle = "Admin - Settings - Market Data Source" }, },
              { label: 'Logout Time', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'logouttime']); this.helpTitle = "Admin - Settings - Logout Time" }, },
              { label: 'Email Configuration', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'emailconfiguration']); this.helpTitle = "Admin - Settings - Email Configuration" }, },
              { label: 'True Status Type', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'truestatustype']); this.helpTitle = "Admin - Settings - True Status Type" }, },
              { label: 'Histogram Cell Threshold', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'histogramcellthreshold']); this.helpTitle = "Admin - Settings - Histogram Cell Threshold" }, },
              { label: 'Script Management', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'scriptmanagement']); this.helpTitle = "Admin - Settings - Script Management" }, },
              { label: 'Logs', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'logs']); this.helpTitle = "Admin - Settings - Logs" }, },
              {
                label: 'Topics', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'topics']); this.helpTitle = "Admin - Settings - Topics" },
                items: [
                  { label: 'Topics - New topic', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'topics', 'newtopic']); this.helpTitle = "Admin - Settings - Topics - New Topic" } },
                  { label: 'Topics - Edit topic', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'topics', 'edittopic']); this.helpTitle = "Admin - Settings - Topics - Edit Topic" } },
                ]
              },
              {
                label: 'Owners', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'owners']); this.helpTitle = "Admin - Settings - Owner" },
                items: [
                  { label: 'Owners - Edit owner', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'owners', 'editowner']); this.helpTitle = "Admin - Settings - Owner - Edit owner" } },
                ]
              },
              { label: 'Server Config', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'settings', 'serverconfig']); this.helpTitle = "Admin - Settings - Server Config" }, },

            ]
          },
          {
            label: 'Market Data', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'marketdata']); this.helpTitle = "Admin - Market Data" },
            items: [
              { label: 'Market Data Selection', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'marketdata', 'selection']); this.helpTitle = "Admin - Market Data - Selection" }, },
              { label: 'Market Data Fetch FX', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'marketdata', 'fetchfx']); this.helpTitle = "Admin - Market Data - Fetch FX" }, },
              { label: 'Market Data View From DB', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'marketdata', 'viewfromdb']); this.helpTitle = "Admin - Market Data - View From DB" }, },
            ]
          },
          {
            label: 'Source Management', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'sourcemanagement']); this.helpTitle = "Admin - Source Management" },
            items: [
              { label: 'Update Data Source', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'sourcemanagement', 'update-data-source']); this.helpTitle = "Admin - Source Management - Update Data Source" }, },
              { label: 'Delete Data Source', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'sourcemanagement', 'delete-data-source']); this.helpTitle = "Admin - Source Management - Delete Data Source" }, },
            ]
          },
          {
            label: 'Authorizations', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations']); this.helpTitle = "Admin - Authorizations" },
            items: [
              {
                label: 'Users', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations', 'users']); this.helpTitle = "Admin - Authorizations - Users" }, items: [
                  { label: 'New User', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations', 'users', 'newuser']); this.helpTitle = "Admin - Authorizations - Users - New User" } },
                  { label: 'Edit User', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations', 'users', 'edituser']); this.helpTitle = "Admin - Authorizations - Users - Edit User" } },
                ]
              },
              {
                label: 'Roles', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations', 'roles']); this.helpTitle = "Admin - Authorizations - Roles" },
                items: [
                  { label: 'New Role', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations', 'roles', 'newrole']); this.helpTitle = "Admin - Authorizations - Roles - New Role" } },
                  { label: 'Edit Role', command: (event) => { this.sharedService.getHelpDocumentation(['admin', 'authorizations', 'roles', 'editrole']); this.helpTitle = "Admin - Authorizations - Roles - Edit Role" } },
                ]
              },
            ]
          },
        ]
      }
      ,


      {
        label: 'User Settings',
        command: (event) => { this.sharedService.getHelpDocumentation(['usersettings']); this.helpTitle = "User Settings" },
        items: [
          { label: 'Change Password', command: (event) => { this.sharedService.getHelpDocumentation(['usersettings', 'changepassword']); this.helpTitle = "Change Password" } },
          { label: 'Change Theme', command: (event) => { this.sharedService.getHelpDocumentation(['usersettings', 'changetheme']); this.helpTitle = "Change Theme" } },
          { label: 'Change Load Record Number', command: (event) => { this.sharedService.getHelpDocumentation(['usersettings', 'loadingsettings']); this.helpTitle = "Change Load Record Number" } },

        ]
      }
    ];

    this.themeSubscription = this.appService.themeChange
      .subscribe((theme: any) => {
        this.document.getElementById(this.theme).setAttribute('id', theme);
        this.theme = theme;
        //this.themeUrl = "assets/scss/"+theme+".scss";


      })
  }

  getDocumentationHeight() {
    if (this.helpTitleStyle) {
      return window.innerHeight - this.helpTitleStyle.nativeElement.offsetHeight - 40 - 16 - 17 - 10;
    }
    else {
      return window.innerHeight - 120;
    }
  }

  getHelpImages() {
    for (var i in this.helpImages) {
      let number = +i + 1;
      var id = document.getElementsByClassName("helpImage" + number)
      if (id.length != 0) {
        //let img=id[0].getElementsByTagName("img");
        for (let item = 0; item < id.length; item++) {
          id[item].classList.add("documentationImage");
          this.helpImage = 'data:image/jpeg;base64,' + this.helpImages[i].image;
          id[item]['src'] = this.helpImage;

        }
      }
    }
  }

  //this is to export the pdf
  exportAsPDF(param) {
    this.sharedService.exportHelpDocumentationFile(param);
  }

  getHelpDocumentation(param) {
    this.aggregParam = "param";
    this.parameters[2] = param;
    this.sharedService.getHelpDocumentation(this.parameters);
  }
  //this is to close cardabel navigation windows

  closeCardabelWindows() {
    console.log("cardabel");

    this.authService.closeNavigationWindows();

  }

  showContextMenu(event) {
    this.appService.showContextMenu(event);
  }



  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.visibleHelpSubscription.unsubscribe();
    this.helpDataSubscription.unsubscribe();
    this.helpTitleSubscription.unsubscribe();
    this.themeSubscription.unsubscribe();
    this.helpImageSubscription.unsubscribe();
  }
}
