import { Injectable, isDevMode } from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AuthService } from './auth/auth-service';
import { Subject } from 'rxjs';
import { ConfigurationLoader } from "./configuration/configuration-loader.service";
import { SharedInitializationService } from './shared/shared-common-initialization.service';


@Injectable()
export class AppService {
    static showMessage(arg0: string, arg1: string) {
        throw new Error('Method not implemented.');
    }


    // url = "http://localhost:80/FraudDetect/WSDataWorkflow.asmx/";
    // constructor(private messageService: MessageService){
    //   }    
      
    constructor(private messageService: MessageService,
   configurationLoader: ConfigurationLoader){
        if (isDevMode()) {
         this.url = configurationLoader.getConfiguration().cardabelUrl;
          this.appUrl = configurationLoader.getConfiguration().appNavigationUrl;
        }
        else { 
        
         this.url = window.location.origin+"/FraudDetect/WSDataWorkflow.asmx/";
         this.appUrl = window.location.origin+"/";
         }

        console.log(window.location.origin);
        console.log(this.url);
        console.log(window.location);
      }

   

    getUrl() {
        return this.url;
    }

    getAppUrl() {
      return this.appUrl;
    }

    workflow: string = "";
    table: string = "";
    subtable: string ="";
    type: string = "";
    source: string = "";
    segAttribute: string = "";
    url: String;
    appUrl: String;
    topic: string = "ALL";
    selectedTopicChange = new Subject<any>();

    theme: string = "theme-dark";
    themeChange = new Subject<string>();
    // try
    previousMessage="";
    //
    filtersClearedChange= new Subject<any>();

    //to close navigation windows
    navigationWindows: {url:string, name:string}[] = [];
    navigationWindowsChange= new Subject<any>();
    loadRecordNumber: number = 10000;
    auditFiles: boolean = false;

    displayContextMenuChange = new Subject<any>();

    startSpin() {
        var spinId = document.getElementById('spinningContainer');
      //  console.log(spinId);
      if (spinId != null) {
        spinId.classList.add('spinningClass');
      }
       
    }
    
    stopSpin() {
        var spinId = document.getElementById('spinningContainer');
       // console.log(spinId);
       if (spinId != null) {
        spinId.classList.remove('spinningClass');
       }
     
    }
    startSpin2() {
      var spinId = document.getElementById('spinningContainer');
    //  console.log(spinId);
    if (spinId != null)  {
      spinId.classList.add('spinningClass2');
    }
     
  }

  
  stopSpin2() {
      var spinId = document.getElementById('spinningContainer');
     // console.log(spinId);
     if (spinId != null) {
      spinId.classList.remove('spinningClass2');
     }
   
  }
    showMessage(type, message) {
      console.log("this.previousMessage");
      console.log("type");
      console.log("message")
      
        if (type === 'Error') {
        //  if (message!==this.previousMessage){
          this.messageService.add({severity: 'error', sticky: true, summary: 'Error Message', detail:message })
        //  }
        }
        else if (type === 'Success') {
          this.messageService.add({severity: 'success', summary: 'Success Message', detail: message})
        }
        else if (type === "Info") {
          this.messageService.add({severity: 'info', summary: 'Info Message', detail: message})
        }
        else if (type === "Warning") {
          this.messageService.add({severity: 'warn',sticky: true, summary: 'Warning Message', detail: message})
        }
      
      this.previousMessage=message;
    }

    setWorkflow(workflow) {
      this.workflow = workflow;
    }

    getWorkflow() {
      return this.workflow;
    }

    setTable(table) {
      this.table = table;
    }

    setUserTable(table) {
      this.table  = table;
    }

    getTable() {
      return this.table;
    }

    setSubtable(subtable) {
      console.log("ssssssssssssssssetSubtable");
      this.subtable = subtable;
    }

    setUserSubtable(subtable) {
      console.log("ssssssssssssssssetUserSubtable");
      this.subtable  = subtable;
    }


    getSubtable() {
      return this.subtable;
    }

    setSource(source) {
      this.source = source;
    }

    getSource() {
      return this.source;
    }

    setType(type) {
      this.type = type;
    }

    getType() {
      return this.type;
    }

    setSegAttribute(segAttribute) {
      this.segAttribute = segAttribute;
    }


    getSegAttribute() {
      return this.segAttribute;
    }

    setTheme(theme) {
      this.theme = theme;
      this.themeChange.next(this.theme)

    }

    getTheme() {
      return this.theme;
    }

    setLoadRecordNumber(number) {
      this.loadRecordNumber = number;
    }

    getLoadRecordNumber() {
      return this.loadRecordNumber;
    }
    
    setAuditFiles(param) {
      this.auditFiles = param;
    }

    getAuditFiles() {
      return this.auditFiles;
    }

    setSelectedTopic(topic,from) {
      this.topic = topic;
      let data =  {
        topic: this.topic, 
        from: from
      }
      this.selectedTopicChange.next(data);
    }
  
    getSelectedTopic() {
      return this.topic;
    }

    clearFilters() {
      this.filtersClearedChange.next(true);
    }

    //this allows to set the navigation windows
    setNavigationWindows(url,popup) {
      this.navigationWindows.push({"url": url, "name": popup});
    }


    //this allows to return the navigation windows
    returnNavigationWindows() {
      return this.navigationWindows;
    }

    //display context menu

    showContextMenu(event) {
      this.displayContextMenuChange.next(event);
    }


    handleError(error: HttpErrorResponse) {
    //  this.stopSpin();
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
          return throwError(
            error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
            return throwError(
              error.message);
        }
        // return an observable with a user-facing error message
        
    
        };
     
       
     

}
