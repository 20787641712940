import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowNameService } from './workflow-name.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class WorkflowMainStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService,
    private router: Router,
  ) { }

  url = this.appService.getUrl();

  viewChart: boolean = false;
  //this is called in the tables steps and workflow name table selected components and determines if the charts are displayed or not 
  viewChartChange = new Subject<boolean>();
  viewSourceChart: boolean = false;
  //this is called in the tables steps and workflow name table selected components and determines if the charts are displayed or not 
  viewSourceChartChange = new Subject<boolean>();
  viewChartsAttributes: any[] = [];
  viewWFStructure: boolean = false;
  //this is called in the tables steps and workflow name table selected components and determines if the charts are displayed or not 
  viewWFStructureChange = new Subject<boolean>();
  viewMutualInfo: boolean = false;
  //this is called in the tables steps and workflow name table selected components and determines if the mutual info is displayed or not 
  viewMutualInfoChange = new Subject<boolean>();
  mutualInfo: any[] = [];
  //this is called in the workflow mutual info component
  mutualInfoChange = new Subject<any[]>();
  mutualInfoTitleChange = new Subject<string>();
  //this is called in the workflow view charts components and automatically updates the attributes to be viewed
  viewChartsAttributesChange = new Subject<any[]>();
  statAllTablesAttributes: any[] = [];
  //this is called in the view chart component and automatically updates the chart data
  statAllTablesAttributesChange = new Subject<any[]>();
  statAllTablesAttributesData: any[] = [];
  //this is called in the view chart component and automatically updates the chart detail data
  statAllTablesAttributesDataChange = new Subject<any[]>();
  statAllTablesAttributesTableData: any[] = [];
  //this is called in the view chart component and automatically updates the chart detail data
  statAllTablesAttributesTableDataChange = new Subject<any[]>();

  tablePotentialFieldIDs: any[] = [];
  //this is called in the field id component and automatically updates the potential ids 
  tablePotentialFieldIDSChange = new Subject<any[]>();

  newfieldIDChange = new Subject<any>();

  //this is called to change the table steps, and subtable steps
  showTableSteps: boolean = false;
  showTableStepsChange = new Subject<boolean>();
  subTableStepsChange = new Subject<any[]>();

  dateBucketLadderNameArray: any[] = [];
  dateBucketLadderDetailArray: any[] = [];
  //this is called in the new dim component and automatically updates the date bucket ladder detail array
  dateBucketLadderDetailArrayChange = new Subject<any[]>();
  allScripts: any[] = [];
  //this is called in the super cycle component and automatically updates all the scripts
  allScriptsChange = new Subject<any[]>();
  allScriptNames: { Name: string }[] = [];
  allScriptNamesChange = new Subject<any[]>();
  allRuleScriptNamesChange = new Subject<any[]>();
  allTrueStatusTypes: any[] = [];

  allScripts_package: any[] = [];
  allScripts_packageChange = new Subject<any[]>();

  // this is for the status of an execution
  executionStatusChange = new Subject<any>();

  viewSubtableStatsChange = new Subject<boolean>();

  //determines whether the view wf structure area should be displayed or not
  //called in workflow view wf structure and workflow name table selected components
  setViewWFStructure(value) {
    this.viewWFStructure = value;
    this.viewWFStructureChange.next(this.viewWFStructure);
  }

  //returns the view workflow structure (true or false)
  //called in the table steps component
  getViewWFStructure() {
    return this.viewWFStructure;
  }
  //determines whether the view chart area should be displayed or not
  //called in workflow view charts and workflow name table selected components
  setViewChart(value) {
    this.viewChart = value;
    this.viewChartChange.next(this.viewChart);
  }

  //determines whether the view source chart area should be displayed or not
  //called in workflow source charts and workflow name components
  setViewSourceChart(value, from) {
    this.viewSourceChart = value;
    this.viewSourceChartChange.next(this.viewSourceChart);
  }


  //set the attributes to be viewed
  //called in workflow name table selected
  setViewChartAttributes(attributes) {
    this.viewChartsAttributes = attributes;
    this.viewChartsAttributesChange.next(this.viewChartsAttributes);
  }

  //determines whether the view mutual info area should be displayed or not
  //called in workflow view charts and workflow name table selected components
  setViewMutualInfo(value) {
    this.viewMutualInfo = value;
    this.viewMutualInfoChange.next(this.viewMutualInfo);
  }
  //determines whether the view chart area should be displayed or not
  //called in workflow view charts and workflow name table selected components
  setViewSubtableStats(value) {
    this.viewSubtableStatsChange.next(value);
  }
  //returns the attributes to be viewed in charts
  //called in workflow view charts
  getViewChartAttributes() {
    return this.viewChartsAttributes;
  }

  //Returns the number of anomalies and correct records (bayes only) and an histogram of all the attribute values for the table.
  //called in analysis menu bayes component
  //getStatTableAllAttributesHistos(workflow, segAttributeValue, proMode, nbbins )
  getStatTableAllAttributesHistos(workflow, segAttributeValue, nbbins, withInvisibleAttributes) {
    const url = this.url;
    const webservice = "StatTableAllAttributesHistos";
    const completeUrl = url + webservice;

    this.appService.startSpin2();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)
      // .set("proMode", proMode)
      .set("nbbins", nbbins)
      .set("withInvisibleAttributes", withInvisibleAttributes)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          // this.statAllTablesAttributes = response.tableRows2;
          this.statAllTablesAttributes = response.tableRows;
          this.statAllTablesAttributesChange.next(this.statAllTablesAttributes);
          this.statAllTablesAttributesData = response.tableRows2;
          this.statAllTablesAttributesDataChange.next(this.statAllTablesAttributesData);
          this.statAllTablesAttributesTableData = response.tableRows3;
          this.statAllTablesAttributesTableDataChange.next(this.statAllTablesAttributesTableData);

          //to be part of another service   this.errorsDetected = response.tableRows;
          //to be part of another service    this.errorsDetectedChange.next(this.errorsDetected);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin2();
      });
  }

  //Returns the mutual information for the selected attributes
  //called in workflow name table selected component
  getStatMutualInfo(workflow, segAttributeValue, attrList) {
    const url = this.url;
    const webservice = "StatMutualInformation";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)
      .set("attrList", attrList)


    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.mutualInfo = response.tableRows;
          this.mutualInfoChange.next(this.mutualInfo);
          this.mutualInfoTitleChange.next("Mutual Information");
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Returns the  correlation matrice for all the attributes
  //called in workflow name table selected component
  getStatCorrelation(workflow, segAttributeValue) {
    const url = this.url;
    const webservice = "StatCorrelation";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", segAttributeValue)



    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.mutualInfo = response.tableRows2;
          this.setMutualInfoTitle("Correlation")
          this.mutualInfoChange.next(this.mutualInfo);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  //change mutual info title
  //called in workflow table selected
  setMutualInfoTitle(title) {
    this.mutualInfoTitleChange.next(title)
    if (title === "Mutual Information") {
      this.mutualInfo = [];
      this.mutualInfoChange.next(this.mutualInfo);

    }
  }
  //returns all the statAllTablesAttributes
  // is called in workflow/view chart item (but getStatTableAllAttributesHistos is not !!! )
  getStatTableAllAttributes() {
    //   console.log(this.statAllTablesAttributes);
    return this.statAllTablesAttributes;
  }

  //copies the selected table to save time
  //called in workflow name table selected component 
  copyBaseTable(workflow, table, fromWFTable) {
    const url = this.url;
    const webservice = "WFBaseTableCopyFrom";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("fromWFTable", fromWFTable)



    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.workflowService.updateWorkflowTable(table, response.wfStructure);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //copies the selected subtable to save time
  //called in workflow subtable selected component
  copyBaseTableSubTable(workflow, table, subTableName, fromWFSubTable) {
    const url = this.url;
    const webservice = "WFBaseTableCopyFromSubTable";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subTableName)
      .set("fromWFSubTable", fromWFSubTable)



    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.workflowService.updateWorkflowTable(table, response.wfStructure);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //deletes the selected table 
  //called in workflow name table selected component 
  deleteBaseTable(workflow, table) {
    const url = this.url;
    const webservice = "WFBaseTableDelete";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.appService.setTable("");
          this.appService.setSubtable("");
          this.workflowService.updateWorkflowTable(table, response.wfStructure);

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //deletes the selected subtable 
  //called in workflow subtable selected component 
  deleteBaseTableSubTable(workflow, table, subtableName) {
    const url = this.url;
    const webservice = "WFBaseTableDeleteSubTable";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttrValue", table)
      .set("subTableName", subtableName)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.appService.setSubtable("");
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Returns the potential unique ID attributes for a table.
  //called in workflow name table selected
  getTablePotentialIDS(workflow, source, segAttribute, segAttributeValue) {
    const url = this.url;
    const webservice = "WFBaseTableGetPotentialIDS";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("srcName", source)
      .set("segAttr", segAttribute)
      .set("segAttrValue", segAttributeValue)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        
        if (response.statusCode > -1) {
          //  console.log(response);
          this.tablePotentialFieldIDs = response.tableRows;
          this.tablePotentialFieldIDSChange.next(this.tablePotentialFieldIDs);
        }
        else {
          this.tablePotentialFieldIDs = [];
          this.tablePotentialFieldIDSChange.next(this.tablePotentialFieldIDs);
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.tablePotentialFieldIDs = [];
        this.tablePotentialFieldIDSChange.next(this.tablePotentialFieldIDs);
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //returns the potentiel field ids that were found and sends it the field id component
  //called in fieldID component
  getTablePotentialFieldIDS() {
    return this.tablePotentialFieldIDs;
  }

  //Updates the workflow table with the ID of the table.
  // and the trustatustypeprefix (defaut="All")
  //called in fieldID component
  setTableFieldID(workflow, segAttribute, table, fieldID, potentialIDs, trueStatusTypePrefix) {
    const url = this.url;
    const webservice = "WFBaseTableSetFieldID";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttr", segAttribute)
      .set('segAttrValue', table)
      .set('fieldID', fieldID)
      .set('potentialIDs', potentialIDs)
      .set('trueStatusTypePrefix', trueStatusTypePrefix);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          // this.showTableSteps=true;
          //  this.showTableStepsChange.next(this.showTableSteps);
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
          this.newfieldIDChange.next(fieldID);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      }
      );
  }
    //Updates the workflow table with the truestatustype prefix of the table.
  //called in fieldID component
  setTableTrueStatusTypePrefix(workflow, segAttribute, table, trueStatusTypePrefix) {
    const url = this.url;
    const webservice = "WFBaseTableSetTrueStatusTypePrefix";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set("segAttr", segAttribute)
      .set('segAttrValue', table)
      .set('trueStatusTypePrefix', trueStatusTypePrefix);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      }
      );
  }
  //Changes the status of the variables (visible/invisible) in the workflow and saves the workflow.
  //called in visible Attr component
  setTableAttributesStatus(apply_and_chain, workflow, table, attributesList) {
    const url = this.url;
    const webservice = "WFBaseTableSetAttributesStatus";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('variablesToChange', attributesList);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          //  console.log(response);
          // this.showTableSteps=true;
          //  this.showTableStepsChange.next(this.showTableSteps);
          this.appService.showMessage('Success', response.statusText);
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Success', error.statusText);
        this.appService.stopSpin();
      });
  }

  // Gets the list of ladders defined in the config directory.
  //called in new dim component
  getNewDim_DateBucket_LadderNames() {

    const url = this.url;
    const webservice = "WFGetLadderNames";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.dateBucketLadderNameArray = response.tableRows;

        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  };

  //returns the ladder name array
  //called in new dim array
  // !!!!! MAKE SUBSCRIPTIO?
  getNewDim_DateBucket_LadderNameArray() {
    return this.dateBucketLadderNameArray;
  }

  //Loads the ladder from the config directory.
  //called in new dim component
  getNewDim_DateBucket_LadderNameDetail(ladderName) {
    const url = this.url;
    const webservice = "WFGetLadder";
    const completeUrl = url + webservice;

    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("ladderName", ladderName)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.dateBucketLadderDetailArray = response.tableRows;
          this.dateBucketLadderDetailArrayChange.next(this.dateBucketLadderDetailArray);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Creates a new field according to the new dimension parameter.
  //called in new dim component
  setTableNewDimAttribute(apply_and_chain, workflow, table, formula, newDimAttribute) {
    const url = this.url;
    const webservice = "WFBaseTableSetNewDimAttribute";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('newDimFormula', formula)
      .set('newDimAttribute', newDimAttribute)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          //  console.log(response)
          this.appService.showMessage('Success', response.statusText);


  
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
          /// this.showTableSteps = false;
          //  this.showTableStepsChange.next(this.showTableSteps)
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Removes the new dimension fields indicated in the jsonString 'variablesToRemove'.
  //called in new dim component
  removeTableNewDimAttribute(apply_and_chain, workflow, table, variablesToRemove, formula) {
    const url = this.url;
    const webservice = "WFBaseTableRemoveNewDimAttribute";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('variablesToRemove', variablesToRemove)
      .set('formula', formula)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          //  console.log(response);
          this.appService.showMessage('Success', response.statusText);
          // this.showTableSteps=true;
          // this.showTableStepsChange.next(this.showTableSteps);

          this.workflowService.updateWorkflowTable(table, response.wfStructure);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Creates a new field according to the new dimension parameter.
  //called in new dim component
  setTableNewDimAttributeComment(workflow, table, newDimAttrName, newDimAttrComment) {
    const url = this.url;
    const webservice = "WFBaseTableSetNewDimAttributeComment";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', table)
      .set('newDimAttrName', newDimAttrName)
      .set('newDimAttrComment', newDimAttrComment)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage('Success', response.statusText);
          if (this.showTableSteps) {
            console.log("youpi");
            //  this.subTableStepsChange.next();
          }
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
          /// this.showTableSteps = false;
          //  this.showTableStepsChange.next(this.showTableSteps)
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Quantisizes all the continuous variables in the indicated table using the number of intervals passed as nbbins.
  //called in discret component
  applyTableDiscretization(apply_and_chain, workflow, segAttribute, table, nbbins, method, discretAttributes) {
    // only one method for now: "Gaussian Mixtures"
    const url = this.url;
    const webservice = "WFBaseTableDiscretizeNumAttributes";
    const completeUrl = url + webservice;

    console.log(discretAttributes);
    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('apply_and_chain', apply_and_chain)
      .set("wfName", workflow)
      .set("segAttr", segAttribute)
      .set('segAttrValue', table)
      .set('nbbins', nbbins)
      .set('discretMethod', method)
      .set('discretAttributes', discretAttributes);

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response);
          this.appService.showMessage('Success', response.statusText);
          this.showTableSteps = true;
          this.showTableStepsChange.next(this.showTableSteps);
          this.workflowService.updateWorkflowTable(table, response.wfStructure);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }
  //Sets the filters in the list for the workflow/table referenced by the input parameters
  //called in filters component
  setSubTableFilters(apply_and_chain, workflow, tableName, subtableName, filtersArray, whereClause) {
    const url = this.url;
    const webservice = "WFBaseSubTableSetFilters";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('filtersList', filtersArray)
      .set('whereClause', whereClause)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //  console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          //  console.log(response)
          this.appService.showMessage('Success', response.statusText);
          //  this.showTableSteps=true;
          //  this.showTableStepsChange.next(this.showTableSteps);
          console.log("update successfull");
          if (response.wfStructure.WFType == 'Matching') {
            if (apply_and_chain) {
              this.executionStatusChange.next();
            }
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Returns all existing scripts (in python) written in the scripts/subdir directory (with the parameters)
  //called in super cycle component
  getAllScripts(subdirName) {
    const url = this.url;
    const webservice = "WFgetAllScripts";
    const completeUrl = url + webservice;

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("subdir", subdirName)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);

        if (response.statusCode > -1) {
          this.allScripts = response.tableRows;
          this.allScriptsChange.next(this.allScripts);
        }
        else {
          let scripts = [];
          this.allScriptsChange.next(scripts);
          //27072020 this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        let scripts = [];
        this.allScriptsChange.next(scripts);
        //27072020 this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin2();
      });
  }

  // get all the python scripts in the client directory foolowing the name of the folder passed as parameter
  //called in measures, post aggreg, new dim, target components
  getAllScriptNames(subdirName) {

    const url = this.url;
    const webservice = "WFGetAllScriptNames";
    const completeUrl = url + webservice;
    this.appService.startSpin();
    //Create new HttpParams
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("subdir", subdirName)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.allScriptNames = response.tableRows;
          if (subdirName.toLowerCase()=="newnum"){
            this.allScriptNamesChange.next(this.allScriptNames);
          }
          else if (subdirName.toLowerCase()=="demo"){
            this.allRuleScriptNamesChange.next(this.allScriptNames);
          }
        }
        else {
          let scripts = [];
          this.allScriptNamesChange.next(scripts);
          //27072020this.appService.showMessage('Error', response.statusText)
        }
        this.appService.stopSpin();
      }, (error) => {
        //this.appService.showMessage('Error', error.statusText)
        let scripts = [];
        this.allScriptNamesChange.next(scripts);
        this.appService.stopSpin();
      });
  }

  //Add a subtable for the workflow/table and new subTableName referenced by the input parameters
  //called in workflow name table selected component
  addNewSubtable(workflow, tableName, subtableName, source, type, segregationAttribute, subTableType) {
    const url = this.url;
    const webservice = "WFBaseTableAddSubTable";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('subTableType', subTableType)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        // console.log(response);
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);

        if (response.statusCode > -1) {
          // console.log(response);
          this.appService.showMessage('Success', response.statusText);
          this.router.navigate(["workflow", source, type, workflow, segregationAttribute, tableName, subtableName])
          // this.showTableSteps=true;
          // this.showTableStepsChange.next(this.showTableSteps);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin();
      });
  }

  //Returns all existing scripts (in python) written in the scripts/subdir directory (with the parameters)
  //called in super cycle component
  getAllScripts_package(package_name) {
    const url = this.url;
    const webservice = "WFgetAllScripts_package";
    const completeUrl = url + webservice;

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("package", package_name)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);

        if (response.statusCode > -1) {
          this.allScripts_package = response.tableRows;
          this.allScripts_packageChange.next(this.allScripts_package);
        }
        else {
          let scripts = [];
          //this.allScripts_packageChange.next(scripts);
          this.appService.showMessage('Error', response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        let scripts = [];
        this.allScripts_packageChange.next(scripts);
        //27072020 this.appService.showMessage('Error', error.statusText);
        this.appService.stopSpin2();
      });
  }
}