import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WorkflowNameService } from './workflow-name.service';
import { MultiBarChart } from 'src/app/shared/d3/d3multibarchart';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { MultiStackedBarChart } from 'src/app/shared/d3/d3multistackedbarchart';
import { StackedBarChart } from 'src/app/shared/d3/d3stackedbarchart';


@Injectable()
export class WorkflowKernelStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private workflowService: WorkflowNameService) { }

  url = this.appService.getUrl();

  kernelChartStatusChange = new Subject<any[]>();
  kernelMissingValuesChange = new Subject<any[]>();
  kernelDuplicatesChange = new Subject<any[]>();
  kernelDuplicatesNumberChange = new Subject<any[]>();
  kernelResultChange = new Subject<any[]>();
  kernelDuplicatesRecordsChange = new Subject<any[]>();


   //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableAttrTypes(apply_and_chain, workflow, tableName, subtableName, attributesList) {
    const url = this.url;
    const webservice = "WFKernelTableSetAttrTypes";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

     //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  getKernelTableMissingValues(workflow, tableName, subtableName) {
    const url = this.url;
    const webservice = "WFKernelTableGetMissingValues";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
 
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          this.kernelMissingValuesChange.next(response.tableRows);  
        }
        else {
          if (response.statusCode == -2){

            this.appService.showMessage("Warning", response.statusText);
            this.kernelMissingValuesChange.next(response.tableRows)
          }
          else {
             this.appService.showMessage("Error", response.statusText);
          }
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

     //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableMissingValues(apply_and_chain, workflow, tableName, subtableName, attributesList) {
    const url = this.url;
    const webservice = "WFKernelTableSetMissingValues";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('attributesList', attributesList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

       //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  getKernelTableDuplicates(workflow, tableName, subTableName, attributesList) {
    const url = this.url;
    const webservice = "WFKernelTableGetDuplicatesValues";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subTableName)
      .set('attributesList', attributesList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
       // console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (response.tableRows.length >0){
          this.kernelDuplicatesChange.next(response.tableRows);
          this.kernelDuplicatesNumberChange.next(response.tableRows2[0]);
          }
          else{
            this.appService.showMessage("Warning", response.statusText);
            this.kernelDuplicatesChange.next(response.tableRows);
            this.kernelDuplicatesNumberChange.next(response.tableRows2[0]);
          }
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

         //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  getKernelTableDuplicatesRecords(workflow, tableName, subTableName, attributesList, duplicateSet){
    const url = this.url;
    const webservice = "WFKernelTableGetDuplicatesRecords";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subTableName)
      .set('attributesList', attributesList)
      .set('duplicateSet', duplicateSet)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          if (response.tableRows.length >0){
            this.kernelDuplicatesRecordsChange.next(response.tableRows);
          }

        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
       //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableDuplicatesValues(apply_and_chain, workflow, tableName, subtableName, duplicatesList) {
    const url = this.url;
    const webservice = "WFKernelTableSetDuplicatesValues";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('duplicatesList', duplicatesList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          //console.log(response)
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            //this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

       //Sets the attrlist with new attrtypes in the workflow/table/and returns back the workflow
  //called in workflow kernel steps
  setKernelTableRuleScripts(workflow, tableName, subtableName, ruleScriptsList) {
    const url = this.url;
    const webservice = "WFKernelTableSetRuleScripts";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('ruleScriptsList', ruleScriptsList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTable(tableName, response.wfStructure);
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);

        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }



}