import * as d3 from 'd3';

export class BarChart {
    public parentId: string;
    public yVariable: string;
    public title: string;
    public height: number;
    public width: number;
    public domain: string;
    public xVariable: string;
    public color: string;
    public valueline: boolean;
    public valuelinevariable:string;
    public valuelinecolor: string;
    public scatter: boolean;
    public scattervariable:string;
    public scattercolor: string;
    public scattercolor1: string;
    public rotateLabels:boolean;
    public margin: {top: number, bottom: number, right: number, left: number};
    public legendAppear: boolean;
    public lines:any[];
    public clickedGauss: string;
    public barChartData: any[];
    //new created
    svg: any;

    //clip paths
    clip: any;
    clipx: any;
    clipy: any;
    //svg groups
    g2: any;
    g1: any;
    g: any;
    //transition t
    t: any;

    //scales
    xWidth: number;
    x: any;
    y: any;
    yAxisCall: any;
    xAxisCall: any;
    xAxis: any;
    yAxis: any;

    yRotate: any;
    xRotate: any;
    defs: any;
    dataFiltered: any[] = [];

    //update vis variables
    linePath: any;
    zoom: any;
    maxY: any;
    
    rects: any;
    data: any[] = [];
    param: boolean = false;
    lineRemove: boolean = false;
    filtered: any[];
    tooltip: any;
    lineGroup: any;
    lineText: any;

    
 

    constructor(
            parentId: string,
            yVariable: string,
            title: string,
            height: number,
            width: number,
            domain: string,
            xVariable: string,
            color: string,
            valueline: boolean,
            valuelinevariable:string,
            valuelinecolor: string,
            scatter: boolean,
            scattervariable:string,
            scattercolor: string,
            scattercolor1: string,
            rotateLabels:boolean,
            margin: {top: number, bottom: number, right: number, left: number},
            legendAppear: boolean,
            lines:any[],
            clickedGauss: string,
            barChartData: any[],
            ) {
        this.parentId = parentId;
        this.yVariable = yVariable;
        this.title = title;
        this.height = height;
        this.width = width;
        this.domain = domain;
        this.xVariable = xVariable;
        this.color = color;
        this.valueline = valueline;
        this.valuelinevariable = valuelinevariable;
        this.valuelinecolor = valuelinecolor;
        this.scatter = scatter;
        this.scattervariable = scattervariable;
        this.scattercolor = scattercolor;
        this.scattercolor1 = scattercolor1;
        this.rotateLabels = rotateLabels;
        this.margin = margin;
        this.legendAppear = legendAppear;
        this.lines = lines;
        this.clickedGauss = clickedGauss;
        this.barChartData = barChartData;
        
      //  this.initVis();
    }

    initVis() {
       this.height = this.height - this.margin.top - this.margin.bottom;
       this.width = this.width - this.margin.left - this.margin.right;

       this.clip = "clip"+this.parentId.substr(1);
       this.clipx = "clipx" +this.parentId.substr(1);
       this.clipy = "clipy"+this.parentId.substr(1);
       var id = d3.select(this.parentId);
       id.selectAll('svg').remove();
      
    //   d3.selectAll(this.parentId).remove('svg');
       this.svg = d3.select(this.parentId)
            .append('svg')
            .attr('width', this.width + this.margin.left+this.margin.right)
            .attr('height', this.height +this.margin.top + this.margin.bottom)
        
        this.g2= this.svg.append("g")
            .attr("transform", "translate(" +this.margin.left+ ", "+ this.margin.top+")")
            .attr("clip-path", "url(#"+this.clip+")")

        this.g1= this.svg.append("g")
            .attr("transform", "translate(" +this.margin.left+ ", "+ this.margin.top+")")
            .attr("clip-path", "url(#"+this.clip+")")

        this.g= this.svg.append("g")
            .attr("transform", "translate(" +this.margin.left+ ", "+ this.margin.top+")")
          //  .attr("clip-path", "url(#"+this.clip+")")

        this.t = () => { return d3.transition().duration(1000)}

        this.xWidth = this.width - this.margin.right;
        this.x = d3.scaleBand()
                    .range([0, this.xWidth-80])
                    .padding(0.5)
        
        this.y = d3.scaleLinear()
                    .range([this.height, 0])
        
        this.yRotate = d3.scaleBand()
                        .range([this.height, 0 ])
                        .padding(0.5)
        
        this.xRotate = d3.scaleLinear()
                        .range([0, this.xWidth-80]);
        
        //init clip paths to make sure that the graph (line/scatter/)stays within chart limits when zooming
        this.defs = this.g.append('defs');
        
        this.defs
            .append('svg:clipPath')
            .attr('id', this.clip)
            .attr("class", "clip")
            .append('svg:rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', this.xWidth - 80)
            .attr('height', this.height);
    
        this.defs
            .append('svg:clipPath')
            .attr('id', this.clipx)
            .attr("class", "clipx")
            .append('svg:rect')  
            .attr('x', 0)
            .attr('y', this.height)
            .attr('width', this.xWidth -80)
            .attr('height', this.margin.bottom);
        
        this.defs
            .append('svg:clipPath')
            .attr('id', this.clipy)
            .attr("class", "clipy")
            .append('svg:rect')  
            .attr('x', -this.margin.left+2)
            .attr('y', 0)
            .attr('width', this.margin.left)
            .attr('height', this.height);
        
        this.yAxisCall = d3.axisLeft()
                            .ticks(4)

        this.xAxisCall = d3.axisBottom()
                            .ticks(2)
                            .tickFormat(function(d) {
                                return d;
                               /* if (typeof d === 'string') {
                                    return "" + this.capitalizeFirstLetter(d);
                                }
                                else if (typeof d === 'boolean'){
                                    return d;
                                }
                                else {
                                    return d.toFixed(2);
                                } */
                            
                            });
        
         //x and y axis are appended in html
        this.xAxis = this.g.append("g")
            .attr('clip-path', 'url(#'+this.clipx+')')
            .append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + this.height +")")
            .attr("dx", "0.71em");



        this.yAxis = this.g.append("g")
            .attr('clip-path', 'url(#'+this.clipy+')')
            .append("g")
            .attr("class", "y axis");
        
        
        
        this.wrangleData(this.domain, this.yVariable, this.barChartData, this.title)
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    wrangleData(domain, yVariable, barChartData, title) {
        this.domain = domain;
        this.yVariable = yVariable;
        this.dataFiltered = barChartData;
        this.title = title;
        var vis = this;

        this.dataFiltered.forEach(function(d) {
            d[yVariable] = + d[yVariable];
            
            if (vis.valueline === true) {
                d[vis.valuelinevariable] = + d[vis.valuelinevariable]
            }
            if (vis.scatter === true) {
                 d[vis.scattervariable] = + d[vis.scattervariable]
            }
           
        })
        console.log(this.dataFiltered);
        console.log(this.valueline);
        console.log(this.yRotate)
        this.updateVis();

    }

    updateVis() {
        var vis = this;

        
        //init text title if needed

        this.g.selectAll(".title").remove();

        this.g.append("text")
            .attr("class", "title")
            .attr("y", -5)
            .attr("x", -50)
            .attr("font-size", "12px")
            .attr("text-anchor", "start")
            .text(this.title)

        this.linePath = this.g1.append("path")
                .attr("clip-path", "url(#"+this.clip+")")
                .attr("class", "line")
                .attr("fill", "none")
                .attr("stroke", this.valuelinecolor)
                .attr("stroke-width", "1px")

        // initialize zoom function
            this.zoom = d3.zoom()
            .scaleExtent([1, 10])
            .translateExtent([[0, 0], [this.xWidth-80, this.height]])
            .extent([[0, 0], [this.xWidth-80, this.height]])
            .on("zoom", this.zoomed.bind(this))
        
            this.svg
            .call(this.zoom.transform, d3.zoomIdentity)
        
        // Update scales
        this.x
            .domain(this.domain)
        
        this.maxY = d3.max(this.dataFiltered, function(d) { 
                        return d[vis.yVariable]})+0.3;

        if (this.valueline === true) {
            var maxValue = d3.max(this.dataFiltered, function(d) {
                 return d[vis.valuelinevariable]})+0.3;
            
            if (maxValue > this.maxY) {
                this.maxY = maxValue;
            }
        }
        if (this.scatter === true) {
            var maxValue = d3.max(this.dataFiltered, function(d) { 
                return d[vis.scattervariable]})+0.3;
            
            if (maxValue > this.maxY) {
                this.maxY = maxValue;
            }
        }

        this.y.domain([Math.min(0,d3.min(this.dataFiltered, function(d) { 
                                return d[vis.yVariable] })), this.maxY
        ]);
   
        if (this.dataFiltered.length === 0) {
            this.x.domain([0,1]);
            this.y.domain([0,1]);
        }

        // Update axes
        this.xAxisCall.scale(this.x);
        this.xAxis.transition(this.t()).call(this.xAxisCall);
        this.yAxisCall.scale(this.y);
        this.yAxis.transition(this.t()).call(this.yAxisCall);
        
        if (this.dataFiltered.length > 10) {
            this.xAxis
               .selectAll("text")	
               .style("text-anchor", "start")
               .attr("dx", "-.8em")
               .attr("dy", ".15em")
               .attr("transform", "rotate(+35)");
       }
       else {
        this.xAxis
               .selectAll("text")	
               .attr("transform", "rotate(0)");
       }

       if (this.rotateLabels === true) {
        this.xAxis
            .selectAll("text")	
            .style("text-anchor", "start")
            .attr("dx", ".8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(+35)");
        }
   
        var variable =  this.xVariable;

        // init tooltip in div

        this.tooltip = d3.selectAll('body').append("div").attr("class", "toolTip");
        
        this.lineRemove = false;

        //call function to draw bars
        var vis = this;
      this.redrawBars();
     // call function when mouse moves to show tooltip
    

     if (this.valueline === true) {
        this.redrawLine();
 
        }
    
    // if scatter plot is true then follow code below
        if (this.scatter === true) {
          this.redrawScatter();
        }


        if (this.lines.length !=0) {
            this.redrawLines();
        }

            // initialize legend (get data keys, get color and show rect + text)
       this.g.selectAll(".legendClass").remove();
        
       var dataKeys = [];
       if (this.dataFiltered.length != 0) {
           dataKeys = Object.keys(this.dataFiltered[0]);
       }
       
       var keys = [];
        for (var i in dataKeys) {
            if (dataKeys[i] === this.yVariable) {
                keys.push(dataKeys[i])
            }
            else if (dataKeys[i] === this.scattervariable) {
                keys.push(dataKeys[i])
            }
            else if (dataKeys[i] === this.valuelinevariable) {
                keys.push(dataKeys[i])
            }
        }

        var legend = this.g.append("g")
                .attr("transform", function(d, i) { return "translate(-20," +  18 + ")"; })
                .attr("class", "legendClass")
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr("text-anchor", "start")
                .selectAll("g")
                .data(keys)
                .enter().append("g")
                  .attr("transform", function(d, i) { return "translate(-20," + i * 18 + ")"; });
   
        legend.append("rect")
                  .attr("x", this.width-50 )
                  .attr("width", 10)
                  .attr("height", 10) 
                  .attr("fill", function(d) {
                  
                    if ((d=== vis.yVariable) || (d === vis.scattervariable) || (d === vis.valuelinevariable)) {
                         if (d === vis.yVariable) {
                              return vis.color;
                          }
                          
                          else if (d === vis.scattervariable) {
                                  return vis.scattercolor;
                          }
                          
                      
                          else if (d === vis.valuelinevariable) {
                                  return vis.valuelinecolor;
                          }
                    }
                    
                  })
                  .attr("stroke",function(d) {
              
                    if ((d=== vis.yVariable) || (d === vis.scattervariable) || (d === vis.valuelinevariable)) {
                         if (d === vis.yVariable) {
                              return vis.color;
                          }
                          
                          else if (d === vis.scattervariable) {
                                  return vis.scattercolor;
                          }
                          
                      
                          else if (d === vis.valuelinevariable) {
                                  return vis.valuelinecolor;
                          }
                    }
                    
                  })
                  .attr("stroke-width",2)
                  .on("click",function(d) { update(d) });
            
        legend.append("text")
                  .attr("fill", "currentColor")
                  .attr("x", this.width-35)
                  .attr("y",4)
                  .attr("dy", "0.32em")
                  .text(function(d) { if ((d=== vis.yVariable) || (d === vis.scattervariable) || (d === vis.valuelinevariable)) {
                  return d; } });
        
        if (this.legendAppear === false) {
            d3.selectAll(".legendClass").remove();
        }
            
             
      // call zoom on whole svg + cancel zooming when double clicking
       this.svg
            .call(this.zoom)
            .on("dblclick.zoom", null)

    
    
    // when clicking on legend element, show or hide line/bar/scatter chart
    vis.filtered = [];
    function update(column) {
         // add the clicked key if not included:
        if (vis.filtered.indexOf(column) == -1) {
            vis.filtered.push(column); 
          // if all bars are un-checked, reset:
          if(vis.filtered.length == keys.length) vis.filtered = [];
        }
        // otherwise remove it:
        else {
            vis.filtered.splice(vis.filtered.indexOf(column), 1);
        }
        
        vis.redrawBars();
        
        if (vis.valueline === true) {
            if (vis.filtered.indexOf(vis.valuelinevariable) > -1) {
                 vis.redrawLine();
            }
            else if (vis.lineRemove === true) {
                vis.redrawLine();
            }
            
        }
        if (vis.scatter === true) {
            vis.redrawScatter();
        }
        
         // update legend:
    legend.selectAll("rect")
    //  .transition(vis.t())
      .attr("fill",function(d) {
        if (vis.filtered.length) {
          if (vis.filtered.indexOf(d) == -1) {
           // return vis.colorScale(d); 
                     if (d === vis.yVariable) {
                          return vis.color;
                      }
                      
                      else if (d === vis.scattervariable) {
                              return vis.scattercolor;
                      }
                      
                  
                      else if (d === vis.valuelinevariable) {
                              return vis.valuelinecolor;
                      }
                
          }
           else {
            return "white"; 
          }
        }
        else {
                    if (d === vis.yVariable) {
                          return vis.color;
                      }
                      
                      else if (d === vis.scattervariable) {
                              return vis.scattercolor;
                      }
                      
                  
                      else if (d === vis.valuelinevariable) {
                              return vis.valuelinecolor;
                      }
        }
      })
     // .duration(100);
      
       
    }

   
    
   
    
    

   
   

        
    }

  mousemoved(d) { 
      var vis = this;

        this.tooltip
         .style("left", d3.event.pageX + 3+ "px")
           .style("top", d3.event.pageY -50 + "px")
          .style("display", "inline-block")
          .style("z-index",30000)
        .html(function() {
            var tableBodyCells = "";
            for (var i in vis.dataFiltered) {
                if (vis.dataFiltered[i][vis.xVariable] === d[vis.xVariable]) {
                    var keys =  Object.keys(vis.dataFiltered[i]);
                    console.log(keys)
                    for (var j in keys) {
                        var key = keys[j];
                        var name = '';
                        var value = '';
                        var color = '';
                        
                        if (key === vis.yVariable) {
                             name = key; 
                             value = vis.dataFiltered[i][key].toFixed(2);
                             color = vis.color;
                        }
                        else if (key === vis.scattervariable) {
                             name = key; 
                             value = vis.dataFiltered[i][key].toFixed(2);
                             color = vis.scattercolor;
                             color = color +";border-radius:50%;"
                        }
                        else if (key === vis.valuelinevariable) {
                             name = key; 
                             value = vis.dataFiltered[i][key].toFixed(2);
                             color = vis.valuelinecolor;
                             color = color+ ";height:1px;"
                        }
                       
                        if ((name != "") && (value != "") && (color != "")) {
                            tableBodyCells = tableBodyCells+"<tr><td class='td1'><div style='height:20px; width:20px;background-color:"+color+"!important;'></div></td><td class='td2'>"+name+"</td><td class='td3'>"+value+"</td></tr>";
                            }                    
                    }

                }
            }
        
            return "<table><tbody><tr><th colspan='3'>"+d[vis.xVariable]+"</th></tr>"+tableBodyCells+"</tbody></table>"
        
    }) 
}

  redrawBars() {
        var data = [];
        var vis = this;
        data = this.dataFiltered.filter(function(k){ 
                            if (vis.filtered) {
                                if (vis.filtered.indexOf(vis.yVariable) === -1) {
                                  return k
                                }
                                
                            } 
                            else {
                                return k
                            }; 
                        }); 
        console.log(data);
        
        this.rects = this.g1
        .selectAll("rect").data(data)
    
    // EXIT old elements not present in new data.
    this.rects.exit()
        .attr("class", "exit")
        .transition(this.t())
        .attr("height", 0)
        .attr("y", this.height)
        .style("fill-opacity", "0.1")
        .remove();

    // UPDATE old elements present in new data.
     this.rects.attr("class", "update")
        .transition(this.t())
            .attr("y", function(d){ return vis.y(d[vis.yVariable]); })
            .attr("height", function(d){ return (vis.height - vis.y(d[vis.yVariable])); })
            .attr("x", function(d){ return vis.x(d[vis.xVariable]) })
            .attr("width", vis.x.bandwidth) 
           
    this.rects.enter()
        .append("rect")
        .attr("class", "enter")
        .attr("y",vis.height)
        .attr("height",0)
        .attr("width",vis.x.bandwidth)
        .attr("x", function(d){ return vis.x(d[vis.xVariable]) })
        .on("mousemove", function(d) { vis.mousemoved(d)}) 
        .on("mouseout", function(d){ vis.tooltip.style("display", "none");})
        .attr("fill", this.color)
        .transition(this.t())
            .attr("height", function(d){ return (vis.height - vis.y(d[vis.yVariable])); })
            .attr("y", function(d){ return vis.y(d[vis.yVariable]); })
            
  }

  redrawLine() {
        
    var data = [];
    var vis = this;
    data = vis.dataFiltered.filter(function(k){ 
                        if (vis.filtered) {
                            if (vis.filtered.indexOf(vis.valuelinevariable) === -1) {
                              return k;
                            } 
                        } 
                        else {
                            return k;
                        }; 
                    });
    vis.lineRemove = false;
    
    if (data.length === 0) {
        vis.lineRemove = true;
    }
    
     vis.g1.selectAll(".line").remove();
     vis.g1.selectAll(".lineCircle").remove();
    
      function transition(path) {
        path.transition()
            .duration(3000)
            .attrTween("stroke-dasharray", tweenDash);
        }
      function tweenDash() {
            var l = this.getTotalLength(),
                i = d3.interpolateString("0," + l, l + "," + l);
            return function (t) { return i(t); };
        }
   
    
                    
        
        this.linePath = this.g1.append("path")
            .attr("clip-path", "url(#"+this.clip+")")
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke", this.valuelinecolor)
            .attr("stroke-width", "1px")
          
     
      var line = d3.line()
            .x(function(d) { return vis.x(d[vis.xVariable])+vis.x.bandwidth()/2; })
            .y(function(d) { return vis.y(d[vis.valuelinevariable]); })
            .curve(d3.curveMonotoneX);

       // vis.g.select(".line")
     
    
       this.linePath
        .attr("d", line(data))
       .call(transition)
 
     
      // Add the scatterplot for line path
   var dots = this.g1.selectAll(".lineCircle")
        .data(data)
   
   dots.exit().remove();
   
      dots
      .enter().append("circle")
        .attr("class", "lineCircle")
        .attr("r", 2)
        .attr("fill", this.valuelinecolor)
        .attr("cx", function(d) { return vis.x(d[vis.xVariable])+vis.x.bandwidth()/2; })
        .attr("cy", function(d) { return vis.y(d[vis.valuelinevariable]) })
        .on("mousemove", function(d) { vis.mousemoved(d)}) 
        .on("mouseout", function(d){ vis.tooltip.style("display", "none");})
        .on("click", function(d) {
          if (this.clickedGauss === true) {
              console.log(d);
        /*   $scope.Filter.globalList = [];
          $scope.gridOptionsFilter = [];
          var newdata = {};
          var newdata2 = {};
          newdata.condition = undefined;
          newdata.field = $scope.gaussdata.selected;
          newdata.placeholder = "<";
          newdata.term = d.maxBucket;
          if (d.maxBucket != "Max") {
               $scope.gridOptionsFilter.push(newdata);
          }
         
          newdata2.condition = undefined;
          newdata2.field = $scope.gaussdata.selected;
          newdata2.placeholder = ">";
          newdata2.term = d.minBucket;
          
          if (d.minBucket != "Min") {
                $scope.gridOptionsFilter.push(newdata2);
          }
              
         if ($scope.workFlow.selected.WFDynSegAttribute.length>0) {
             var newdata3 = {};
             newdata3.condition = undefined;
             newdata3.field = $scope.workFlow.selected.WFDynSegAttribute;
             newdata3.placeholder ="=";
             newdata3.term = $scope.wfDynSegAttrValue.selected.Name;
             $scope.gridOptionsFilter.push(newdata3);
            
         }

         $scope.gridOptionsSort = [];
         $scope.Sort.globalList = [];
         var newdata = {};
         newdata.direction = "asc";
         newdata.field = $scope.gaussdata.selected;
         $scope.gridOptionsSort.push(newdata);
        
          tooltip.style("display", "none");
        
          $scope.loadFilterSortGlobal();
          tooltip.style("display", "none"); */
          }
          
        }); 
}
  
  redrawScatter() {
    //get all dots
   //  vis.g2.selectAll(".scatterDot").remove();
     var data = [];
     var vis = this;
   
     data = vis.dataFiltered.filter(function(k){ 
                       if (vis.filtered) {
                           if (vis.filtered.indexOf(vis.scattervariable) === -1) {
                             return k;
                           } 
                       } 
                       else {
                           return k;
                       }; 
                   }); 
  
   console.log(data);
    this.g2.selectAll(".dot").remove();
   
     var scatterplot = this.g2.selectAll(".dot")
           .data(data)
      
     scatterplot.exit().remove();
     
       console.log(this.y.range());
   
       scatterplot
         .enter().append("circle")
         .attr("class", "dot scatterDot")
         .attr("r", 0)
         .attr("cx", function(d) {return vis.x(d[vis.xVariable])+vis.x.bandwidth()/2})
         .attr("cy", function(d) {return   vis.y(d[vis.scattervariable])})
         .style("fill", this.scattercolor)
         .on("mousemove", function(d) { vis.mousemoved(d)}) 
         .on("mouseout", function(d){ vis.tooltip.style("display", "none");})
         .on("mouseout", function(d){ vis.tooltip.style("display", "none");})
         .transition(this.t())
             .attr("r", function(d) {
                   if ((d[vis.scattervariable] != null)&&(d[vis.scattervariable] != 0)) {
                       return 4.5;
                   }
                   else {
                       return 0;
                   }
               })
            
         
}

  redrawLines() {

    var vis = this;
     
    if ((!this.lines) || (this.lines.length === 0)) {
      
      if (this.lineGroup){
       this.lineGroup.exit().remove();
      }
    
  }
   d3.selectAll(".lineGroup").remove();
   d3.selectAll(".lineText").remove();
  
       for (var i in this.lines) {
               if (this.lineGroup){
                   this.lineGroup.exit().remove();
           }
           //vis.lineGroup.exit().remove();
           if (this.lines[i].value != 0) {
               this.lineGroup=  this.g1.append('line')
                   //.attr("transform", "translate(" + vis.margin.left + 
                       //  ", " + vis.margin.top + ")")
                   .attr("class", "lineGroup")
                   .style('stroke', this.lines[i].color)
                   .style('stroke-width', '2px')
                   .attr('x1', 0)
                   .attr('y1', this.y(this.lines[i].value))
                   .attr('x2', this.width)
                   .attr('y2', this.y(this.lines[i].value))
                   
                   this.lineText=  this.g1.append("g")
                                   .attr("class", "lineText")
                       this.lineText
                           .append('text')
                           .text(this.lines[i].title)
                           .style('fill', this.lines[i].color )
                           .attr('x', 3)
                           .attr('y', this.y(this.lines[i].value)-5)
           }  
           }
   }

   zoomed()  {
    var vis = this;
    // declare zoom event
    var t = d3.event.transform;
   // var xt = t.rescaleX(vis.x);
   if (this.param === false) {
        this.x.range([0, (this.xWidth-80) * t.k]);
       //rescale x axis because of zoom
       this.xAxisCall.scale(this.x);
       this.xAxis
        .attr("transform", "translate(" + t.x + "," + (this.height) + ")")
        .call(this.xAxisCall);
        
        //apply zoom to bars
        this.g1.selectAll("rect")
           .attr("transform", function(d) { return "translate(" + ( t.x) + ",0)scale(" + t.k + ",1)"; });
    }
    else {
        this.yRotate.range([0, this.height * t.k]);
       //rescale x axis because of zoom
       vis.yAxisCall.scale(this.yRotate);
       this.yAxis
        .attr("transform", "translate(" + 0+ "," + t.y + ")")
        .call(this.yAxisCall);
        
         //apply zoom to bars
         this.g1.selectAll("rect")
           .attr("transform", function(d) { return "translate(" + 0 + ","+t.y+")scale(" + 1 + ","+t.k+")"; });
    }

    
    //zoom if there is a line
    if (this.valueline === true) {
      
        this.linePath
              .attr("transform", function(d) { return "translate(" + ( t.x) + ",0)scale(" + t.k + ",1)"; })
             // .attr('stroke-width', 1/(t.k)+"px")

             this.g1.selectAll("circle")
             .attr("transform", function(d) { return "translate(" + ( t.x) + ",0)scale(" + t.k + ",1)"; })
         
   
    }
    
    //zoom if there is a scatterplot
    if (this.scatter === true) {
        this.g2.selectAll("circle")
                .attr("transform", function(d) { return "translate(" + ( t.x) + ",0)scale(" + t.k + ",1)"; })
                .attr("r",function(d) {
                 if ((d[vis.scattervariable] != null)&&(d[vis.scattervariable] != 0)) {
                            return 4.5 /(t.k);
                        }
                        else {
                            return 0;
                        }
                })
    }
    
   


}

    

    


    redrawChart(width, height, margin) {
        var vis = this;
        vis.margin = margin;
        vis.height = height - vis.margin.top - vis.margin.bottom;

        vis.width = width - vis.margin.left - vis.margin.right;
    
        vis.svg 
            .attr("width", vis.width + vis.margin.left + vis.margin.right)
            .attr("height", vis.height + vis.margin.top + vis.margin.bottom);
        
        vis.g2
            .attr("transform", "translate(" + vis.margin.left + 
                ", " + vis.margin.top + ")");
        
        vis.g1
          .attr("transform", "translate(" + vis.margin.left + 
                ", " + vis.margin.top + ")");
        
        vis.xWidth = vis.width-vis.margin.right
        
        vis.x
            .range([0,vis.xWidth ])
        
        vis.xAxis
            .attr("transform", "translate(0," + vis.height +")")
           
    
        vis.y.range([this.height, 0]);
        
        vis.defs.selectAll("#"+vis.clipx).selectAll("rect")
          .attr('x', 0)
          .attr('y', vis.height)
          .attr('width', vis.xWidth)
          .attr('height', vis.margin.bottom);
        
          vis.defs.selectAll("#"+vis.clip).selectAll("rect")
          //.attr('id', 'clip')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', vis.xWidth)
          .attr('height', vis.height);
        
          vis.defs.selectAll("#"+vis.clipy).selectAll("rect")
          //.attr('id', 'clip')
          .attr('x', -vis.margin.left+2)
          .attr('y', 0)
          .attr('width', vis.margin.left)
          .attr('height', vis.height);
    
        
          vis.updateVis();
    }


    rotate(param) {
        if (param === true) {
            this.yRotate
              .domain(this.domain)
            
              this.param = true;
    
              this.xRotate.domain([Math.min(0,d3.min(this.dataFiltered, function(d) { return d[this.yVariable] })), 
                d3.max(this.dataFiltered, function(d) { return d[this.yVariable]})+0.3]);
    
            // Update axes
            this.xAxisCall.scale(this.xRotate);
            this.xAxis.transition(this.t()).call(this.xAxisCall);
            this.yAxisCall.scale(this.yRotate);
            this.yAxis.transition(this.t()).call(this.yAxisCall);
            
            this.yAxis
                .selectAll("text")	
                .style("text-anchor", "end")
                .attr("dx", "-.1em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(+35)");
     
            this.rects = this.g1
                .selectAll("rect").data(this.data)
        // EXIT old elements not present in new data.
        this.rects.exit()
            .attr("class", "exit")
            .transition(this.t())
            .attr("height", 0)
            .attr("y", this.height)
            .style("fill-opacity", "0.1")
            .remove();
    
        // UPDATE old elements present in new data.
        this.rects.attr("class", "update")
            .transition(this.t())
                .attr("y", function(d){ return this.yRotate(d[this.xVariable]); })
                .attr("height", this.yRotate.bandwidth)
                .attr("x", 0)
                .attr("width", function(d){ return ( this.xRotate(d[this.yVariable])); })
            
        this.rects.enter()
            .append("rect")
            .attr("class", "enter")
            .attr("y", function(d) { return (this.yRotate(d[this.xVariable]))})
            .attr("width",0)
            .attr("height",this.yRotate.bandwidth)
            .attr("x", 0 )
            .on("mousemove", function(d) { this.mousemoved(d)}) 
            .on("mouseout", function(d){ this.tooltip.style("display", "none");})
            .transition(this.t())
                .attr("width", function(d){ return ( this.xRotate(d[this.yVariable])); })
                .attr("x", function(d){ return 0; })
                .attr("fill", this.color)
        }
        else {
            this.param = false;
            this.updateVis();
        }
    }
}


