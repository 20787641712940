import { Subject } from "rxjs";
import * as i0 from "@angular/core";
var CardabelVirtualFilterService = /** @class */ (function () {
    function CardabelVirtualFilterService() {
        this.filterTextChange = new Subject();
        this.numericMinChange = new Subject();
        this.numericMaxChange = new Subject();
        this.dateToChange = new Subject();
        this.dateFromChange = new Subject();
        this.selectAllRowsChange = new Subject();
        this.numericFilterAndOrChange = new Subject();
        this.inNotConditionChange = new Subject();
    }
    CardabelVirtualFilterService.prototype.returnFilters = function (filterSliceStream, filters) {
        for (var i in filters) {
            var value = filters[i].value;
            var field = filters[i].field;
            var type = filters[i].filterType;
            var value2 = filters[i].value2;
            var condition = filters[i].filterCondition;
            if ((type === "greater") || (type === "less")) {
                if (typeof value === "string") {
                    if (value.indexOf("K") > -1) {
                        value = value.substr(0, value.indexOf('K'));
                        value = +value * 1000;
                    }
                    else if (value.indexOf("k") > -1) {
                        value = value.substr(0, value.indexOf('k'));
                        value = +value * 1000;
                    }
                    else if (value.indexOf("M") > -1) {
                        value = value.substr(0, value.indexOf('M'));
                        value = +value * 1000000;
                    }
                    else if (value.indexOf("m") > -1) {
                        value = value.substr(0, value.indexOf('m'));
                        value = +value * 1000000;
                    }
                    else if (value.toString() != "") {
                        value = +value;
                    }
                }
                if (typeof value2 === "string") {
                    if (value2.indexOf("K") > -1) {
                        value2 = value2.substr(0, value2.indexOf('K'));
                        value2 = +value2 * 1000;
                    }
                    else if (value2.indexOf("k") > -1) {
                        value2 = value2.substr(0, value2.indexOf('k'));
                        value2 = +value2 * 1000;
                    }
                    else if (value2.indexOf("M") > -1) {
                        value2 = value2.substr(0, value2.indexOf('M'));
                        value2 = +value2 * 1000000;
                    }
                    else if (value2.indexOf("m") > -1) {
                        value2 = value2.substr(0, value2.indexOf('m'));
                        value2 = +value2 * 1000000;
                    }
                    else if (value2.toString() != "") {
                        value2 = +value2;
                    }
                }
            }
            if ((type === "dateFrom") || (type === "dateTo")) {
                value = new Date(value);
                if ((value2 != undefined) && (value2 != null) && (value2 != "")) {
                    value2 = new Date(value2);
                }
            }
            filterSliceStream = filterSliceStream.filter(function (item) {
                //if (type === 'isEmpty') {
                if (type === 'empty') {
                    if ((item[field] != undefined) && (item[field] != null) && (item[field] != '')) {
                        return false;
                    }
                }
                else if (type === 'notempty') { //if (type === 'isNotEmpty') {
                    if ((item[field] === undefined) || (item[field] === null) || (item[field] === '')) {
                        return false;
                    }
                }
                else if (type === '+') {
                    if ((item[field] === undefined) || (item[field] === null)) {
                        return false;
                    }
                    else if ((value != "") && (value != undefined)) {
                        if (item[field].toString().toLowerCase().indexOf(value.toString().toLowerCase()) === -1) {
                            return false;
                        }
                    }
                }
                else if (type === '-') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1) {
                            return false;
                        }
                    }
                }
                else if (type === '=') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase() != value.toString().toLowerCase()) {
                            return false;
                        }
                    }
                }
                else if (type === '!') {
                    if ((value != "") && (value != undefined) && (item[field] != undefined) && (item[field] != null)) {
                        if (item[field].toString().toLowerCase() === value.toString().toLowerCase()) {
                            return false;
                        }
                    }
                }
                else if (type === 'in') {
                    var returnValue1 = true;
                    var returnValue2 = false;
                    if (condition === 'NOT') {
                        returnValue1 = false;
                        returnValue2 = true;
                    }
                    for (var i in value) {
                        if (item[field] === null) {
                            if (value[i] === "null") {
                                value[i] = null;
                            }
                            if (item[field] === value[i]) {
                                return returnValue1;
                            }
                        }
                        else if (typeof item[field] === 'string') {
                            if ((value[i] != 'null') && (value[i] != null)) {
                                if (item[field].toString().toLowerCase() === (value[i].toLowerCase())) {
                                    return returnValue1;
                                }
                            }
                        }
                        else if (typeof item[field] === 'number') {
                            if ((value[i] != 'null') && (value[i] != null)) {
                                value[i] = +value[i];
                            }
                            if (item[field] === value[i]) {
                                return returnValue1;
                            }
                        }
                    }
                    return returnValue2;
                }
                else if (((type === 'greater') || (type === 'less')) && (item[field] != undefined) && (item[field] != null)) {
                    if ((value2 != undefined) && (value2 != null) && (value2.toString() != "")) {
                        if (condition === true) {
                            if (type === "greater") {
                                if (item[field] >= value) {
                                    if (item[field] <= value2) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                                else {
                                    return false;
                                }
                            }
                            else {
                                if (item[field] <= value) {
                                    if (item[field] >= value2) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }
                                else {
                                    return false;
                                }
                            }
                        }
                        else {
                            if (type === 'greater') {
                                if (item[field] >= value) {
                                    return true;
                                }
                                else if (item[field] <= value2) {
                                    return true;
                                }
                                return false;
                            }
                            else if (type === 'less') {
                                if (item[field] <= value) {
                                    return true;
                                }
                                else if (item[field] >= value2) {
                                    return true;
                                }
                                return false;
                            }
                        }
                    }
                    else if (type === "greater") {
                        if (value.toString() != "") {
                            if (item[field] < value) {
                                return false;
                            }
                        }
                    }
                    else if (type === 'less') {
                        if (value.toString() != "") {
                            if (item[field] > value) {
                                return false;
                            }
                        }
                    }
                }
                else if (((type === 'dateFrom') || (type === 'dateTo')) && (item[field] != undefined) && (item[field] != null)) {
                    var newDate = new Date(item[field]);
                    if ((value2) && (value2 != "") && (value2 != null)) {
                        if (type === 'dateFrom') {
                            if ((newDate >= value) && (newDate <= value2)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                        else if (type === 'dateTo') {
                            if ((newDate <= value) && (newDate >= value2)) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else if (type === 'dateFrom') {
                        if (newDate <= value) {
                            return false;
                        }
                    }
                    else if (type === 'dateTo') {
                        if (newDate >= value) {
                            return false;
                        }
                    }
                }
                return true;
            });
        }
        ;
        return filterSliceStream;
    };
    CardabelVirtualFilterService.prototype.formatFilters = function (filters, filterField, filterValue, filterType, filterValue2, filterCondition, inputType) {
        var fieldExists = false;
        var i = filters.findIndex(function (item) { return item.field === filterField; });
        if (i != -1) {
            filters[i].value = filterValue;
            filters[i].value2 = filterValue2;
            filters[i].inputType = inputType;
            filters[i].filterType = filterType;
            filters[i].filterCondition = filterCondition;
            if ((filterType === 'greater') || (filterType === 'less')) {
                if (filters[i].value === "") {
                    if ((filterValue2 != null) && (filterValue2) && (filterValue2 != "")) {
                        filters[i].value = filterValue2;
                        filters[i].value2 = "";
                        if (filterType === "greater") {
                            filters[i].filterType = "less";
                        }
                        else {
                            filters[i].filterType = "greater";
                        }
                    }
                }
            }
            if ((filterType === 'dateFrom') || (filterType === 'dateTo')) {
                if (filters[i].value === "") {
                    if ((filterValue2 != null) && (filterValue2) && (filterValue2 != "")) {
                        filters[i].value = filterValue2;
                        filters[i].value2 = "";
                        if (filterType === "dateFrom") {
                            filters[i].filterType = "dateTo";
                        }
                        else {
                            filters[i].filterType = "dateFrom";
                        }
                    }
                }
            }
        }
        else {
            filters.push({ field: filterField, value: filterValue, filterType: filterType, value2: filterValue2, filterCondition: filterCondition, inputType: inputType });
        }
        for (var k = filters.length - 1; k > -1; k--) {
            if ((filters[k].filterType != 'empty') && (filters[k].filterType != 'notempty') && (((filters[k].value === "") && (filters[k].value2 === "")) || filters[k].value === null || filters[k].value2 === null)) {
                filters.splice(k, 1);
            }
        }
        return filters;
    };
    CardabelVirtualFilterService.prototype.changeFilterText = function (filterText, numericMin, numericMax, dateTo, dateFrom, numericFilterAndOr, selectAllRows, inNotCondition) {
        this.filterTextChange.next(filterText);
        this.numericMinChange.next(numericMin);
        this.numericMaxChange.next(numericMax);
        this.dateToChange.next(dateTo);
        this.dateFromChange.next(dateFrom);
        this.numericFilterAndOrChange.next(numericFilterAndOr);
        this.selectAllRowsChange.next(selectAllRows);
        this.inNotConditionChange.next(inNotCondition);
    };
    CardabelVirtualFilterService.prototype.changeGlobalFilter = function (activeGlobalIcon, globalFiltersArray, globalFilter, globalFilterText, filters, filterText) {
        if (!activeGlobalIcon) {
            for (var i in globalFiltersArray) {
                globalFilter[globalFiltersArray[i].name] = false;
                globalFilterText[globalFiltersArray[i].name] = '';
            }
            globalFiltersArray = [];
        }
        else {
            for (var i in filters) {
                globalFilter[filters[i].field] = true;
                globalFilterText[filters[i].field] = filters[i].filterType + " " + filters[i].value;
                if (filters[i].filterType === 'in') {
                    if (filters[i].filterCondition === "NOT") {
                        globalFilterText[filters[i].field] = "not " + filters[i].filterType + " " + filters[i].value;
                    }
                }
                filterText[filters[i].field] = '';
                var fieldExists = false;
                for (var j in globalFiltersArray) {
                    if (globalFiltersArray[j].field === filters[i].field) {
                        if ((globalFiltersArray[j].inputType === "numeric") || globalFiltersArray[j].inputType === "date") {
                            if (globalFiltersArray[j].filterType === filters[i].filterType) {
                                globalFiltersArray[j] = filters[i];
                                fieldExists = true;
                                break;
                            }
                        }
                        else {
                            globalFiltersArray[j] = filters[i];
                            fieldExists = true;
                            break;
                        }
                    }
                }
                if (fieldExists === false) {
                    globalFiltersArray.push(filters[i]);
                }
            }
        }
        return [globalFiltersArray, globalFilter, globalFilterText, filterText];
    };
    CardabelVirtualFilterService.prototype.resetFilterText = function (column, filterText, dateTo, dateFrom, numericMin, numericMax, numericFilterAndOr, inNotCondition, selectAllRows) {
        for (var i in column) {
            if (column[i].type === "string") {
                filterText[column[i].name] = "";
            }
            else if (column[i].type === "date") {
                dateTo[column[i].name] = "";
                dateFrom[column[i].name] = "";
            }
            else {
                numericMin[column[i].name] = "";
                numericMax[column[i].name] = "";
                numericFilterAndOr[column[i].name] = true;
            }
            if ((column[i].name != "C") && (column[i].name != "R") && (column[i].name != "guiIndex")) {
                selectAllRows[column[i].name] = false;
                //this.selectAll(this.column[i].name)
            }
            inNotCondition[column[i].name] = "IN";
        }
        return [filterText, dateTo, dateFrom, numericMin, numericMax, numericFilterAndOr, inNotCondition, selectAllRows];
    };
    CardabelVirtualFilterService.prototype.setGlobalFilterCSS = function (globalFiltersArray, globalFilters, global, activeGlobalIcon, globalFilter, globalFilterText, numericFilterAndOr, globalSorts, globalSort, globalSortText) {
        globalFiltersArray = JSON.parse(JSON.stringify(globalFilters));
        if (global === true) {
            activeGlobalIcon = true;
            globalFilter = {};
            globalFilterText = {};
            for (var i in globalFilters) {
                globalFilter[globalFilters[i].field] = true;
                numericFilterAndOr[globalFilters[i].field] = globalFilters[i].filterCondition;
                var filterType = globalFilters[i].filterType;
                if (filterType === "dateTo") {
                    filterType = "to";
                }
                else if (filterType === "dateFrom") {
                    filterType = "from";
                }
                globalFilterText[globalFilters[i].field] = filterType + " " + globalFilters[i].value;
                if (globalFilters[i].value2 != '') {
                    var filterType2 = "";
                    if (globalFilters[i].filterType === "dateTo") {
                        filterType2 = "from";
                    }
                    else if (globalFilters[i].filterType === "dateFrom") {
                        filterType2 = "to";
                    }
                    else if (globalFilters[i].filterType === "less") {
                        filterType2 = "greater";
                    }
                    else if (globalFilters[i].filterType === "greater") {
                        filterType2 = "less";
                    }
                    globalFilterText[globalFilters[i].field] = globalFilterText[globalFilters[i].field] + " " + filterType2 + " " + globalFilters[i].value2;
                }
                if (globalFilters[i].filterType === 'in') {
                    if (globalFilters[i].filterCondition === 'NOT') {
                        globalFilterText[globalFilters[i].field] = "not " + globalFilters[i].filterType + " " + globalFilters[i].value;
                    }
                }
            }
            globalSort = {};
            globalSortText = {};
            for (var k in globalSorts) {
                globalSort[globalSorts[k].field] = true;
                globalSortText[globalSorts[k].field] = globalSorts[k].direction;
                //this.column = this.service.setSortOnColumnArray(this.sortColumns[i].field, this.column, null);
            }
        }
        else {
            activeGlobalIcon = false;
            globalFilter = {};
            globalFilterText = {};
            globalSort = {};
            globalSortText = {};
        }
        return [activeGlobalIcon, globalFiltersArray, globalFilter, globalFilterText, numericFilterAndOr, globalSort, globalSortText];
    };
    CardabelVirtualFilterService.prototype.removeFilter = function (header, type, from, filterType, groupedColumn, alreadyGrouped, filterText, filtered, numberSelectedCheck, globalFilterText, numericMin, numericMax, dateTo, dateFrom, selectAllRows, globalFiltersArray, globalFilter) {
        groupedColumn = false;
        alreadyGrouped = false;
        if (type === "string") {
            filterText[header] = '';
            filtered[header] = [];
            numberSelectedCheck[header] = 'All';
            globalFilterText[header] = "";
        }
        else if (type === "numeric") {
            if (from === "column") {
                numericMin[header] = "";
                numericMax[header] = "";
            }
            else {
                if (filterType === "greater") {
                    numericMin[header] = "";
                }
                else {
                    numericMax[header] = "";
                }
            }
        }
        else if (type === "date") {
            dateTo[header] = "";
            dateFrom[header] = "";
            if (filterType === "dateTo") {
                dateTo[header] = "";
            }
            else {
                dateFrom[header] = "";
            }
        }
        selectAllRows[header] = false;
        //this.selectAll(header)
        for (var i = globalFiltersArray.length - 1; i > -1; i--) {
            if (globalFiltersArray[i].field === header) {
                if (from === "column") {
                    globalFilter[globalFiltersArray[i].field] = false;
                    globalFilterText[globalFiltersArray[i].field] = "";
                    globalFiltersArray.splice(+i, 1);
                }
                else {
                    if (globalFiltersArray[i].filterType === filterType) {
                        globalFilter[globalFiltersArray[i].field] = false;
                        globalFilterText[globalFiltersArray[i].field] = "";
                        globalFiltersArray.splice(+i, 1);
                    }
                }
            }
        }
        return [groupedColumn, alreadyGrouped, filterText, filtered, numberSelectedCheck, globalFilterText, numericMin, numericMax, dateTo, dateFrom, selectAllRows, globalFiltersArray, globalFilter];
    };
    CardabelVirtualFilterService.prototype.returnMatchingFilters = function (filterSliceStream, matchingArray, groupField, matchingRecGroup, matchingSignedMeasure, selectedMatchingView, initRowGroupMetaData, filterOnMatchingRec, filterOnMatchingSize, filterOnSignedMeasure, filterMatchingRecANDOR, filterMatchingRecMin, filterMatchingRecMax, filterMatchingSizeANDOR, filterMatchingSizeMin, filterMatchingSizeMax, filterSignedMeasureANDOR, filterSignedMeasureMin, filterSignedMeasureMax) {
        var useFilter = function (arr) {
            return arr.filter(function (value, index, self) {
                return self.indexOf(value) === index;
            });
        };
        var _loop_1 = function () {
            var param = matchingArray[u].name;
            var operator = matchingArray[u].operator;
            var filterOn = "filterOn" + param;
            var filterMin = "filter" + param + "Min";
            var filterMax = "filter" + param + "Max";
            var filterANDOR = "filter" + param + "ANDOR";
            if (eval(filterOn)) {
                //  let groupField = groupField;
                var matchingFilters = [];
                if ((param === 'SignedMeasure') || (param === 'MatchingRec')) {
                    var keys = filterSliceStream.map(function (el) {
                        return el[groupField];
                    });
                    keys = useFilter(keys);
                    var _loop_2 = function () {
                        var key = +keys[j];
                        var filteredData = filterSliceStream.filter(function (item) { return item[groupField] === key; });
                        var newdata = void 0;
                        if (param === 'MatchingRec') {
                            var matchingField_1 = matchingRecGroup;
                            if (matchingRecGroup === selectedMatchingView) {
                                matchingField_1 = "cardabel_rec";
                            }
                            var mappedData = filteredData.map(function (item) { return item[matchingField_1]; });
                            console.log(filteredData);
                            console.log(mappedData);
                            // Using Array.filter
                            var result = useFilter(mappedData);
                            console.log(result);
                            newdata = {
                                key: key,
                                diffItems: result.length
                            };
                        }
                        else {
                            newdata = {
                                key: key,
                                sum: filteredData.reduce(function (accumulator, current) { return accumulator + current[matchingSignedMeasure]; }, 0)
                            };
                        }
                        matchingFilters.push(newdata);
                    };
                    for (var j in keys) {
                        _loop_2();
                    }
                }
                if (param === "MatchingSize") {
                    var keys = Object.keys(initRowGroupMetaData);
                    for (var m in keys) {
                        var key = +keys[m];
                        var newdata = {
                            key: key,
                            size: initRowGroupMetaData[key].size
                        };
                        matchingFilters.push(newdata);
                    }
                }
                var min_1;
                if ((eval(filterMin)) && (eval(filterMin) != "")) {
                    min_1 = +eval(filterMin);
                }
                else {
                    min_1 = null;
                }
                var max_1;
                if ((eval(filterMax)) && (eval(filterMax) != "")) {
                    max_1 = +eval(filterMax);
                }
                else {
                    max_1 = null;
                }
                var condition_1 = eval(filterANDOR);
                matchingFilters = matchingFilters.filter(function (item) {
                    if (min_1 != null && max_1 === null) {
                        if (Math.abs(item[operator]) >= min_1) {
                            return true;
                        }
                        else
                            return false;
                    }
                    else if (min_1 === null && max_1 != null) {
                        if (Math.abs(item[operator]) <= max_1) {
                            return true;
                        }
                        else
                            return false;
                    }
                    else if (min_1 === null && max_1 === null) {
                        return true;
                    }
                    else if (condition_1 === true) {
                        if ((Math.abs(item[operator]) >= min_1) && (Math.abs(item[operator]) <= max_1)) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                    else {
                        if ((Math.abs(item[operator]) <= min_1) || (Math.abs(item[operator]) >= max_1)) {
                            return true;
                        }
                        else {
                            return false;
                        }
                    }
                });
                var filteredKeys_1 = matchingFilters.map(function (el) { return el.key; });
                filterSliceStream = filterSliceStream.filter(function (item) {
                    if (filteredKeys_1.indexOf(item[groupField]) > -1) {
                        return true;
                    }
                    else {
                        return false;
                    }
                });
            }
        };
        for (var u = 0; u < matchingArray.length; u++) {
            _loop_1();
        }
        return filterSliceStream;
    };
    CardabelVirtualFilterService.prototype.formatBookmarkFilters = function (filterField, filterValue, filterType, filterValue2, filterCondition, inputType, filter, selectedRows, inNotCondition, filterText, clickFilter, numericFilterAndOr, numericMin, numericMax, dateTo, dateFrom, numberSelectedCheck) {
        if (filterType === "in") {
            filter[filterField] = inputType + "Keyboard";
            for (var k in filterValue) {
                selectedRows[filterField][filterValue[k]] = true;
            }
            inNotCondition[filterField] = filterCondition;
            numberSelectedCheck[filterField] = filterValue.length;
            // this.closeMenu(filterField, inputType);
        }
        else if (inputType === "string") {
            if ((filterType != 'empty') && (filterType != 'notempty')) {
                filterText[filterField] = filterValue;
            }
            clickFilter[filterField] = filterType;
        }
        else if (inputType === "numeric") {
            numericFilterAndOr[filterField] = filterCondition;
            if (filterType === "greater") {
                numericMin[filterField] = filterValue;
                numericMax[filterField] = filterValue2;
            }
            else if (filterType === "less") {
                numericMin[filterField] = filterValue2;
                numericMax[filterField] = filterValue;
            }
        }
        else if (inputType === "date") {
            if (filterType === "dateTo") {
                dateTo[filterField] = new Date(filterValue);
                dateFrom[filterField] = new Date(filterValue2);
            }
            else {
                dateTo[filterField] = new Date(filterValue2);
                dateFrom[filterField] = new Date(filterValue);
            }
        }
        return [filter, selectedRows, inNotCondition, filterText, clickFilter, numericFilterAndOr, numericMin, numericMax, dateTo, dateFrom, numberSelectedCheck];
    };
    CardabelVirtualFilterService.ngInjectableDef = i0.defineInjectable({ factory: function CardabelVirtualFilterService_Factory() { return new CardabelVirtualFilterService(); }, token: CardabelVirtualFilterService, providedIn: "root" });
    return CardabelVirtualFilterService;
}());
export { CardabelVirtualFilterService };
