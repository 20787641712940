import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';


@Injectable()
export class SourceService{
 
    constructor(private appService: AppService, 
                private sharedInitializationService: SharedInitializationService,
                private httpClient: HttpClient){}
    
    url = this.appService.getUrl();

    statAllSourceAttributes: any[];
    //called in new workflow component
    statAllSourceAttributesChange = new Subject<any[]>();
    statAllSourceAttributesData: any[];
    //called in new workflow component
    statAllSourceAttributesDataChange = new Subject<any[]>();
    statAllSourceAttributesTableData: any[];
     //called in new workflow component
    statAllSourceAttributesTableDataChange = new Subject<any[]>();

    //Returns data for histograms of all the attribute values.
    //called in new workflow component
    getStatSourceAllAttributesHistos(source, sourceFromWFName) {
        const url = this.url;
        const webservice = "StatSourceAllAttributesHistos";
        const completeUrl = url+webservice;

        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
                      .set("srcName", source)
                      .set("sourceFromWFName", sourceFromWFName)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
         .pipe(
           catchError(this.appService.handleError)
         )
         .subscribe((response:any) => {
           console.log(response);
           //response = response.replace(/[()]/g, '');
           response= response.substring(1,response.length-1);
           response = JSON.parse(response);
           if (response.statusCode > -1 ) {
            this.statAllSourceAttributes = response.tableRows;
            this.statAllSourceAttributesChange.next(this.statAllSourceAttributes);
            this.statAllSourceAttributesData = response.tableRows2;
            this.statAllSourceAttributesDataChange.next(this.statAllSourceAttributesData);
            this.statAllSourceAttributesTableData = response.tableRows3;
            this.statAllSourceAttributesTableDataChange.next(this.statAllSourceAttributesTableData);
        }
          else {
             this.appService.showMessage('Error', response.statusText);
          }
          this.appService.stopSpin();
         }, (error) => {
          this.appService.showMessage('Error', error.statusText);
          this.appService.stopSpin();
        }); 
    }

}