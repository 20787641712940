<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
      <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center">
    <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-3">
      <h3 class="sectionTitle">Histogram Cells Threshold</h3>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-10 offset-md-1 col-xs-12 col-sm-12">
    <div class="container">
      <form [formGroup]="histogramCellThresholdForm" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div class="btn-group applyButtonGroup">
            <button class=" btn btn-primary btn-sm" type="submit" [disabled]="!histogramCellThresholdForm.valid">Save changes</button>
          </div>
        
        </div>
      </div>
      <div class="row margin-top-20">
        <div class="col-md-4 offset-md-4 col-xs-12 col-sm-12">
          <div class="row">
            <div class="col-md-6 col-xs-12 col-sm-12">
              <input
              autocomplete="off"       
              type="number"
              id="histogramCellThreshold"
              formControlName="histogramCellThreshold"
              class="form-control">
            </div>
            <label class="col-md-6 col-xs-12 col-sm-12" for="histogramCellThreshold"> (in thousands)</label>
          </div>
        </div>
      </div>
      </form>

    </div>
  </div>
</div>
