import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamicModule } from 'ng-dynamic-component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatFormFieldModule, MatFormFieldControl } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CardabelVirtualDatatableComponent } from './cardabel-virtual-datatable.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { MatMenu, MatMenuModule, MatCheckboxModule, MatInputModule, MatSelectModule, } from '@angular/material';
import { DialogModule } from 'primeng/dialog';
import { CardabelListBoxModule } from '../cardabel-listbox/cardabel-listbox.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { CheckNumberPipe } from '../checkNumber.pipe';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CardabelVirtualStandardRowComponent } from './cardabel-virtual-standard-row/cardabel-virtual-standard-row.component';
import { CardabelVirtualDefaultCellComponent } from './cardabel-virtual-default-cell/cardabel-virtual-default-cell.component';
import { CardabelVirtualNumericCellComponent } from './cardabel-virtual-numeric-cell/cardabel-virtual-numeric-cell.component';
import { CardabelVirtualRowexpandedButtonComponent } from './cardabel-virtual-rowexpanded-button/cardabel-virtual-rowexpanded-button.component';
import { CardabelVirtualRowexpandedRowComponent } from './cardabel-virtual-rowexpanded-row/cardabel-virtual-rowexpanded-row.component';
import { CardabelVirtualGroupedRowComponent } from './cardabel-virtual-grouped-row/cardabel-virtual-grouped-row.component';
import { CardabelVirtualScrollcolumnsDialogComponent } from './cardabel-dialogs/cardabel-virtual-scrollcolumns-dialog/cardabel-virtual-scrollcolumns-dialog.component';
import { CardabelVirtualSelectsignedmeasureDialogComponent } from './cardabel-dialogs/cardabel-virtual-selectsignedmeasure-dialog/cardabel-virtual-selectsignedmeasure-dialog.component';
import { CardabelVirtualGroupfiltermatchingDialogComponent } from './cardabel-dialogs/cardabel-virtual-groupfiltermatching-dialog/cardabel-virtual-groupfiltermatching-dialog.component';
import { CardabelVirtualMovecolumnsDialogComponent } from './cardabel-dialogs/cardabel-virtual-movecolumns-dialog/cardabel-virtual-movecolumns-dialog.component';
import { CardabelVirtualButtonRowComponent } from './cardabel-virtual-button-row/cardabel-virtual-button-row.component';
import { CardabelVirtualHeaderRowComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row.component';
import { CardabelVirtualButtonRowFiltersListComponent } from './cardabel-virtual-button-row/cardabel-virtual-button-row-filters-list/cardabel-virtual-button-row-filters-list.component';
import { CardabelVirtualButtonRowMainglobalfiltersListComponent } from './cardabel-virtual-button-row/cardabel-virtual-button-row-mainglobalfilters-list/cardabel-virtual-button-row-mainglobalfilters-list.component';
import { CardabelVirtualButtonRowExportsListComponent } from './cardabel-virtual-button-row/cardabel-virtual-button-row-exports-list/cardabel-virtual-button-row-exports-list.component';
import { CardabelVirtualHeaderRowColumnMenuComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-column-menu/cardabel-virtual-header-row-column-menu.component';

import { CardabelVirtualHeaderRowFilterRowComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-filter-row/cardabel-virtual-header-row-filter-row.component';
import { CardabelVirtualRowFilterRowStringComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-filter-row/cardabel-virtual-row-filter-row-string/cardabel-virtual-row-filter-row-string.component';
import { CardabelVirtualRowFilterRowNumericComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-filter-row/cardabel-virtual-row-filter-row-numeric/cardabel-virtual-row-filter-row-numeric.component';
import { CardabelVirtualRowFilterRowDateComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-filter-row/cardabel-virtual-row-filter-row-date/cardabel-virtual-row-filter-row-date.component';
import { CardabelVirtualRowFilterSelectionFiltersComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-filter-row/cardabel-virtual-row-filter-selection-filters/selection-filters.component';
import { CardabelVirtualHeaderRowContentComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-content/cardabel-virtual-header-row-content.component';
import { CardabelVirtualStandardRowContentComponent } from './cardabel-virtual-standard-row/cardabel-virtual-standard-row-content/cardabel-virtual-standard-row-content.component';
import { CardabelVirtualGroupedRowContentComponent } from './cardabel-virtual-grouped-row/cardabel-virtual-grouped-row-content/cardabel-virtual-grouped-row-content.component';
import { CardabelVirtualMatchingtabRowComponent } from './cardabel-virtual-matchingtab-row/cardabel-virtual-matchingtab-row.component';
import { CardabelVirtualHeaderRowHeaderColumnMatchingMenuComponent } from './cardabel-virtual-header-row/cardabel-virtual-header-row-column-menu/cardabel-virtual-header-row-header-column-matching-menu/matching-menu.component';

@NgModule({
  declarations: [CardabelVirtualDatatableComponent, CheckNumberPipe, CardabelVirtualStandardRowComponent, CardabelVirtualDefaultCellComponent, CardabelVirtualNumericCellComponent, CardabelVirtualRowexpandedButtonComponent, CardabelVirtualRowexpandedRowComponent, CardabelVirtualGroupedRowComponent, CardabelVirtualMovecolumnsDialogComponent, CardabelVirtualScrollcolumnsDialogComponent, CardabelVirtualSelectsignedmeasureDialogComponent, CardabelVirtualGroupfiltermatchingDialogComponent, CardabelVirtualButtonRowComponent, CardabelVirtualHeaderRowComponent, CardabelVirtualButtonRowFiltersListComponent, CardabelVirtualButtonRowMainglobalfiltersListComponent, CardabelVirtualButtonRowExportsListComponent, CardabelVirtualHeaderRowColumnMenuComponent, CardabelVirtualHeaderRowHeaderColumnMatchingMenuComponent, CardabelVirtualHeaderRowFilterRowComponent, CardabelVirtualRowFilterRowStringComponent, CardabelVirtualRowFilterRowNumericComponent, CardabelVirtualRowFilterRowDateComponent, CardabelVirtualRowFilterSelectionFiltersComponent, CardabelVirtualHeaderRowContentComponent, CardabelVirtualStandardRowContentComponent, CardabelVirtualGroupedRowContentComponent, CardabelVirtualMatchingtabRowComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    ScrollingModule,
    DragDropModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    LayoutModule,
    DynamicModule.withComponents([]),
    MultiSelectModule,
    FormsModule,
    ToggleButtonModule,
    OwlDateTimeModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    DialogModule,
    MultiSelectModule,
    MatSelectModule,
    CardabelListBoxModule,
    NgbModule,
    ClipboardModule,
    VirtualScrollerModule

  ],
  entryComponents: [],
  exports: [CardabelVirtualDatatableComponent, NgbModule, DragDropModule, MultiSelectModule],
})
export class CardabelVirtualTableModule { }