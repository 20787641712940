import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { DashboardTooltipContextMenuService } from './shared/dashboard-tooltip-contextmenu.service';


@Injectable()
export class DashboardBubbleChartService {

    constructor(private httpClient: HttpClient,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService,
        private dashboardTooltipContextMenuService: DashboardTooltipContextMenuService,
    ) { }

    //bubble axis
    getBubbleXandY(vis) {
        console.log("bubble");
        // get bubble distinct values in order to get correct dividor
        let keys = ['xHeader', 'yHeader'];
        let newdata = vis.dataFiltered.map(item => { return keys.map(element => item[element]) })


        vis.bubbleDistinctValues = [];

        for (var m in newdata) {
            //let findIndex = distinctValues.findIndex(item => newdata[m]["xHeader"]===item["xHeader"] && newdata[m]["yHeader"]===item["yHeader"] );
            let findIndex = vis.bubbleDistinctValues.findIndex(item => item["xHeader"] === newdata[m][0] && item["yHeader"] === newdata[m][1]);
            if (findIndex === -1) {
                vis.bubbleDistinctValues.push({ xHeader: newdata[m][0], yHeader: newdata[m][1] })
            }
        }


        let yType = "notnumeric";
        let xType = "notnumeric";
        let scales = [];
        xType = vis.dashboardInitTypesScalesService.getVariableTypes(vis.dataFiltered, vis.xVariable, vis);
        yType = vis.dashboardInitTypesScalesService.getVariableTypes(vis.dataFiltered, vis.yVariable, vis);

        vis.yDividor = vis.dashboardAxisFormattingService.getYDividor(vis.yVariable, yType, vis);
        vis.xDividor = vis.dashboardAxisFormattingService.getXDividor(vis.xVariable, vis, vis.bubbleDistinctValues);

        let types = [{ name: "x", axisName: "xAxis", type: xType, variable: vis.xVariable, axisOrientation: d3.axisBottom(), dividor: vis.xDividor, range: vis.width }, { name: "y", axisName: "yAxis", type: yType, variable: vis.yVariable, axisOrientation: d3.axisLeft(), dividor: vis.yDividor, range: vis.height }]

        for (var k in types) {
            if (types[k].type === 'numeric') {
                scales.push({ graphType: "bubble", name: types[k].name, axisName: types[k].axisName, type: d3.scaleLinear(), scaleType: "linear", range: types[k].range, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor },)
            }
            else {
                vis.dataFiltered.sort(function (a, b) {
                    return d3.ascending(a[vis.xVariable], b[vis.xVariable])
                })
                scales.push({ graphType: "bubble", name: types[k].name, axisName: types[k].axisName, type: d3.scaleBand(), scaleType: "band", range: types[k].range, variable: types[k].variable, axisOrientation: types[k].axisOrientation, dividor: types[k].dividor },)
            }
        }


        vis.dashboardInitTypesScalesService.setScales(scales, vis, vis.bubbleDistinctValues)

        let minx = Math.abs(d3.min(vis.dataFiltered, function (d) { return d[vis.zVariable] }));
        let maxx = Math.abs(d3.max(vis.dataFiltered, function (d) { return d[vis.zVariable] }));
        let difference = (maxx - minx) / minx;
        let maxRange = Math.min(Math.max(5, 4 * Math.abs(difference)), 40);
        if (Math.abs(difference) < 5) {
            maxRange = 20;
        }

        let minRange = 4;


        if (vis.dataFiltered.length === 1) {
            minRange = 10;
            maxRange = 10;
        }
        vis.z = d3.scaleLinear()
            .range([minRange, maxRange])

        vis.z.domain([0, Math.max(Math.abs(d3.min(vis.dataFiltered, function (d) { return d[vis.zVariable] })),
        Math.abs(d3.max(vis.dataFiltered, function (d) { return d[vis.zVariable] })))]);

        vis.colorScale = d3.scaleOrdinal()
            .domain(vis.domain)
            .range(vis.colorScheme)

        vis.xDomain = vis.x.domain();


        // initialize legend (get data keys, get color and show rect + text)
        if (vis.g) {
            vis.g.selectAll(".legendClass").remove();
        }

        vis.xType = xType;
        vis.yType = yType;
        return vis;
    }


    //set bubbles chart
    setBubbles(vis) {
        var data = [];

        vis.xAxis.selectAll("line")
            .style("stroke", function (d, i) {
                return i % vis.xDividor == 0 ? "#bbbbbb" : "transparent";
            });

        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();

            let count = 0;

            vis.colorScale = d3.scaleOrdinal()
                .range(vis.colorScheme)


            vis.g1.selectAll(".arc").remove();

            for (var k in vis.bubbleDistinctValues) {
                let filteredData = vis.dataFiltered.filter(item => vis.bubbleDistinctValues[k]['xHeader'] === item[vis.xVariable] && vis.bubbleDistinctValues[k]["yHeader"] === item[vis.yVariable])
                count = count + filteredData.length;

                var pie = d3.pie()
                    .sort(null)
                    .value(function (d) { return Math.abs(d["col"]); });

                let max = Math.abs(d3.max(filteredData, function (d) { return d[vis.zVariable] }))

                if (vis.bubbleDistinctValues[k].yHeader === "Other" && vis.bubbleDistinctValues[k].xHeader === "Other") {
                    console.log(max);
                }
                var path = d3.arc()
                    .outerRadius(vis.z(max))
                    .innerRadius(0);
                let className = "arc item" + k;
                let pies = vis.g1.selectAll("." + className)
                    .data(pie(filteredData))
                    .enter().append("g")
                    .attr("class", className)


                pies.append("path")
                    .attr("d", path)
                    .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i + vis.bubbleDistinctValues[k].xHeader})
                    .attr("fill", function (d) {
                        if (filteredData.length === 0) {
                            return '#fff';
                        }
                        return vis.colorScale(d.data["Categorie"]);
                    })
                    .attr("stroke", function (d) {
                        if (filteredData.length === 0) {
                            return vis.colorScale(d.data["Categorie"]);
                        }
                        return '#fff';

                    })
                    .attr("transform", function (d) {
                        let x = vis.dashboardInitTypesScalesService.getCoordinates(vis, d, "x");
                        let y = vis.dashboardInitTypesScalesService.getCoordinates(vis, d, "y");
                        return "translate(" + x + "," + y + ")"
                    })
                    .on("mouseover", function (d) { vis.tooltip = d3.selectAll('body').append("div").attr("class", "toolTip") })
                    .on("mouseout", function () {
                        d3.selectAll(".toolTip").remove()
                        if (vis.focus) {
                            vis.focus.style('display', "none");

                        }

                    })
                    .on("mousemove", function (d) {
                        if (vis.tooltip) {
                            vis.dashboardTooltipContextMenuService.mousemoved(vis, d3.mouse(this), d3.event.pageX, d3.event.pageY, filteredData)
                        }

                    })
                    .on("click", function (d, i) {
                        vis.bubbleContextMenu2 = false;
                        vis.bubbleClickMenu = true;
                        vis.bubbleMouseThis = d3.mouse(this)[1];
                        if (d3.event.altKey) {
                            vis.bubbleContextMenu2 = true;
                        }
                        vis = vis.dashboardBubbleChartService.setClickMenu(d, i, vis, filteredData, d3.mouse(this)[1], d3.event, "click");
                        setTimeout(() => {
                            vis.bubbleClickMenu = false;
                        }, 1000)
                    })
                    .on("contextmenu", function (d, i) {
                        d3.event.preventDefault();
                        vis.bubbleMouseThis = d3.mouse(this)[1];
                        vis.bubbleContextMenu2 = true;
                        vis.bubbleClickMenu = true;
                        vis = vis.dashboardBubbleChartService.setClickMenu(d, i, vis, filteredData, d3.mouse(this)[1], d3.event, "contextmenu")
                    })
            }

            return vis;
        }
    }

    setClickMenu(d, i, vis, filteredData, mouseThis, event, param) {
        if (filteredData.length > 1) {
            vis.displayClickMenu = true;
            vis.cd.detectChanges();

            d3.selectAll(".toolTip").remove()
            vis.clickMenu = d3.selectAll(".clickMenu")
                .attr("class", "toolTip2 clickMenu");

            vis.clickValues = filteredData;

            for (var k in vis.clickValues) {
                vis.clickValues[k].color = vis.colorScale(vis.clickValues[k].Categorie);
            }

            if (param === "contextmenu") {
                vis.bubbleContextMenu = true;
            }

            let pageX = vis.x(d.data.xHeader) + vis.x.bandwidth() * 2 + 15;
            let pageY = vis.y(d.data.yHeader) + vis.y.bandwidth();
            let orientation = "left";
            let orientationPx = pageX + 50;
            let topPx = pageY - 50;

            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;

            if (windowWidth - orientationPx < 150) {
                orientation = "right";
                orientationPx = pageX;
                topPx = pageY - 50;
            }

            vis.clickMenu
                .style("display", "inline-block")
                .style("z-index", 2200)

            setTimeout(() => {
                if (vis.clickMenu.node() != null) {
                    if (windowWidth - orientationPx < vis.clickMenu.node().getClientRects()[0].width + 50) {
                        orientation = "right";
                        orientationPx = windowWidth - pageX;


                    }

                    if (pageY + vis.clickMenu.node().getClientRects()[0].height + 10 > windowHeight) {
                        topPx = windowHeight - 10 - vis.clickMenu.node().getClientRects()[0].height
                    }
                }

                vis.clickMenu
                    .style(orientation, orientationPx + "px")
                    .style("top", topPx + "px")


            }, 100)

        }
        else if (param === "click") {
            vis.displayClickMenu = false;
            vis.dashboardFilterChartService.setClickFilter(vis, mouseThis, d.data, i, event)
        }
        else if (param === "contextmenu") {
            let yValue;
            if (vis.view != 'sidebar') {
                [vis.xPosition, vis.yPosition, vis.filtervalue, vis.displayContextMenu, yValue] = vis.dashboardTooltipContextMenuService.setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu, mouseThis, d.data, event);
                if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                    vis.cd.detectChanges();
                }
                let element = document.getElementsByClassName("contextMenu") as HTMLCollectionOf<HTMLElement>;
                document.body.appendChild(element[0]);

                // vis.expandedChartEmit.emit({ value: true });
                if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                    vis.cd.detectChanges();

                }
            }
        }
        return vis;
    }


}