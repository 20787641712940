
import { SelectItem } from 'primeng/api';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ScriptManagementService } from './script-management.service';
import { Subscription } from 'rxjs';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-script-management',
  templateUrl: './script-management.component.html',
  styleUrls: ['./script-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScriptManagementComponent implements OnInit, OnDestroy {

  @ViewChild("fileDropRef") fileDropEl: ElementRef;
  optionsScriptManagement: SelectItem[];
  selectedOptionScript: any;
  visibleHelpSidebar;
  files: any[] = [];
  formattedFiles: any[] = [];
  initFiles: any[] = [];
  subscriptionDownloadFile = new Subscription();
  downloadFileName: string;

  constructor(private scriptManagementService: ScriptManagementService,
    private appService: AppService,
    private sharedService: SharedInitializationService,
    private cd: ChangeDetectorRef) {

    this.optionsScriptManagement = [
      { label: 'Download', value: 'Download', icon: 'pi-download' },
      { label: 'Upload', value: 'Upload', icon: 'pi-upload' }
    ];

  }

  ngOnInit() {
    console.log("script management")
    this.subscriptionDownloadFile = this.scriptManagementService.downloadNameChange
      .subscribe((data: any) => {
        this.downloadFileName = data;
        this.scriptManagementService.adminDownloadScript("Matching", this.downloadFileName);
      }
      )

  }

  scriptManagementChange() {
    if (this.selectedOptionScript.value == 'Download') {
      this.scriptManagementService.adminDownloadScriptName("Matching");
    }
  }

  //this allows to get the help documentation for settings market data source
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "scriptmanagement"];
    this.sharedService.showHelpDocumentation("Admin Settings Script Management");

    this.sharedService.getHelpDocumentation(parameters)
  }

  /**
 * on file drop handler
 */
  onFileDropped($event) {
    if (this.files.length === 1) {
      this.appService.showMessage("Error", "Please choose only one file")
    }
    else {
      this.prepareFilesList($event);
    }

  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    if (this.files.length === 1) {
      this.appService.showMessage("Error", "Please choose only one file")
    }
    else {
      this.prepareFilesList(files);
    }

  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (var k = 0; k < files.length; k++) {
      let newdata = files[k];
      this.initFiles.push(newdata);

    }

    for (const item of files) {
      item.progress = 0;
      let newdata = {
        name: item.name
      }
      this.files.push(item);
      this.formattedFiles.push(newdata)
      this.uploadFilesSimulator(this.initFiles.length - 1);
    }



  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
    this.formattedFiles.splice(index, 1);
    this.initFiles.splice(index, 1);
    this.fileDropEl.nativeElement.value = "";
  }
  //this is to get file 
  getFile(file) {
    //this.analysisAuditService.analysisDownloadAuditFile(this.tab.workflow, this.fieldIDValue, file)
  }

  acceptFile() {

    this.scriptManagementService.adminUploadScript(this.initFiles, "Matching");
  }

  cancelFile() {
    if (this.files.length > 0) {
      this.deleteFile(0);
    }
  }
  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index]) {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);

            } else {
              this.files[index].progress += 20;
              this.cd.detectChanges();
            }
          }
          else {
            clearInterval(progressInterval)
          }

        }, 100);
      }
    }, 1000);
  }

  /**
  * format bytes
  * @param bytes (File size in bytes)
  * @param decimals (Decimals point)
  */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionDownloadFile.unsubscribe();
  }
}
