import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef, ViewEncapsulation, ViewRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { EmailConfigurationService } from './email-configuration.service';
import { Subscription } from 'rxjs';
import { AdminGetEmailConfigurationData } from './admin-get-email-configuration-data.model';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { dashboardData } from 'src/app/dashboard/dashboard.model';
import { EmailTitleOption } from './emailTitleOption.model';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { AppService } from 'src/app/app.service';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class EmailConfigurationComponent implements OnInit, OnDestroy {
  //showForm
  displayEmail = false;

  //initialize form and subscriptions
  emailConfigurationForm: FormGroup;
  emailConfigurationFormValue: any;
  subscriptionEmailData = new Subscription();
  subscriptionDashboardData = new Subscription();

  allFields: any;

  //initialize variables for email body 
  emailBodyFP: SelectItem[];
  emailBodySP: SelectItem[];
  emailBodyTP: SelectItem[];
  bodyFP: any;
  bodySP: any;
  bodyTP: any;
  allFieldsFP: SelectItem[];
  selectedFieldFP: SelectItem;
  selectedFieldSP: SelectItem;
  selectedFieldTP: SelectItem;
  selectedFP = new FormControl('');
  //initialize sender email
  emailAddress: string;
  password: string;
  showEmailPassword: boolean = false;
  companyText: string;
  emailTitle: string;
  signatureText: string;
  //initialize values for email object
  titleOptions: EmailTitleOption[];
  previousEmailObject: String[];
  pendingEmailObject: String[];
  nextEmailObject: { name: string, type: string }[];
  showEmailNewText = false;
  @ViewChild('emailNewText') emailTextValue: ElementRef;
  value;

  //initialize values for frequencies
  // frequencies: SelectItem[];

  trueStatus = [
    { label: 'True Status', value: 'trueStatus' },
    { label: 'True Status Type', value: 'trueStatusType' },
  ]
  trueStatusSelected: string = 'trueStatus';
  allFieldsRC = [
    { label: 'Workflow', value: 'wfname' , order:1},
    { label: 'Table', value: 'wftable' , order:2},
    { label: 'Sub-Table', value: 'wfsubtable', order:3},
  ]
  emailBodyRC: SelectItem[];
  bodyRC: any;
  selectedFieldRC: SelectItem;

  nbDays: number= 1;

  constructor(private emailService: EmailConfigurationService,
    private dashboardService: DashboardService,
    private appService: AppService,
    private sharedService: SharedInitializationService,
    private sharedGeneralFunctions: SharedGeneralFunctionsService,
    private cd: ChangeDetectorRef) { }

  //on initialization            
  ngOnInit() {
    this.initForm();
    this.emailService.getEmailConfigurationData();
    //create the subscription that will fire each time the configuration data is changed. Once the data is sent, we set the configuration values in the html form
    this.subscriptionEmailData = this.emailService.emailConfigurationDataChange
      .subscribe((response: any) => {
        console.log(response);
        /*
        this.bodyFP = [];
        this.bodySP = [];
        this.bodyTP = [];
        for (var i in this.allFieldsFP) {
          for (var j in response.emailBodyFirstPart) {
            if (this.allFieldsFP[i].label === response.emailBodyFirstPart[j]) {
              this.bodyFP.push(this.allFieldsFP[i])
            }
          }
          for (var k in response.emailBodySecondPart) {
            if (this.allFieldsFP[i].label === response.emailBodySecondPart[k]) {
              this.bodySP.push(this.allFieldsFP[i])
            }
          }
          for (var m in response.emailBodySecondPart) {
            if (this.allFieldsFP[i].label === response.emailBodyThirdPart[m]) {
              this.bodyTP.push(this.allFieldsFP[i])
            }
          }
        }
        */
        this.bodyRC = [];
        for (var i in this.allFieldsRC) {
          for (var m in response.recapFields) {
            if (this.allFieldsRC[i].value === response.recapFields[m]) {
              this.bodyRC.push(this.allFieldsRC[i])
            }
          }
        }
        this.emailAddress = response.emailSender;
        this.password = response.emailPassword;
        this.emailTitle = response.emailTitle;
        this.companyText = response.companyText;
        this.signatureText = response.signatureText;
        this.trueStatusSelected = response.recapStatus;
        this.nbDays = response.nbDays;
        this.previousEmailObject = [];

        /*for (var i in response.emailTitle) {
          this.previousEmailObject.push(response.emailTitle[i].name)

          if (this.sharedGeneralFunctions.elementExists(this.titleOptions, "label", response.emailTitle[i].name) === false) {
            this.titleOptions.push(new EmailTitleOption(response.emailTitle[i].name, response.emailTitle[i].name, response.emailTitle[i].type))
          }
        }
       
        //this.selectedFP.setValue(this.bodyFP);
        console.log(this.previousEmailObject);
    */
        this.emailConfigurationForm.get('emailSender').setValue(this.emailAddress);
        this.emailConfigurationForm.get('emailPassword').setValue(this.password);
        this.emailConfigurationForm.get('emailTitle').setValue(this.emailTitle);
        this.emailConfigurationForm.get('companyText').setValue(this.companyText);
        this.emailConfigurationForm.get('signatureText').setValue(this.signatureText);
        this.emailConfigurationForm.get('selectedFieldRC').setValue(this.bodyRC);
        this.emailConfigurationForm.get('emailTrueStatus').setValue(this.trueStatusSelected);
        this.emailConfigurationForm.get('nbDays').setValue(this.nbDays);
        /*
        /*
        this.emailConfigurationForm.get('emailObject').setValue(this.previousEmailObject);
        this.emailConfigurationForm.get('emailFrequency').setValue(response.frequency);
        this.emailConfigurationForm.get('selectedFieldFP').setValue(this.bodyFP);
        this.emailConfigurationForm.get('selectedFieldSP').setValue(this.bodySP);
        this.emailConfigurationForm.get('selectedFieldTP').setValue(this.bodyTP);
        */
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })

    /*
    //create subscrition to get the anomaly fields required
    this.subscriptionDashboardData = this.dashboardService.dashboardDataFieldsChange
      .subscribe((response: dashboardData) => {
        this.allFields = this.emailService.convertToPrimeArray(Object.keys(response.tableRows[0]));
        console.log(this.allFields);
        this.allFieldsFP = this.allFields;
       this.emailService.getEmailConfigurationData();
      //  this.emailConfigurationForm.get('selectedFieldFP').setValue(this.bodyFP);
      })

    let dashboardName = "";

    this.dashboardService.getDashboardDataFields(dashboardName);

    //create the list of possible frequencies 
    this.frequencies = [
      { label: 'daily', value: 'daily' },
      { label: 'weekly', value: 'weekly' },
      { label: 'monthly', value: 'monthly' },
      { label: 'last run', value: 'last run' },
    ]
    */
    //create the liste of options for the email object (variables and string)
    /*this.titleOptions = [
      { label: '# New Predictions', value: '# New Predictions', type: 'variable' },
      { label: '# Old Predictions', value: '# Old Predictions', type: 'variable' },
      { label: '# New Predictions By Workflow', value: '# New Predictions By Workflow', type: 'variable' },
      { label: 'New Text', value: 'New Text', type: 'string' },
    ]*/
  }// end onInit

  //initializes the form without any values. The subscription will then update the values within.
  private initForm() {
    this.emailConfigurationForm = new FormGroup({
      emailSender: new FormControl('', Validators.required),
      emailPassword: new FormControl('', Validators.required),
      emailTitle: new FormControl(''),
      companyText: new FormControl(''),
      signatureText: new FormControl(''),
      emailTrueStatus: new FormControl('', Validators.required),
      selectedFieldRC: new FormControl('', Validators.required),
      nbDays: new FormControl('', Validators.required),

      /*
      emailObject: new FormControl('', Validators.required),
      emailFrequency: new FormControl('', Validators.required),
      selectedFieldFP: new FormControl('', Validators.required),
      selectedFieldSP: new FormControl('', Validators.required),
      selectedFieldTP: new FormControl('', Validators.required),
      */
    });
    this.emailConfigurationForm.get('emailTrueStatus').setValue(this.trueStatusSelected);
    this.emailConfigurationForm.get('nbDays').setValue(this.nbDays);
  }

  //on submit, change the data to the webservice format and send in the request
  onSubmit() {
    console.log(this.emailConfigurationForm);

    this.emailConfigurationFormValue = this.emailConfigurationForm.value;
    /*this.nextEmailObject = [];
    for (var i in this.emailConfigurationFormValue.emailObject) {
      for (var j in this.titleOptions) {
        if (this.titleOptions[j].label === this.emailConfigurationFormValue.emailObject[i]) {
          this.nextEmailObject.push({ name: this.titleOptions[j].label, type: this.titleOptions[j].type })
        }
      }
    }

    this.emailConfigurationFormValue.emailObject = this.nextEmailObject;
    */
    console.log(this.emailConfigurationFormValue);
    this.emailConfigurationFormValue.selectedFieldRC = this.emailConfigurationFormValue.selectedFieldRC.sort(this.compareFields)
    
    this.emailService.setEmailConfigurationData(this.emailConfigurationFormValue)

  }
  compareFields(a, b) {

    var A = a.order;
    var B = b.order;
    if (A < B) {
      return -1;
    } else if (A > B) {
      return 1;
    } else {
      return 0;
    }

  }
  /*
    //if ("New text is checked, open text box div and close it if it's unckecked")
    onEmailTitleChange() {
      if (this.emailConfigurationForm.value.emailObject.indexOf("New Text") > -1) {
        this.showEmailNewText = true;
      }
      else if ((this.emailConfigurationForm.value.emailObject.indexOf("New Text") === -1) && (this.showEmailNewText === true)) {
        this.showEmailNewText = false;
      }
    }
  
    //add new text value to email object, verifies if text value is null
    addEmailNewText() {
      if (this.emailTextValue.nativeElement.value === "") {
        this.appService.showMessage('Error', "Please fill in the text box.")
      }
      else {
        this.titleOptions.push(new EmailTitleOption(this.emailTextValue.nativeElement.value, this.emailTextValue.nativeElement.value, "string"));
        this.pendingEmailObject = this.emailConfigurationForm.get('emailObject').value;
  
        if (this.pendingEmailObject.indexOf("New Text") > -1) {
          this.pendingEmailObject.splice(this.pendingEmailObject.indexOf("New Text"), 1);
        }
  
        this.pendingEmailObject.push(this.emailTextValue.nativeElement.value);
        console.log(this.pendingEmailObject);
        this.showEmailNewText = false;
  
        this.emailConfigurationForm.get('emailObject').setValue(this.pendingEmailObject);
      }
    }
    */
  //if the sender's email is changed please enter a new password

  onChangeEmailAddress() {
    console.log(this.emailAddress)
    console.log(this.emailConfigurationForm.value.emailSender);

    if ((this.emailConfigurationForm.value.emailSender != this.emailAddress) && (this.emailConfigurationForm.value.emailSender != "")) {

      this.showEmailPassword = true;
      this.emailConfigurationForm.get('emailPassword').setValue('');
      console.log('this 1')
    }
    else {
      console.log('this false')
      this.showEmailPassword = false;
      this.emailConfigurationForm.get('emailPassword').setValue(this.password);
    }
  }
  //check if element exists in array of objects

  //this allows to get the help documentation for settings logout time
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "emailconfiguration"];
    this.sharedService.showHelpDocumentation("Admin Settings Email Configuration");

    this.sharedService.getHelpDocumentation(parameters)
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionEmailData.unsubscribe();
    //this.subscriptionDashboardData.unsubscribe();
  }
}
