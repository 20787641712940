import { ViewRef } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
var DashboardPDFService = /** @class */ (function () {
    function DashboardPDFService() {
    }
    DashboardPDFService.prototype.displayPDF = function (vis) {
        vis.displayPDFClass = true;
        var PDF = new jsPDF('l', 'mm', 'a4');
        vis.windowWidth = PDF.internal.pageSize.width * 1 / 0.2645833333;
        vis.windowHeight = PDF.internal.pageSize.height * 1 / 0.2645833333;
        vis.sourceMaxDragPosition = JSON.parse(JSON.stringify(vis.maxDragPosition));
        vis.maxDragPosition = Math.max(vis.maxDragPosition, vis.maxDragPosition * 2.5 - PDF.internal.pageSize.height * 1 / 0.2645833333);
        vis.marginLeft = (window.innerWidth - vis.windowWidth) / 2;
        setTimeout(function () {
            vis.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
        }, 100);
    };
    DashboardPDFService.prototype.downloadPdf = function (vis) {
        vis.appService.startSpin2();
        if (vis.cd && !vis.cd.destroyed) {
            vis.cd.detectChanges();
        }
        var PDF = new jsPDF('l', 'mm', 'a4');
        var performanceTime = performance.now();
        console.log(performanceTime);
        setTimeout(function () {
            var img = [];
            var requiredPages = vis.columns.length; // count of div
            var pages = 1;
            var _loop_1 = function () {
                var id = vis.mainTab + vis.tabAlias + "column" + i;
                var htmlelement = document.getElementById(id);
                var position = 0;
                html2canvas(htmlelement).then(function (canvas) {
                    console.log(canvas);
                    console.log(htmlelement);
                    var number = +htmlelement.id.slice(-1);
                    var FILEURI = canvas.toDataURL('image/jpeg');
                    var alias = "alias" + number;
                    var pdfHeight = PDF.internal.pageSize.height;
                    var imgWidth = vis.columns[number].width * vis.windowWidth * 0.2645833333;
                    var imgHeight = vis.columns[number].height * vis.windowHeight * 0.2645833333;
                    var yPosition = vis.columns[number].topPosition * vis.windowHeight * 0.2645833333;
                    var xPosition = vis.columns[number].leftPosition * vis.windowWidth * 0.2645833333;
                    var pageIndex = Math.ceil((yPosition + imgHeight) / pdfHeight);
                    if (pageIndex > pages) {
                        PDF.addPage();
                        yPosition = yPosition + 30 - (pageIndex - 1) * pdfHeight;
                        pages++;
                        PDF.setPage(pages);
                    }
                    else {
                        if (pageIndex > 1) {
                            yPosition = yPosition + 30 - (pageIndex - 1) * pdfHeight;
                        }
                        PDF.setPage(pageIndex);
                    }
                    PDF.addImage(FILEURI, 'JPEG', xPosition, yPosition, imgWidth, imgHeight, alias);
                    img.push(FILEURI);
                    if (img.length === requiredPages) {
                        var sourceName = "";
                        for (var k in vis.tab.source) {
                            if (k.toString() === "0") {
                                sourceName = vis.tab.source[k].name;
                            }
                            else {
                                sourceName = sourceName + "-" + vis.tab.source[k].name;
                            }
                        }
                        var filename = new Date().toLocaleDateString() + "-" + vis.tabAlias + "-" + sourceName + ".pdf";
                        PDF.save(filename);
                        vis.displayPDFClass = false;
                        vis.maxDragPosition = vis.sourceMaxDragPosition;
                        vis.windowHeight = window.innerHeight;
                        vis.windowWidth = window.innerWidth;
                        setTimeout(function () {
                            vis.dashboardService.setChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
                        }, 100);
                        vis.appService.stopSpin2();
                    }
                    else {
                        // PDF.addPage();
                    }
                });
            };
            for (var i in vis.columns) {
                _loop_1();
            }
        }, 4000);
        console.log(performance.now() - performanceTime);
    };
    return DashboardPDFService;
}());
export { DashboardPDFService };
