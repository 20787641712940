import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';



@Injectable()
export class DashboardPieChartService {

    constructor(private httpClient: HttpClient,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }

    setPie(vis) {
        vis.colorScale = d3.scaleOrdinal(vis.colorScheme);
        vis.pieG
        .attr("transform", "translate(" + (vis.width + vis.margin.right + vis.margin.left) / 2 +
            ", " + (vis.height + vis.margin.top + vis.margin.bottom) / 2 + ")")

        var pie = d3.pie()
            .sort(null)
            .value(function (d) { return Math.abs(d["col"]); });

        var radius = Math.min(vis.width + vis.margin.right + vis.margin.left, vis.height + vis.margin.top + vis.margin.bottom) / 2;
        console.log(radius);
        console.log(vis.width);
        console.log(vis.margin);
        console.log(vis.height);
        console.log(vis.width+vis.margin.right+vis.margin.left);
        console.log(vis.height+vis.margin.top+vis.margin.bottom);

        var path = d3.arc()
            .outerRadius(radius - 10)
            .innerRadius(0);


        var label = d3.arc()
            .outerRadius(radius - 40)
            .innerRadius(radius - 40);

        vis.pieG.selectAll(".arc").remove();

        let data = JSON.parse(JSON.stringify(vis.dataFiltered))
        let filteredData = vis.dataFiltered.filter(item => item.col != 0);
        if (filteredData.length === 0) {
            let newdata = {Categorie: "Empty", col: 1};
            data.push(newdata);
        }
        vis.pies = vis.pieG.selectAll(".arc")
            .data(pie(data))
            
            .enter().append("g")
            .attr("class", "arc")


        vis.pies.append("path")
            .attr("d", path)
            .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i })
            .attr("fill", function (d) {
                if (filteredData.length === 0)  {
                    return '#fff';
                }
                return vis.colorScale(d.data["Categorie"]);
            })
            .attr("stroke", function(d) {
                if (filteredData.length === 0)  {
                    return vis.colorScale(d.data["Categorie"]);
                }
                return '#fff';
               
            })
           // .attr("transform", "translate(60,60)")
            

        return vis;
    }

}