<div class="selected-topic-title margin-top-20">
    <h2>{{selectedTopic}}</h2>
    <div class="row margin-top-10">
        <div class="col-md-1">
        </div>
        <div class="col-md-11">
          <p-table id = "topicDataTable" [value]="topicData" dataKey="{{groupField1}}"  [scrollable]="true" scrollHeight="480px">
            <ng-template pTemplate="header">
                <tr>
                    <th>Sources</th>
                    <th>Workflows</th>
                    <th>Tables</th>
                    <th>Subtables</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded" let-columns="columns">
                <tr class="ui-widget-header" *ngIf="rowGroupMetadata[rowData[groupField1]].index === rowIndex">
                    <td colspan="4">
                        <a (click)="displaySecondRow[rowData[groupField1]] = !displaySecondRow[rowData[groupField1]]" >
                            <i id="arrow" [ngClass]="displaySecondRow[rowData[groupField1]] ? 'fa fa-lg fa-fw fa-chevron-circle-down' : 'fa fa-lg fa-fw fa-chevron-circle-right'"></i>
                            <span>{{rowData[groupField1]}}</span>
                        </a>
                    </td>
                </tr>
                <tr class="ui-widget-header" *ngIf="secondRowGroupMetadata[rowData[groupField1]][rowData[groupField2]].index === rowIndex && displaySecondRow[rowData[groupField1]]">
                  <td></td>  
                  <td colspan="3">
                        <a [pRowToggler]="rowData" (click)="displayThirdRow[rowData[groupField1]][rowData[groupField2]] = !displayThirdRow[rowData[groupField1]][rowData[groupField2]]" >
                            <i id="arrow" [ngClass]="displayThirdRow[rowData[groupField1]][rowData[groupField2]] ? 'fa fa-lg fa-fw fa-chevron-circle-down' : 'fa fa-lg fa-fw fa-chevron-circle-right'"></i>
                            <span>{{rowData[groupField2]}}</span>
                        </a>
                    </td>
                </tr>

              <tr class="ui-widget-header" *ngIf="thirdRowGroupMetadata[rowData[groupField1]][rowData[groupField2]][rowData[groupField3]].index === rowIndex && displayThirdRow[rowData[groupField1]][rowData[groupField2]]">
                <td></td>
                <td></td> 
                <td colspan="2">
                      <a [pRowToggler]="rowData" (click)="displayForthRow[rowData[groupField1]][rowData[groupField2]][rowData[groupField3]] = !displayForthRow[rowData[groupField1]][rowData[groupField2]][rowData[groupField3]]" >
                          <i id="arrow" [ngClass]="displayForthRow[rowData[groupField1]][rowData[groupField2]][rowData[groupField3]] ? 'fa fa-lg fa-fw fa-chevron-circle-down' : 'fa fa-lg fa-fw fa-chevron-circle-right'"></i>
                          <span>{{rowData[groupField3]}}</span>
                      </a>
                  </td>
              </tr>
              <tr *ngIf="displayForthRow[rowData[groupField1]][rowData[groupField2]][rowData[groupField3]]&& displayThirdRow[rowData[groupField1]][rowData[groupField2]]">
                <td></td>
                <td></td>
                <td></td> 
                <td>{{rowData[groupField4]}}</td>
            </tr>
            </ng-template>
            <ng-template>
            
            </ng-template>

        </p-table>
        </div>
      </div>
</div>
