import { Component, OnInit, OnDestroy, ViewRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';
import { AuthorizationsService } from '../../authorizations/authorizations.service';
import { PackagesService } from '../packages.service';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss']
})
export class OwnersComponent implements OnInit, OnDestroy {

  //initialize nature of logs
  viewNatures = [
    { label: 'Owners view', value: 'Owners view' },
    { label: 'Workflows view', value: 'Workflows view' }
  ];
  selectedViewNature: string = 'Owners view';
  //variable to get all the owners (active and inactive)
  owners: any[] = [];
  filteredOwners: any[] = [];
  allShownOwners: boolean = true;
  //subscription to get all the owners when updated
  ownersSubscription = new Subscription();

  //form control to initialize the selected owner in the list
  owner = new FormControl('');
  //subscription to update the selected owner (when changing)
  ownerSubscription = new Subscription();

  //all the possible workflows
  workflows: any[] = [];
  filteredWorkflows: any[] = [];
  //subscription so that workflows are automatically updated
  workflowsSubscription = new Subscription();
  //form control to initialize the selected owner in the list
  workflow = new FormControl('');
  //subscription to update the selected owner (when changing)
  workflowSubscription = new Subscription();

  constructor(private authorizationsServices: AuthorizationsService,
    private packagesService: PackagesService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    //allows to get all the active and inactive owners, all the sources, webservices, workflows and users
    this.authorizationsServices.getAllWorkflows();
    this.packagesService.getOwnerData();

    //allows to update the owners when one is changed // created
    this.ownersSubscription = this.packagesService.ownersChange
      .subscribe((owners: any) => {
        this.owners = owners;
        console.log(this.owners)
        this.filterActive('');
        /*
        for (var i in this.route.children) {
          if (this.route.children[i].snapshot.url[0]) {
            if (this.route.children[i].snapshot.url[0].path === "new") {
              this.router.navigate(["admin", "settings", "owners"]);
            }
          }
        }
        */
        this.cd.detectChanges();

      })

    //allows to update the selected owner and navigate to its specific page
    this.ownerSubscription = this.owner.valueChanges
      .subscribe((owner: any) => {
        console.log(owner)
        if (owner.UserName) {

          this.router.navigate(["admin", "settings", "owners", "owner",owner.UserName])
        }

        else {
          this.router.navigate(["admin", "settings", "owners"])
        }

      })
    this.workflowsSubscription = this.authorizationsServices.workflowsChange
      .subscribe((workflows: any) => {
        this.workflows = workflows;
        this.filterActiveWorkflows('');
        if (this.cd && !(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      })
    //allows to update the selected owner and navigate to its specific page
    this.workflowSubscription = this.workflow.valueChanges
      .subscribe((workflow: any) => {
        console.log(workflow)
        if (workflow.WFName) {

          this.router.navigate(["admin", "settings", "owners", "workflow", workflow.WFName])
        }

        else {
          this.router.navigate(["admin", "settings", "owners"])
        }

      })
  }// ng_init

  //filter active or all owners
  filterActive(param) {
    this.owner.setValue("");

    this.filteredOwners = JSON.parse(JSON.stringify(this.owners))
  }
  //filter active or all workflows
  filterActiveWorkflows(param) {
    this.workflow.setValue("");

    this.filteredWorkflows = JSON.parse(JSON.stringify(this.workflows))
  }
  //allows to clear the form control when clicking on new

  viewNatureChange(){
    this.owner.setValue("");
    this.workflow.setValue("");
    this.router.navigate(['admin', 'settings', 'owners'])
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.ownersSubscription.unsubscribe();
    this.ownerSubscription.unsubscribe();
    this.workflowsSubscription.unsubscribe();
    this.workflowSubscription.unsubscribe();
  }

}
