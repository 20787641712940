import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth-service';
import { AppService } from '../app.service';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError ,map} from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
//import 'rxjs/add/operator/map';

@Injectable()
export class AuthGuard implements CanActivate {
  
    //authenticationSubscription = new Subscription;

    constructor(private router: Router,
                private authService: AuthService,
                private httpClient: HttpClient,
                private route: ActivatedRoute,
                private appService: AppService ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        console.log(this.route);
        console.log(this.route.snapshot.url);
        console.log('Window Name' + window.name)
        let loggedIn = true;
        if (window.name.indexOf('navigate') === -1) {
           loggedIn = this.authService.isLoggedin()
        }
        if (loggedIn === false) {
            this.router.navigate(['/login'])
            window.location.href = window.location.host;
            window.location.reload();
           // window.location.reload();
            return false;
        }
        else {
        const url = this.appService.getUrl();
        const webservice = "AccessCheckForLogout";
        const completeUrl = url+webservice;
    
    //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
   
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


  
     return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
        .pipe(
            //map(response => new NmUser(response)),
           // catchError(this.appService.handleError)
        
        map((response: any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
               if (response.statusCode > -1 ) {
                   // this.isAuthenticated = true;
                    //this.isAuthenticatedChange.next(this.isAuthenticated)
                    return true;
                }
               else {
                    //this.isAuthenticated = false;
                    window.location.reload()
                 //  this.router.navigate(['/login']);
                  // this.isAuthenticatedChange.next(this.isAuthenticated);
                    this.appService.showMessage('Error', response.statusText)
                    return false;
               }
        }))
       /* this.authenticationSubscription = this.authService.isAuthenticatedChange
            .subscribe((data: boolean) => {
                if (data === false) {
                    this.router.navigate['/login'];
                }
                return data;
            })

    /*    if(this.authService.isUserStillLoggedIn()) {
            return true;
        } else {
           
            return false;
        } */ 
     }
    }
}