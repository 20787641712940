import {
  Component, OnInit, NgModule, Input, ViewEncapsulation, ViewChild, ElementRef, SimpleChanges, Output,
  EventEmitter
} from '@angular/core';
import * as d3 from 'd3';
import { visitAll } from '@angular/compiler';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { getCurrentDebugContext } from '@angular/core/src/view/services';
import { AnalysisTab } from 'src/app/analysis/models/analysis-tab.model';
import { AnalysisAuditService } from 'src/app/analysis/services/analysis-audit.service';
import { ContextMenu } from 'primeng/contextmenu';

@Component({
  selector: 'cardabel-scatter-plot',
  templateUrl: './cardabel-scatter-plot.component.html',
  styleUrls: ['./cardabel-scatter-plot.component.scss']
})
export class CardabelScatterPlotComponent implements OnInit {

  @Input() parentElement: string = "";
  @Input() title: string = "";
  @Input() fieldID: string = "";
  @Input() xVariable: string = "";
  @Input() yVariable: string = "";
  @Input() dotColor: string = "";
  @Input() height: number = 250;
  @Input() width: number = 1000;
  @Input() margin: any = { top: 10, right: 30, bottom: 60, left: 150 };
  @Input() tab: AnalysisTab;
  @Input() data: any[] = [];
  @Input() numberarray: any[] = [];
  @Input() colorArray: any = {};
  @Input() methodArray: any = {};

  @Output() onAuditClick: EventEmitter<any> = new EventEmitter();
  @Output() onLabelClick: EventEmitter<any> = new EventEmitter();

  nbDotsMax = 10000 // not too much points for audit (too long otherwise)
  currentColor: any = {};

  displayContextMenu: boolean = false;
  contextMenuContent: any[] = [];
  xPosition: number = 0;
  yPosition: number = 0;

  x;
  xAxis;
  xAxisCall;
  rect;
  t;

  y;
  yAxis;
  yAxisCall;

  clip;

  constructor() { }

  ngOnInit() {
    console.log("scatter plot")
    setTimeout(() => {
      this.initVis();
    }, 140)

  }

  initVis() {
    var vis = this;
    vis.t = function () { return d3.transition().duration(1000); }

    var legendVals = Object.keys(vis.colorArray);
    var legendMethods = Object.keys(vis.methodArray);

    var currentColor = { ...vis.colorArray };

    var width = this.getScatterWidth() - vis.margin.left - vis.margin.right;
    var height = this.getScatterHeight() - vis.margin.top - vis.margin.bottom;


    var tooltip;
    let tooltipClass = "toolTip toolTip" + vis.parentElement;

    d3.select(tooltipClass).remove();

    var scattooltip;
    //let scattooltipClass = "scat-toolTip scat-toolTip" + vis.parentElement;
    let scattooltipClass = "scat-toolTip";
    d3.select(scattooltipClass).remove();
    // append the svg object to the body of the page
    let id = "#" + this.parentElement + "Chart";

    // legend method
    var legendMethod = d3.select('.legendMethod')
      .selectAll("legend")
      .data(legendMethods)

    var p = legendMethod.enter().append("div")
      .attr("class", "legendsScatter")
      .append("p").attr("class", "legend-name")

    p.insert("text").text(function (d, i) {
      return d + " : " + vis.methodArray[d]
    })

    // legend scatter with number of dots
    var legendScatter = d3.select('.legendScatter')
      .selectAll("legend")
      .data(legendVals)

    var p = legendScatter.enter().append("div")
      .attr("class", "legendsScatter")
      .append("p").attr("class", "legend-name-big")


    p.append("span").attr("class", "key-dot").style("background", function (d, i) {
      console.log(d);

      return vis.colorArray[d];
    }).on("click", (function (d) {

      return function (d) {
        currentColor[d] = currentColor[d] == vis.colorArray[d] ? "white" : vis.colorArray[d];
        d3.select(this).style("background", currentColor[d]);
        d3.selectAll('circle')
          .filter(function (dot) {
            if (dot !== undefined) {
              let q = dot['rank_anomaly'];
              if (q == '') { q = 'n' };
              return (vis.colorArray[q] == vis.colorArray[d])
            }
          })
          // .transition()
          .attr("fill", currentColor[d] == "white" ? "transparent" : currentColor[d])
          .style("stroke", currentColor[d] == "white" ? "transparent" : "grey")
      }
    })())

    p.insert("text").text(function (d, i) { return d + "(" + vis.numberarray[d] + ")" })

    /// scatter plot itself
    // set the dimensions and margins of the graph

    var svg = d3.select(id)
      .append("svg")
      .attr("width", width + vis.margin.left + vis.margin.right)
      .attr("height", height + vis.margin.top + vis.margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + vis.margin.left + "," + vis.margin.top + ")");

    // Add X axis
    let Minx = d3.min(this.data, function (d) { return d[vis.xVariable] })
    let Maxx = d3.max(this.data, function (d) { return d[vis.xVariable] })
    let difference = Math.abs(Minx - Maxx) / 20;

    vis.x = d3.scaleLinear()
      .domain([Minx - difference, Maxx + difference])
      .range([0, width]);

    vis.xAxis = svg.append("g")
      .attr("transform", "translate(0," + height + ")")

    vis.xAxisCall = d3.axisBottom(vis.x)

    vis.xAxis.call(vis.xAxisCall);

    // Add Y axis
    vis.yAxis = svg.append("g");

    if (this.yVariable != '') {
      let Miny = d3.min(this.data, function (d) { return d[vis.yVariable] })
      let Maxy = d3.max(this.data, function (d) { return d[vis.yVariable] })
      vis.y = d3.scaleLinear()
        .domain([Miny - 0.3, Maxy + 0.3])
        .range([height, 0]);

      vis.yAxisCall = d3.axisLeft(vis.y);
      vis.yAxis.call(vis.yAxisCall);

    }
    else { //this.yVariable = '' only one axis
      vis.y = d3.scaleLinear()
        .domain([0, 0])
        .range([height, 0]);

      vis.yAxisCall = d3.axisLeft(vis.y);
      vis.yAxis.call(vis.yAxisCall);

    }

    var g = svg.append('g')
    vis.clip = g.append("defs").append("svg:clipPath")
      .attr("id", "clip")
      .append("svg:rect")
      .attr("width", width)
      .attr("height", height)
      .attr("x", 0)
      .attr("y", 0);



    var zoom = d3.zoom()
      .scaleExtent([1, 10])
      .translateExtent([[0, 0], [width, height]])
      .extent([[0, 0], [width, height]])
      .on("zoom", zoomed.bind(this))

    vis.rect = svg.append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "transparent")
      .call(zoom)
      .on("dblclick.zoom", null)

    initScatter();
    //+ cancel zooming when double clicking
    //.on("dblclick.zoom", null)


    //************************************************************
    // Zoom specific updates
    //************************************************************
    function zoomed() {

      var vis = this;
      // if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
      var t = d3.event.transform;
      console.log(t)
      let x2 = vis.x;
      let y2 = vis.y;
      if (t) {
        x2 = t.rescaleX(vis.x)
        y2 = t.rescaleY(vis.y)
        //  vis.x = x2;
      }

      vis.xAxisCall.scale(x2);
      vis.xAxis.transition(vis.t()).call(vis.xAxisCall);


      vis.yAxisCall.scale(y2);
      vis.yAxis.transition(vis.t()).call(vis.yAxisCall);

      d3.selectAll(".circleClass")
        .attr("cx", function (d) {
          return x2(d[vis.xVariable]);
        })
        .attr("cy", function (d) {
          if (vis.yVariable != "") {
            return y2(d[vis.yVariable]);
          }
          else {
            return y2(0)
          }

        })
    }




    // ####################################
    // ######### Helper Functions #########
    // ####################################

    function initScatter() {

      console.time('for copy');
      let data2 = [...vis.data];
      console.timeEnd('for copy');
      // remove null values
      let data1
      if (vis.yVariable !== '') {
        data1 = data2.filter((item => {
          if ((item[vis.xVariable] != null) && (item[vis.yVariable] != null)) {
            return item;
          }
        }))
      }
      else {
        data1 = data2.filter((item => {
          if ((item[vis.xVariable] != null)) {
            return item;
          }
        }))
      }
      data1.sort((a, b) => (a[vis.xVariable] > b[vis.xVariable]) ? 1 : ((b[vis.xVariable] > a[vis.xVariable]) ? -1 : 0));

      var keys = Object.keys(vis.data[0]);
      let keys_used = [];
      for (var k in keys) {
        if ((keys[k] != 'rank_anomaly') && (keys[k] != 'color') && (keys[k] != 'currentDotColor') && (keys[k] != vis.fieldID)) {
          keys_used.push(keys[k]);
        }
      }

      if (vis.yVariable !== '') {
        // Add dots

        svg.append('g')
          .attr("clip-path", "url(#clip)")
          .selectAll("circle")
          .data(vis.data)
          .enter()
          .append("circle")
          .attr("class", "circleClass")
          .attr("cx", function (d) { return vis.x(d[vis.xVariable]); })
          .attr("cy", function (d) { if (vis.yVariable != '') { return vis.y(d[vis.yVariable]) } else { return vis.y(0) }; })
          .attr("r", 5)
          .style("stroke", "grey")
          .style("stroke-width", 0.5)
          .attr("fill", function (d) { d["currentDotColor"] = d[vis.dotColor]; return d[vis.dotColor]; })
          // click on a dot disappears/reappears
          .on("click", (function (d) { return function (d) { dotclick2axis(d); } })())
          .on("mousemove", function (d) {

            tooltip
              .attr("class", tooltipClass)
              .style("left", d3.event.pageX + 20 + "px")
              .style("top", d3.event.pageY - 50 + "px")
              .style("display", "inline-block")
              .html(function () {
                var tableBodyCells = html2axis(d, data1, keys_used);
                return "<table><tbody>" + tableBodyCells + "</tbody></table>";
              });
          })
          .on("mouseover", function () { scattooltip = d3.select('body').append("div").attr("class", "scattoolTip"); tooltip = d3.select('body').append("div").attr("class", "toolTip"); })
          .on("mouseout", function (d) { tooltip.style("display", "none"); d3.selectAll(".toolTip").remove(); })
          //when right click
          // label and audit only possible when on tab Analysis
          // not possible on worklow tab
          .on("contextmenu", function (d, index) {
            if (vis.parentElement == "analysisGaussParam") {
              // not too much points
              if (data1.length < vis.nbDotsMax) {
                d3.event.preventDefault();
                vis.xPosition = d3.mouse(this)[0] + 50;
                vis.yPosition = d3.mouse(this)[1] - 50;
                console.log(d3.mouse(this))
                contextMenu2axis(d, data1);
              }
            }
          })

        svg.append("text").attr("transform",
          "translate(" + (width / 2) + " ," +
          (height + vis.margin.top + 20) + ")")
          .style("text-anchor", "middle").text(vis.xVariable)
        // text label for the y axis
        svg.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - vis.margin.left)
          .attr("x", 0 - (height / 2))
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text(vis.yVariable);


      }
      else { // only one dimension
        // Add dots
        svg.append('g')
          .attr("clip-path", "url(#clip)")
          .selectAll("dot")
          .data(vis.data)
          .enter()
          .append("circle")
          .attr("class", "circleClass")
          .attr("cx", function (d) { return vis.x(d[vis.xVariable]); })
          .attr("cy", function (d) { return vis.y(0); })
          .attr("r", 5)
          .style("stroke", "grey")
          .style("stroke-width", 0.5)
          .attr("fill", function (d) { d["currentDotColor"] = d[vis.dotColor]; return d[vis.dotColor]; })
          // click on a dot disappears/reappears
          .on("click", (function (d) { return function (d) { dotclick1axis(d); } })())

          .on("mousemove", function (d) {

            tooltip
              .attr("class", tooltipClass)
              //   .attr("transform", "translate(" + (vis.x1(d.name)   + 55  ) + ",0)")    
              .style("left", d3.event.pageX + 20 + "px")
              .style("top", d3.event.pageY - 50 + "px")
              .style("display", "inline-block")
              .html(function () {
                var tableBodyCells = html1axis(d, data1, keys_used);
                return "<table><tbody>" + tableBodyCells + "</tbody></table>";
              });
          })
          .on("mouseover", function () { scattooltip = d3.select('body').append("div").attr("class", "scattoolTip"); tooltip = d3.select('body').append("div").attr("class", "toolTip"); })
          .on("mouseout", function (d) { tooltip.style("display", "none"); d3.selectAll(".toolTip").remove(); })
          // when right click
          // label and audit only possible when on tab Analysis
          // not possible on worklow tab
          .on("contextmenu", function (d, index) {
            if (vis.parentElement == "analysisGaussParam") {
              // not too much points
              if (data1.length < vis.nbDotsMax) {
                d3.event.preventDefault();
                vis.xPosition = d3.mouse(this)[0] + 50;
                vis.yPosition = d3.mouse(this)[1] - 50;
                console.log(d3.mouse(this))
                contextMenu1axis(d, data1);
              }
            }
          })


        svg.append("text").attr("transform",
          "translate(" + (width / 2) + " ," +
          (height + vis.margin.top + 30) + ")")
          .style("text-anchor", "middle").text(vis.xVariable)
      }

    }
    /*
        function getZindex(d, vis) {
          let zindex = 0; 
          // colorArray = [{ 's': 'yellow', 'k': 'lightgreen', 'sk': 'brown', 'g': 'orange', 'gs': 'blue', 'gk': 'green', 'gsk': 'red', 'n': 'black' }];
           if (d[vis.dotColor] === 'yellow') {
             zindex = 7;
           }
           else if (d[vis.dotColor] === 'lightgreen') {
             zindex = 6;
           }
           else if (d[vis.dotColor] === 'brown') {
             zindex = 5;
           }
           else if (d[vis.dotColor] === 'orange') {
             zindex = 4;
           }
           else if (d[vis.dotColor] === 'blue') {
             zindex = 3;
           }
           else if (d[vis.dotColor] === 'green') {
             zindex = 2;
           }
           else if (d[vis.dotColor] === 'red') {
             zindex = 1;
           }
           return zindex;
        }
        */

    function dotclick1axis(d) {

      scattooltip.style("display", "none"); d3.selectAll(".scattoolTip").remove();
      vis.scatLabelClose();
      d["currentDotColor"] = d["currentDotColor"] === d[vis.dotColor] ? "transparent" : d[vis.dotColor];
      let color_coor = d["currentDotColor"];
      let x_coor = d[vis.xVariable];

      d3.selectAll('circle')
        .filter(function (dot) {

          if (dot !== undefined) {
            if (dot[vis.xVariable] === x_coor) {
              dot["currentDotColor"] = color_coor;
              return dot;
            }
          }
        })
        // .transition()
        .attr("fill", function (d) { if (color_coor === "transparent") { return "transparent" } else { return color_coor } })
        .style("stroke", function (d) { if (color_coor === "transparent") { return "transparent" } else { return "grey" } })
    }

    function dotclick2axis(d) {
      scattooltip.style("display", "none"); d3.selectAll(".scattoolTip").remove();
      vis.scatLabelClose();
      d["currentDotColor"] = d["currentDotColor"] == d[vis.dotColor] ? "transparent" : d[vis.dotColor];
      let color_coor = d["currentDotColor"];
      let x_coor = d[vis.xVariable];
      let y_coor = d[vis.yVariable];

      d3.selectAll('circle')
        .filter(function (dot) {

          if (dot !== undefined) {
            if ((dot[vis.xVariable] === x_coor) && (dot[vis.yVariable] === y_coor)) {
              dot["currentDotColor"] = color_coor;
              return dot;
            }
          }
        })
        //.transition()
        .attr("fill", color_coor === "transparent" ? "transparent" : color_coor)
        .style("stroke", color_coor === "transparent" ? "transparent" : "grey")
    }

    function html1axis(d, data1, keys_used) {
      var tableBodyCells = "";
      //var keys = Object.keys(vis.data[0]);
      //for (var i in vis.data) {
      /*
    let lenData = vis.data.length

    for (var i = 0; i < lenData; i++) {

      if (vis.data[i][vis.xVariable] === d[vis.xVariable]) {
        var color = vis.data[i].currentDotColor;
        tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + vis.data[i][vis.fieldID] + "</th></tr>";

        for (var k in keys) {
          if ((keys[k] != 'rank_anomaly') && (keys[k] != 'color') && (keys[k] != 'currentDotColor') && (keys[k] != vis.fieldID)) {
            tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys[k] + "</td><td >" + vis.data[i][keys[k]] + "</td></tr>";

          }
        }
      }
      else {
        if (vis.data[i][vis.xVariable] > d[vis.xVariable]) {
          break;
        }
      }
    }
    */

      let lenData = data1.length;
      for (var i = 0; i < lenData; i++) {
        if ((data1[i][vis.xVariable] === d[vis.xVariable]) && (data1[i][vis.yVariable] === d[vis.yVariable])) {
          var color = data1[i].currentDotColor;
          tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + data1[i][vis.fieldID] + "</th></tr>";
          for (var k in keys_used) {
            tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys_used[k] + "</td><td >" + data1[i][keys_used[k]] + "</td></tr>";
          }
        }
        else {// because vis.data (data1) is sorted
          if (data1[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }
      /*
      let lenData = vis.data.length;
      for (var i = 0; i < lenData; i++) {
        if ((vis.data[i][vis.xVariable] === d[vis.xVariable]) && (vis.data[i][vis.yVariable] === d[vis.yVariable])) {
          var color = vis.data[i].currentDotColor;
          tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + vis.data[i][vis.fieldID] + "</th></tr>";
          for (var k in keys_used) {
              tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys[k] + "</td><td >" + vis.data[i][keys[k]] + "</td></tr>";
          }
        }
        else {// because vis.data is sorted
          if (vis.data[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }*/
      return tableBodyCells;

    }

    function html2axis(d, data1, keys_used) {
      var tableBodyCells = "";
      //var keys = Object.keys(vis.data[0]);
      //for (var i in vis.data) {

      /*
       let lenData = vis.data.length;
       for (var i = 0; i < lenData; i++) {
         if ((vis.data[i][vis.xVariable] === d[vis.xVariable]) && (vis.data[i][vis.yVariable] === d[vis.yVariable])) {
           var color = vis.data[i].currentDotColor;
           tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + vis.data[i][vis.fieldID] + "</th></tr>";
           for (var k in keys) {
             if ((keys[k] != 'rank_anomaly') && (keys[k] != 'color') && (keys[k] != 'currentDotColor') && (keys[k] != vis.fieldID)) {
               tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys[k] + "</td><td >" + vis.data[i][keys[k]] + "</td></tr>";
             }
           }
         }
         else {// because vis.data is sorted
           if (vis.data[i][vis.xVariable] > d[vis.xVariable]) {
             break;
           }
         }
       }
       */
      /* console.time('for loop N°2');
       
       let keys_used=[];
       for (var k in keys) {
         if ((keys[k] != 'rank_anomaly') && (keys[k] != 'color') && (keys[k] != 'currentDotColor') && (keys[k] != vis.fieldID)) {
           keys_used.push(keys[k]);
         }
       }
       */
      let lenData = data1.length;
      for (var i = 0; i < lenData; i++) {
        if ((data1[i][vis.xVariable] === d[vis.xVariable]) && (data1[i][vis.yVariable] === d[vis.yVariable])) {
          var color = data1[i].currentDotColor;
          tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + data1[i][vis.fieldID] + "</th></tr>";
          for (var k in keys_used) {
            tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys_used[k] + "</td><td >" + data1[i][keys_used[k]] + "</td></tr>";
          }
        }
        else {// because vis.data (data1) is sorted
          if (data1[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }
      /*
      let lenData = vis.data.length;
      for (var i = 0; i < lenData; i++) {
        if ((vis.data[i][vis.xVariable] === d[vis.xVariable]) && (vis.data[i][vis.yVariable] === d[vis.yVariable])) {
          var color = vis.data[i].currentDotColor;
          tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + vis.data[i][vis.fieldID] + "</th></tr>";
          for (var k in keys_used) {
              tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys[k] + "</td><td >" + vis.data[i][keys[k]] + "</td></tr>";
          }
        }
        else {// because vis.data is sorted
          if (vis.data[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }
      */
      // console.timeEnd('for loop N°2');
      /* if noise added in python then we must compare the gaussmeasures
      // instead of dimensions created by reduction
      let measures = [];
      for (var k in keys) {
        if ((keys[k] != 'rank_anomaly') && (keys[k] != 'color')&& (keys[k] != 'currentDotColor')&& (keys[k] != vis.fieldID)&& (keys[k] != vis.xVariable)&& (keys[k] != vis.yVariable)) {
          measures.push(keys[k]);
        }
      }
      for (var i in vis.data) {
        let found = 0;
        for (var m in measures){
          if (vis.data[i][measures[m]] === d[measures[m]]){
            found = found+1;
          }
        }
        if (found == measures.length){
         var color = vis.data[i].currentDotColor;
          tableBodyCells = tableBodyCells + "<tr><th ><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></th><th>" + vis.fieldID + "</th><th >" + vis.data[i][vis.fieldID] + "</th></tr>";
          for (var k in keys) {
            if ((keys[k] != 'rank_anomaly') && (keys[k] != 'color')&& (keys[k] != 'currentDotColor')&& (keys[k] != vis.fieldID)) {
              tableBodyCells = tableBodyCells + "<tr><td><div></div></td><td >" + keys[k]+ "</td><td >" + vis.data[i][keys[k]] + "</td></tr>";
            }
          }
        }
      }
      */
      return tableBodyCells;

    }



    function contextMenu1axis(d, data1) {

      vis.displayContextMenu = true;
      vis.contextMenuContent = [];

      var keys = Object.keys(vis.data[0]);
      /*
      let fieldID = "";
      for (var k in keys) {
        if ((keys[k] != vis.xVariable) && (keys[k] != 'rank_anomaly') && (keys[k] != 'color')) {
          fieldID = keys[k];
          break;
        }
      }
      */
      //for (var i in vis.data) {
      /*let lenData = vis.data.length
      for (var i = 0; i < lenData; i++) {

        if ((vis.data[i][vis.xVariable] === d[vis.xVariable])) {
          let newdata = {
            color: vis.data[i].currentDotColor,
            fieldID: vis.fieldID,
            fieldIDValue: vis.data[i][vis.fieldID],
            xVariableValue: vis.data[i][vis.xVariable],
            yVariableValue: 0,

          }
          vis.contextMenuContent.push(newdata);

        }
        else {
          if (vis.data[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }
      */
      let lenData = data1.length
      for (var i = 0; i < lenData; i++) {

        if ((data1[i][vis.xVariable] === d[vis.xVariable])) {
          let newdata = {
            color: data1[i].currentDotColor,
            fieldID: vis.fieldID,
            fieldIDValue: data1[i][vis.fieldID],
            xVariableValue: data1[i][vis.xVariable],
            yVariableValue: 0,

          }
          vis.contextMenuContent.push(newdata);

        }
        else {
          if (data1[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }
    }

    function contextMenu2axis(d, data1) {

      vis.displayContextMenu = true;
      vis.contextMenuContent = [];

      vis.onLabelClick.emit({ fieldIDValueArray: [] });
      var keys = Object.keys(vis.data[0]);
      /*
      let fieldID = "";
      for (var k in keys) {
        if ((keys[k] != vis.xVariable) && (keys[k] != vis.yVariable) && (keys[k] != 'rank_anomaly') && (keys[k] != 'color')) {
          fieldID = keys[k];
          break;
        }
      }
      */
      /* 07/07/2022
       for (var i in vis.data) {
         if ((vis.data[i][vis.xVariable] === d[vis.xVariable]) && (vis.data[i][vis.yVariable] === d[vis.yVariable])) {
           let newdata = {
             color: vis.data[i].currentDotColor,
             fieldID: vis.fieldID,
             fieldIDValue: vis.data[i][vis.fieldID],
             xVariableValue: vis.data[i][vis.xVariable],
             yVariableValue: vis.data[i][vis.yVariable]
 
           }
           vis.contextMenuContent.push(newdata);
         }
 
         else {
           if (vis.data[i][vis.xVariable] > d[vis.xVariable]) {
             break;
           }
         }
       }*/
      for (var i in data1) {
        if ((data1[i][vis.xVariable] === d[vis.xVariable]) && (data1[i][vis.yVariable] === d[vis.yVariable])) {
          let newdata = {
            color: data1[i].currentDotColor,
            fieldID: vis.fieldID,
            fieldIDValue: data1[i][vis.fieldID],
            xVariableValue: data1[i][vis.xVariable],
            yVariableValue: data1[i][vis.yVariable]

          }
          vis.contextMenuContent.push(newdata);
        }

        else {
          if (data1[i][vis.xVariable] > d[vis.xVariable]) {
            break;
          }
        }
      }

    }
  }// end initVis


  scatResize() {

  }
  // when click on label selected go the label selected page
  scatLabel(fieldIDValueArray) {
    console.log('label' + fieldIDValueArray);

    this.onLabelClick.emit({ fieldIDValueArray: fieldIDValueArray });
  }

  //close label/audit  box
  scatLabelClose() {
    this.displayContextMenu = false;
    this.onLabelClick.emit({ fieldIDValueArray: [] });
  }
  // when clik on audit for a record go the  audit page
  scatAudit(fieldIDValue) {
    console.log('audit' + fieldIDValue);
    this.onAuditClick.emit({ fieldIDValue: fieldIDValue });
  }

  getScatterWidth() {
    let id = document.getElementById("scatterPlot");
    let width = 300
    if (id && id != null) {
      width = id.getBoundingClientRect().width - 50;
    }
    return width;
  }

  getScatterHeight() {
    let id = document.getElementById("scatterPlot");
    let height = 500;
    if (id && id != null) {
      height = id.getBoundingClientRect().height - 150;
    }
    return height;
  }
}

@NgModule({
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule
  ],
  exports: [RouterModule, CardabelScatterPlotComponent, ReactiveFormsModule, FormsModule],
  declarations: [CardabelScatterPlotComponent],
  providers: [

  ]
})
export class CardabelScatterPlotModule {

}

