import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { WorkflowNameService } from './workflow-name.service';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { ConfirmationService } from 'primeng/api';


@Injectable()
export class WorkflowMatchingStepsService {
  constructor(private appService: AppService,
    private httpClient: HttpClient,
    private confirmationService: ConfirmationService,
    private workflowService: WorkflowNameService) { }

  url = this.appService.getUrl();

 // resultsScenarioChange = new Subject<any[]>();
  resultsTrainChange = new Subject<any[]>();

  resultsTestChange = new Subject<any[]>();
  modelsTestChange = new Subject<any[]>();

  // this is for the status of an execution
  executionStatusChange = new Subject<any>();

  resultsStatChange = new Subject<any[]>();

  //Sets the segregation, signed_measure, target, sortdatesList in the workflow/table/and returns back the workflow
  //called in workflow matching steps
  setMatchingTableFeatures1(apply_and_chain, workflow, tableName, subtableName, signed_measure,signed_measure_null_values, segregation, targetLabel, sortDatesList) {
    const url = this.url;
    const webservice = "WFMatchingTableSetFeatures1";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('segregation', segregation)
      .set('signedMeasure', signed_measure)
      .set('signedMeasureNullValues',signed_measure_null_values)
      .set('targetLabel', targetLabel)
      .set('sortDatesList', sortDatesList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  //Sets the feature attributes  and filter in the workflow/table/and returns back the workflow
  //called in workflow matching steps
  setMatchingTableFeatures2(apply_and_chain, workflow, tableName, subtableName, featureAttributes, featureFilter) {
    const url = this.url;
    const webservice = "WFMatchingTableSetFeatures2";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('featureAttributes', featureAttributes)
      .set('featureFilter', featureFilter)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  // train the model 
  applyMatchingTableTrain(workflow, tableName, subtableName, trainNature, trainDataSet, train, period, nextCardRec,
    tpThreshold, timeOut, splitDate,priorityDate, maxRecoSize, toleranceError, toleranceCcy) {
    const url = this.url;
    const webservice = "WFMatchingTableApplyTrain";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('trainNature', trainNature)
      .set('trainDataSet', trainDataSet)
      .set('trainDate', train)
      .set('trainPeriod', period)
      .set('nextCardRec', nextCardRec)
      .set('tpThreshold', tpThreshold)
      .set('timeOut', timeOut)
      .set('splitDate', splitDate)
      .set('priorityDate', priorityDate)
      .set('maxRecoSize', maxRecoSize)
      .set('toleranceError', toleranceError)
      .set('toleranceCcy', toleranceCcy)


    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {

        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          this.resultsTrainChange.next(response.tableRows);
          // this.executionStatusChange.next(response.tableRows2);
          this.executionStatusChange.next();
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  // get the results of the train by error and run
  getMatchingTableResultsMode(workflow, tableName, subtableName, mode) {
    const url = this.url;
    const webservice = "WFMatchingTableGetResultsMode";
    const completeUrl = url + webservice;

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('matchingMode', mode)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          if (mode == 'train') {
            this.resultsTrainChange.next(response.tableRows);
          }
          else {
            this.resultsTestChange.next(response.tableRows);
            /*
            if (mode == 'request') {
              this.resultsScenarioChange.next(response.tableRows);
            }
            else {
              this.resultsTestChange.next(response.tableRows);
            }
            */
          }
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin2();
      });
  }

  // get the results of the train by error and run
  getMatchingTableModels(workflow, tableName, subtableName) {
    const url = this.url;
    const webservice = "WFMatchingTableGetModels";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response);
          this.modelsTestChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  //not used
  /*
  //Sets the requests with action and filters in the workflow/table/and returns back the workflow
  //called in workflow matching steps
  setMatchingTableRequests(apply_and_chain, workflow, tableName, subtableName, requestsList) {
    const url = this.url;
    const webservice = "WFMatchingTableSetRequests";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams 
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("apply_and_chain", apply_and_chain)
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('requestsList', requestsList)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          if (apply_and_chain) {
            this.executionStatusChange.next();
          }
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  */
/*
  //Sets the priority and execute the requests in the workflow/table/and returns back the workflow
  //called in workflow matching steps
  applyMatchingTableScenario(workflow, tableName, subtableName, train, nextCardRec, tpThreshold,
    timeOut, scenarioOn, subtable, subtableType) {
    const url = this.url;
    const webservice = "WFMatchingTableApplyScenario";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams 
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('scenarioDate', train)
      .set('nextCardRec', nextCardRec)
      .set('tpThreshold', tpThreshold)
      .set('timeOut', timeOut)
      .set('scenarioOn', scenarioOn)
      .set('scenarioSubTable', subtable)
      .set('scenarioSubTableType', subtableType)
      .set('nextCardRec', nextCardRec)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {

          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          this.resultsScenarioChange.next(response.tableRows);
          this.executionStatusChange.next();
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {

          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  */
  // train the model 
  applyMatchingTableTest(workflow, tableName, subtableName, testNature, dynamicDate, testDate, testModels) {
    const url = this.url;
    const webservice = "WFMatchingTableApplyTest";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('testNature', testNature)
      .set('dynamicDate', dynamicDate)
      .set('testDate', testDate)
      .set('testModels', testModels)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);
          this.resultsTestChange.next(response.tableRows);
          this.executionStatusChange.next();
          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }

  // get the results of the train by error and run
  getMatchingTableResultsStat(workflow, tableName, subtableName, statDate) {
    const url = this.url;
    const webservice = "WFMatchingTableGetResultsStat";
    const completeUrl = url + webservice;

    this.appService.startSpin2();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('statDate', statDate)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');


    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.resultsStatChange.next(response.tableRows);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin2();
      }, (error) => {
        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin2();
      });
  }

  // set matching exception
  setMatchingTableException(workflow, tableName, subtableName, exceptions, whereClause, exceptBatch) {
    const url = this.url;
    const webservice = "WFMatchingTableSetException";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('exceptionsList', exceptions)
      .set('whereClause', whereClause)
      .set('exceptBatch', exceptBatch)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
  // set matching rule
  setMatchingTableRule(workflow, tableName, subtableName, rules, whereClause, segregation, script, errorMax, errorMaxTol, comboType) {
    const url = this.url;
    const webservice = "WFMatchingTableSetRule";
    const completeUrl = url + webservice;

    this.appService.startSpin();

    //Create new HttpParams */
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

      .set("wfName", workflow)
      .set('segAttrValue', tableName)
      .set('subTableName', subtableName)
      .set('rulesList', rules)
      .set('whereClause', whereClause)
      .set('segregation', segregation)
      .set('script', script)
      .set('errorMax',errorMax)
      .set('errorMaxTol',errorMaxTol)
      .set('comboType',comboType)

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
      .pipe(
        catchError(this.appService.handleError)
      )
      .subscribe((response: any) => {
        console.log(response);

        //response = response.replace(/[()]/g, '');
        response = response.substring(1, response.length - 1);
        response = JSON.parse(response);
        if (response.statusCode > -1) {
          console.log(response)
          this.appService.showMessage("Success", response.statusText);

          this.workflowService.updateWorkflowTableSubTable(tableName, response.wfStructure, subtableName);
        }
        else {
          this.appService.showMessage("Error", response.statusText);
        }
        this.appService.stopSpin();
      }, (error) => {

        this.appService.showMessage("Error", error.statusText);
        this.appService.stopSpin();
      });
  }
}