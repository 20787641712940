import { V } from "@angular/cdk/keycodes";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "src/app/app.service";
import { AuthService } from "src/app/auth/auth-service";
import { CardabelVirtualFilterService } from "src/app/shared/cardabel-virtual-datatable/services/cardabel-virtual-filter.service";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { MatchingService } from "./matching.service";

@Injectable()
export class MatchingGridService {

    multiSelect: any = {};
    matchingCheckedRow: any = {};
    balance: any = {};
    variance: any = {};
    cardabel_rec: any = {};

    ongoingModification: any = {};


    constructor(private appService: AppService, private authService: AuthService, private httpClient: HttpClient, private sharedInitService: SharedInitializationService, private workflowNameService: WorkflowNameService, private matchingService: MatchingService, private filterService: CardabelVirtualFilterService) {

    }

    setMultiSelect(tab, value) {
        this.multiSelect[tab] = value;
        this.matchingService.multiSelectChange[tab].next(this.multiSelect[tab])
    }

    initMatchingCheckedRow(tab, data) {
        this.matchingCheckedRow[tab] = {};
        for (var k in data) {
            this.matchingCheckedRow[tab]["item" + data[k].matchingGuiIndex] = false;
        }

        return this.matchingCheckedRow[tab];
    }


    setMatchingCheckedRow(tab, rows, data, matchingCheckedRow, filters) {
        this.matchingCheckedRow[tab] = matchingCheckedRow;
        console.log(filters);
        data = this.filterService.returnFilters(data,filters);
        
        if ((rows.length === 0) && (!this.multiSelect[tab])) {
            let matchingCheckedRowKeys = Object.keys(this.matchingCheckedRow[tab]);

            for (var t in matchingCheckedRowKeys) {
                this.matchingCheckedRow[tab][matchingCheckedRowKeys[t]] = false;
            }
            this.matchingService.matchingCheckedRowChange[tab].next(this.matchingCheckedRow[tab])
        }
        else if ((rows.length === 1) && (!this.multiSelect[tab])) {
            let cardabel_rec = rows[0].cardabel_rec;
            console.log(cardabel_rec);

            let reconciled_rows = data.filter(item => item.cardabel_rec === cardabel_rec);
            let matchingCheckedRowKeys = Object.keys(this.matchingCheckedRow[tab]);

            for (var t in matchingCheckedRowKeys) {
                this.matchingCheckedRow[tab][matchingCheckedRowKeys[t]] = false;
            }
            for (var k in reconciled_rows) {
                this.matchingCheckedRow[tab]["item" + reconciled_rows[k].matchingGuiIndex] = true;
            }
            this.matchingService.matchingCheckedRowChange[tab].next(this.matchingCheckedRow[tab])

        }
        else {

            if (Object.values(this.matchingCheckedRow[tab]).indexOf(true) === -1) {
                this.setMultiSelect(tab, false);
                this.setOngoingModification(tab, false, undefined);
            }

            this.matchingService.matchingCheckedRowChange[tab].next(this.matchingCheckedRow[tab])
        }

    }

    getCheckedRows(tab) {
        let checkedRows = [];
        let matchingCheckedRows = Object.keys(this.matchingCheckedRow[tab]);
        let matchingCheckedRowsIndexes = [];
        for (var k in matchingCheckedRows) {
            if (this.matchingCheckedRow[tab][matchingCheckedRows[k]]) {
                let matchingGuiIndex = +matchingCheckedRows[k].replace("item", "");
                matchingCheckedRowsIndexes.push(matchingGuiIndex)
            }

        }

        for (var i in this.matchingService.gridData[tab]) {
            if (matchingCheckedRowsIndexes.indexOf(this.matchingService.gridData[tab][i].matchingGuiIndex) > -1) {
                checkedRows.push(this.matchingService.gridData[tab][i])
            }
            
        }
        return checkedRows;
    }

    getUpdatedCheckedRows(tab, checkedRow, gridData) {
        let matchingCheckedRows = Object.keys(this.matchingCheckedRow[tab]);
        let matchingCheckedRowsIndexes = [];
        for (var k in matchingCheckedRows) {
            if (this.matchingCheckedRow[tab][matchingCheckedRows[k]]) {
                let matchingGuiIndex = +matchingCheckedRows[k].replace("item", "");
                matchingCheckedRowsIndexes.push(matchingGuiIndex)
            }

        }
        for (var i in gridData) {
            if (matchingCheckedRowsIndexes.indexOf(gridData[i].matchingGuiIndex) > -1) {
                checkedRow["item" + gridData[i].guiIndex] = true;
            }
            else {
                checkedRow["item" + gridData[i].guiIndex] = false;
            }
        }

        return checkedRow;
    }

    initBalance(tab, items) {
        this.balance[tab] = {};
        for (var i in items) {
            this.balance[tab][items[i]] = 0;
        }
    }

    setBalance(tab, item, balance) {
        this.balance[tab][item] = balance;
    }
    getVariance(tab) {

        let variance = 0;
        let keys = Object.keys(this.balance[tab]);
        for (var k in keys) {
            if (+k === 0) {
                variance = this.balance[tab][keys[k]]
            }
            else {
                variance = variance + this.balance[tab][keys[k]]
            }

        }

        console.log(variance)
        this.matchingService.varianceChange[tab].next(variance)
    }

    initVariance(tab, data, matchingSignedMeasure, distinctSegregationValues, gridSegregationAttribute) {
        this.variance[tab] = {};

        let distinctValues = this.getDistinctItems("cardabel_rec", data);

        for (var k in distinctValues) {
            this.variance[tab][distinctValues[k]] = {};

            for (var n in distinctSegregationValues) {
                this.variance[tab][distinctValues[k]].variance   = data.map(c => c.cardabel_rec === distinctValues[k] ? c[matchingSignedMeasure] : 0)
                .reduce((sum, current) => sum + current);
            }

            
            if (Math.abs(this.variance[tab][distinctValues[k]].variance) < this.matchingService.varianceThreshold[tab]) {
                this.variance[tab][distinctValues[k]].reconciled = true;
            }

            this.variance[tab][distinctValues[k]].variance = this.variance[tab][distinctValues[k]].variance.toFixed(2)
        }

        console.log(this.variance[tab])

    }

    //this is to give distinct values in excel filters
    getDistinctItems(headerName, gridData) {

        let distinctValues = [];

        //filterValuesArray = [...new Set(filterValuesArray.map(obj => obj.age)) ];
        distinctValues = gridData
            .map(p => p[headerName])


        distinctValues = distinctValues
            .filter((headerName, index, arr) => arr.indexOf(headerName) == index)
            .sort()

        return distinctValues;

    }

    returnReconciled(tab, item) {
        //if (this.variance[tab][item['cardabel_rec']]) {
        if (this.variance[tab]) {
            if (this.variance[tab][item['cardabel_rec']]) {
                return this.variance[tab][item['cardabel_rec']].reconciled;
            }
            else {
                return false;
            }

        }
        else {
            return false;
        }

        // }
        // return false;
    }

    returnVariance(tab, item) {
        // if (this.variance[tab][item['cardabel_rec']]) {

        return this.variance[tab][item['cardabel_rec']].variance;
        // }
        // return 0;
    }

    setOngoingModification(tab, value, cardabel_rec) {
        this.cardabel_rec[tab] = cardabel_rec;
        this.ongoingModification[tab] = value;
        this.matchingService.ongoingModificationChange[tab].next(value);
    }

    getOngoingModification(tab) {
        let ongoingModification = false;
        if (this.ongoingModification[tab]) {
            ongoingModification = true;
        }
        return ongoingModification;
    }

    saveReconciliation(tab, cardabel_rec, reconciliationComment) {
        let checkedValues = Object.values(this.matchingCheckedRow[tab.name]);
        let checkedIndexes = [];
        for (var k in checkedValues) {
            if (checkedValues[k] === true) {
                let index = this.matchingService.gridData[tab.name].findIndex(element => +element.matchingGuiIndex === +k)
                checkedIndexes.push(index);
            }
        }

        /* if (cardabel_rec === -1 ) {
             let cardabel_recs = this.getDistinctItems("cardabel_rec", this.matchingService.gridData[tab])
            
             let max_cardabel_rec =  Math.max.apply(Math, cardabel_recs);
 
             cardabel_rec = max_cardabel_rec+1;
             console.log(cardabel_recs)
             console.log(max_cardabel_rec)
             console.log(cardabel_rec)
         } */

        let indexes = this.matchingService.gridData[tab.name].filter(element => element["cardabel_rec"] === cardabel_rec);
        let cardabel_indexes = indexes.map(element => element.matchingGuiIndex);
        console.log(cardabel_indexes);

        let removedTransactions = [];
        let transactions = [];
        let matchingRecords = [];

        for (var m in cardabel_indexes) {
            if (checkedIndexes.indexOf(cardabel_indexes[m]) === -1) {
                /* let newdata = {
                     id: this.matchingService.gridData[tab.name][cardabel_indexes[+m]][this.matchingService.fieldID[tab.name]]
                 }
 
                 removedTransactions.push(newdata);
                 */
                removedTransactions.push(this.matchingService.gridData[tab.name][cardabel_indexes[+m]][this.matchingService.fieldID[tab.name]]);
                //this.matchingService.gridData[tab.name][cardabel_indexes[+m]]["cardabel_rec"] = -1;
            }

        }

        for (var i in checkedIndexes) {
            transactions.push(this.matchingService.gridData[tab.name][checkedIndexes[+i]][this.matchingService.fieldID[tab.name]])
        }

        if (removedTransactions.length != 0) {
            let param = "delete";
            // this.matchingService.updateCardabelRec(tab, -1 , "delete", removedTransactions);
            let newdata = { param: param, cardabel_rec: cardabel_rec, transactions: removedTransactions };
            matchingRecords.push(newdata);
        }

        if (transactions.length != 0) {
            let param = "update";

            if (cardabel_rec === -1) {
                param = "create"
            }

            if (cardabel_rec === -2) {
                param = "delete";
                cardabel_rec = -1;
            }

            let newdata = { param: param, cardabel_rec: cardabel_rec, transactions: transactions };
            matchingRecords.push(newdata);
        }
        // this.matchingService.updateCardabelRec(tab, -1 , param, transactions);
        if (matchingRecords.length != 0) {
            this.matchingService.updateCardabelRec(tab, matchingRecords, checkedIndexes, reconciliationComment);
        }

    }

    updateLocalFilters(filters, tab) {
        this.matchingService.localFiltersChange[tab].next(filters);
    }



}