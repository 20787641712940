<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
      <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>

<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12 justify-content-center">
    <div class="col-xs-10 offset-xs-1 col-sm-6 offset-sm-3 col-md-4 offset-md-4">
      <h3 class="sectionTitle">True status types</h3>
    </div>

  </div>
</div>
<div class="row">
  <div class="col-md-10 col-xs-12 col-sm-12 offset-md-1">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="btn-group applyButtonGroup">
            <button class="btn btn-primary btn-sm" (click)="applyTrueStatusType()" [disabled]="">Save changes</button>
          </div>
        </div>
      </div>
      <div class="row margin-top-10">
        <div class="col-xs-12 col-md-8 col-sm-12 offset-md-2 justify-content-center">
         <app-settings-table-body #trueStatusTypesTable [tableData]="trueStatusData" [groupField1]="groupField1" [groupField2]="groupField2" [title1]="'Prefix'" [title2]="'True Status Types'"></app-settings-table-body>
        </div>
      </div>
    </div>
  </div>
</div>
