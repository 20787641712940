import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppService } from "src/app/app.service";
import { CustomHttpParamEncoder } from "src/app/shared/custom-http-param-encoder";
import { SharedInitializationService } from "src/app/shared/shared-common-initialization.service";
import { SharedGeneralFunctionsService } from "src/app/shared/shared-general-functions.service";
import { WorkflowNameService } from "src/app/workflow/services/workflow-name.service";
import { KernelErrors } from "../../models/kernel/kernel-error.model";

@Injectable()
export class AnalysisKernelService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
        private workflowNameService: WorkflowNameService,
        private sharedService: SharedInitializationService,
        private sharedFunctionService: SharedGeneralFunctionsService) {
    }

    url = this.appService.getUrl();


    //anomaly count by type (rule, simple, combined)
    errorsDetected: {} = {};
    errorsDetectedChange: { [index: string]: Subject<KernelErrors[]> } = {};


    //this is to set up the kernel tab changes 
    initTabChanges(tabs) {
        for (var i in tabs) {

            this.errorsDetectedChange[tabs[i].name] = new Subject<any[]>();
            this.errorsDetected[tabs[i].name] = [];

        }
    }


    //Returns the number of anomalies and correct records for the kernelian workflows
    //called in analysis menu 
    // getKernelStatsCount(workflow,source, segAttribute, segAttributeValue, proMode )  {
    getKernelStatsCount(workflow, segAttributeValue, tab) {
        const url = this.url;
        const webservice = "StatKernelCountTables";
        const completeUrl = url + webservice;

        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("wfName", workflow)
            .set("segAttrValue", segAttributeValue)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                console.log(response)
                if (response.statusCode > -1) {
                    console.log(response.tableRows);
                    this.errorsDetected[tab] = response.tableRows;
                    this.errorsDetectedChange[tab].next([...this.errorsDetected[tab]]);
                }
                else {
                    this.appService.showMessage('Error', response.statusText);
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });
    }

    //get the number of records for kernel combinations
    //called in 
    getKernelCombinationNbRec(tab) {
        console.log("errorsDetected");
        let errorFile = { "Rules": 0, "Missing": 0, "Duplicates": 0, "Correct": 0, "AllAnomaly": 0 };
        for (var i in this.errorsDetected[tab]) {
            let name = this.errorsDetected[tab][i]
            switch (Object.keys(this.errorsDetected[tab][i])[0]) {
                case 'Rules':
                    errorFile.Rules = this.errorsDetected[tab][i]["Rules"];
                    break;
                case 'Missing':
                    errorFile.Missing = this.errorsDetected[tab][i]["Missing"];
                    break;
                case 'Duplicates':
                    errorFile.Duplicates = this.errorsDetected[tab][i]["Duplicates"];
                    break;
                case 'Correct':
                    errorFile.Correct = this.errorsDetected[tab][i]["Correct"];
                    break;
                case 'AllAnomaly':
                    errorFile.AllAnomaly = this.errorsDetected[tab][i]["AllAnomaly"];
                    break;
                case 'FielID':
                    //fieldID =this.errorsDetected[i]["FielID"];// from apservices tablestat
                    break;
                default:
                    break;
            }
        }
        var nbRec = 0;
        if (this.errorsDetected[tab] !== undefined) {
            nbRec = errorFile.AllAnomaly + errorFile.Correct - errorFile.Missing - errorFile.Rules;
        }
        return nbRec;
    }

}