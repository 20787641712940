import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MarketDataSourceService } from './market-data-source.service';
import { Subscription } from 'rxjs';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Component({
  selector: 'app-market-data-source',
  templateUrl: './market-data-source.component.html',
  styleUrls: ['./market-data-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketDataSourceComponent implements OnInit, OnDestroy {

  optionsMarketDataSource: SelectItem[];
  selectedOptionSource: any;
  visibleHelpSidebar;

  subscriptionMarketDataSource = new Subscription();


  constructor(private marketdatasourceservice: MarketDataSourceService, private sharedService: SharedInitializationService) {

    this.optionsMarketDataSource = [
      { label: 'File', value: 'File', icon: 'pi-file' },
      { label: 'Web', value: 'Web', icon: 'pi-globe' },
      { label: 'None', value: 'None', icon: 'pi-ban' }
    ];

  }

  ngOnInit() {
    console.log(this.optionsMarketDataSource)
    this.marketdatasourceservice.getMarketDataSource();

    this.subscriptionMarketDataSource = this.marketdatasourceservice.marketDataSourceChange
      .subscribe(
        (selectedOptionSource: string) => {
          for (var i in this.optionsMarketDataSource) {
            if (this.optionsMarketDataSource[i].label === selectedOptionSource) {
              this.selectedOptionSource = this.optionsMarketDataSource[i];
            }
          }
        
        }
      );
  }

  onSaveChanges() {
    this.marketdatasourceservice.setMarketDataSource(this.selectedOptionSource.label);
  }

  //this allows to get the help documentation for settings market data source
  getHelpDocumentation() {
    let parameters = ["admin", "settings", "marketdatasource"];
    this.sharedService.showHelpDocumentation("Admin Settings Market Data Source");

    this.sharedService.getHelpDocumentation(parameters)
  }
  //this is to destroy subscriptions when component is destroyed
  ngOnDestroy() {
    this.subscriptionMarketDataSource.unsubscribe();
  }
}
