import { Injectable, ViewRef } from '@angular/core';

@Injectable()
export class DashboardChartDimensionsService {
    constructor() { }

    /* COMPUTING TAB DASHBOARD FILTER POSITIONS */
    computeFilterWidth(vis) {
       
        let nameId = "dashboard-main-subtab-row" + vis.mainTab;
        let id = document.getElementById(nameId);
    
        setTimeout(() => {
          if (id != null) {
            vis.leftWidth = Math.max(id.getBoundingClientRect().width + 15, 200);
            vis.filterWidth = vis.windowWidth - vis.leftWidth - 50 - 10;
          }
        }, 10)
      }
    
    /* COMPUTING CHART HEIGHTS & WIDTHS */

    resetColumnDimensions(columns, vis) {
        //this.maxDragPosition = 0;
        for (var i in columns) {

            if (columns[i].margin === undefined) {
                columns[i].margin = { top: 10, bottom: 20, right: 20, left: 70, changed: false };
            }
            if (!columns[i].margin.changed) {
                columns[i].margin.changed = false;
            }
            if (columns[i].xAxis === undefined) {
                columns[i].xAxis = { minimum: undefined, maximum: undefined, changed: false }
            }
            if (columns[i].yAxis === undefined) {
                columns[i].yAxis = { minimum: undefined, maximum: undefined, changed: false }
            }
            if (columns[i].y1Axis === undefined) {
                columns[i].y1Axis = { minimum: undefined, maximum: undefined, changed: false }
            }

            if (columns[i].displayOther === undefined) {
                columns[i].displayOther = true;
            }
            columns[i].dragPosition.bx = columns[i].dragPosition.x + columns[i].width;
            columns[i].dragPosition.by = columns[i].dragPosition.y + columns[i].height;

            columns[i].topPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.y));
            columns[i].leftPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.x));
            columns[i].bottomPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.by));
            columns[i].rightPosition = JSON.parse(JSON.stringify(columns[i].dragPosition.bx));

            vis.dashboardChartDimensionsService.setBottomPosition(columns[i].bottomPosition + 90 / vis.windowHeight, vis)

            if (columns[i].aggMethod === '') {
                columns[i].aggMethod = "count";
            }
            if (columns[i].aggMethodCombo === '' || !columns[i].aggMethodCombo) {
                columns[i].aggMethodCombo = "count";
            }
            if (!columns[i].datePeriodFixedOption || columns[i].datePeriodFixedOption.name === '') {
                columns[i].datePeriodFixedOption = {
                    name:"Period"
                }
            }
        }

        return columns;
    }

    getTopLeftPositions(vis) {
        let maxTopPosition = 50 / vis.windowHeight;
        let newTopPosition = 50 / vis.windowHeight;
        let newLeftPosition = 33 / vis.windowWidth;

        let goToLine = false;
        let goToLineIndex = 0;
        let leftPosition = 33 / vis.windowWidth;
        let number = vis.columns.length - 1;



        for (var i in vis.columns) {
            if (vis.columns[i].topPosition + vis.columns[i].height >= maxTopPosition) {
                if (vis.columns[i].topPosition > maxTopPosition) {
                    maxTopPosition = vis.columns[i].topPosition;
                }


                leftPosition = vis.columns[i].leftPosition + vis.columns[i].width + 350 / vis.windowWidth;

                if (leftPosition > 1) {
                    goToLineIndex = +i;
                    goToLine = true;
                    newTopPosition = maxTopPosition + vis.columns[i].height + 33 / vis.windowHeight;
                    newLeftPosition = 33 / vis.windowWidth;
                }
                else {
                    newLeftPosition = vis.columns[i].leftPosition + vis.columns[i].width + 33 / vis.windowWidth;
                }

                let bottomPosition = newTopPosition + 390 / vis.windowHeight;
                this.setBottomPosition(bottomPosition, vis)

            }
        }
        return [newTopPosition, newLeftPosition]
    }

    setBottomPosition(bottomPosition, vis) {
        vis.maxDragPosition = Math.max(vis.maxDragPosition, bottomPosition * vis.windowHeight + 10);
    }

    changeColumnDimensions(vis, param, event) {

        let divParam = "windowHeight";

        if (param === "marginLeft") {
            if (event.target.valueAsNumber < 10) {
                event.target.valueAsNumber = 10;
            }
            vis.columns[vis.index].margin = { top: 10, bottom: 20, right: 20, left: event.target.valueAsNumber, changed: true };
        }
        else if (event.target.valueAsNumber > 0) {
            if (param === "width" || param === "leftPosition") {
                divParam = "windowWidth"
            }
            if (param === "width" || param === "height" || isNaN(event.target.valueAsNumber)) {
                if (event.target.valueAsNumber < 150) {
                    event.target.valueAsNumber = 150;
                }
            }
            vis.columns[vis.index][param] = event.target.valueAsNumber / vis[divParam];

            if (param === "leftPosition") {
                vis.columns[vis.index].dragPosition.x = event.target.valueAsNumber / vis[divParam];
                vis.columns[vis.index].dragPosition.bx = (event.target.valueAsNumber + vis.columns[vis.index].width) / vis[divParam];
            }
            if (param === "topPosition") {
                vis.columns[vis.index].dragPosition.y = event.target.valueAsNumber / vis[divParam];
                vis.columns[vis.index].dragPosition.by = (event.target.valueAsNumber + vis.columns[vis.index].height) / vis[divParam];
            }
        }
        else {
            vis.columns[vis.index][param] =vis.columns[vis.index][param];
        }

        vis.dashboardService.setChartChange(vis.mainTab, vis.tabAlias, vis.columns, vis.index, vis.index);

    }

    /*TEMPLATES*/

    //this allows to change the templates
    changeTemplate(name, vis) {

        let containerWidth = vis.dashContainer.nativeElement.clientWidth - 50;
        let containerHeight = window.innerHeight - 160;

        vis.columns = [];

        let templates = [
            {
                name: "template1",
                dimensions: [
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 50) / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 50) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    }
                ]
            },
            {
                name: "template2",
                dimensions: [
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight + 20) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 50) / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 50) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    }
                ]
            }
            ,
            {
                name: "template3",
                dimensions: [
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    },
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth / 2) / vis.windowWidth,
                        height: (containerHeight + 20) / vis.windowHeight,
                        xValue: (containerWidth / 2 + 50) / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    }
                ]
            }
            ,
            {
                name: "template4",
                dimensions: [
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    },
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 22) / 3 + 52) / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    }
                    ,
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 22) / 3 * 2 + 74) / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth + 21) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    }

                ]
            },
            {
                name: "template5",
                dimensions: [
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    },
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 22) / 3 + 52) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    }
                    ,
                    {
                        width: ((containerWidth - 22) / 3) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: ((containerWidth - 22) / 3 * 2 + 74) / vis.windowWidth,
                        yValue: (containerHeight / 2 + 65) / vis.windowHeight
                    }
                    ,
                    {
                        width: (containerWidth + 21) / vis.windowWidth,
                        height: (containerHeight / 2) / vis.windowHeight,
                        xValue: 33 / vis.windowWidth,
                        yValue: 45 / vis.windowHeight
                    }

                ]
            }
        ]

        for (var i in templates) {
            if (templates[i].name === name) {
                for (var j in templates[i].dimensions) {
                    let newdata = {
                        dimension: '',
                        xHeader: '',
                        yHeader: '',
                        type: '',
                        top: 3,
                        aggMethod: 'count',
                        aggregatedDimensionMethod: '',
                        numericHeader: '',
                        numericDecimal: 0,
                        dateHeader: '',
                        dateBucketing: { name: '' },
                        dateFormat: { name: 'DD-MM-YYYY' },
                        datePeriod: { name: "Days" },
                        datePeriodValue: 20,
                        datePeriodFixedDate: new Date(),
                        displayOther: true,
                        activeResize: false,
                        width: templates[i].dimensions[j].width,
                        height: templates[i].dimensions[j].height,
                        dragPosition: { x: templates[i].dimensions[j].xValue, y: templates[i].dimensions[j].yValue, bx: templates[i].dimensions[j].xValue + templates[i].dimensions[j].width, by: templates[i].dimensions[j].yValue + templates[i].dimensions[j].height },
                        topPosition: templates[i].dimensions[j].yValue,
                        leftPosition: templates[i].dimensions[j].xValue,
                        colorScheme: ['#0daaba', '#0C6D98', '#0788AF', '#16A5C2'],
                        commentBox: "",
                        xAxisLabel: "",
                        yAxisLabel: "",
                        title: "",
                        tooltip: "",
                        margin: { top: 10, bottom: 20, right: 20, left: 70, changed: false },
                        xAxis: { minimum: undefined, maximum: undefined, changed: false },
                        yAxis: { minimum: undefined, maximum: undefined, changed: false },
                        yAxis1: { minimum: undefined, maximum: undefined, changed: false },
                    }

                    vis.columns.push(newdata)
                }

            }
        }

        vis.dashboardService.initChartChanges(vis.mainTab, vis.tabAlias, vis.columns);
    }

    /* DRAGGING */

    //return dragged positions
    getDraggedPositionsAndDragArray(i, vis) {

        vis.draggedPositions = {
            x: [],
            y: [],
            deviations: []
        }

        for (var k in vis.columns) {
            if (k.toString() != i.toString()) {
                vis.draggedPositions.x.push(Math.ceil(vis.columns[k].dragPosition.x * vis.windowWidth));
                vis.draggedPositions.x.push(Math.ceil(vis.columns[k].dragPosition.bx * vis.windowWidth));
                vis.draggedPositions.y.push(Math.ceil(vis.columns[k].dragPosition.y * vis.windowHeight));
                vis.draggedPositions.y.push(Math.ceil(vis.columns[k].dragPosition.by * vis.windowHeight));
            }

        }

        vis.dragArray = [{ name: "draggedX", param: 'x', transformIndex: 0, windowValue: vis.windowWidth }, { name: "draggedBottomX", param: 'x', transformIndex: 0, windowValue: vis.windowWidth }, { name: "draggedY", param: 'y', transformIndex: 1, windowValue: vis.windowHeight }, { name: "draggedBottomY", param: 'y', transformIndex: 1, windowValue: vis.windowHeight }]

    }

    setDraggedLinePositions(transform, i, vis) {
        for (var k in vis.dragArray) {
            vis[vis.dragArray[k].name] = false;
            let bottomValue = 0;
            if (vis.dragArray[k].name === "draggedBottomX") {
                bottomValue = vis.columns[i].width;
            }
            else if (vis.dragArray[k].name === "draggedBottomY") {
                bottomValue = vis.columns[i].height;
              
            }

            if (vis.draggedPositions[vis.dragArray[k].param].indexOf(Math.ceil(vis.columns[i].dragPosition[vis.dragArray[k].param] * vis.dragArray[k].windowValue + bottomValue * vis.dragArray[k].windowValue + transform[vis.dragArray[k].transformIndex])) > -1) {
                vis[vis.dragArray[k].name] = true;
                let position = vis.dragArray[k].name + 'Position';

                vis[position] = vis.columns[i].dragPosition[vis.dragArray[k].param] + bottomValue + transform[vis.dragArray[k].transformIndex] / vis.dragArray[k].windowValue
                if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                    vis.cd.detectChanges();
                }
            }
        }
    }

    draggedStarted(i, vis) {
        vis.dragging = true;
        vis.draggingColumnIndex = +i;
        this.getDraggedPositionsAndDragArray(i, vis)
    }

    draggedMoved(event, i, vis) {
        const element = event.source.element.nativeElement.style.transform;
        const values = element.split(/\w+\(|\);?/);
        const transform = values[1].split(/,\s?/g).map(numStr => parseInt(numStr));
        this.setDraggedLinePositions(transform, i, vis)
    }

    draggedStopped(event, column, i, vis) {

        vis.dragging = false;
        vis.draggingColumnIndex = null;
        vis.changePosition[column.dimension] = true;
        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
            vis.cd.detectChanges();
        }
        vis.changePosition[column.dimension] = false;

        const element = event.source.element.nativeElement.style.transform;
        const values = element.split(/\w+\(|\);?/);
        const transform = values[1].split(/,\s?/g).map(numStr => parseInt(numStr));

        vis.columns[i].dragPosition.x = vis.columns[i].leftPosition + transform[0] / vis.windowWidth;
        vis.columns[i].dragPosition.y = Math.max(45 / vis.windowHeight, vis.columns[i].topPosition + transform[1] / vis.windowHeight);
        vis.columns[i].dragPosition.bx = vis.columns[i].dragPosition.x + vis.columns[i].width;
        vis.columns[i].dragPosition.by = vis.columns[i].dragPosition.y + vis.columns[i].height;

        for (var k in vis.dragArray) {
            this[vis.dragArray[k].name] = false;
        }

        vis.maxDragPosition = Math.max(vis.columns[i].dragPosition.by * vis.windowHeight + 154, vis.maxDragPosition);
    }


    /* RESIZING  */
    //this is to init variables when the resize has started
    resizeStart(column: any, index: number, grabber, vis) {

        if (!vis.viewerMode) {
            vis.resizing = true;
            column.activeResize = true;
            if (!column.width) {
                //   column.width = this.headerDiv.nativeElement.children[index].getBoundingClientRect().width;
            }
        }
        for (var k in vis.dragArray) {
            this[vis.dragArray[k].name] = false;
        }
        this.getDraggedPositionsAndDragArray(index, vis);
    }


    //when the resize has ended make sure that variables are reset + grabber stops its transform
    resizeEnd(column: any, grabberReset = false, i, grabber, vis) {
        if ((!vis.viewerMode) && (!vis.dragging)) {
            vis.resizing = false;
            vis._oldHeight = null;
            vis._oldWidth = null;
            column.activeResize = false;
            if (grabberReset) {
                vis.grabber.style.transform = 'none';
            }

        }
        for (var k in vis.dragArray) {
            vis[vis.dragArray[k].name] = false;
        }
        vis.maxDragPosition = Math.max(vis.columns[i].dragPosition.by * vis.windowHeight + 154, vis.maxDragPosition);

    }
    resizingEvent(event, column, i, grabber, vis) {
        if ((!vis.viewerMode) && (!vis.dragging)) {
            console.log(event)
            const targetLeft = event.pointerPosition.x;
            const targetHeight = event.pointerPosition.y;
            vis.grabber = event.source.element.nativeElement as HTMLElement;
            vis.grabberName = grabber;

            if (!vis._oldWidth) {
                vis._oldWidth = targetLeft;
            }
            if (!vis._oldHeight) {
                vis._oldHeight = targetHeight;
            }

            let newWidth = 150;
            let newHeight = 150;

            let minHeight = 200;
            let minWidth = 200;

            if (column.type === "comment box") {
                minHeight = 50;
                minWidth = 100;
            }

            if (grabber === "grabber") {
                newWidth = Math.max(column.width * vis.windowWidth + (targetLeft - vis._oldWidth), minWidth);
                newHeight = Math.max(column.height * vis.windowHeight + targetHeight - vis._oldHeight, minHeight);
                column.dragPosition.bx = column.dragPosition.x + newWidth / vis.windowWidth;
                column.dragPosition.by = column.dragPosition.y + newHeight / vis.windowHeight;
            }
            else if (grabber === "grabber1") {
                newWidth = Math.max(column.width * vis.windowWidth + (targetLeft - vis._oldWidth), minWidth);
                newHeight = Math.max(column.height * vis.windowHeight - (targetHeight - vis._oldHeight), minHeight);
                column.dragPosition.bx = column.leftPosition + column.width + newWidth / vis.windowWidth;
                column.dragPosition.y = (column.topPosition + column.height) - newHeight / vis.windowHeight;
                vis.columns[i].topPosition = JSON.parse(JSON.stringify(column.dragPosition.y));
            }
            else if (grabber === "grabber2") {
                newWidth = Math.max(column.width * vis.windowWidth - (targetLeft - vis._oldWidth), minWidth);
                newHeight = Math.max(column.height * vis.windowHeight - (targetHeight - vis._oldHeight), minHeight);
                column.dragPosition.x = (column.leftPosition + column.width) - newWidth / vis.windowWidth;
                column.dragPosition.y = (column.topPosition + column.height) - newHeight / vis.windowHeight;
                vis.columns[i].topPosition = JSON.parse(JSON.stringify(column.dragPosition.y));
                vis.columns[i].leftPosition = JSON.parse(JSON.stringify(column.dragPosition.x));
            }
            else if (grabber === "grabber3") {
                newWidth = Math.max(column.width * vis.windowWidth - (targetLeft - vis._oldWidth), minWidth);
                newHeight = Math.max(column.height * vis.windowHeight + targetHeight - vis._oldHeight, minHeight);
                column.dragPosition.x = column.leftPosition + column.width - newWidth / vis.windowWidth;
                column.dragPosition.by = column.topPosition + newHeight / vis.windowHeight;
                vis.columns[i].leftPosition = JSON.parse(JSON.stringify(column.dragPosition.x));
            }

            vis._oldWidth = targetLeft;
            vis._oldHeight = targetHeight;

            column.width = newWidth;
            column.height = newHeight;

            vis.columns[i].width = newWidth / vis.windowWidth;
            vis.columns[i].height = newHeight / vis.windowHeight;

            vis.columns[i].dragPosition.x = JSON.parse(JSON.stringify(column.dragPosition.x));
            vis.columns[i].dragPosition.y = JSON.parse(JSON.stringify(column.dragPosition.y));
            vis.columns[i].dragPosition.bx = JSON.parse(JSON.stringify(column.dragPosition.bx));
            vis.columns[i].dragPosition.by = JSON.parse(JSON.stringify(column.dragPosition.by));

            let transform = [0, 0];
            this.setDraggedLinePositions(transform, i, vis);

            this.setBottomPosition(column.dragPosition.by + 154 / vis.windowHeight, vis)

            vis.grabber.style.transform = 'none';
            vis.dashboardService.setChartChange(vis.mainTab, vis.tabAlias, vis.columns, i, i)
        }
    }

}