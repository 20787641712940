import { HttpClient, HttpClientJsonpModule, HttpParams, HttpHeaders } from '@angular/common/http';

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from './user.model';
import { AccessLogin } from './access-login.model';
import { AppService } from '../app.service';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from '../shared/shared-common-initialization.service';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { Subscription } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class AuthService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
        private sharedService: SharedInitializationService,
        //private logoutTimeService: LogoutTimeService,
        private router: Router,
        private route: ActivatedRoute) { };

    //CHECK WHETHER USER IS LOGGED IN OR NOT. THERE IS A SUBJECT TOO SO THAT IT CAN NOTIFY CHANGES TO THE HEADER COMPONENT BECAUSE WE USE A NG IF
    // TO DISPLAY THE HEADER MENU

    isAuthenticated: boolean = false;
    isAuthenticatedChange = new Subject<boolean>();

    //if user has to change password
    changePasswordChange = new Subject<boolean>();

    setUserDefaultSubscription = new Subscription;
    loggedInUserSubscription = new Subscription;
    logoutTimeSubscription = new Subscription;

    //GET USERCONNECTED
    userEmail: string;
    userName: string;
    LastConnection: string;
    lastPage: string;
    userConnectedChange = new Subject<User[]>();
    tabSelectedChange = new Subject<string>();

    canNewWorkflow : boolean=false;

    private user: User[] = [{
        username:"",
        email: "",
        lastConnection: ""
    }];

    theme: string = "";

    url = this.appService.getUrl();

    isLoggedin() {

        console.log(this.isAuthenticated)
     
        return this.isAuthenticated;
    }

    isUserStillLoggedIn() {

        const url = this.url;
        const webservice = "AccessCheckForLogout";
        const completeUrl = url + webservice;

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                // console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.isAuthenticated = true;


                }
                else {
                    this.closeNavigationWindows();
                    this.isAuthenticated = false;
                    this.isAuthenticatedChange.next(this.isAuthenticated)
                    this.loggedInUserSubscription.unsubscribe();
                    this.setUserDefaultSubscription.unsubscribe();
                    this.router.navigate(['/login']);
                    this.isAuthenticatedChange.next(this.isAuthenticated);
                    this.appService.showMessage('Error', response.statusText)
                    window.location.reload();
                }
            }, (error) => {
                console.log(error);
                this.router.navigate(['/login']);
                window.location.reload();
                this.appService.showMessage('Error', error.statusText)
                return false;
            });

    }

    isNotLoggedIn() {

    }

    changeAuthentication() {
        // this.isAuthenticated = !this.isAuthenticated;
        this.isAuthenticatedChange.next(this.isAuthenticated)
    }

    // SERVICES THAT ALLOW THE LOGIN OF THE USER AS WELL AS THE LOGOUT

    loginUser(value: { username: string, password: string }) {
        console.log(value);
        const url = this.url;
        const webservice = "AccessLogin";
        const completeUrl = url + webservice;

        // const params2 = {"userEmailorName":value.username, "password": value.password}

        let params2 = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("password", value.password)
            .set("userEmailorName", value.username); //Create new HttpParams


        const params = params2.toString();
        console.log(params)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



        return this.httpClient.post(completeUrl, params2, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                 

                    if ((response.tableRows[0].passwordExpired === true) || (response.tableRows[0].temporaryPassword)) {
                        this.changePasswordChange.next(true);
                    }
                    else {
                        this.isAuthenticated = true;
                        this.isAuthenticatedChange.next(this.isAuthenticated);
                        this.guiTabsArray = response.tableRows[0].userGuiTabs;
                        
                        if (response.tableRows[0].canNewWorkflow) {
                            this.canNewWorkflow = response.tableRows[0].canNewWorkflow;
                       }
                        if (response.tableRows[0].favoriteWF) {
                            this.appService.setWorkflow(response.tableRows[0].favoriteWF)
                        }
                        if (response.tableRows[0].favoriteTable) {
                            this.appService.setUserTable(response.tableRows[0].favoriteTable)
                        }
                        if (response.tableRows[0].favoriteSubTable) {
                            this.appService.setUserSubtable(response.tableRows[0].favoriteSubTable)
                        }
                        if (response.tableRows[0].workflowType) {
                            this.appService.setType(response.tableRows[0].workflowType)
                        }
                        if (response.tableRows[0].lastPage) {
                            this.lastPage = response.tableRows[0].lastPage.toLowerCase();
                            this.lastPage = this.lastPage.charAt(0).toUpperCase() + this.lastPage.slice(1);
                        }
                        if (response.tableRows[0].LastConnection) {
                            this.LastConnection = response.tableRows[0].LastConnection;
                        }
                        if (response.tableRows[0].favoriteSegAttribute) {
                            this.appService.setSegAttribute(response.tableRows[0].favoriteSegAttribute)
                        }

                        if (response.tableRows[0].favoriteSource) {
                            this.appService.setSource(response.tableRows[0].favoriteSource)
                        }
                        if (response.tableRows[0].favoriteTheme) {
                            this.theme = response.tableRows[0].favoriteTheme;

                            this.appService.setTheme(this.theme);
                        }

                        if (response.tableRows[0].favoriteLoadRecordNumber) {
                            let number = +response.tableRows[0].favoriteLoadRecordNumber
                            this.appService.setLoadRecordNumber(number)
                        }
                        if (response.tableRows[0].auditFiles) {
                            let auditFiles = JSON.parse(response.tableRows[0].auditFiles);
                            this.appService.setAuditFiles(auditFiles);
                        }

                        this.userName = response.tableRows[0].userName;
                        this.userEmail = response.tableRows[0].userEmail;
                        this.user = [];
                        this.user.push(new User(this.userName, this.userEmail, this.LastConnection));
                        this.userConnectedChange.next(this.user);

                        console.log(this.guiTabsArray);
                        console.log(this.lastPage);
                        this.sharedService.getUsers();
                        this.sharedService.getTopicData();
                        if (this.guiTabsArray.length === 0) {
                            this.appService.showMessage('Error', "You are not authorized to access the application. Please contact your admin.")
                        }
                        else {
                            this.tabSelectedChange.next('topics')
                            this.router.navigate(['/topics'])
                        }

                       /* else if (this.lastPage === "") {
                            this.tabSelectedChange.next(this.guiTabsArray[0].toLowerCase())
                            this.router.navigate(['/' + this.guiTabsArray[0].toLowerCase()]);
                           
                        }
                        else if (this.guiTabsArray.indexOf(this.lastPage) > -1) {
                            this.tabSelectedChange.next(this.lastPage.toLowerCase())
                            this.router.navigate(['/' + this.lastPage.toLowerCase()]);
                        }
                        else {
                            this.tabSelectedChange.next(this.guiTabsArray[0].toLowerCase())
                            this.router.navigate(['/' + this.guiTabsArray[0].toLowerCase()])
                        } */
                        
                        if (response.tableRows[0].displayLoginMessage) {
                            if (response.tableRows[0].displayLoginMessage.toLowerCase() === "true") {
                                if (response.tableRows[0].loginMessage) {
                                    if (response.tableRows[0].loginMessage != '') {
                                        this.appService.showMessage("Warning", response.tableRows[0].loginMessage);
                                    }
                                }
                            }
                        }
                        

                        this.loggedInUserSubscription = interval(10000).subscribe(x => {
                            this.isUserStillLoggedIn();
                        })


                        this.setUserDefaultSubscription = interval(30000).subscribe(x => {
                            this.logoutUser(false)
                        });

                    }


                }
                else {
                    this.isAuthenticated = false;
                    this.isAuthenticatedChange.next(this.isAuthenticated)
                    this.appService.showMessage('Error', response.statusText);
                }
            }, (error) => {
                this.isAuthenticated = false;
                this.isAuthenticatedChange.next(this.isAuthenticated)
                this.appService.showMessage('Error', error.statusText);

            });

    }


    logoutUser(logout) {
        console.log("setuserdefaultttttttttttttttttttttt");
        const url = this.url;
        const webservice = "WFSetUserDefault";
        const completeUrl = url + webservice;
        if (logout === true) {
            this.closeNavigationWindows();
            this.isAuthenticated = false;
            this.isAuthenticatedChange.next(this.isAuthenticated);
            this.guiTabsArray = [];
            this.setUserDefaultSubscription.unsubscribe();
            this.loggedInUserSubscription.unsubscribe();
        }


        //  this.user = [];
        var path = this.router.url;
        var tab = this.router.url.split('/')[1];
        tab = tab.charAt(0).toUpperCase() +
            tab.slice(1);
        
    
/*
        if (tab === "Dashboard") {
            tab = "DashBoard"
        }*/
        //   console.log(this.route.snapshot);

        let workflow = this.appService.getWorkflow();
        let table = this.appService.getTable();
        let subtable = this.appService.getSubtable();
        let segAttribute = this.appService.getSegAttribute();
        let type = this.appService.getType();
        let source = this.appService.getSource();
        let theme = this.appService.getTheme();
        let numberRecords = JSON.stringify(this.appService.getLoadRecordNumber());

        //create new http params
        let params2 = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userEmail", this.userEmail)
            .set("lastPage", tab)
            .set("wfNameDef", workflow)
            .set("wfTableDef", table)
            .set("wfSubTableDef", subtable)
            .set("wfTypeDef", type)
            .set("wfSegAttributeDef", segAttribute)
            .set("wfSource", source)
            .set("theme", theme)
            .set("numberRecords", numberRecords)
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



        return this.httpClient.post(completeUrl, params2, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {
                //  console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if ((response.statusCode > -1) && (logout === true)) {
                    const url = this.url;
                    const webservice = "AccessLogout";
                    const completeUrl = url + webservice;
                    this.user = [];

                    //create new http params
                    let params2 = new HttpParams({ encoder: new CustomHttpParamEncoder() })

                    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');



                    return this.httpClient.post(completeUrl, params2, { headers, responseType: 'text', withCredentials: true })
                        .pipe(
                            //map(response => new NmUser(response)),
                            catchError(this.appService.handleError)
                        )
                        .subscribe((response: any) => {
                            console.log(response);
                            //response = response.replace(/[()]/g, '');
                            response = response.substring(1, response.length - 1);
                            response = JSON.parse(response);
                            if (response.statusCode > -1) {
                               // this.router.navigate(['/login']);
                                window.location.reload();
                            }
                            else {
                                //this.router.navigate(['/login']);
                                window.location.reload();
                                this.appService.showMessage('Error', response.statusText);

                            }
                        }, (error) => {
                           // this.router.navigate(['/login']);
                           window.location.reload();
                            this.appService.showMessage('Error', error.statusText);

                        });

                }
                else if (response.statusCode === -1) {
                    if (logout === true) {
                        //this.router.navigate(['/login']);
                        window.location.reload();
                    }
                    this.appService.showMessage('Error', response.statusText);


                }
            }, (error) => {
              //  this.router.navigate(['/login']);
              window.location.reload();
                this.appService.showMessage('Error', error.statusText);

            });


        

    }

    //  GET AUTHORIZED GUITABS + UPDATE THEM IF CHANGED IN ADMIN
    guiTabsArray = [];

    getGuiTabs() {
        return this.guiTabsArray;
    }

    updateGuiTabs(guitabs) {
        this.guiTabsArray = guitabs;
    }

    getUser() {

        return this.user;
    }

    getUsername() {
       let username = "";
        if (this.user) {
            if (this.user[0]) {
              username= this.user[0].username;
            }
          }
        return username;
    }

    getCanNewWorkflow() {
        return this.canNewWorkflow;
    }
    //this is to close all navigation windows when user is logged out
    closeNavigationWindows() {
        let navigationWindows = this.appService.returnNavigationWindows();
        for (var i in navigationWindows) {
            //  console.log(this[this.navigationWindows[i]])
            if (window.open(navigationWindows[i].url, navigationWindows[i].name, "" )) {
                window.open(navigationWindows[i].url, navigationWindows[i].name, "").close();

            }
        }
    }

    selectNewTab(tab) {
        this.tabSelectedChange.next(tab);
    }
}