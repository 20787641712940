<div class="topics-dashboard">

  <div class="btn-group applyButtonGroup refresh" >
   
    <button *ngIf="selectedTopic === ''" class=" btn btn-primary btn-sm" (click)="applyRefresh()">
    <span>
      <i class="fa fa-redo"></i>
    </span> Refresh</button>
    <div >
      <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
    </div>
  </div>

  <div class="container ">

    <div class="row" *ngIf="selectedTopic === ''">

      <div class="col-md-4" *ngFor="let topic of topics; let index = index">

        <mat-card class="example-card topics-dashboard-card" (click)="filterCard(topicNames[index], topic)">
          <mat-card-header>
            <mat-card-title class="topics-dashboard-title">
              <span class="topics-dashboard-title-name" [cardabelTooltip]="topicNames[index]">{{topicNames[index]}} </span> <span
                class="topics-dashboard-title-content">{{topic[topicNames[index]][0].Workflows.length}} workflows
              </span></mat-card-title>
          </mat-card-header>
          <mat-card-content>


            <div class="row topics-dashboard-secondary-container">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12 topics-dashboard-secondary-number">

                    {{topic[topicNames[index]][0]['OpenOwner'] | number: '1.0-0'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 topics-dashboard-predictions-text">
                    Open
                  </div>
                </div>
              </div>
              <div class="col-md-6 ">
                <div class="row">
                  <div class="col-md-12 topics-dashboard-secondary-number">
                    {{topic[topicNames[index]][0]['Closed owner'] | number: '1.0-0'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 topics-dashboard-predictions-text">
                    Closed
                  </div>
                </div>

              </div>
            </div>
            <div class="row topics-dashboard-secondary-container topics-dashboard-progress-bar-container">
              <div class="col-md-12 topics-dashboard-progress-bar">

                <div class="topics-dashboard-progress-bar-content"
                  [style.minWidth]="topic[topicNames[index]][0].OwnerProgress+'%'" [style.maxWidth]="100+'%'"></div>
                <div class="topics-dashboard-progress-bar-text">Owned & Assigned</div>
              </div>

            </div>
            <div class="row topics-dashboard-secondary-container">
              <div class="col-md-6 ">
                <div class="row">
                  <div class="col-md-12 topics-dashboard-secondary-number">
                    {{topic[topicNames[index]][0]['Open topic'] | number: '1.0-0'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 topics-dashboard-predictions-text">
                    Open
                  </div>
                </div>

              </div>
              <div class="col-md-6 ">
                <div class="row">
                  <div class="col-md-12 topics-dashboard-secondary-number">
                    {{topic[topicNames[index]][0]['Closed topic'] | number: '1.0-0'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 topics-dashboard-predictions-text">
                    Closed
                  </div>
                </div>

              </div>
            </div>
            <div class="row topics-dashboard-secondary-container topics-dashboard-progress-bar-container">
              <div class="col-md-12 topics-dashboard-progress-bar">

                <div class="topics-dashboard-progress-bar-content"
                  [style.minWidth]="topic[topicNames[index]][0].TopicProgress+'%'" [style.maxWidth]="100+'%'"></div>
                <div class="topics-dashboard-progress-bar-text">All users</div>
              </div>

            </div>
            <div class="row topics-dashboard-secondary-container">
              <div class="col-md-6 ">
                <div class="row">
                  <div class="col-md-12 topics-dashboard-secondary-number">
                    {{topic[topicNames[index]][0]['Records in'] | number: '1.0-0'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 topics-dashboard-predictions-text">
                    Total Records
                  </div>
                </div>

              </div>
              <div class="col-md-6 ">
                <div class="row">
                  <div class="col-md-12 topics-dashboard-secondary-number">
                    {{topic[topicNames[index]][0].Sources | number: '1.0-0'}}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 topics-dashboard-predictions-text">
                    Sources
                  </div>
                </div>

              </div>
            </div>
          </mat-card-content>

        </mat-card>
      </div>

      <!-- <div class="col-md-4">
        <mat-card class="example-card topics-dashboard-card" (click)="filterCard(topicNames[index])">
          <mat-card-header>
            <mat-card-title class="topics-dashboard-title">Add topic <span class="topics-dashboard-title-content">    </span></mat-card-title>
          </mat-card-header>
          <mat-card-content class="topics-dashboard-add-content">
            <i class="fa fa-plus"></i>
            </mat-card-content>
            <div class="row">
              <div class="col-md-12 topics-dashboard-progress-bar">
                <div class="topics-dashboard-progress-bar-content" [style.minWidth]="0+'%'" [style.maxWidth]="100+'%'"></div>
              </div>
            </div>
            </mat-card>
      </div> -->
    </div>
    <div class="row" *ngIf="selectedTopic != ''">
      <cardabel-topics-dashboard-item [selectedTopic]="selectedTopic" [selectedTopicData]="selectedTopicData">
      </cardabel-topics-dashboard-item>
    </div>
  </div>

</div>