<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <i class="pi pi-question-circle piHelp" id="helpIcon" (click)="getHelpDocumentation()"></i>
  </div>
</div>
<div class="row">
  <div class="col-md-12 textAlignCenter">
    <h3>{{workflowName}}</h3>
  </div>
</div>

<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-2 offset-md-8">
    <div class="btn-group cardabel-topic-btn-group">

      <button class="btn btn-primary btn-sm"
      [disabled]="(!topicsChanged)"
      (click)="applyUpdateWorkflowTopicsData('update')">
      <span>
        <i class="fa fa-cog"></i>
      </span> Apply
    </button>
    </div>
  </div>
</div>
<div id="editTopicContainer">

  <div class="row margin-top-40">
    <div class="col-md-1"></div>
    <div class="col-md-5 textAlignCenter">
      <h5 class="topicTitle">Topics</h5>
      <app-cardabel-listbox id="topicContainer" [formControl]="selectedTopics" [options]="topics"
        [multiple]="true" [filterBy]="'topicName'" [placeholder]="''" [tooltipPosition]="'after'"
        [tooltipDisabled]="false" [tooltipText]="" [title]="''"
        >
      </app-cardabel-listbox>
    </div>


    <div class="col-md-1"></div>
  </div>
</div>



