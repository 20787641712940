import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as d3 from 'd3';

import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { SharedGeneralFunctionsService } from 'src/app/shared/shared-general-functions.service';
import { AppService } from 'src/app/app.service';


@Injectable()
export class DashboardTreemapChartService {

    constructor(private httpClient: HttpClient,
        private appService: AppService,
        private router: Router,
        private dashboardService: DashboardService,
        private sharedGeneralService: SharedGeneralFunctionsService
    ) { }


    setTreemap(vis) {
        var color = d3.scaleOrdinal(vis.colorScheme)


        var nest = d3.nest()
            .key(function (d) { return d[vis.yVariable]; })
            //  .key(function(d) { return d["Code_Famile"]})
            .rollup(function (d) { return d3.sum(d, function (d) { return d[vis.xVariable]; }); })
            .entries(vis.dataFiltered)


        var treemap = d3.treemap()
            .size([vis.width, vis.height])
            .padding(1)
            .round(true);

        var root = d3.hierarchy({ values: nest }, function (d) { return d.values; })
            .sum(function (d) { 
                if (isNaN(d.value)) {
                    return 0;
                }
                return Math.abs(d.value);
             })
            .sort(function (a, b) { return Math.abs(b.value) - Math.abs(a.value); });

        /*var root = d3.hierarchy(nest2.entries(vis.data))
            .sum(function (d) { return d.value; })
            .sort(function (a, b) { return b.value - a.value; }); */

        treemap(root);

        console.log(root);
        console.log(root.leaves());

        let id = "#" + vis.type + vis.header + vis.tabAlias + vis.mainTab + vis.graphIndex;
        d3.selectAll(id).selectAll(".node").remove();

        vis.treemap = d3.selectAll(id)
            .attr("style", "transform:translateY(15px)")
            .selectAll(".node")
            .data(root.leaves())
            .enter().append("rect")
            .attr("class", "node")
            .attr("id", function (d, i) { return vis.type + vis.mainTab + vis.tabAlias + vis.header + vis.graphIndex + i })
            .attr("title", function (d, i) { console.log(d); return d.data.key + "\n" + d.data.value; })
            .style("left", function (d) { return d.x0 + "px" })
            .style("top", function (d) { return d.y0 + "px" })
            .style("width", function (d) { return d.x1 - d.x0 + "px"; })
            .style("height", function (d) { return d.y1 - d.y0 + "px"; })
            .style("background", function (d) { while (d.depth > 1) d = d.parent; return color(d.data.key); })

        vis.treemapTexts = vis.treemap
            .append("div")
            .attr("class", "node-label")
            .text(function (d) { return d.data.key })
            .append("div")
            .attr("class", "node-value")
            .text(function (d) { return d.data.value; })


        // and to add the text labels
        /*    vis.treemap
              .selectAll("vals")
              .data(root.leaves())
              .enter()
              .append("text")
                .attr("x", function(d){ return d.x0+5})    // +10 to adjust position (more right)
                .attr("y", function(d){ return d.y0+35})    // +20 to adjust position (lower)
                .text(function(d){ return d.data.value })
                .attr("font-size", "11px")
                .attr("fill", "white")
          
            // Add title for the 3 groups
            vis.treemap
              .selectAll("titles")
              .data(root.descendants().filter(function(d){return d.depth==1}))
              .enter()
              .append("text")
                .attr("x", function(d){ return d.x0})
                .attr("y", function(d){ return d.y0+21})
                .text(function(d){ return d.data.key })
                .attr("font-size", "19px")
                .attr("fill",  function(d){ return color(d.data.key)} ) */

        return vis;
    }

}