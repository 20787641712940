import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../app.service';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { Router } from '@angular/router';
var DashboardService = /** @class */ (function () {
    function DashboardService(httpClient, appService, router) {
        this.httpClient = httpClient;
        this.appService = appService;
        this.router = router;
        this.url = this.appService.getUrl();
        this.dashboardDataFieldsChange = new Subject();
        this.dashboardData = {};
        this.dashboardDataChange = {};
        this.dataFromHeader = {};
        this.dashboardRepeatedHeadersChange = {};
        this.dashboardConfig = {};
        this.dashboardConfigChange = {};
        this.sharedWithModifications = {};
        this.sharedWithoutModifications = {};
        this.sharedWithModificationsChange = {};
        this.sharedWithoutModificationsChange = {};
        this.dashboardList = [];
        this.dashboardListChange = new Subject();
        this.dashboardSavedChange = {};
        this.sourceArray = [];
        this.dashboardFileData = {};
        this.dashboardAggregatedVariable = {};
        this.initFiles = {};
        this.files = {};
        this.chartChange = {};
        this.chartConfigData = {};
    }
    DashboardService.prototype.initDashboardTabChanges = function (tabs) {
        for (var i in tabs) {
            this.dashboardDataChange[tabs[i].name] = new Subject();
            this.dataFromHeader[tabs[i].name] = false;
            this.dashboardRepeatedHeadersChange[tabs[i].name] = new Subject();
            this.dashboardConfigChange[tabs[i].name] = new Subject();
            this.sharedWithModificationsChange[tabs[i].name] = new Subject();
            this.sharedWithoutModificationsChange[tabs[i].name] = new Subject();
            this.dashboardSavedChange[tabs[i].name] = new Subject();
        }
    };
    DashboardService.prototype.initChartChanges = function (mainTab, tabAlias, columns) {
        for (var i in columns) {
            this.chartChange[mainTab + tabAlias + i] = new Subject();
            this.chartConfigData[mainTab + tabAlias + i] = [];
        }
    };
    DashboardService.prototype.initEditColumnChartChanges = function (mainTab, tabAlias, graphIndex) {
        this.chartChange[mainTab + tabAlias + graphIndex] = new Subject();
        this.chartConfigData[mainTab + tabAlias + graphIndex] = [];
    };
    DashboardService.prototype.setChartChanges = function (mainTab, tabAlias, columns) {
        for (var i in columns) {
            this.chartConfigData[mainTab + tabAlias + i] = columns[i];
            this.chartChange[mainTab + tabAlias + i].next(columns[i]);
        }
    };
    DashboardService.prototype.setChartChange = function (mainTab, tabAlias, columns, graphIndex, index) {
        this.chartConfigData[mainTab + tabAlias + graphIndex] = columns[index];
        this.chartChange[mainTab + tabAlias + graphIndex].next(this.chartConfigData[mainTab + tabAlias + graphIndex]);
    };
    DashboardService.prototype.getChartChange = function (mainTab, tabAlias, graphIndex) {
        return this.chartConfigData[mainTab + tabAlias + graphIndex];
    };
    DashboardService.prototype.setDataFromHeader = function (param, tab) {
        this.dataFromHeader[tab] = param;
    };
    //Gets all the anomalies already generated for the selected record type in the chosen dashboard.
    //used for dashboard data as well as Email Configuration List;
    DashboardService.prototype.getDashboardDataFields = function (dashboardName) {
        var _this = this;
        this.appService.startSpin();
        var url = this.url;
        var webservice = "AdminGetDBDataFields";
        var completeUrl = url + webservice;
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("dashboardName", dashboardName);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                console.log(response);
                _this.dashboardData = response;
                _this.dashboardDataFieldsChange.next(_this.dashboardData);
            }
            else {
                _this.appService.showMessage('Error', response.statusText);
            }
            _this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            _this.appService.stopSpin();
        });
    };
    //Gets join of allpredictions, anomalystatus and source tables
    //used for dashboard
    DashboardService.prototype.getDashboardData = function (source, source_only, predictionMode, tab, filter, filterNumber) {
        var _this = this;
        this.appService.startSpin2();
        var topicName = this.appService.getSelectedTopic();
        var url = this.url;
        var webservice = "DashboardUserGetData";
        var completeUrl = url + webservice;
        source = JSON.stringify(source);
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("sourceList", source)
            .set("source_only", source_only)
            .set('predictionMode', predictionMode)
            .set("topicName", topicName)
            .set("dataFilter", filter)
            .set("filterNumber", filterNumber);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            if (response != "") {
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    // console.log(response);
                    if (response.tableRows.length > 0) {
                        response.tableRows.map(function (obj) {
                            obj.system = "CARDABEL";
                            obj.aggregated = false;
                            return obj;
                        });
                        if (!_this.dashboardFileData[tab]) {
                            _this.dashboardFileData[tab] = [];
                        }
                        _this.dashboardData[tab] = _this.dashboardFileData[tab].concat(response.tableRows);
                        _this.returnDashboardData(tab);
                    }
                    else {
                        _this.appService.showMessage('Warning', response.statusText);
                    }
                    if (response.tableRows2.length > 0) {
                        _this.dashboardRepeatedHeadersChange[tab].next(response.tableRows2[0].AttrInAllSources);
                    }
                }
                else {
                    _this.appService.showMessage('Error', response.statusText);
                }
            }
            else {
                _this.appService.showMessage('Error', "The data source is too large to be loaded in the application.");
            }
            _this.appService.stopSpin2();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            _this.appService.stopSpin2();
        });
    };
    //returns the dashboard data
    //used for dashboard
    DashboardService.prototype.returnDashboardData = function (tab) {
        if (this.dashboardData[tab]) {
            this.dashboardDataChange[tab].next(this.dashboardData[tab].slice());
        }
    };
    //set file data
    DashboardService.prototype.setFileData = function (tab, data, aggregatedOption, aggregatedVariable) {
        var aggregated = false;
        if (aggregatedOption === "Aggregated") {
            aggregated = true;
        }
        data.map(function (obj) {
            obj.aggregated = aggregated;
            return obj;
        });
        this.dashboardAggregatedVariable[tab] = aggregatedVariable;
        this.dashboardFileData[tab] = data;
    };
    DashboardService.prototype.setDashboardFiles = function (initFiles, files, tab) {
        this.initFiles[tab] = initFiles.slice();
        this.files[tab] = files.slice();
    };
    DashboardService.prototype.getDashboardFiles = function (tab) {
        return [this.initFiles[tab], this.files[tab]];
    };
    //Gets the user dashboard config for given source and dashboardtype
    //used for dashboard
    DashboardService.prototype.getDashboardList = function (username, from) {
        var _this = this;
        // let newDashboardList = JSON.stringify([])
        var topicName = this.appService.getSelectedTopic();
        var url = this.url;
        var webservice = "DashboardUserGetDashboardList";
        var completeUrl = url + webservice;
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            //.set("newDashboardList", newDashboardList)
            .set("topicName", topicName);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                console.log(response);
                _this.dashboardList = response.tableRows;
                for (var i in _this.dashboardList) {
                    _this.dashboardList[i].sharedWithModifications = JSON.parse(_this.dashboardList[i].sharedWithModifications);
                    _this.dashboardList[i].sharedWithoutModifications = JSON.parse(_this.dashboardList[i].sharedWithoutModifications);
                    _this.dashboardList[i].sources = JSON.parse(_this.dashboardList[i].sources);
                }
                var newdata = {
                    dashboardList: _this.dashboardList,
                    from: from
                };
                _this.dashboardListChange.next(newdata);
            }
            else {
                _this.dashboardList = [];
                var newdata = {
                    dashboardList: _this.dashboardList,
                    from: from
                };
                _this.dashboardListChange.next(newdata);
            }
            //    this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            //     this.appService.stopSpin();
        });
    };
    //return dashboardList
    DashboardService.prototype.returnDashboardList = function () {
        return this.dashboardList;
    };
    //Gets the user dashboard config for given source and dashboardtype
    //used for dashboard
    DashboardService.prototype.getDashboardUserConfig = function (username, sourceOnlyMode, dashboardtype, tab) {
        var _this = this;
        var url = this.url;
        var webservice = "DashboardUserLoad";
        var completeUrl = url + webservice;
        var db = this.dashboardList.find(function (item) { return item.name === dashboardtype; });
        if (db) {
            var creator = db.creator;
            var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
                .set("userName", username)
                .set("sourceOnlyMode", sourceOnlyMode)
                .set("dashboardType", dashboardtype)
                .set("creator", creator);
            var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            //   this.appService.startSpin();
            return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
                .pipe(
            //map(response => new NmUser(response)),
            catchError(this.appService.handleError))
                .subscribe(function (response) {
                console.log(response);
                //response = response.replace(/[()]/g, '');
                response = response.substring(1, response.length - 1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    console.log(response);
                    _this.dashboardConfig[tab] = response.tableRows;
                    for (var i in _this.dashboardConfig[tab]) {
                        _this.dashboardConfig[tab][i].config = JSON.parse(_this.dashboardConfig[tab][i].config);
                        //this.dashboardConfig[i].filters = JSON.parse(this.dashboardConfig[i].filters);
                    }
                    for (var k in _this.dashboardList) {
                        if (_this.dashboardList[k].name === dashboardtype) {
                            _this.sharedWithModifications[tab] = _this.dashboardList[k].sharedWithModifications;
                            _this.sharedWithoutModifications[tab] = _this.dashboardList[k].sharedWithoutModifications;
                            break;
                        }
                    }
                    _this.dashboardConfigChange[tab].next(_this.dashboardConfig[tab]);
                    _this.sharedWithModificationsChange[tab].next(_this.sharedWithModifications[tab]);
                    _this.sharedWithoutModificationsChange[tab].next(_this.sharedWithoutModifications[tab]);
                }
                else {
                    _this.dashboardConfig[tab] = [];
                    _this.dashboardConfigChange[tab].next(_this.dashboardConfig[tab]);
                    _this.sharedWithModifications[tab] = [];
                    _this.sharedWithoutModifications[tab] = [];
                    _this.sharedWithModificationsChange[tab].next(_this.sharedWithModifications[tab]);
                    _this.sharedWithoutModificationsChange[tab].next(_this.sharedWithoutModifications[tab]);
                    //  this.appService.showMessage('Error', response.statusText)
                }
                //this.appService.stopSpin();
            }, function (error) {
                _this.appService.showMessage('Error', error.statusText);
                //this.appService.stopSpin();
            });
        }
    };
    //Saves the user dashboard config for given source and dashboardtype
    //used for dashboard
    DashboardService.prototype.saveDashboardUserConfig = function (username, source, sourceOnlyMode, dashboardtype, dashboardconfig, sharedWithModifications, sharedWithoutModifications, tab, from, filter, filterNumber) {
        var _this = this;
        var url = this.url;
        var webservice = "DashboardUserSave";
        var completeUrl = url + webservice;
        dashboardconfig = JSON.stringify(dashboardconfig);
        var previousAuthorizedUsersArray = [];
        var sourceList = JSON.stringify(source);
        var dashboardNameExists = false;
        var creator = username;
        for (var i in this.dashboardList) {
            if ((this.dashboardList[i].name === dashboardtype)) {
                creator = this.dashboardList[i].creator;
                if (creator === this.dashboardList[i].creator) {
                    var arr1 = this.dashboardList[i].sharedWithModifications;
                    var arr2 = this.dashboardList[i].sharedWithoutModifications;
                    previousAuthorizedUsersArray = Array.from(new Set(arr1.concat(arr2)));
                    /*  this.dashboardList[i].sharedWithModifications = [...sharedWithModifications];
                      this.dashboardList[i].sharedWithoutModifications = [...sharedWithoutModifications];
                      this.dashboardList[i].sources = source;
                      this.dashboardList[i].sourceOnly = sourceOnlyMode; */
                }
                dashboardNameExists = true;
                break;
            }
        }
        /*  if (dashboardNameExists === false) {
              this.dashboardList.push({ name: dashboardtype, sources: source, sourceOnly: sourceOnlyMode, creator: username, sharedWithModifications: sharedWithModifications, sharedWithoutModifications: sharedWithoutModifications })
          } */
        /*  let dashboardList = JSON.stringify(this.dashboardList);*/
        var sharedWithList = JSON.stringify(sharedWithModifications);
        var sharedWithoutList = JSON.stringify(sharedWithoutModifications);
        var sourceOnly = sourceOnlyMode;
        if ((sourceOnlyMode === false) || (sourceOnlyMode.toLowerCase() === "false")) {
            sourceOnly = "anomaly";
        }
        else if ((sourceOnlyMode === true) || (sourceOnlyMode.toLowerCase() === "true")) {
            sourceOnly = "sourceOnly";
        }
        var otherAuthorizedUsersArray = Array.from(new Set(sharedWithModifications.concat(sharedWithoutModifications)));
        var otherAuthorizedUsers = JSON.stringify(otherAuthorizedUsersArray);
        var difference = previousAuthorizedUsersArray.filter(function (x) { return !otherAuthorizedUsersArray.includes(x); });
        var previousAuthorizedUsers = JSON.stringify(difference);
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("sourceOnlyMode", sourceOnly)
            .set("dashboardType", dashboardtype)
            .set("dashboardConfig", dashboardconfig)
            .set("creator", creator)
            .set("dashboardSourcesList", sourceList)
            .set("sharedWithModifications", sharedWithList)
            .set("sharedWithoutModifications", sharedWithoutList)
            .set("otherAuthorizedUsers", otherAuthorizedUsers)
            .set("previousAuthorizedUsers", previousAuthorizedUsers)
            .set("dataFilter", filter)
            .set("filterNumber", filterNumber);
        // this.appService.startSpin();
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                _this.appService.showMessage('Success', response.statusText);
                _this.dashboardConfig[tab] = JSON.parse(dashboardconfig);
                _this.dashboardSavedChange[tab].next(true);
                if (from === "header") {
                    _this.dashboardConfigChange[tab].next(_this.dashboardConfig[tab]);
                }
                _this.getDashboardList(username, from);
                //this.router.navigate(['dashboard', source, sourceOnly, dashboardtype, true])
            }
            else {
                _this.appService.showMessage('Error', response.statusText);
                _this.dashboardSavedChange[tab].next(false);
            }
            //this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            _this.dashboardSavedChange[tab].next(false);
            // this.appService.stopSpin();
        });
    };
    //Saves the user dashboard config for given source and dashboardtype
    //used for dashboard
    DashboardService.prototype.deleteDashboardUser = function (username, sourceOnlyMode, dashboardtype, tab) {
        var _this = this;
        var url = this.url;
        var webservice = "DashboardUserDelete";
        var completeUrl = url + webservice;
        var creator = "";
        var users = [];
        for (var i in this.dashboardList) {
            if ((this.dashboardList[i].name === dashboardtype)) {
                creator = this.dashboardList[i].creator;
                users = this.dashboardList[i].sharedWithModifications.concat(this.dashboardList[i].sharedWithoutModifications);
                // this.dashboardList.splice(+i, 1)
                break;
            }
        }
        var userList = JSON.stringify(users);
        //  let dashboardList = JSON.stringify(this.dashboardList);
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userName", username)
            .set("sourceOnlyMode", sourceOnlyMode)
            .set("dashboardType", dashboardtype)
            .set("creator", creator)
            .set("users", userList);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                _this.appService.showMessage('Success', response.statusText);
                _this.router.navigate(['dashboard']);
                _this.getDashboardList(username, "header");
            }
            else {
                _this.appService.showMessage('Error', response.statusText);
                _this.getDashboardList(username, "header");
            }
            _this.appService.stopSpin();
        }, function (error) {
            _this.appService.showMessage('Error', error.statusText);
            _this.appService.stopSpin();
        });
    };
    //this is to format data according to aggregation method
    // used for card, and dashbaord chart
    DashboardService.prototype.formatDashboardData = function (initData, header, xHeader, yHeader, method, name, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate, tab) {
        var filteredData = [];
        var formattedData = [];
        var vis = this;
        var data = initData.slice();
        var systems = [];
        var removeDates = false;
        var headers = [header, xHeader, yHeader];
        if (data.length != 0) {
            for (var i in data) {
                for (var k in headers) {
                    if (headers[k] != "") {
                        if ((data[i][headers[k]] === null) || (data[i][headers[k]] === 'Null')) {
                            if (header === "truestatus") {
                                data[i][headers[k]] = "Unknown";
                            }
                            else {
                                data[i][headers[k]] = "Null";
                            }
                        }
                        else if (data[i][headers[k]] == undefined) {
                        }
                        else if ((data[i][headers[k]] === "") || (data[i][headers[k]] === " ")) {
                            data[i][headers[k]] = "Unknown";
                        }
                        else if (typeof data[i][headers[k]] === 'string') {
                            if (data[i][headers[k]].indexOf(":") > -1) {
                                data[i][headers[k]] = data[i][headers[k]].replace(":", "-");
                            }
                        }
                    }
                }
            }
            if ((dateBucketing != "") && dateBucketing) {
                // var today = new Date();
                /* var lastDate = new Date(
                     Math.max(
                       ...data.map(element => {
                         return new Date(element[header]);
                       }),
                     ),
                   );
                   */
                // 15/06/2022
                // pb sort with null
                for (var i in data) {
                    if (data[i][header] == null) {
                        data[i][header] = "";
                    }
                }
                var chartDataLength = JSON.parse(JSON.stringify(data.length));
                data = data.filter(function (item) { return new Date(item[header]).toString() != "Invalid Date"; });
                if (chartDataLength != data.length) {
                    removeDates = true;
                }
                if (data.length > 0) {
                    var sorteddata = data.slice();
                    sorteddata = sorteddata.sort(function (a, b) { return a[header] > b[header] ? 1 : -1; });
                    var lastDate = sorteddata[sorteddata.length - 1][header];
                    lastDate = new Date(lastDate);
                    var maxDate_1 = new Date(lastDate);
                    if (datePeriod === "Days" || (!datePeriod)) {
                        maxDate_1.setDate(lastDate.getDate() - datePeriodValue);
                    }
                    else if (datePeriod === "Months") {
                        var numberOfDays = datePeriodValue * 30;
                        maxDate_1.setDate(lastDate.getDate() - numberOfDays);
                    }
                    else if (datePeriod == "Weeks") {
                        var numberOfDays = datePeriodValue * 7;
                        maxDate_1.setDate(lastDate.getDate() - numberOfDays);
                    }
                    else if (datePeriod === "Years") {
                        var numberOfDays = datePeriodValue * 365;
                        maxDate_1.setDate(lastDate.getDate() - numberOfDays);
                    }
                    else if (datePeriod === "Fixed Date") {
                        maxDate_1 = new Date(datePeriodFixedDate);
                    }
                    filteredData = data.filter(function (item) { return new Date(item[header]) > maxDate_1; });
                    console.log(maxDate_1);
                    console.log(filteredData);
                    data = JSON.parse(JSON.stringify(filteredData));
                }
            }
            if ((method === "count") || (!method)) {
                if ((xHeader != "") || (yHeader != "")) {
                    formattedData = this.formatAggregatedDashboardData(data, header, xHeader, yHeader, method, name, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate);
                }
                else if ((!dateBucketing) || (dateBucketing === "")) {
                    /* formattedData = data.reduce(
                         (map => (r, item) => (!map.has(item[header]) && map.set(item[header], r[r.push({ Categorie: item[header], [name]: 0 }) - 1]), map.get(item[header])[name]++, r))(new Map),
                         []) */
                    var dashboardAggregatedVariable_1 = this.dashboardAggregatedVariable[tab];
                    data.reduce(function (res, item) {
                        var _a, _b;
                        if (item[header] && item[header] != null) {
                            if ((item["system"] === "CARDABEL") || (item["aggregated"] === false) || (!dashboardAggregatedVariable_1) || (!item["system"])) {
                                if (!res[item[header]]) {
                                    res[item[header]] = (_a = { Categorie: item[header] }, _a[name] = 0, _a.increase = "0%", _a);
                                    formattedData.push(res[item[header]]);
                                }
                                if (systems.indexOf(item["system"]) === -1) {
                                    systems.push(item["system"]);
                                }
                                res[item[header]][name]++;
                            }
                            else if ((dashboardAggregatedVariable_1 != "") || (dashboardAggregatedVariable_1)) {
                                if (!res[item[header]]) {
                                    res[item[header]] = (_b = { Categorie: item[header] }, _b[name] = +item[dashboardAggregatedVariable_1], _b);
                                    formattedData.push(res[item[header]]);
                                }
                                else {
                                    res[item[header]][name] = res[item[header]][name] + (+item[dashboardAggregatedVariable_1]);
                                }
                                if (systems.indexOf(item["system"]) === -1) {
                                    systems.push(item["system"]);
                                }
                            }
                        }
                        return res;
                    }, {});
                }
                else {
                    data.reduce(function (res, item) {
                        var _a, _b;
                        if (item[header] != null && typeof item[header] === "string") {
                            var _c = item[header].split('-'), year = _c[0], month = _c[1], day = _c[2];
                            var newHeader = "";
                            var newName = "";
                            _a = vis.getNewNameNewHeader(year, month, day, dateBucketing), newName = _a[0], newHeader = _a[1];
                            if (!res[newName]) {
                                res[newName] = (_b = { Categorie: newHeader }, _b[name] = 0, _b);
                                formattedData.push(res[newName]);
                            }
                            if (systems.indexOf(item["system"]) === -1) {
                                systems.push(item["system"]);
                            }
                            res[newName][name]++;
                        }
                        return res;
                    }, {});
                }
            }
            else {
                var dashboardAggregatedVariable_2 = this.dashboardAggregatedVariable[tab];
                data.reduce(function (res, value) {
                    var _a, _b, _c;
                    if (value[header] && value[header] != null) {
                        var newHeader = value[header];
                        var newName = value[header];
                        if ((xHeader != "") || (yHeader != "")) {
                            newName = newName + value[xHeader] + value[yHeader];
                        }
                        if (dateBucketing != "" && dateBucketing) {
                            if (value[header] != null) {
                                var _d = value[header].split('-'), year = _d[0], month = _d[1], day = _d[2];
                                _a = vis.getNewNameNewHeader(year, month, day, dateBucketing), newName = _a[0], newHeader = _a[1];
                            }
                        }
                        if (!res[newName]) {
                            var formattedValue = value[numericHeader];
                            if (!isNaN(value[numericHeader]) && value[numericHeader] != null && value != "") {
                                console.log(formattedValue);
                                formattedValue = +value[numericHeader];
                                formattedValue = formattedValue.toFixed(numericDecimal);
                                if ((xHeader != "") || (yHeader != "")) {
                                    res[newName] = (_b = { Categorie: value[header], xHeader: value[xHeader], yHeader: value[yHeader] }, _b[name] = +formattedValue, _b.number = 1, _b);
                                }
                                else {
                                    res[newName] = (_c = { Categorie: newHeader }, _c[name] = +formattedValue, _c.number = 1, _c);
                                }
                                if ((value["system"] === "CARDABEL") || (value["aggregated"] === false) || (!dashboardAggregatedVariable_2) || (!value["system"])) {
                                }
                                else if (dashboardAggregatedVariable_2 != '') {
                                    res[newName].number = +value[dashboardAggregatedVariable_2];
                                }
                                if (systems.indexOf(value["system"]) === -1) {
                                    systems.push(value["system"]);
                                }
                                formattedData.push(res[newName]);
                            }
                        }
                        else {
                            var formattedValue = value[numericHeader];
                            var formattedValueNumericType = true;
                            if ((!isNaN(value[numericHeader]) && value[numericHeader] != null)) {
                                formattedValue = +value[numericHeader];
                                formattedValue = formattedValue.toFixed(numericDecimal);
                            }
                            else {
                                formattedValueNumericType = false;
                            }
                            if (method === "min") {
                                res[newName][name] = Math.min(res[newName][name], formattedValue);
                            }
                            if (method === "max") {
                                res[newName][name] = Math.max(res[newName][name], formattedValue);
                            }
                            if (method === "sum") {
                                if (formattedValueNumericType) {
                                    res[newName][name] = +res[newName][name] + (+formattedValue);
                                }
                            }
                            if (method === "average") {
                                if (formattedValueNumericType) {
                                    res[newName][name] = +res[newName][name] + (+formattedValue);
                                }
                                if ((value["system"] === "CARDABEL") || (value["aggregated"] === false) || (!dashboardAggregatedVariable_2) || (!value["system"])) {
                                    res[newName]["number"]++;
                                }
                                else if (dashboardAggregatedVariable_2 != '') {
                                    res[newName].number = res[newName].number + (+value[dashboardAggregatedVariable_2]);
                                }
                            }
                            if (systems.indexOf(value["system"]) === -1) {
                                systems.push(value["system"]);
                            }
                        }
                    }
                    return res;
                }, {});
            }
            if (method === "average") {
                for (var m in formattedData) {
                    formattedData[m][name] = (formattedData[m][name] / formattedData[m].number);
                    if ((!isNaN(formattedData[m][name])) && formattedData[m][name] != null) {
                        formattedData[m][name] = formattedData[m][name].toFixed(numericDecimal);
                    }
                }
            }
            for (var j in formattedData) {
                if (formattedData[j].Categorie === null) {
                    formattedData[j].Categorie = "Null";
                }
                if (formattedData[j].Categorie === 0) {
                    formattedData[j].Categorie = "0";
                }
            }
        }
        systems = this.getSystems(systems, tab);
        return [formattedData, systems, removeDates];
    };
    DashboardService.prototype.getSystems = function (data, tab) {
        var _a = this.getDashboardFiles(tab), initFiles = _a[0], files = _a[1];
        var systems = [];
        if (initFiles) {
            if (initFiles.length != 0) {
                systems = data;
            }
        }
        return systems;
    };
    DashboardService.prototype.formatAggregatedDashboardData = function (data, header, xHeader, yHeader, method, name, numericHeader, dateBucketing, numericDecimal, datePeriod, datePeriodValue, datePeriodFixedDate) {
        var formattedData = [];
        data.reduce(function (res, value) {
            var _a;
            var newName = value[header];
            if (xHeader != "") {
                newName = newName + value[xHeader];
            }
            if (yHeader != "") {
                newName = newName + value[yHeader];
            }
            if (!res[newName]) {
                res[newName] = (_a = { Categorie: value[header], xHeader: value[xHeader], yHeader: value[yHeader] }, _a[name] = 1, _a);
                formattedData.push(res[newName]);
            }
            else {
                res[newName][name]++;
            }
            return res;
        }, {});
        return formattedData;
    };
    DashboardService.prototype.getNewNameNewHeader = function (year, month, day, dateBucketing) {
        var newName = "";
        var newHeader = "";
        if (dateBucketing === "Month") {
            newHeader = year + '-' + month;
            newName = year + month;
        }
        else if (dateBucketing === "Year") {
            newHeader = year + "-" + "01";
            newName = year + "-" + "01";
        }
        else if (dateBucketing === "Week") {
            var newDay = "01";
            if ((+day >= 7) && (+day < 14)) {
                newDay = "07";
            }
            else if ((+day >= 14) && (+day < 21)) {
                newDay = "14";
            }
            if ((+day >= 21) && (+day < 28)) {
                newDay = "21";
            }
            if ((+day >= 28)) {
                newDay = "28";
            }
            newHeader = year + '-' + month + '-' + newDay;
            newName = year + month + newDay;
        }
        else if (dateBucketing === "Day") {
            newHeader = year + '-' + month + '-' + day;
            newName = year + month + day;
        }
        else if (dateBucketing === "Quarter") {
            var newMonth = "03";
            if (+month > 3 && +month <= 6) {
                newMonth = "06";
            }
            if (+month > 6 && +month <= 9) {
                newMonth = "09";
            }
            if (+month > 9 && +month <= 12) {
                newMonth = "12";
            }
            newHeader = year + '-' + newMonth;
            newName = year + newMonth;
        }
        return [newName, newHeader];
    };
    DashboardService.prototype.getTooltip = function (type, aggregatedDimensionMethod, aggMethod, header, numericHeader, dateBucketing) {
        var tooltip = "";
        var lasttooltippart = " for each" + header;
        if (dateBucketing != "") {
            if (dateBucketing === "20last") {
                lasttooltippart = " for the last 20 " + header;
            }
            else if (dateBucketing === "month") {
                lasttooltippart = " for " + header + "grouped by month";
            }
        }
        if (type === "Click here to see") {
            if (aggregatedDimensionMethod === "count") {
                tooltip = type + " the number of records in each " + header;
            }
            else if ((aggMethod === 'count') || (!aggMethod)) {
                tooltip = type + " the number of " + header + " that have the same number of records.";
            }
        }
        else if (aggregatedDimensionMethod === "count") {
            tooltip = type + " the number of " + header + " that have the same number of records.";
        }
        else if ((aggMethod === 'count') || (!aggMethod)) {
            tooltip = type + " the number of records in each " + header;
        }
        else if (aggMethod === 'min') {
            tooltip = type + " the minimum " + numericHeader + lasttooltippart;
        }
        else if (aggMethod === 'max') {
            tooltip = type + " the maximum " + numericHeader + lasttooltippart;
        }
        else if (aggMethod === 'average') {
            tooltip = type + " the average " + numericHeader + lasttooltippart;
        }
        else if (aggMethod === 'sum') {
            tooltip = type + " the sum of all the " + numericHeader + lasttooltippart;
        }
        return tooltip;
    };
    DashboardService.prototype.returnLabels = function (xAxisLabel, yAxisLabel, header, aggMethod, aggregatedDimensionMethod, numericHeader, dateBucketing, dateHeader, xHeader, yHeader, type) {
        var label1 = "";
        var label2 = "";
        if ((xAxisLabel === "") || (!xAxisLabel)) {
            if (type === "bubble") {
                label1 = xHeader;
            }
            else if ((dateBucketing != "") && (dateBucketing)) {
                label1 = dateBucketing + " of " + dateHeader;
            }
            else if ((aggregatedDimensionMethod != "") || (aggMethod === "")) {
                label1 = "count";
            }
            else {
                label1 = aggMethod;
                if ((aggMethod != "") && (aggMethod != "count")) {
                    label1 = aggMethod + " of " + numericHeader;
                }
            }
        }
        else {
            label1 = xAxisLabel;
        }
        if ((dateBucketing != "") && (dateBucketing) && (yAxisLabel === "")) {
            label2 = aggMethod + " of " + numericHeader;
            if ((aggMethod === "") || (aggMethod === "count")) {
                label2 = "count";
            }
        }
        else if ((yAxisLabel === "") || (!yAxisLabel)) {
            label2 = header;
            if (type === "bubble") {
                label2 = yHeader;
            }
        }
        else {
            label2 = yAxisLabel;
        }
        if ((type === "line") && ((xAxisLabel === '') || (!xAxisLabel)) && ((yAxisLabel === "") || (!yAxisLabel))) {
            xAxisLabel = label2;
            yAxisLabel = label1;
        }
        else {
            xAxisLabel = label1;
            yAxisLabel = label2;
        }
        return [xAxisLabel, yAxisLabel];
    };
    DashboardService.prototype.expandChart = function (displayContextMenu, height, width, vis) {
        // this.margin.bottom = 100;
        displayContextMenu = false;
        height = window.innerHeight - 330;
        width = window.innerWidth - 200;
        if (vis.type === "treemap") {
            width = window.innerWidth - 300;
        }
        return [displayContextMenu, height, width, vis];
    };
    DashboardService.prototype.setSourceArray = function (source) {
        this.sourceArray = source;
    };
    DashboardService.prototype.getSourceArray = function () {
        return this.sourceArray;
    };
    DashboardService.prototype.timeFormat = function (dateFormat) {
        var timeFormat = "%d-%m-%Y";
        if (dateFormat === "YYYY") {
            timeFormat = "%Y";
        }
        else if (dateFormat === "YYYY-MM") {
            timeFormat = "%Y-%m";
        }
        else if (dateFormat === "YYYY-MM-DD") {
            timeFormat = "%Y-%m-%d";
        }
        else if (dateFormat === "YYYY-DD-MM") {
            timeFormat = "%Y-%d-%m";
        }
        else if (dateFormat === "DD-MM-YYYY") {
            timeFormat = "%d-%m-%Y";
        }
        else if (dateFormat === "MM-DD-YYYY") {
            timeFormat = "%m-%d-%Y";
        }
        else if (dateFormat === "month-YYYY") {
            timeFormat = "%b-%Y";
        }
        else if (dateFormat === "YYYY-month") {
            timeFormat = "%Y-%b";
        }
        else if (dateFormat === "YYYY-Q") {
            timeFormat = "%Y-Q%q";
        }
        else if (dateFormat === "Q-YYYY") {
            timeFormat = "Q%q-%Y";
        }
        return timeFormat;
    };
    DashboardService.prototype.changeNullValues = function (header, data) {
        for (var i in data) {
            if (header != "") {
                if ((data[i][header] === null) || (data[i][header] === 'Null')) {
                    if (header === "truestatus") {
                        data[i][header] = "Unknown";
                    }
                    else {
                        data[i][header] = "Null";
                    }
                }
                else if (data[i][header] == undefined) {
                }
                else if ((data[i][header] === "") || (data[i][header] === " ")) {
                    data[i][header] = "Unknown";
                }
                else if (typeof data[i][header] === 'string') {
                    if (data[i][header].indexOf(":") > -1) {
                        data[i][header] = data[i][header].replace(":", "-");
                    }
                }
            }
        }
        return data;
    };
    return DashboardService;
}());
export { DashboardService };
