import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpClientJsonpModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Injectable()
export class SourceManagementService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
        private sharedInitService: SharedInitializationService,
    ){}


    url= this.appService.getUrl();

    //update source
    //called in update-data-source component
    updateDataSource(source) {
        const url = this.url;
        const webservice = "AdminUpgradeWFAttrFromSource";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("srcName", source)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);

            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.appService.showMessage('Success', response.statusText);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }


       //update source
    //called in update-data-source component
    deleteDataSource(source) {
        const url = this.url;
        const webservice = "AdminDeleteDataSource";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("srcName", source)

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);

            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.sharedInitService.getAuthorizedSources("ALL");
                this.appService.showMessage('Success', response.statusText);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
  
    
}