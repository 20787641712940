import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpClientJsonpModule, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';
import { SharedInitializationService } from 'src/app/shared/shared-common-initialization.service';

@Injectable()
export class LogsService {

    constructor(
        private appService: AppService,
        private httpClient: HttpClient,
        private sharedService: SharedInitializationService,
    ){}


    url= this.appService.getUrl();

    logsFileChange = new Subject<any>();

    adminExportLogs(logFile, nbLines) {
        const url = this.url;
        const webservice = "AdminExportLogFile";
        const completeUrl = url+webservice;

        this.appService.startSpin();

        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("logFile",logFile)
        .set("logNbLines", nbLines);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
 
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                    this.logsFileChange.next(response.tableRows);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }

    adminDownloadLogs(logFile) {

        const url = this.url;
        const webservice = "AdminDownloadLogFile";
        const completeUrl = url + webservice;
    
        this.appService.startSpin();
        //Create new HttpParams */
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
          .set("logFile", logFile)
    
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    
        return this.httpClient.post(completeUrl, params, { headers, responseType: 'blob', withCredentials: true })
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response: any) => {
            console.log(response);
            let date=new Date().toLocaleDateString()
            let name = logFile + "_" + date;
            this.sharedService.downloadCSVFile(response, name);
            // window.open(downloadURL);

            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText);
            this.appService.stopSpin();
          });
      }
    
    
}