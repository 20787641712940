import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { TrueStatusTypeModel } from './true-status-type.model';
import { Subject } from 'rxjs';
import { WebserviceSetDeleteResponse } from 'src/app/shared/webservice-set-delete-response.model';
import { catchError } from 'rxjs/operators';
import { CustomHttpParamEncoder } from 'src/app/shared/custom-http-param-encoder';

@Injectable()
export class TrueStatusTypeService{
    constructor(private appService: AppService, 
                private httpClient: HttpClient){}
    
    url = this.appService.getUrl();

    trueStatusTypes: {truestatustype: string, prefix: string}[];
    trueStatusTypesChange= new Subject<{truestatustype: string, prefix: string}[]>();
    arrayObjects:{truestatustype: string, label:string, prefix: string, value:string}[];

    //O. Gets Admin True Status Type
    //called in true status type component

    getTrueStatusTypes() {
        const url = this.url;
        const webservice = "AdminGetTrueStatusType";
        const completeUrl = url+webservice;
        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
                this.trueStatusTypes = response.tableRows;
                this.trueStatusTypesChange.next(this.trueStatusTypes);
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }

      //Deletes the selected true status type.
    //called in true status type component
    updateTrueStatusTypes(allTrueStatusTypes: string) {
      const url = this.url;
      const webservice = "AdminUpdateAllTrueStatusTypes";
      const completeUrl = url+webservice;
      this.appService.startSpin();
      //Create new HttpParams
      let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set("allTrueStatusTypes",allTrueStatusTypes); 
      
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

      return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
        .pipe(
          catchError(this.appService.handleError)
        )
        .subscribe((response:any) => {
          console.log(response);
          //response = response.replace(/[()]/g, '');
          response= response.substring(1,response.length-1);
          response = JSON.parse(response);
          if (response.statusCode > -1 ) {
           this.appService.showMessage('Success', response.statusText)          
           this.getTrueStatusTypes();
          }
          else {
            this.appService.showMessage('Error', response.statusText)          
          }
          this.appService.stopSpin();
        }, (error) => {
          this.appService.showMessage('Error', error.statusText)          
          this.appService.stopSpin();
        });
  }
  // not used
    //Deletes the selected true status type.
    //called in true status type component
   /* deleteTrueStatusType(truestatustype: string, prefix: string) {
        const url = this.url;
        const webservice = "AdminDeleteTrueStatusType";
        const completeUrl = url+webservice;
        this.appService.startSpin();
        //Create new HttpParams
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("newtruestatustype",truestatustype)
        .set("newprefix", prefix); 
        
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
             this.appService.showMessage('Success', response.statusText)          
             this.getTrueStatusTypes();
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
    */
   //unused
    //Creates a true status type.
    // called in true status type component
  /*  addTrueStatusType(truestatustype: string, prefix: string) {
        const url = this.url;
        const webservice = "AdminSetTrueStatusType";
        const completeUrl = url+webservice;
        this.appService.startSpin();
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
        .set("newtruestatustype",truestatustype)
        .set("newprefix", prefix); //Create new HttpParams
        
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  
        return this.httpClient.post(completeUrl, params, {headers, responseType:'text', withCredentials:true})
          .pipe(
            catchError(this.appService.handleError)
          )
          .subscribe((response:any) => {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response= response.substring(1,response.length-1);
            response = JSON.parse(response);
            if (response.statusCode > -1 ) {
             this.appService.showMessage('Success', response.statusText)          
             this.getTrueStatusTypes();
            }
            else {
              this.appService.showMessage('Error', response.statusText)          
            }
            this.appService.stopSpin();
          }, (error) => {
            this.appService.showMessage('Error', error.statusText)          
            this.appService.stopSpin();
          });
    }
    */
    convertToPrimeArray(array: {truestatustype: string, prefix: string}[]) {  
        this.arrayObjects = [];
        for (var i in array) {
            if (array[i].truestatustype != "") {
                this.arrayObjects.push({value: array[i].truestatustype, label: array[i].truestatustype, truestatustype: array[i].truestatustype, prefix: array[i].prefix})
            }
           
        }
        return this.arrayObjects;
    }
    convertToAuditArray(array: {truestatustype: string, prefix: string}[]) {  
      var arrayObjects = [];
      for (var i in array) {
          if (array[i].truestatustype != "") {
              arrayObjects.push({value: array[i].truestatustype, label: array[i].truestatustype})
          }
         
      }
      return arrayObjects;
  }


    //return true status types
    returnTrueStatusTypes() {
      return this.trueStatusTypes;
    }
}