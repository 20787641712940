export class User {
    public username: string;
    public email: string;
    public lastConnection: string;

    constructor(username: string, email: string, lastConnection: string) {
        this.username = username;
        this.email = email;
        this.lastConnection = lastConnection;
    }
}