import { Injectable, ViewRef } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AppService } from '../../../../../app.service';

import { Router } from '@angular/router';
import * as d3 from 'd3';
import { DashboardService } from '../../../../dashboard.service';
import { SharedGeneralFunctionsService } from '../../../../../shared/shared-general-functions.service';


@Injectable()
export class DashboardTooltipContextMenuService {

    constructor(private dashboardService: DashboardService) { }

    // setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu);
    setContextMenu(vis, window, xPosition, yPosition, height, x, y, dataFiltered, xVariable, yVariable, filtervalue, displayContextMenu, mouseThis, d, event) {

        var windowWidth = window.outerWidth;
        var maxWidth = windowWidth * 3 / 4;

        if (event.pageX > maxWidth) {
            xPosition = event.pageX - 240;
        }
        else {
            xPosition = event.pageX + 50;
        }
        yPosition = event.pageY - 80;
        let index;
        let yValue;
        let header = vis.dashboardChartService.getHeader(vis)
        if (vis.type === "bar") {
            index = Math.floor((height - mouseThis) / y.step());
            yValue = vis.y.domain()[index];
        }
        else if (vis.type === "treemap") {
            yValue = d.data.key;
        }
        else if (vis.type === "line" && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            let lineData;
            let systems;
            let removeDates
            let datePeriod = vis.datePeriod;
            if (vis.datePeriodFixedOption === "Fixed Date") {
                datePeriod = vis.datePeriodFixedOption
            }
            [lineData, systems, removeDates] = this.dashboardService.formatDashboardData(vis.data, vis.header, "", "", vis.aggMethod, "col", vis.numericHeader, vis.dateBucketing, vis.numericDecimal, datePeriod, vis.datePeriodValue, vis.datePeriodFixedDate, vis.mainTab);
            yValue = d["Categorie"];
            if (vis.dateBucketing === "Quarter") {
                yValue = new Date(yValue)
            }
            for (var i in lineData) {
                let date = new Date(lineData[i][vis.xVariable])

                if (yValue.toDateString() === date.toDateString()) {

                    filtervalue = lineData[i]

                }
            }
        }
        else if (vis.type === "bubble" || vis.type === "line" || vis.type === "combo" || (vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis))) {
            yValue = d["Categorie"]
        }
        else if (vis.type === "pie") {
            yValue = d.data["Categorie"]
        }

        if ((yValue) && (yValue != 'Other')) {
            let header = vis.dashboardChartService.getHeader(vis);
            if (vis.type === "treemap" || vis.type === "combo" || vis.type === "pie" || vis.type === "bar" || vis.type === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                for (var i in dataFiltered) {
                    if (dataFiltered[i]["Categorie"].toString() === yValue.toString()) {
                        filtervalue = dataFiltered[i];
                    }
                }
            }
            else if (vis.type === "bubble") {
                filtervalue = d;
            }

            displayContextMenu = true;

        }

        if (event != null) {
            event.preventDefault();
        }


        return [xPosition, yPosition, filtervalue, displayContextMenu, yValue];
    }

    mousemoved(vis, position, pageX, pageY, d) {
        var index;

        var yValue;
        if ((vis.type === "line") || (vis.type === "combo")) {
            yValue = d["Categorie"]
        }
        else if ((vis.type === "bubble")) {
            yValue = d[0]["Categorie"]
        }
        else if (vis.type === "bar") {
            index = Math.floor((vis.height - position[1]) / vis.y.step());
            yValue = vis.y.domain()[index];
        }
        else if (vis.type === "pie") {
            yValue = d.data["Categorie"]
        }
        if (yValue) {
            let orientation = "left";
            let orientationPx = pageX + 3;
            let topPx = pageY - 50;

            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;

            if (windowWidth - orientationPx < 150) {
                orientation = "right";
                orientationPx = windowWidth - orientationPx + 20;
                topPx = pageY - 50;
            }


            vis.tooltip
                //.style(orientation, orientationPx + "px")
                .style("display", "inline-block")
                .style("z-index", 2200)
                .html(function () {

                    return vis.dashboardTooltipContextMenuService.setTooltip(vis.type, yValue, vis, d, position);


                })

            if (vis.tooltip.node()) {

                if (vis.tooltip.node().getClientRects()[0]) {
                    if (orientationPx + vis.tooltip.node().getClientRects()[0].width > windowWidth) {

                    }

                    if (windowWidth - orientationPx < vis.tooltip.node().getClientRects()[0].width + 50) {
                        orientation = "right";
                        orientationPx = windowWidth - pageX;
                    }

                    if (pageY + vis.tooltip.node().getClientRects()[0].height + 10 > windowHeight) {
                        topPx = windowHeight - 10 - vis.tooltip.node().getClientRects()[0].height
                    }

                    vis.tooltip
                        .attr("width", vis.tooltip.node().getClientRects()[0].width + 50 + 'px')
                }
            }
            vis.tooltip
                .style(orientation, orientationPx + "px")
                .style("top", topPx + "px")

            console.log(orientation);
            console.log(orientationPx);
            console.log(topPx)

            vis.cd.detectChanges();
            setTimeout(() => {
                console.log(vis.tooltip)
                return vis;
            }, 10)


            // console.log(vis.toolTip.getBoundingClientRect())
        }

        return vis;
    }

    setTooltip(param, yValue, vis, d, mouseThis) {

        //   vis.g.selectAll(".focus").remove()

        let header = vis.dashboardChartService.getHeader(vis)

        if (param === "bar" || param === "pie" || param === "line" && !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis) || vis.dateBucketing === 'Quarter') {
            var tableBodyCells = "";
            let yVariable = param === 'line' ? vis.xVariable : vis.yVariable;
            let xVariable = param === 'line' ? vis.yVariable : vis.xVariable;
            if (param === "bar" || param === "line") {
                color = vis.color
            }
            else {
                color = vis.colorScale(yValue)
            }

            if (param === "line") {
                //  x = vis.x(dDate[vis.xVariable]);
                vis.focus.style('display', null);
                var x = vis.x(d[vis.xVariable]);
                if (vis.dashboardInitTypesScalesService.getVariableTypes(vis.data, header, vis) === "notnumeric" || vis.dateBucketing === "Quarter") {
                    x = x + vis.x.bandwidth() / 2;
                }

                let xDomain = vis.x.domain();
                var y = vis.y(d[vis.yVariable]);

                vis = vis.dashboardTooltipContextMenuService.getFocusLine(x, xDomain, y, vis)
            }


            for (var i in vis.dataFiltered) {
                if (vis.dataFiltered[i][yVariable] === yValue) {
                    var keys = Object.keys(vis.dataFiltered[i])
                    for (var j in keys) {
                        var key = keys[j];
                        var name = '';
                        var value;
                        //  var color = '';

                        if (key === xVariable) {
                            name = vis.aggMethod;

                            if (name === "") {
                                name = "count"
                            }

                            if (vis.aggregatedDimensionMethod === "count") {
                                name = "count";
                            }

                            value = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(vis.dataFiltered[i][key])


                        }



                        if ((name != "") && (value != "") && (color != "")) {
                            tableBodyCells = tableBodyCells + "<div style='border-left: 3px solid " + color + ";padding-left:15px;height:28px'><div style='text-align:left; display:inline-flex; height:28px'><div style='color: #ffffff; padding:0px'><div style='position: relative; top:48%; transform: translateY(-50%)'>" + name + ": </div></div><div style='font-weight: bold; font-size:1rem'><div style='position: relative; top:48%; transform: translateY(-50%); padding-left:10px'>" + value + "</div></div></div></div>"
                            //  tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></td><td class='td2'>" + name + "</td><td class='td3'>" + value + "</td></tr>";
                        }
                    }

                }
            }
            if (vis.dateBucketing === "Quarter") {
                yValue = vis.dashboardAxisFormattingService.getTickFormat(yValue, vis);
            }
            return "<div style='border-radius: 10px; background-color: black; opacity:0.9; padding: 9px; color:" + color + "'><div class=''><h6>" + yValue + "</h6></div><div class=''>" + tableBodyCells + "</div>"
            // return "<table><tbody><tr><th colspan='3'>" + yValue + "</th></tr>" + tableBodyCells + "</tbody></table>"
        }
        else if (param === "bubble") {
            var tableBodyCells = "";
            let keys = ["xVariable", "yVariable", "zVariable"]
            for (var n in d) {
                tableBodyCells = tableBodyCells + "<div style='margin-top:20px;color:" + vis.colorScale(d[n]["Categorie"]) + "'><div class=''><h6 style='color:" + vis.colorScale(d[n]["Categorie"]) + "'>" + d[n]["Categorie"] + "</h6></div>";
                for (var j in keys) {
                    var key = keys[j];
                    var name = '';
                    var value;
                    var color = '';

                    if (key === "zVariable") {
                        name = vis.aggMethod;

                        if (name === "") {
                            name = "count"
                        }

                        if (vis.aggregatedDimensionMethod === "count") {
                            name = "count";
                        }

                        if (+d[n][vis.zVariable] != null) {

                            value = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(+d[n][vis.zVariable])
                        }
                        else {
                            value = d[n][vis.zVariable]
                        }

                        color = vis.colorScale(d[n]["Categorie"])
                    }
                    else {
                        name = vis[vis[key]];
                        if ((!isNaN(+d[n][vis[key]]) && (d[n][vis[key]] != null) && d[n][vis[key]] != "")) {

                            value = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(+d[n][vis[key]])
                        }
                        else {
                            value = d[n][vis[key]]
                        }

                        color = vis.colorScale(d[n]["Categorie"])
                    }


                    if ((name != "") && (value != "") && (color != "")) {
                        tableBodyCells = tableBodyCells + "<div style='border-left: 3px solid " + color + ";padding-left:15px;height:28px'><div style='text-align:left; display:inline-flex; height:28px'><div style='color: #ffffff; padding:0px'><div style='position: relative; top:48%; transform: translateY(-50%);color:" + color + "'>" + name + ": </div></div><div style='font-weight: bold; font-size:1rem'><div style='position: relative; top:48%; transform: translateY(-50%);padding-left:10px;color:" + color + "'>" + value + "</div></div></div></div>"
                        //tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></td><td class='td2'>" + name + "</td><td class='td3'>" + value + "</td></tr>";
                    }
                }

            }



            return "<div style='border-radius: 10px; background-color: black; opacity:0.9; padding: 9px;" + tableBodyCells + "</div>";
            // tableBodyCells;
            // return "<table><tbody><tr><th colspan='3'>" + d["Categorie"] + "</th></tr>" + tableBodyCells + "</tbody></table>"
        }
        else if (param === "line" && vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
            vis.focus.style('display', null);

            let header = vis.dashboardChartService.getHeader(vis);
            /*  if (vis.transformZoom) {
                x2 = vis.transformZoom.rescaleX(vis.x);
      
              } */

            var mouseDate = vis.x.invert(mouseThis[0]);

            let i = vis.bisectDate(vis.dataFiltered, mouseDate, 1); // returns the index to the current data ite

            var d0 = vis.dataFiltered[i - 1]
            var d1 = vis.dataFiltered[i];

            if ((d0 && d1) || (vis.dataFiltered.length === 1 && d0) || (vis.dataFiltered.length === 1 && d1) || !vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                var dDate;
                if (vis.dataFiltered.length === 1) {
                    dDate = vis.dataFiltered[0]
                }
                else {
                    dDate = mouseDate - d0[vis.xVariable] > d1[vis.xVariable] - mouseDate ? d1 : d0;
                }

                //  x = vis.x(dDate[vis.xVariable]);
                var x = vis.x(dDate[vis.xVariable])
                var xDomain = d3.extent(vis.dataFiltered, function (e) { return e[vis.xVariable]; })
                var y = vis.y(dDate[vis.yVariable]);

                vis = vis.dashboardTooltipContextMenuService.getFocusLine(x, xDomain, y, vis)


                var tableBodyCells = "";
                var sequences = "";
                var color = "";
                sequences = "";

                color = vis.color;
                let count = 0;

                let name = vis.yVariable;
                var value = d[name];



                if (!vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {
                    value = d[vis.yVariable];
                }

                value = vis.dashboardAxisFormattingService.getTickFormat(value, vis);
                /*if ((!isNaN(value)) && value != null) {
                    value = +value;
                    value = Intl.NumberFormat('en-US', { minimumFractionDigits: vis.numericDecimal, maximumFractionDigits: vis.numericDecimal }).format(value)

                } */

                if (vis.aggMethod === "") {
                    vis.aggMethod = "count"
                }

                // tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:10px; width:20px;background-color:" + color + "!important;'></div></td><td class='td2'>" + vis.aggMethod + "</td><td class='td3'>" + value + "</td></tr>";
                tableBodyCells = "<div style='border-left: 3px solid " + vis.color + ";padding-left:15px;height:28px'><div style='text-align:left; display:inline-flex; height:28px'><div style='color: #ffffff; padding:0px'><div style='position: relative; top:48%; transform: translateY(-50%)'>" + vis.aggMethod + ": </div></div><div style='font-weight: bold; font-size:1rem'><div style='position: relative; top:48%; transform: translateY(-50%)'>" + value + "</div></div></div></div>"
                tableBodyCells = tableBodyCells + sequences;

                let method = "";
                var date = d[vis.xVariable];
                date = vis.multiFormat(date);

                if (!vis.dashboardInitTypesScalesService.isDimensionDate(header, vis.data, vis)) {

                    date = d[vis.xVariable]
                }


                return "<div style='border-radius: 10px; min-width: fit-content; background-color: black; opacity:0.9; padding: 9px; color:" + vis.color + "'><div class=''><h6>" + date + "</h6></div><div class=''>" + tableBodyCells + "</div>"



            }
        }
        else if (param === "combo") {
            var tableBodyCells = "";
            vis.focus.style('display', null);
            for (var i in vis.dataFiltered) {
                if (vis.dataFiltered[i][vis.xVariable] === yValue) {
                    var keys = Object.keys(vis.dataFiltered[i]);
                    let comboKeyValues = [
                        { name: "aggMethod", aggHeader: vis.numericHeader, value: "col" },
                        { name: "aggMethodCombo", aggHeader: vis.comboHeader, value: "colCombo" }
                    ]
                    for (var j in comboKeyValues) {
                        let key = comboKeyValues[j].name;
                        let keyValue = comboKeyValues[j].value;
                        let aggHeader = comboKeyValues[j].aggHeader
                        let name = vis[key];
                        var value = vis.dataFiltered[i][keyValue];
                        value = vis.dashboardAxisFormattingService.getTickFormat(value, vis);
                        let color = vis.color;
                        if (vis.colorScheme[j]) {
                            color = vis.colorScheme[j];
                        }

                        let newname = name;
                        if (name != "count") {
                            newname = name + " of " + aggHeader;
                        }



                        if ((name != "") && (value != "") && (color != "")) {
                            tableBodyCells = tableBodyCells + "<div style='margin-top:10px;border-left: 3px solid" + color + ";padding-left:15px;height:28px'><div style='text-align:left; display:inline-flex; height:28px'><div style='color: #ffffff; padding:0px;' class='col-md-8'><div style='position: relative; top:48%; transform: translateY(-50%); white-space:nowrap'>" + newname + ": " + " " + "</div></div><div style='font-weight: bold; font-size:1rem' class='col-md-4'><div style='position: relative; top:48%; transform: translateY(-50%)'>" + " " + value + "</div></div></div></div>"
                            //  tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></td><td class='td2'>" + name + " of " + aggHeader + "</td><td class='td3'>" + value + "</td></tr>";
                        }

                        vis.focus.selectAll("circle").remove();
                        vis.focusCircle = "focusCircle";

                        vis.focus.append('circle')
                            .attr('id', 'focusCircle')
                            // .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
                            .attr('r', 8)
                            .attr('class', 'circle focusCircle')
                            .attr("fill", color)
                            .style("opacity", 0.5)
                            .attr('cx', vis.x(yValue) + vis.x.bandwidth() / 2)
                            .attr('cy', vis.y(vis.dataFiltered[i]["col"]));

                        // vis.g1.lower();

                    }



                }
            }
            return "<div style='border-radius: 10px; min-width: fit-content; background-color: black; opacity:0.8; padding: 9px; color:" + vis.color + "'><div class=''><h6>" + yValue + "</h6></div><div class=''>" + tableBodyCells + "</div>"
            //            return "<table><tbody><tr><th colspan='3'>" + yValue + "</th></tr>" + tableBodyCells + "</tbody></table>"
        }

        //return 

    }

    getFocusLine(x, xDomain, y, vis) {
        vis.focusLineY = "focusLineY";
        vis.focusCircle = "focusCircle";

        vis.focus.selectAll('circle').remove();
        vis.focus.selectAll('line').remove();

        vis.focus.append('line')
            .attr('id', 'focusLineX')
            //.attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr('class', 'focusLine')
            .style('fill', 'none')
            .style("stroke", vis.color)
            .style("opacity", 0.5)
            .style("stroke-width", "1px")
        vis.focus.append('line')
            .attr('id', 'focusLineY')
            /// .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr('class', 'focusLine')
            .style('fill', 'none')
            .style("stroke", vis.color)
            .style("opacity", 0.5)
            .style("stroke-width", "1px")

        vis.focus.append('circle')
            .attr('id', 'focusCircle')
            // .attr("transform", "translate(" + vis.margin.left + ", " + vis.margin.top + ")")
            .attr('r', 8)
            .attr('class', 'circle focusCircle')
            .attr("fill", vis.color)
            .style("opacity", 0.5)
            .attr('cx', x)
            .attr('cy', y);

        vis.focus.select('#focusLineX')
            .attr('x1', x).attr('y1', 0)
            .attr('x2', x).attr('y2', vis.height);

        /*    vis.focus.select('#' + vis.focusLineY)
            .attr('x1', function () {
                if (xDomain[0] < 0) {
                    return vis.x(xDomain[0])
                }
                else {
                    return 0;
                }

            })
            .attr('y1', y)
            .attr('x2', function () {
                return vis.x(xDomain[1])
            })
            .attr('y2', y);
*/
        return vis;
    }

    setChartTooltip(vis, type) {
        let param = "";
        if ((type === "bar")) {
            param = "rect";

            vis.svg.selectAll(".overlay").remove();

            vis[param] = vis.svg.append(param)
                .attr("transform", "translate(" + vis.margin.left + "," + vis.margin.top + ")")
                .attr("class", "overlay zoom")
                .attr("fill", "transparent")
                .attr("width", vis.width)
                .attr("height", function (d) { return vis.height })
            vis = this.setClickMousemoveContextMenu(vis, "rect")
        }
        else if (type === "bubble") {
            param = "bubblesCircles";
        }
        else if (type === "treemap") {
            param = "treemap";
        }
        else if (type === "line") {
            param = "dots";
        }
        else if (type === "combo") {
            param = "rects";
            vis = this.setClickMousemoveContextMenu(vis, "rects");
            vis = this.setClickMousemoveContextMenu(vis, "dots")
        }
        else if (type === "pie") {
            param = "pies"
        }

        if (type != "combo") {
            vis = this.setClickMousemoveContextMenu(vis, param)
        }

        return vis;
    }

    setClickMousemoveContextMenu(vis, param) {
        if (vis[param]) {
            vis[param]
                .on("mouseover", function (d) { vis.tooltip = d3.selectAll('body').append("div").attr("class", "toolTip") })
                .on("mouseout", function () {
                    d3.selectAll(".toolTip").remove()
                    if (vis.focus) {
                        vis.focus.style('display', "none");

                    }

                })
                .on("mouseenter", function (d) {
                    //  vis.mousemoved(vis, d3.mouse(this), d3.event.pageX, d3.event.pageY, d)
                })

                .on("mousemove", function (d) {
                    console.
                        log(d + "bubble now")
                    vis.dashboardTooltipContextMenuService.mousemoved(vis, d3.mouse(this), d3.event.pageX, d3.event.pageY, d)
                })
                .on("contextmenu", function (d) {
                    let yValue;
                    if (vis.view != 'sidebar') {
                        [vis.xPosition, vis.yPosition, vis.filtervalue, vis.displayContextMenu, yValue] = vis.dashboardTooltipContextMenuService.setContextMenu(vis, window, vis.xPosition, vis.yPosition, vis.height, vis.x, vis.y, vis.dataFiltered, vis.xVariable, vis.yVariable, vis.filtervalue, vis.displayContextMenu, d3.mouse(this)[1], d, d3.event);
                        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                            vis.cd.detectChanges();
                        }
                        let element = document.getElementsByClassName("contextMenu") as HTMLCollectionOf<HTMLElement>;
                        document.body.appendChild(element[0]);

                        // vis.expandedChartEmit.emit({ value: true });
                        if (vis.cd && !(vis.cd as ViewRef).destroyed) {
                            vis.cd.detectChanges();

                        }
                    }

                })
                .on("click", function (d, i) {
                    vis = vis.dashboardFilterChartService.setClickFilter(vis, d3.mouse(this)[1], d, i, d3.event)
                })
        }
        return vis;
    }
}