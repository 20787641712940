import * as d3 from 'd3';

export class StackedBarChart {
    public parentElement: string;
    public variable: string;
    public title: string;
    public height: number;
    public width: number;
    public domain: any;
    public xVariable: string;
    public color: any[];
    public lines: any[];
    public multiBarChartData: any[];

    margin: { left: number, right: number, top: number, bottom: number }
    svg;
    g;
    g1;
    t;
    colorScale;
    x;
    y;
    defs;
    yAxisCall;
    xAxisCall;
    xAxis;
    yAxis;
    dataFiltered;
    filtered;
    rects;
    slice;
    lineGroup;
    lineText;
    subgroups;
    max;
    zoom;
    rect;

    constructor(
        parentElement: string,
        variable: string,
        title: string,
        height: number,
        width: number,
        xVariable: string,
        color: any,
        lines: any[],
        multiBarChartData: any[],

    ) {
        this.parentElement = parentElement;
        this.variable = variable;
        this.title = title;
        this.height = height;
        this.width = width;
        this.xVariable = xVariable;
        this.color = color;
        this.lines = lines;
        this.multiBarChartData = multiBarChartData;


    }

    initVis() {
        this.margin = { left: 70, right: 100, top: 10, bottom: 60 };
        this.height = this.height - this.margin.top - this.margin.bottom;
        this.width = this.width - this.margin.left - this.margin.right;
        var id = d3.select(this.parentElement);
        id.selectAll('svg').remove();

        this.svg = d3.select(this.parentElement)
            .append("svg")
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom);

        this.g = this.svg.append("g")
            .attr("transform", "translate(" + this.margin.left +
                ", " + this.margin.top + ")")
            .attr('clip-path', 'url(#clipBody)');

        this.g1 = this.svg.append("g")
            .attr("transform", "translate(" + this.margin.left +
                ", " + this.margin.top + ")")
            .attr('clip-path', 'url(#clipBody)')

        //init transition
        this.t = () => { return d3.transition().duration(1000); }

        //init color scale
        this.colorScale = d3.scaleOrdinal()
            .range(this.color);

        //init first x scale
        this.x = d3.scaleBand()
            .range([0, this.width])
            .paddingInner(0.1);


        this.y = d3.scaleLinear().range([this.height, 0]);

        //init multi bar charts container
        this.g = this.svg.append("g")
            .attr("transform", "translate(" + (this.margin.left) +
                ", " + this.margin.top + ")");

        this.defs = this.g.append('defs');

        this.defs
            .append('svg:clipPath')
            .attr('id', 'clipBody')
            .append('svg:rect')
            .attr("class", "clipBody")
            .attr('x', 2)
            .attr('y', 0)
            .attr('width', this.width)
            .attr('height', this.height);

        this.defs
            .append('svg:clipPath')
            .attr('id', 'clipAxis')
            .append('svg:rect')
            .attr("class", "clipAxis")
            .attr('x', 0)
            .attr('y', this.height)
            .attr('width', this.width)
            .attr('height', this.margin.bottom);

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        //format axis
        this.yAxisCall = d3.axisLeft()
            .ticks(4);

        this.xAxisCall = d3.axisBottom()
            .tickFormat(function (d) {
                if (typeof d === 'string') {
                    return "" + capitalizeFirstLetter(d);
                }
                else {
                    return d.toFixed(2);
                }
            });

        this.xAxis = this.g.append("g")
            .attr('clip-path', 'url(#clipAxis)')
            .append("g")
            .attr("id", "xAxis")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + this.height + ")")
            .attr("dx", "0.71em")

      

        this.yAxis = this.g.append("g")
            .attr("class", "y axis");


        //chart title
        this.g.append("text")
            .attr("class", "title")
            .attr("y", -15)
            .attr("x", -50)
            .attr("font-size", "12px")
            .attr("text-anchor", "start")
            .text(this.title)


        this.wrangleData(this.multiBarChartData);
    }

    wrangleData(multiBarChartData) {
        var vis = this;
        //   vis.dataFiltered = multiBarChartData;
        let keys= [];
        if (multiBarChartData[0]) {
            keys = Object.keys(multiBarChartData[0])
            keys.splice(keys.indexOf("Categorie"),1)
        }
        vis.subgroups = keys;
        vis.multiBarChartData = multiBarChartData;
        vis.dataFiltered = d3.stack()
            .keys(vis.subgroups)
            (multiBarChartData)
        console.log(vis.dataFiltered)

        
        vis.max = 0;

        for (var i in multiBarChartData) {
            let keys = Object.keys(multiBarChartData[i]);
            let numberAnomalies = 0;
            for (var j in keys) {
                if (keys[j] != this.xVariable) {
                    numberAnomalies = numberAnomalies+ multiBarChartData[i][keys[j]]
                }
            }

            if (numberAnomalies > vis.max) {
                vis.max = numberAnomalies;
            }
        }
        console.log(vis.max)

        vis.domain = d3.map(multiBarChartData, function (d) { return (d.Categorie) }).keys()

        console.log(vis.domain)

        vis.updateVis();
    }

    updateVis() {
        var vis = this;
        var variable = vis.xVariable;


        //get keys
        var keys = [];

        if (vis.dataFiltered.length != 0) {
            vis.x.domain(vis.domain);
            vis.y.domain([0, vis.max]);
            console.log(d3.max(vis.dataFiltered, function (item) { return d3.max(item.values, function (d) { return d.value; }); }))

        }
        else {
            vis.x.domain([0, 1]);
            vis.y.domain([0, 1])
        }


        // Update axes
        vis.xAxisCall.scale(vis.x);
        vis.xAxis.transition(vis.t()).call(vis.xAxisCall);
        vis.yAxisCall.scale(vis.y);
        vis.yAxis.transition(vis.t()).call(vis.yAxisCall);

        vis.g1.selectAll('.barGroup'+vis.parentElement.substring(1)).remove();

        vis.zoom = d3.zoom()
            .scaleExtent([1, 3])
            .translateExtent([[0, 0], [vis.width, vis.height]])
            .extent([[0, 0], [vis.width, vis.height]])
            .on("zoom", zoomed.bind(this))
           // .transition()
           vis.redrawBars();
           vis.redrawLines();
        d3.selectAll(".legendClass").remove();

        var legend = vis.g.append("g")
            .attr("class", "legendClass")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("text-anchor", "start")
            .attr("transform", function () { return "translate(20,0)"; })
            .selectAll("g")
            .data(vis.subgroups)
            .enter().append("g")
            .attr("transform", function (d, i) { return "translate(0," + i * 20 + ")"; });

        legend.append("rect")
            .attr("x", vis.width)
            .attr("width", 17)
            .attr("height", 17)
            .attr("fill", vis.colorScale)
            .attr("stroke", vis.colorScale)
            .attr("stroke-width", 2)
        //   .on("click",function(d) { update(d) });

        legend.append("text")
            .attr("x", vis.width + 21)
            .attr("y", 9.5)
            .attr("dy", "0.32em")
            .attr("fill", "currentColor")
            .text(function (d) { return d; });

        vis.svg
            .call(vis.zoom)
            .on("dblclick.zoom", null)


        function zoomed() {
            var zoomEvent = d3.event.transform;

            /*  vis.g1.selectAll("rect")
                 .attr("transform", t); */

            vis.x.range([0, vis.width * zoomEvent.k]);
            vis.xAxisCall.scale(vis.x);
            vis.xAxis
                .attr("transform", "translate(" + zoomEvent.x + "," + (vis.height) + ")")
                .call(vis.xAxisCall);

            vis.rects
            .attr("class", "update")
                .attr("x", function (d) { return vis.x(d.data.Categorie) + zoomEvent.x; })
                .attr("width", vis.x.bandwidth())
         


            console.log(vis.y(4));



        }

    }


    redrawBars() {
        var vis = this;
        d3.selectAll(".toolTip").remove()

        // color palette = one color per subgroup
        var color = d3.scaleOrdinal()
            .domain(vis.subgroups)
            .range(['#e41a1c', '#377eb8', '#4daf4a'])
    
            this.xAxis
            .selectAll("text")	
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-20)");

        vis.rects;

        if (vis.rects) {
            d3.selectAll(".barGroup"+vis.parentElement.substring(1)).remove();

        }
      
       console.log(vis.dataFiltered)
       var tooltip;

        vis.rects = vis.svg.append("g")
            .selectAll("g")
            .data(vis.dataFiltered)
            .enter().append("g")
            .attr("class", "barGroup"+vis.parentElement.substring(1))
            .attr("transform", "translate(" + this.margin.left +
                ", " + this.margin.top + ")")
            .attr('clip-path', 'url(#clipBody)')
            .attr("fill", function (d) { return vis.colorScale(d.key); })
            .selectAll("rect")
            // enter a second time = loop subgroup per subgroup to add all rectangles
            .data(function (d) { return d; })
            .enter().append("rect")
            .attr('height', 0)
            .attr('y', vis.height) 
            .attr("x", function (d) { return vis.x(d.data.Categorie); })
            .attr("width", vis.x.bandwidth())
            .on("mousemove", function (d) {
                
                tooltip
                    //   .attr("transform", "translate(" + (vis.x1(d.name)   + 55  ) + ",0)")    
                    .style("left", d3.event.pageX + 20 + "px")
                    .style("top", d3.event.pageY - 50 + "px")
                    .style("display", "inline-block")
                    .style("z-index", 2200)
                    .html(function () {
                        var tableBodyCells = "";

                        let totalValue=0;
                        for (var i in vis.subgroups) {
                            var name = vis.subgroups[i];
                            var value = d.data[vis.subgroups[i]];
                            var color = vis.colorScale(name).toString();
                            tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></td><td class='td2'>" + name + "</td><td class='td3'>" + value  + "</td></tr>";
                            totalValue = totalValue +value;
                        }
                        tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + "grey" + "!important;'></div></td><td class='td2'>" +"Total" + "</td><td class='td3'>" + totalValue  + "</td></tr>";
       
                        return "<table><tbody><tr><th colspan='3'>" + d.data.Categorie + "</th></tr>" + tableBodyCells + "</tbody></table>"
                    });
            })
            .on("mouseover", function () { 
            })
            .on("mouseout", function (d) {d3.selectAll(".toolTip").remove(); 
             })
         //   .transition(vis.t())
           // .duration(500)
            .attr("x", function (d) { return vis.x(d.data.Categorie); })
            .attr("width", vis.x.bandwidth())
            .attr("y", function (d) { return vis.y(d[1]); })
            .attr("height", function (d) { return vis.y(d[0]) - vis.y(d[1]); })

              //append zoom to rectangle that has the same dimensions as svg
              vis.rect = vis.svg.append("rect")
              .attr("transform", "translate(" + vis.margin.left + "," + vis.margin.top + ")")
              .attr("class", "overlay zoom")
              .attr("fill", "transparent")
              .attr("width", vis.width)
              .attr("height", vis.height)
              .on("mouseover", function () {    tooltip = d3.select('body').append("div").attr("class", "toolTip");
             })
              .on("mouseout", function () {    d3.selectAll(".toolTip").remove();  
             })
              .on("mousemove", mousemove)
              .call(vis.zoom)
              .on("dblclick.zoom", null)
  
  
  
  
          function mousemove() {
         
              var transform = d3.zoomTransform(this);
              console.log(transform)
  
                  vis.x.range([0, vis.width * transform.k]);
                  //rescale x axis because of zoom
                  vis.xAxisCall.scale(vis.x);
  
                  var index = Math.floor((Math.abs(transform.x / vis.x.step())) + d3.mouse(this)[0] / vis.x.step())
                  let pagedata = true;
                  
                  var xValue = vis.x.domain()[index];
                  let d;
                  let dataType = "pageData";
                 
                  for (var k in vis.multiBarChartData) {
                      if (vis.multiBarChartData[k][vis.xVariable] === xValue) {
                       
                              d = vis.multiBarChartData[k];
                      }
                  }

                 
                  tooltip
                  .style("left", d3.event.pageX + 20 + "px")
                  .style("top", d3.event.pageY - 80 + "px")
                  .style("display", "inline-block")
                  .style("z-index", 2200)
                  .html(function () {
                      if (d) {
                      var tableBodyCells = "";

                      let totalValue = 0;
                      for (var i in vis.subgroups) {
                          var name = vis.subgroups[i];
                          var value = d[vis.subgroups[i]];
                          var color = vis.colorScale(name).toString();
                          if (value) {
                              tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + color + "!important;'></div></td><td class='td2'>" + name + "</td><td class='td3'>" + value + "</td></tr>";
                              totalValue = totalValue + value;
                          }

                      }
                      tableBodyCells = tableBodyCells + "<tr><td class='td1'><div style='height:20px; width:20px;background-color:" + "grey" + "!important;'></div></td><td class='td2'>" + "Total" + "</td><td class='td3'>" + totalValue + "</td></tr>";

                      return "<table><tbody><tr><th colspan='3'>" + d.Categorie + "</th></tr>" + tableBodyCells + "</tbody></table>"
                  }});

          }

    }

    redrawLines() {
        var vis = this;
        if ((!vis.lines) || (vis.lines.length === 0)) {

            if (vis.lineGroup) {
                vis.lineGroup.exit().remove();
            }

        }
        d3.selectAll(".lineGroup").remove();
        d3.selectAll(".lineText").remove();

        for (var i in vis.lines) {
            if (vis.lineGroup) {
                vis.lineGroup.exit().remove();
            }
            //vis.lineGroup.exit().remove();
            if (vis.lines[i].value != 0) {

                vis.lineGroup = vis.g1.append('line')
                    //.attr("transform", "translate(" + vis.margin.left + 
                    //  ", " + vis.margin.top + ")")
                    .attr("class", "lineGroup")
                    .style('stroke', vis.lines[i].color)
                    .style('stroke-width', '2px')
                    .attr('x1', 0)
                    .attr('y1', vis.y(vis.lines[i].value))
                    .attr('x2', vis.width)
                    .attr('y2', vis.y(vis.lines[i].value))

                vis.lineText = vis.g1.append("g")
                    .attr("class", "lineText")
                vis.lineText
                    .append('text')
                    .text(vis.lines[i].title)
                    .style('fill', vis.lines[i].color)
                    .attr('x', 3)
                    .attr('y', vis.y(vis.lines[i].value) - 5)
            }
        }


    }

    redrawChart(width, height, margin) {
        var vis = this;
        vis.margin = margin;
        //change heights and widths of groups whend expanding/collapsing chart
      //  vis.margin = margin;
        vis.height = height - vis.margin.top - vis.margin.bottom;
        vis.width = width - vis.margin.left - vis.margin.right;

        vis.svg
            .attr("width", vis.width + vis.margin.left + vis.margin.right)
            .attr("height", vis.height + vis.margin.top + vis.margin.bottom);

        vis.g
            .attr("transform", "translate(" + vis.margin.left +
                ", " + vis.margin.top + ")");


        vis.g1
            .attr("transform", "translate(" + vis.margin.left +
                ", " + vis.margin.top + ")");

        vis.x
            .range([0, vis.width])

        vis.y.range([vis.height, 0]);

        vis.defs.selectAll(".clipBody")
            // .attr("id", "clip")
            .attr('x', 2)
            .attr('y', 0)
            .attr('width', vis.width)
            .attr('height', vis.height)

        vis.defs.selectAll(".clipAxis")
            .attr('x', 0)
            .attr('y', vis.height)
            .attr('width', vis.width)
            .attr('height', vis.margin.bottom)

        vis.xAxis
            .attr("transform", "translate(0," + vis.height + ")")


        vis.updateVis();

    }
}