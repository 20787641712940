import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject, interval } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from './user.model';
import { AppService } from '../app.service';
import { catchError } from 'rxjs/operators';
import { SharedInitializationService } from '../shared/shared-common-initialization.service';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { Subscription } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../app.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../shared/shared-common-initialization.service";
import * as i4 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(appService, httpClient, sharedService, 
    //private logoutTimeService: LogoutTimeService,
    router, route) {
        this.appService = appService;
        this.httpClient = httpClient;
        this.sharedService = sharedService;
        this.router = router;
        this.route = route;
        //CHECK WHETHER USER IS LOGGED IN OR NOT. THERE IS A SUBJECT TOO SO THAT IT CAN NOTIFY CHANGES TO THE HEADER COMPONENT BECAUSE WE USE A NG IF
        // TO DISPLAY THE HEADER MENU
        this.isAuthenticated = false;
        this.isAuthenticatedChange = new Subject();
        //if user has to change password
        this.changePasswordChange = new Subject();
        this.setUserDefaultSubscription = new Subscription;
        this.loggedInUserSubscription = new Subscription;
        this.logoutTimeSubscription = new Subscription;
        this.userConnectedChange = new Subject();
        this.tabSelectedChange = new Subject();
        this.canNewWorkflow = false;
        this.user = [{
                username: "",
                email: "",
                lastConnection: ""
            }];
        this.theme = "";
        this.url = this.appService.getUrl();
        //  GET AUTHORIZED GUITABS + UPDATE THEM IF CHANGED IN ADMIN
        this.guiTabsArray = [];
    }
    ;
    AuthService.prototype.isLoggedin = function () {
        console.log(this.isAuthenticated);
        return this.isAuthenticated;
    };
    AuthService.prototype.isUserStillLoggedIn = function () {
        var _this = this;
        var url = this.url;
        var webservice = "AccessCheckForLogout";
        var completeUrl = url + webservice;
        //create new http params
        var params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            // console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                _this.isAuthenticated = true;
            }
            else {
                _this.closeNavigationWindows();
                _this.isAuthenticated = false;
                _this.isAuthenticatedChange.next(_this.isAuthenticated);
                _this.loggedInUserSubscription.unsubscribe();
                _this.setUserDefaultSubscription.unsubscribe();
                _this.router.navigate(['/login']);
                _this.isAuthenticatedChange.next(_this.isAuthenticated);
                _this.appService.showMessage('Error', response.statusText);
                window.location.reload();
            }
        }, function (error) {
            console.log(error);
            _this.router.navigate(['/login']);
            window.location.reload();
            _this.appService.showMessage('Error', error.statusText);
            return false;
        });
    };
    AuthService.prototype.isNotLoggedIn = function () {
    };
    AuthService.prototype.changeAuthentication = function () {
        // this.isAuthenticated = !this.isAuthenticated;
        this.isAuthenticatedChange.next(this.isAuthenticated);
    };
    // SERVICES THAT ALLOW THE LOGIN OF THE USER AS WELL AS THE LOGOUT
    AuthService.prototype.loginUser = function (value) {
        var _this = this;
        console.log(value);
        var url = this.url;
        var webservice = "AccessLogin";
        var completeUrl = url + webservice;
        // const params2 = {"userEmailorName":value.username, "password": value.password}
        var params2 = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("password", value.password)
            .set("userEmailorName", value.username); //Create new HttpParams
        var params = params2.toString();
        console.log(params);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params2, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if (response.statusCode > -1) {
                if ((response.tableRows[0].passwordExpired === true) || (response.tableRows[0].temporaryPassword)) {
                    _this.changePasswordChange.next(true);
                }
                else {
                    _this.isAuthenticated = true;
                    _this.isAuthenticatedChange.next(_this.isAuthenticated);
                    _this.guiTabsArray = response.tableRows[0].userGuiTabs;
                    if (response.tableRows[0].canNewWorkflow) {
                        _this.canNewWorkflow = response.tableRows[0].canNewWorkflow;
                    }
                    if (response.tableRows[0].favoriteWF) {
                        _this.appService.setWorkflow(response.tableRows[0].favoriteWF);
                    }
                    if (response.tableRows[0].favoriteTable) {
                        _this.appService.setUserTable(response.tableRows[0].favoriteTable);
                    }
                    if (response.tableRows[0].favoriteSubTable) {
                        _this.appService.setUserSubtable(response.tableRows[0].favoriteSubTable);
                    }
                    if (response.tableRows[0].workflowType) {
                        _this.appService.setType(response.tableRows[0].workflowType);
                    }
                    if (response.tableRows[0].lastPage) {
                        _this.lastPage = response.tableRows[0].lastPage.toLowerCase();
                        _this.lastPage = _this.lastPage.charAt(0).toUpperCase() + _this.lastPage.slice(1);
                    }
                    if (response.tableRows[0].LastConnection) {
                        _this.LastConnection = response.tableRows[0].LastConnection;
                    }
                    if (response.tableRows[0].favoriteSegAttribute) {
                        _this.appService.setSegAttribute(response.tableRows[0].favoriteSegAttribute);
                    }
                    if (response.tableRows[0].favoriteSource) {
                        _this.appService.setSource(response.tableRows[0].favoriteSource);
                    }
                    if (response.tableRows[0].favoriteTheme) {
                        _this.theme = response.tableRows[0].favoriteTheme;
                        _this.appService.setTheme(_this.theme);
                    }
                    if (response.tableRows[0].favoriteLoadRecordNumber) {
                        var number = +response.tableRows[0].favoriteLoadRecordNumber;
                        _this.appService.setLoadRecordNumber(number);
                    }
                    if (response.tableRows[0].auditFiles) {
                        var auditFiles = JSON.parse(response.tableRows[0].auditFiles);
                        _this.appService.setAuditFiles(auditFiles);
                    }
                    _this.userName = response.tableRows[0].userName;
                    _this.userEmail = response.tableRows[0].userEmail;
                    _this.user = [];
                    _this.user.push(new User(_this.userName, _this.userEmail, _this.LastConnection));
                    _this.userConnectedChange.next(_this.user);
                    console.log(_this.guiTabsArray);
                    console.log(_this.lastPage);
                    _this.sharedService.getUsers();
                    _this.sharedService.getTopicData();
                    if (_this.guiTabsArray.length === 0) {
                        _this.appService.showMessage('Error', "You are not authorized to access the application. Please contact your admin.");
                    }
                    else {
                        _this.tabSelectedChange.next('topics');
                        _this.router.navigate(['/topics']);
                    }
                    /* else if (this.lastPage === "") {
                         this.tabSelectedChange.next(this.guiTabsArray[0].toLowerCase())
                         this.router.navigate(['/' + this.guiTabsArray[0].toLowerCase()]);
                        
                     }
                     else if (this.guiTabsArray.indexOf(this.lastPage) > -1) {
                         this.tabSelectedChange.next(this.lastPage.toLowerCase())
                         this.router.navigate(['/' + this.lastPage.toLowerCase()]);
                     }
                     else {
                         this.tabSelectedChange.next(this.guiTabsArray[0].toLowerCase())
                         this.router.navigate(['/' + this.guiTabsArray[0].toLowerCase()])
                     } */
                    if (response.tableRows[0].displayLoginMessage) {
                        if (response.tableRows[0].displayLoginMessage.toLowerCase() === "true") {
                            if (response.tableRows[0].loginMessage) {
                                if (response.tableRows[0].loginMessage != '') {
                                    _this.appService.showMessage("Warning", response.tableRows[0].loginMessage);
                                }
                            }
                        }
                    }
                    _this.loggedInUserSubscription = interval(10000).subscribe(function (x) {
                        _this.isUserStillLoggedIn();
                    });
                    _this.setUserDefaultSubscription = interval(30000).subscribe(function (x) {
                        _this.logoutUser(false);
                    });
                }
            }
            else {
                _this.isAuthenticated = false;
                _this.isAuthenticatedChange.next(_this.isAuthenticated);
                _this.appService.showMessage('Error', response.statusText);
            }
        }, function (error) {
            _this.isAuthenticated = false;
            _this.isAuthenticatedChange.next(_this.isAuthenticated);
            _this.appService.showMessage('Error', error.statusText);
        });
    };
    AuthService.prototype.logoutUser = function (logout) {
        var _this = this;
        console.log("setuserdefaultttttttttttttttttttttt");
        var url = this.url;
        var webservice = "WFSetUserDefault";
        var completeUrl = url + webservice;
        if (logout === true) {
            this.closeNavigationWindows();
            this.isAuthenticated = false;
            this.isAuthenticatedChange.next(this.isAuthenticated);
            this.guiTabsArray = [];
            this.setUserDefaultSubscription.unsubscribe();
            this.loggedInUserSubscription.unsubscribe();
        }
        //  this.user = [];
        var path = this.router.url;
        var tab = this.router.url.split('/')[1];
        tab = tab.charAt(0).toUpperCase() +
            tab.slice(1);
        /*
                if (tab === "Dashboard") {
                    tab = "DashBoard"
                }*/
        //   console.log(this.route.snapshot);
        var workflow = this.appService.getWorkflow();
        var table = this.appService.getTable();
        var subtable = this.appService.getSubtable();
        var segAttribute = this.appService.getSegAttribute();
        var type = this.appService.getType();
        var source = this.appService.getSource();
        var theme = this.appService.getTheme();
        var numberRecords = JSON.stringify(this.appService.getLoadRecordNumber());
        //create new http params
        var params2 = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userEmail", this.userEmail)
            .set("lastPage", tab)
            .set("wfNameDef", workflow)
            .set("wfTableDef", table)
            .set("wfSubTableDef", subtable)
            .set("wfTypeDef", type)
            .set("wfSegAttributeDef", segAttribute)
            .set("wfSource", source)
            .set("theme", theme)
            .set("numberRecords", numberRecords);
        var headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.httpClient.post(completeUrl, params2, { headers: headers, responseType: 'text', withCredentials: true })
            .pipe(
        //map(response => new NmUser(response)),
        catchError(this.appService.handleError))
            .subscribe(function (response) {
            //  console.log(response);
            //response = response.replace(/[()]/g, '');
            response = response.substring(1, response.length - 1);
            response = JSON.parse(response);
            if ((response.statusCode > -1) && (logout === true)) {
                var url_1 = _this.url;
                var webservice_1 = "AccessLogout";
                var completeUrl_1 = url_1 + webservice_1;
                _this.user = [];
                //create new http params
                var params2_1 = new HttpParams({ encoder: new CustomHttpParamEncoder() });
                var headers_1 = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
                return _this.httpClient.post(completeUrl_1, params2_1, { headers: headers_1, responseType: 'text', withCredentials: true })
                    .pipe(
                //map(response => new NmUser(response)),
                catchError(_this.appService.handleError))
                    .subscribe(function (response) {
                    console.log(response);
                    //response = response.replace(/[()]/g, '');
                    response = response.substring(1, response.length - 1);
                    response = JSON.parse(response);
                    if (response.statusCode > -1) {
                        // this.router.navigate(['/login']);
                        window.location.reload();
                    }
                    else {
                        //this.router.navigate(['/login']);
                        window.location.reload();
                        _this.appService.showMessage('Error', response.statusText);
                    }
                }, function (error) {
                    // this.router.navigate(['/login']);
                    window.location.reload();
                    _this.appService.showMessage('Error', error.statusText);
                });
            }
            else if (response.statusCode === -1) {
                if (logout === true) {
                    //this.router.navigate(['/login']);
                    window.location.reload();
                }
                _this.appService.showMessage('Error', response.statusText);
            }
        }, function (error) {
            //  this.router.navigate(['/login']);
            window.location.reload();
            _this.appService.showMessage('Error', error.statusText);
        });
    };
    AuthService.prototype.getGuiTabs = function () {
        return this.guiTabsArray;
    };
    AuthService.prototype.updateGuiTabs = function (guitabs) {
        this.guiTabsArray = guitabs;
    };
    AuthService.prototype.getUser = function () {
        return this.user;
    };
    AuthService.prototype.getUsername = function () {
        var username = "";
        if (this.user) {
            if (this.user[0]) {
                username = this.user[0].username;
            }
        }
        return username;
    };
    AuthService.prototype.getCanNewWorkflow = function () {
        return this.canNewWorkflow;
    };
    //this is to close all navigation windows when user is logged out
    AuthService.prototype.closeNavigationWindows = function () {
        var navigationWindows = this.appService.returnNavigationWindows();
        for (var i in navigationWindows) {
            //  console.log(this[this.navigationWindows[i]])
            if (window.open(navigationWindows[i].url, navigationWindows[i].name, "")) {
                window.open(navigationWindows[i].url, navigationWindows[i].name, "").close();
            }
        }
    };
    AuthService.prototype.selectNewTab = function (tab) {
        this.tabSelectedChange.next(tab);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.AppService), i0.inject(i2.HttpClient), i0.inject(i3.SharedInitializationService), i0.inject(i4.Router), i0.inject(i4.ActivatedRoute)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
