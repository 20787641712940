import { Injectable } from '@angular/core';
import { AppService } from '../app.service';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { CustomHttpParamEncoder } from '../shared/custom-http-param-encoder';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UserSettingsService {
    constructor(private appService: AppService,
        private httpClient: HttpClient,
    ) { }

    url = this.appService.getUrl();

    //update the user password data from admin role
    //called in change password component
    updateUserPassword(username, newpassword, oldpassword) {
        this.appService.startSpin();
        const url = this.url;
        const webservice = "UserAccessUpdatePassword";
        const completeUrl = url + webservice;

        //create new http params
        let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
            .set("userEmailorName", username)
            .set("newuserPassword", newpassword)
            .set("olduserPassword", oldpassword)


        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        return this.httpClient.post(completeUrl, params, { headers, responseType: 'text', withCredentials: true })
            .pipe(
                //map(response => new NmUser(response)),
                catchError(this.appService.handleError)
            )
            .subscribe((response: any) => {

                //response = response.replace(/[()]/g, '');
                response= response.substring(1,response.length-1);
                response = JSON.parse(response);
                if (response.statusCode > -1) {
                    this.appService.showMessage('Success', response.statusText)

                }
                else {
                    this.appService.showMessage('Error', response.statusText)
                }
                this.appService.stopSpin();
            }, (error) => {
                this.appService.showMessage('Error', error.statusText);
                this.appService.stopSpin();
            });


    }
}